import Button from '../../../atom/button.jsx';
import React, { useState } from 'react';
import Photos from '../../dashBoard/myPhotos.jsx';

const Step6 = ({ prevStep, handleSubmitForm,userProfile }) => {
    const [error, setError] = useState('');
 
    const handleCheck = () => {
        if (userProfile?.photos && userProfile.photos.length >= 1) {
            handleSubmitForm(userProfile);
        } else {
            setError('At least one image is required');
        }
    };

    return (
        <>
            <Photos 
                userDetails={userProfile}
                isStep={true}
                errors={error}
                setError={() => setError('')}
            />
            <div className='lg:col-span-2 lg:mt-[25px] lg:mb-[11px] mt-4 flex md:justify-between justify-end z-10'>
                <Button
                    onClick={prevStep} 
                    buttonName={'Previous'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px] md:mr-0 mr-[10px]'}
                />
                <Button
                    onClick={handleCheck} 
                    buttonName={'Complete'}
                    class={'block leading-[normal] bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] ms:px-[30px] px-[15px]'}
                />
            </div>
        </>
    );
};

export default Step6;