import './index.css';
const allCountries = [
    {
        "ru": "Афганистан",
        "lt": "Afganistanas",
        "tr": "Afganistan",
        "en": "Afghanistan",
        "flag": "🇦🇫",
        "code": "AF",
        "dialCode": "+93",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+93",
        "key": 1,
        "dialCodeWithOutPlusSign": "93",
        "countryJoin": "Afghanistan"
    },
    {
        "ru": "Аландские острова",
        "lt": "Alandų salos",
        "tr": "Aland adaları",
        "en": "Åland Islands",
        "flag": "🇦🇽",
        "code": "AX",
        "dialCode": "+358",
        "mask": "999 9999999",
        "maskWithDialCode": "### #######",
        "name": "+358",
        "key": 2,
        "dialCodeWithOutPlusSign": "358",
        "countryJoin": "Åland Islands / Finland"
    },
    {
        "ru": "Албания",
        "lt": "Albanija",
        "tr": "Arnavutluk",
        "en": "Albania",
        "flag": "🇦🇱",
        "code": "AL",
        "dialCode": "+355",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+355",
        "key": 3,
        "dialCodeWithOutPlusSign": "355",
        "countryJoin": "Albania"
    },
    {
        "ru": "Алжир",
        "lt": "Alžyras",
        "tr": "Cezayir",
        "en": "Algeria",
        "flag": "🇩🇿",
        "code": "DZ",
        "dialCode": "+213",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+213",
        "key": 4,
        "dialCodeWithOutPlusSign": "213",
        "countryJoin": "Algeria"
    },
    {
        "ru": "американское Самоа",
        "lt": "Amerikos Samoa",
        "tr": "Amerikan Samoası",
        "en": "American Samoa",
        "flag": "🇦🇸",
        "code": "AS",
        "dialCode": "+1684",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1684",
        "key": 5,
        "dialCodeWithOutPlusSign": "1684",
        "countryJoin": "American Samoa"
    },
    {
        "ru": "андорра",
        "lt": "Andora",
        "tr": "Andorra",
        "en": "Andorra",
        "flag": "🇦🇩",
        "code": "AD",
        "dialCode": "+376",
        "mask": "999 999",
        "maskWithDialCode": "### ###",
        "name": "+376",
        "key": 6,
        "dialCodeWithOutPlusSign": "376",
        "countryJoin": "Andorra"
    },
    {
        "ru": "Ангола",
        "lt": "Angoloje",
        "tr": "Angora",
        "en": "Angola",
        "flag": "🇦🇴",
        "code": "AO",
        "dialCode": "+244",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+244",
        "key": 7,
        "dialCodeWithOutPlusSign": "244",
        "countryJoin": "Angola"
    },
    {
        "ru": "Ангилья",
        "lt": "Angilija",
        "tr": "Anguilla",
        "en": "Anguilla",
        "flag": "🇦🇮",
        "code": "AI",
        "dialCode": "+1264",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1264",
        "key": 8,
        "dialCodeWithOutPlusSign": "1264",
        "countryJoin": "Anguilla"
    },
    {
        "ru": "Антигуа и Барбуда",
        "lt": "Antigva ir Barbuda",
        "tr": "Antigua ve Barbuda",
        "en": "Antigua and Barbuda",
        "flag": "🇦🇬",
        "code": "AG",
        "dialCode": "+1268",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1268",
        "key": 9,
        "dialCodeWithOutPlusSign": "1268",
        "countryJoin": "Antigua and Barbuda"
    },
    {
        "ru": "Аргентина",
        "lt": "Argentina",
        "tr": "Arjantin",
        "en": "Argentina",
        "flag": "🇦🇷",
        "code": "AR",
        "dialCode": "+54",
        "mask": "999 99-9999-9999",
        "maskWithDialCode": "### ##-####-####",
        "name": "+54",
        "key": 10,
        "dialCodeWithOutPlusSign": "54",
        "countryJoin": "Argentina"
    },
    {
        "ru": "Армения",
        "lt": "Armėnija",
        "tr": "Ermenistan",
        "en": "Armenia",
        "flag": "🇦🇲",
        "code": "AM",
        "dialCode": "+374",
        "mask": "999 999999",
        "maskWithDialCode": "### ######",
        "name": "+374",
        "key": 11,
        "dialCodeWithOutPlusSign": "374",
        "countryJoin": "Armenia"
    },
    {
        "ru": "Аруба",
        "lt": "Aruba",
        "tr": "Aruba",
        "en": "Aruba",
        "flag": "🇦🇼",
        "code": "AW",
        "dialCode": "+297",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+297",
        "key": 12,
        "dialCodeWithOutPlusSign": "297",
        "countryJoin": "Aruba"
    },
    {
        "ru": "Австралия",
        "lt": "Australija",
        "tr": "Avustralya",
        "en": "Australia",
        "flag": "🇦🇺",
        "code": "AU",
        "dialCode": "+61",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+61",
        "key": 13,
        "dialCodeWithOutPlusSign": "61",
        "countryJoin": "Australia / Christmas Island / Cocos (Keeling) Islands"
    },
    {
        "ru": "Австрия",
        "lt": "Austrijoje",
        "tr": "Avusturya",
        "en": "Austria",
        "flag": "🇦🇹",
        "code": "AT",
        "dialCode": "+43",
        "mask": "9999 999999",
        "maskWithDialCode": "#### ######",
        "name": "+43",
        "key": 14,
        "dialCodeWithOutPlusSign": "43",
        "countryJoin": "Austria"
    },
    {
        "ru": "Азербайджан",
        "lt": "Azerbaidžanas",
        "tr": "Azerbeycan",
        "en": "Azerbaijan",
        "flag": "🇦🇿",
        "code": "AZ",
        "dialCode": "+994",
        "mask": "999 999 99 99",
        "maskWithDialCode": "### ### ## ##",
        "name": "+994",
        "key": 15,
        "dialCodeWithOutPlusSign": "994",
        "countryJoin": "Azerbaijan"
    },
    {
        "ru": "Багамские о-ва",
        "lt": "Bahamos",
        "tr": "Bahamalar",
        "en": "Bahamas",
        "flag": "🇧🇸",
        "code": "BS",
        "dialCode": "+1242",
        "mask": "(999) 999-999",
        "maskWithDialCode": "(###) ###-###",
        "name": "+1242",
        "key": 16,
        "dialCodeWithOutPlusSign": "1242",
        "countryJoin": "Bahamas"
    },
    {
        "ru": "Бахрейн",
        "lt": "Bahreinas",
        "tr": "Bahreyn",
        "en": "Bahrain",
        "flag": "🇧🇭",
        "code": "BH",
        "dialCode": "+973",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+973",
        "key": 17,
        "dialCodeWithOutPlusSign": "973",
        "countryJoin": "Bahrain"
    },
    {
        "ru": "Бангладеш",
        "lt": "Bangladešas",
        "tr": "Bangladeş",
        "en": "Bangladesh",
        "flag": "🇧🇩",
        "code": "BD",
        "dialCode": "+880",
        "mask": "99999-999999",
        "maskWithDialCode": "#####-######",
        "name": "+880",
        "key": 18,
        "dialCodeWithOutPlusSign": "880",
        "countryJoin": "Bangladesh"
    },
    {
        "ru": "Барбадос",
        "lt": "Barbadosas",
        "tr": "Barbados",
        "en": "Barbados",
        "flag": "🇧🇧",
        "code": "BB",
        "dialCode": "+1246",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1246",
        "key": 19,
        "dialCodeWithOutPlusSign": "1246",
        "countryJoin": "Barbados"
    },
    {
        "ru": "Беларусь",
        "lt": "Baltarusija",
        "tr": "Belarus",
        "en": "Belarus",
        "flag": "🇧🇾",
        "code": "BY",
        "dialCode": "+375",
        "mask": "9 999 999-99-99",
        "maskWithDialCode": "# ### ###-##-##",
        "name": "+375",
        "key": 20,
        "dialCodeWithOutPlusSign": "375",
        "countryJoin": "Belarus"
    },
    {
        "ru": "Бельгия",
        "lt": "Belgija",
        "tr": "Belçika",
        "en": "Belgium",
        "flag": "🇧🇪",
        "code": "BE",
        "dialCode": "+32",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+32",
        "key": 21,
        "dialCodeWithOutPlusSign": "32",
        "countryJoin": "Belgium"
    },
    {
        "ru": "Белиз",
        "lt": "Belizas",
        "tr": "Belize",
        "en": "Belize",
        "flag": "🇧🇿",
        "code": "BZ",
        "dialCode": "+501",
        "mask": "999-9999",
        "maskWithDialCode": "###-####",
        "name": "+501",
        "key": 22,
        "dialCodeWithOutPlusSign": "501",
        "countryJoin": "Belize"
    },
    {
        "ru": "Бенин",
        "lt": "Beninas",
        "tr": "Benin",
        "en": "Benin",
        "flag": "🇧🇯",
        "code": "BJ",
        "dialCode": "+229",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+229",
        "key": 23,
        "dialCodeWithOutPlusSign": "229",
        "countryJoin": "Benin"
    },
    {
        "ru": "Бермудские острова",
        "lt": "Bermudai",
        "tr": "Bermuda",
        "en": "Bermuda",
        "flag": "🇧🇲",
        "code": "BM",
        "dialCode": "+1441",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1441",
        "key": 24,
        "dialCodeWithOutPlusSign": "1441",
        "countryJoin": "Bermuda"
    },
    {
        "ru": "Бутан",
        "lt": "Butanas",
        "tr": "Butan",
        "en": "Bhutan",
        "flag": "🇧🇹",
        "code": "BT",
        "dialCode": "+975",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+975",
        "key": 25,
        "dialCodeWithOutPlusSign": "975",
        "countryJoin": "Bhutan"
    },
    {
        "ru": "Боливия, Многонациональное Государство Боливия",
        "lt": "Bolivija, Bolivijos daugiakalbė valstybė",
        "tr": "Bolivya, Bolivya Çokuluslu Devleti",
        "en": "Bolivia, Plurinational State of bolivia",
        "flag": "🇧🇴",
        "code": "BO",
        "dialCode": "+591",
        "mask": "99999999",
        "maskWithDialCode": "########",
        "name": "+591",
        "key": 26,
        "dialCodeWithOutPlusSign": "591",
        "countryJoin": "Bolivia, Plurinational State of bolivia"
    },
    {
        "ru": "Босния и Герцеговина",
        "lt": "Bosnija ir Hercegovina",
        "tr": "Bosna Hersek",
        "en": "Bosnia and Herzegovina",
        "flag": "🇧🇦",
        "code": "BA",
        "dialCode": "+387",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+387",
        "key": 27,
        "dialCodeWithOutPlusSign": "387",
        "countryJoin": "Bosnia and Herzegovina"
    },
    {
        "ru": "Ботсвана",
        "lt": "Botsvana",
        "tr": "Botsvana",
        "en": "Botswana",
        "flag": "🇧🇼",
        "code": "BW",
        "dialCode": "+267",
        "mask": "99 999 999",
        "maskWithDialCode": "## ### ###",
        "name": "+267",
        "key": 28,
        "dialCodeWithOutPlusSign": "267",
        "countryJoin": "Botswana"
    },
    {
        "ru": "Остров Буве",
        "lt": "Bouvet sala",
        "tr": "Bouvet Adası",
        "en": "Bouvet Island",
        "flag": "🇧🇻",
        "code": "BV",
        "dialCode": "+47",
        "mask": "(99) 99999-9999",
        "maskWithDialCode": "(##) #####-####",
        "name": "+47",
        "key": 29,
        "dialCodeWithOutPlusSign": "47",
        "countryJoin": "Bouvet Island / Norway / Svalbard and Jan Mayen"
    },
    {
        "ru": "Бразилия",
        "lt": "Brazilija",
        "tr": "Brezilya",
        "en": "Brazil",
        "flag": "🇧🇷",
        "code": "BR",
        "dialCode": "+55",
        "mask": "(99) 99999 9999",
        "maskWithDialCode": "(##) ##### ####",
        "name": "+55",
        "key": 30,
        "dialCodeWithOutPlusSign": "55",
        "countryJoin": "Brazil"
    },
    {
        "ru": "Британская территория Индийского океана",
        "lt": "Britanijos Indijos vandenyno teritorija",
        "tr": "Britanya Hint Okyanusu Toprakları",
        "en": "British Indian Ocean Territory",
        "flag": "🇮🇴",
        "code": "IO",
        "dialCode": "+246",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+246",
        "key": 31,
        "dialCodeWithOutPlusSign": "246",
        "countryJoin": "British Indian Ocean Territory"
    },
    {
        "ru": "Бруней-Даруссалам",
        "lt": "Brunėjaus Darusalamas",
        "tr": "Brunei Sultanlığı",
        "en": "Brunei Darussalam",
        "flag": "🇧🇳",
        "code": "BN",
        "dialCode": "+673",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+673",
        "key": 32,
        "dialCodeWithOutPlusSign": "673",
        "countryJoin": "Brunei Darussalam"
    },
    {
        "ru": "Болгария",
        "lt": "Bulgarija",
        "tr": "Bulgaristan",
        "en": "Bulgaria",
        "flag": "🇧🇬",
        "code": "BG",
        "dialCode": "+359",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+359",
        "key": 33,
        "dialCodeWithOutPlusSign": "359",
        "countryJoin": "Bulgaria"
    },
    {
        "ru": "Буркина-Фасо",
        "lt": "Burkina Fasas",
        "tr": "Burkina Faso",
        "en": "Burkina Faso",
        "flag": "🇧🇫",
        "code": "BF",
        "dialCode": "+226",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+226",
        "key": 34,
        "dialCodeWithOutPlusSign": "226",
        "countryJoin": "Burkina Faso"
    },
    {
        "ru": "Бурунди",
        "lt": "Burundis",
        "tr": "Burundi",
        "en": "Burundi",
        "flag": "🇧🇮",
        "code": "BI",
        "dialCode": "+257",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+257",
        "key": 35,
        "dialCodeWithOutPlusSign": "257",
        "countryJoin": "Burundi"
    },
    {
        "ru": "Камбоджа",
        "lt": "Kambodža",
        "tr": "Kamboçya",
        "en": "Cambodia",
        "flag": "🇰🇭",
        "code": "KH",
        "dialCode": "+855",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+855",
        "key": 36,
        "dialCodeWithOutPlusSign": "855",
        "countryJoin": "Cambodia"
    },
    {
        "ru": "Камерун",
        "lt": "Kamerūnas",
        "tr": "Kamerun",
        "en": "Cameroon",
        "flag": "🇨🇲",
        "code": "CM",
        "dialCode": "+237",
        "mask": "9 99 99 99 99",
        "maskWithDialCode": "# ## ## ## ##",
        "name": "+237",
        "key": 37,
        "dialCodeWithOutPlusSign": "237",
        "countryJoin": "Cameroon"
    },
    {
        "ru": "Канада",
        "lt": "Kanada",
        "tr": "Kanada",
        "en": "Canada",
        "flag": "🇨🇦",
        "code": "CA",
        "dialCode": "+1",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1",
        "key": 38,
        "dialCodeWithOutPlusSign": "1",
        "countryJoin": "Canada / United States"
    },
    {
        "ru": "Кабо-Верде",
        "lt": "Žaliasis Kyšulys",
        "tr": "Yeşil Burun Adaları",
        "en": "Cape Verde",
        "flag": "🇨🇻",
        "code": "CV",
        "dialCode": "+238",
        "mask": "999 99 99",
        "maskWithDialCode": "### ## ##",
        "name": "+238",
        "key": 39,
        "dialCodeWithOutPlusSign": "238",
        "countryJoin": "Cape Verde"
    },
    {
        "ru": "Каймановы острова",
        "lt": "Kaimanų salos",
        "tr": "Cayman Adaları",
        "en": "Cayman Islands",
        "flag": "🇰🇾",
        "code": "KY",
        "dialCode": "+345",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+345",
        "key": 40,
        "dialCodeWithOutPlusSign": "345",
        "countryJoin": "Cayman Islands"
    },
    {
        "ru": "Центрально-Африканская Республика",
        "lt": "Centrine Afrikos Respublika",
        "tr": "Orta Afrika Cumhuriyeti",
        "en": "Central African Republic",
        "flag": "🇨🇫",
        "code": "CF",
        "dialCode": "+236",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+236",
        "key": 41,
        "dialCodeWithOutPlusSign": "236",
        "countryJoin": "Central African Republic"
    },
    {
        "ru": "Чад",
        "lt": "Čadas",
        "tr": "Çad",
        "en": "Chad",
        "flag": "🇹🇩",
        "code": "TD",
        "dialCode": "+235",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+235",
        "key": 42,
        "dialCodeWithOutPlusSign": "235",
        "countryJoin": "Chad"
    },
    {
        "ru": "Чили",
        "lt": "Čilė",
        "tr": "Şili",
        "en": "Chile",
        "flag": "🇨🇱",
        "code": "CL",
        "dialCode": "+56",
        "mask": "(9) 9999 9999",
        "maskWithDialCode": "(#) #### ####",
        "name": "+56",
        "key": 43,
        "dialCodeWithOutPlusSign": "56",
        "countryJoin": "Chile"
    },
    {
        "ru": "Китай",
        "lt": "Kinija",
        "tr": "Çin",
        "en": "China",
        "flag": "🇨🇳",
        "code": "CN",
        "dialCode": "+86",
        "mask": "999 9999 9999",
        "maskWithDialCode": "### #### ####",
        "name": "+86",
        "key": 44,
        "dialCodeWithOutPlusSign": "86",
        "countryJoin": "China"
    },
    {
        "ru": "Остров Рождества",
        "lt": "Kalėdų sala",
        "tr": "Noel Adası",
        "en": "Christmas Island",
        "flag": "🇨🇽",
        "code": "CX",
        "dialCode": "+61",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+61",
        "key": 45,
        "dialCodeWithOutPlusSign": "61",
        "countryJoin": "Australia / Christmas Island / Cocos (Keeling) Islands"
    },
    {
        "ru": "Кокосовые (Килинг) острова",
        "lt": "Kokosų (Kylingo) salos",
        "tr": "Cocos (Keeling) Adaları",
        "en": "Cocos (Keeling) Islands",
        "flag": "🇨🇨",
        "code": "CC",
        "dialCode": "+61",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+61",
        "key": 46,
        "dialCodeWithOutPlusSign": "61",
        "countryJoin": "Australia / Christmas Island / Cocos (Keeling) Islands"
    },
    {
        "ru": "Колумбия",
        "lt": "Kolumbija",
        "tr": "Kolombiya",
        "en": "Colombia",
        "flag": "🇨🇴",
        "code": "CO",
        "dialCode": "+57",
        "mask": "999 9999999",
        "maskWithDialCode": "### #######",
        "name": "+57",
        "key": 47,
        "dialCodeWithOutPlusSign": "57",
        "countryJoin": "Colombia"
    },
    {
        "ru": "Коморские острова",
        "lt": "Komorai",
        "tr": "Komorlar",
        "en": "Comoros",
        "flag": "🇰🇲",
        "code": "KM",
        "dialCode": "+269",
        "mask": "999 99 99",
        "maskWithDialCode": "### ## ##",
        "name": "+269",
        "key": 48,
        "dialCodeWithOutPlusSign": "269",
        "countryJoin": "Comoros"
    },
    {
        "ru": "Конго",
        "lt": "Kongas",
        "tr": "Kongo",
        "en": "Congo",
        "flag": "🇨🇬",
        "code": "CG",
        "dialCode": "+242",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+242",
        "key": 49,
        "dialCodeWithOutPlusSign": "242",
        "countryJoin": "Congo"
    },
    {
        "ru": "Конго, Демократическая Республика Конго",
        "lt": "Kongo Demokratinė Kongo Respublika",
        "tr": "Kongo, Demokratik Kongo Cumhuriyeti",
        "en": "Congo, The Democratic Republic of the Congo",
        "flag": "🇨🇩",
        "code": "CD",
        "dialCode": "+243",
        "mask": "99 999 9999",
        "maskWithDialCode": "## ### ####",
        "name": "+243",
        "key": 50,
        "dialCodeWithOutPlusSign": "243",
        "countryJoin": "Congo, The Democratic Republic of the Congo"
    },
    {
        "ru": "Острова Кука",
        "lt": "Kuko salos",
        "tr": "Cook Adaları",
        "en": "Cook Islands",
        "flag": "🇨🇰",
        "code": "CK",
        "dialCode": "+682",
        "mask": "99 999",
        "maskWithDialCode": "## ###",
        "name": "+682",
        "key": 51,
        "dialCodeWithOutPlusSign": "682",
        "countryJoin": "Cook Islands"
    },
    {
        "ru": "Коста Рика",
        "lt": "Kosta Rika",
        "tr": "Kosta Rika",
        "en": "Costa Rica",
        "flag": "🇨🇷",
        "code": "CR",
        "dialCode": "+506",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+506",
        "key": 52,
        "dialCodeWithOutPlusSign": "506",
        "countryJoin": "Costa Rica"
    },
    {
        "ru": "Берег Слоновой Кости",
        "lt": "Dramblio Kaulo Krantas",
        "tr": "Fildişi Sahili",
        "en": "Cote d'Ivoire",
        "flag": "🇨🇮",
        "code": "CI",
        "dialCode": "+225",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+225",
        "key": 53,
        "dialCodeWithOutPlusSign": "225",
        "countryJoin": "Cote d'Ivoire"
    },
    {
        "ru": "Хорватия",
        "lt": "Kroatija",
        "tr": "Hırvatistan",
        "en": "Croatia",
        "flag": "🇭🇷",
        "code": "HR",
        "dialCode": "+385",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+385",
        "key": 54,
        "dialCodeWithOutPlusSign": "385",
        "countryJoin": "Croatia"
    },
    {
        "ru": "Куба",
        "lt": "Kuba",
        "tr": "Küba",
        "en": "Cuba",
        "flag": "🇨🇺",
        "code": "CU",
        "dialCode": "+53",
        "mask": "99 9999999",
        "maskWithDialCode": "## #######",
        "name": "+53",
        "key": 55,
        "dialCodeWithOutPlusSign": "53",
        "countryJoin": "Cuba"
    },
    {
        "ru": "Кипр",
        "lt": "Kipras",
        "tr": "Kıbrıs",
        "en": "Cyprus",
        "flag": "🇨🇾",
        "code": "CY",
        "dialCode": "+357",
        "mask": "99 999999",
        "maskWithDialCode": "## ######",
        "name": "+357",
        "key": 56,
        "dialCodeWithOutPlusSign": "357",
        "countryJoin": "Cyprus"
    },
    {
        "ru": "Республика Чехия",
        "lt": "Čekijos Respublika",
        "tr": "Çek Cumhuriyeti",
        "en": "Czech Republic",
        "flag": "🇨🇿",
        "code": "CZ",
        "dialCode": "+420",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+420",
        "key": 57,
        "dialCodeWithOutPlusSign": "420",
        "countryJoin": "Czech Republic"
    },
    {
        "ru": "Дания",
        "lt": "Danija",
        "tr": "Danimarka",
        "en": "Denmark",
        "flag": "🇩🇰",
        "code": "DK",
        "dialCode": "+45",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+45",
        "key": 58,
        "dialCodeWithOutPlusSign": "45",
        "countryJoin": "Denmark"
    },
    {
        "ru": "Джибути",
        "lt": "Džibutis",
        "tr": "Cibuti",
        "en": "Djibouti",
        "flag": "🇩🇯",
        "code": "DJ",
        "dialCode": "+253",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+253",
        "key": 59,
        "dialCodeWithOutPlusSign": "253",
        "countryJoin": "Djibouti"
    },
    {
        "ru": "Доминика",
        "lt": "Dominika",
        "tr": "Dominika",
        "en": "Dominica",
        "flag": "🇩🇲",
        "code": "DM",
        "dialCode": "+1767",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1767",
        "key": 60,
        "dialCodeWithOutPlusSign": "1767",
        "countryJoin": "Dominica"
    },
    {
        "ru": "Эквадор",
        "lt": "Ekvadoras",
        "tr": "Ekvador",
        "en": "Ecuador",
        "flag": "🇪🇨",
        "code": "EC",
        "dialCode": "+593",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+593",
        "key": 61,
        "dialCodeWithOutPlusSign": "593",
        "countryJoin": "Ecuador"
    },
    {
        "ru": "Египет",
        "lt": "Egiptas",
        "tr": "Mısır",
        "en": "Egypt",
        "flag": "🇪🇬",
        "code": "EG",
        "dialCode": "+20",
        "mask": "9999 999 9999",
        "maskWithDialCode": "#### ### ####",
        "name": "+20",
        "key": 62,
        "dialCodeWithOutPlusSign": "20",
        "countryJoin": "Egypt"
    },
    {
        "ru": "Сальвадор",
        "lt": "Salvadoras",
        "tr": "El Salvador",
        "en": "El Salvador",
        "flag": "🇸🇻",
        "code": "SV",
        "dialCode": "+503",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+503",
        "key": 63,
        "dialCodeWithOutPlusSign": "503",
        "countryJoin": "El Salvador"
    },
    {
        "ru": "Экваториальная Гвинея",
        "lt": "Pusiaujo Gvinėja",
        "tr": "Ekvator Ginesi",
        "en": "Equatorial Guinea",
        "flag": "🇬🇶",
        "code": "GQ",
        "dialCode": "+240",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+240",
        "key": 64,
        "dialCodeWithOutPlusSign": "240",
        "countryJoin": "Equatorial Guinea"
    },
    {
        "ru": "Эритрея",
        "lt": "Eritrėja",
        "tr": "Eritre",
        "en": "Eritrea",
        "flag": "🇪🇷",
        "code": "ER",
        "dialCode": "+291",
        "mask": "99 999 999",
        "maskWithDialCode": "## ### ###",
        "name": "+291",
        "key": 65,
        "dialCodeWithOutPlusSign": "291",
        "countryJoin": "Eritrea"
    },
    {
        "ru": "Эстония",
        "lt": "Estija",
        "tr": "Estonya",
        "en": "Estonia",
        "flag": "🇪🇪",
        "code": "EE",
        "dialCode": "+372",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+372",
        "key": 66,
        "dialCodeWithOutPlusSign": "372",
        "countryJoin": "Estonia"
    },
    {
        "ru": "Эфиопия",
        "lt": "Etiopija",
        "tr": "Etiyopya",
        "en": "Ethiopia",
        "flag": "🇪🇹",
        "code": "ET",
        "dialCode": "+251",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+251",
        "key": 67,
        "dialCodeWithOutPlusSign": "251",
        "countryJoin": "Ethiopia"
    },
    {
        "ru": "Фолклендские (Мальвинские) острова",
        "lt": "Folklando salos (Malvinai)",
        "tr": "Falkland Adaları (Malvinas)",
        "en": "Falkland Islands (Malvinas)",
        "flag": "🇫🇰",
        "code": "FK",
        "dialCode": "+500",
        "mask": "99999",
        "maskWithDialCode": "#####",
        "name": "+500",
        "key": 68,
        "dialCodeWithOutPlusSign": "500",
        "countryJoin": "Falkland Islands (Malvinas)"
    },
    {
        "ru": "Фарерские острова",
        "lt": "Farerų salos",
        "tr": "Faroe Adaları",
        "en": "Faroe Islands",
        "flag": "🇫🇴",
        "code": "FO",
        "dialCode": "+298",
        "mask": "999999",
        "maskWithDialCode": "######",
        "name": "+298",
        "key": 69,
        "dialCodeWithOutPlusSign": "298",
        "countryJoin": "Faroe Islands"
    },
    {
        "ru": "Фиджи",
        "lt": "Fidžis",
        "tr": "Fiji",
        "en": "Fiji",
        "flag": "🇫🇯",
        "code": "FJ",
        "dialCode": "+679",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+679",
        "key": 70,
        "dialCodeWithOutPlusSign": "679",
        "countryJoin": "Fiji"
    },
    {
        "ru": "Финляндия",
        "lt": "Suomija",
        "tr": "Finlandiya",
        "en": "Finland",
        "flag": "🇫🇮",
        "code": "FI",
        "dialCode": "+358",
        "mask": "999 9999999",
        "maskWithDialCode": "### #######",
        "name": "+358",
        "key": 71,
        "dialCodeWithOutPlusSign": "358",
        "countryJoin": "Åland Islands / Finland"
    },
    {
        "ru": "Франция",
        "lt": "Prancūzijoje",
        "tr": "Fransa",
        "en": "France",
        "flag": "🇫🇷",
        "code": "FR",
        "dialCode": "+33",
        "mask": "99 99 99 99 99",
        "maskWithDialCode": "## ## ## ## ##",
        "name": "+33",
        "key": 72,
        "dialCodeWithOutPlusSign": "33",
        "countryJoin": "France"
    },
    {
        "ru": "Французская Гвиана",
        "lt": "Prancūzijos Gviana",
        "tr": "Fransız Guyanası",
        "en": "French Guiana",
        "flag": "🇬🇫",
        "code": "GF",
        "dialCode": "+594",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+594",
        "key": 73,
        "dialCodeWithOutPlusSign": "594",
        "countryJoin": "French Guiana"
    },
    {
        "ru": "Французская Полинезия",
        "lt": "Prancūzų Polinezija",
        "tr": "Fransız Polinezyası",
        "en": "French Polynesia",
        "flag": "🇵🇫",
        "code": "PF",
        "dialCode": "+689",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+689",
        "key": 74,
        "dialCodeWithOutPlusSign": "689",
        "countryJoin": "French Polynesia"
    },
    {
        "ru": "Габон",
        "lt": "Gabonas",
        "tr": "Gabon",
        "en": "Gabon",
        "flag": "🇬🇦",
        "code": "GA",
        "dialCode": "+241",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+241",
        "key": 75,
        "dialCodeWithOutPlusSign": "241",
        "countryJoin": "Gabon"
    },
    {
        "ru": "Гамбия",
        "lt": "Gambija",
        "tr": "Gambiya",
        "en": "Gambia",
        "flag": "🇬🇲",
        "code": "GM",
        "dialCode": "+220",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+220",
        "key": 76,
        "dialCodeWithOutPlusSign": "220",
        "countryJoin": "Gambia"
    },
    {
        "ru": "Грузия",
        "lt": "Gruzijoje",
        "tr": "Gürcistan",
        "en": "Georgia",
        "flag": "🇬🇪",
        "code": "GE",
        "dialCode": "+995",
        "mask": "999 99 99 99",
        "maskWithDialCode": "### ## ## ##",
        "name": "+995",
        "key": 77,
        "dialCodeWithOutPlusSign": "995",
        "countryJoin": "Georgia"
    },
    {
        "ru": "Германия",
        "lt": "Vokietija",
        "tr": "Almanya",
        "en": "Germany",
        "flag": "🇩🇪",
        "code": "DE",
        "dialCode": "+49",
        "mask": "99999 9999999",
        "maskWithDialCode": "##### #######",
        "name": "+49",
        "key": 78,
        "dialCodeWithOutPlusSign": "49",
        "countryJoin": "Germany"
    },
    {
        "ru": "Гана",
        "lt": "Gana",
        "tr": "Gana",
        "en": "Ghana",
        "flag": "🇬🇭",
        "code": "GH",
        "dialCode": "+233",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+233",
        "key": 79,
        "dialCodeWithOutPlusSign": "233",
        "countryJoin": "Ghana"
    },
    {
        "ru": "Гибралтар",
        "lt": "Gibraltaras",
        "tr": "Cebelitarık",
        "en": "Gibraltar",
        "flag": "🇬🇮",
        "code": "GI",
        "dialCode": "+350",
        "mask": "99999999",
        "maskWithDialCode": "########",
        "name": "+350",
        "key": 80,
        "dialCodeWithOutPlusSign": "350",
        "countryJoin": "Gibraltar"
    },
    {
        "ru": "Греция",
        "lt": "Graikija",
        "tr": "Yunanistan",
        "en": "Greece",
        "flag": "🇬🇷",
        "code": "GR",
        "dialCode": "+30",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+30",
        "key": 81,
        "dialCodeWithOutPlusSign": "30",
        "countryJoin": "Greece"
    },
    {
        "ru": "Гренландия",
        "lt": "Grenlandija",
        "tr": "Grönland",
        "en": "Greenland",
        "flag": "🇬🇱",
        "code": "GL",
        "dialCode": "+299",
        "mask": "99 99 99",
        "maskWithDialCode": "## ## ##",
        "name": "+299",
        "key": 82,
        "dialCodeWithOutPlusSign": "299",
        "countryJoin": "Greenland"
    },
    {
        "ru": "Гренада",
        "lt": "Grenada",
        "tr": "Grenada",
        "en": "Grenada",
        "flag": "🇬🇩",
        "code": "GD",
        "dialCode": "+1473",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1473",
        "key": 83,
        "dialCodeWithOutPlusSign": "1473",
        "countryJoin": "Grenada"
    },
    {
        "ru": "Гваделупа",
        "lt": "Gvadelupa",
        "tr": "Guadeloupe",
        "en": "Guadeloupe",
        "flag": "🇬🇵",
        "code": "GP",
        "dialCode": "+590",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+590",
        "key": 84,
        "dialCodeWithOutPlusSign": "590",
        "countryJoin": "Guadeloupe / Saint Barthelemy / Saint Martin"
    },
    {
        "ru": "Гуам",
        "lt": "Guamas",
        "tr": "Guam",
        "en": "Guam",
        "flag": "🇬🇺",
        "code": "GU",
        "dialCode": "+1671",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1671",
        "key": 85,
        "dialCodeWithOutPlusSign": "1671",
        "countryJoin": "Guam"
    },
    {
        "ru": "Гватемала",
        "lt": "Gvatemala",
        "tr": "Guatemala",
        "en": "Guatemala",
        "flag": "🇬🇹",
        "code": "GT",
        "dialCode": "+502",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+502",
        "key": 86,
        "dialCodeWithOutPlusSign": "502",
        "countryJoin": "Guatemala"
    },
    {
        "ru": "шерстяная фуфайка",
        "lt": "Gernsis",
        "tr": "bir tür inek",
        "en": "Guernsey",
        "flag": "🇬🇬",
        "code": "GG",
        "dialCode": "+44",
        "mask": "99999 999999",
        "maskWithDialCode": "##### ######",
        "name": "+44",
        "key": 87,
        "dialCodeWithOutPlusSign": "44",
        "countryJoin": "Guernsey / Isle of Man / Jersey / United Kingdom"
    },
    {
        "ru": "Гвинея",
        "lt": "Gvinėjos",
        "tr": "Gine",
        "en": "Guinea",
        "flag": "🇬🇳",
        "code": "GN",
        "dialCode": "+224",
        "mask": "999 99 99 99",
        "maskWithDialCode": "### ## ## ##",
        "name": "+224",
        "key": 88,
        "dialCodeWithOutPlusSign": "224",
        "countryJoin": "Guinea"
    },
    {
        "ru": "Гвинея-Бисау",
        "lt": "Bisau Gvinėja",
        "tr": "Gine-Bissau",
        "en": "Guinea-Bissau",
        "flag": "🇬🇼",
        "code": "GW",
        "dialCode": "+245",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+245",
        "key": 89,
        "dialCodeWithOutPlusSign": "245",
        "countryJoin": "Guinea-Bissau"
    },
    {
        "ru": "Гайана",
        "lt": "Gajana",
        "tr": "Guyana",
        "en": "Guyana",
        "flag": "🇬🇾",
        "code": "GY",
        "dialCode": "+592",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+592",
        "key": 90,
        "dialCodeWithOutPlusSign": "592",
        "countryJoin": "Guyana"
    },
    {
        "ru": "Гаити",
        "lt": "Haitis",
        "tr": "Haiti",
        "en": "Haiti",
        "flag": "🇭🇹",
        "code": "HT",
        "dialCode": "+509",
        "mask": "99 99 9999",
        "maskWithDialCode": "## ## ####",
        "name": "+509",
        "key": 91,
        "dialCodeWithOutPlusSign": "509",
        "countryJoin": "Haiti"
    },
    {
        "ru": "Гондурас",
        "lt": "Hondūras",
        "tr": "Honduras",
        "en": "Honduras",
        "flag": "🇭🇳",
        "code": "HN",
        "dialCode": "+504",
        "mask": "9999-9999",
        "maskWithDialCode": "####-####",
        "name": "+504",
        "key": 92,
        "dialCodeWithOutPlusSign": "504",
        "countryJoin": "Honduras"
    },
    {
        "ru": "Гонконг",
        "lt": "Honkongas",
        "tr": "Hong Kong",
        "en": "Hong Kong",
        "flag": "🇭🇰",
        "code": "HK",
        "dialCode": "+852",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+852",
        "key": 93,
        "dialCodeWithOutPlusSign": "852",
        "countryJoin": "Hong Kong"
    },
    {
        "ru": "Венгрия",
        "lt": "Vengrija",
        "tr": "Macaristan",
        "en": "Hungary",
        "flag": "🇭🇺",
        "code": "HU",
        "dialCode": "+36",
        "mask": "(99) 999 9999",
        "maskWithDialCode": "(##) ### ####",
        "name": "+36",
        "key": 94,
        "dialCodeWithOutPlusSign": "36",
        "countryJoin": "Hungary"
    },
    {
        "ru": "Исландия",
        "lt": "Islandija",
        "tr": "İzlanda",
        "en": "Iceland",
        "flag": "🇮🇸",
        "code": "IS",
        "dialCode": "+354",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+354",
        "key": 95,
        "dialCodeWithOutPlusSign": "354",
        "countryJoin": "Iceland"
    },
    {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    },
    {
        "ru": "Индонезия",
        "lt": "Indonezija",
        "tr": "Endonezya",
        "en": "Indonesia",
        "flag": "🇮🇩",
        "code": "ID",
        "dialCode": "+62",
        "mask": "9999-999-999",
        "maskWithDialCode": "####-###-###",
        "name": "+62",
        "key": 97,
        "dialCodeWithOutPlusSign": "62",
        "countryJoin": "Indonesia"
    },
    {
        "ru": "Иран, Исламская Республика Персидского залива",
        "lt": "Iranas, Persijos įlankos Islamo Respublika",
        "tr": "İran, Basra Körfezi İslam Cumhuriyeti",
        "en": "Iran, Islamic Republic of Persian Gulf",
        "flag": "🇮🇷",
        "code": "IR",
        "dialCode": "+98",
        "mask": "9999 999 9999",
        "maskWithDialCode": "#### ### ####",
        "name": "+98",
        "key": 98,
        "dialCodeWithOutPlusSign": "98",
        "countryJoin": "Iran, Islamic Republic of Persian Gulf"
    },
    {
        "ru": "Ирак",
        "lt": "Irakas",
        "tr": "Irak",
        "en": "Iraq",
        "flag": "🇮🇶",
        "code": "IQ",
        "dialCode": "+964",
        "mask": "9999 999 9999",
        "maskWithDialCode": "#### ### ####",
        "name": "+964",
        "key": 99,
        "dialCodeWithOutPlusSign": "964",
        "countryJoin": "Iraq"
    },
    {
        "ru": "Ирландия",
        "lt": "Airija",
        "tr": "İrlanda",
        "en": "Ireland",
        "flag": "🇮🇪",
        "code": "IE",
        "dialCode": "+353",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+353",
        "key": 100,
        "dialCodeWithOutPlusSign": "353",
        "countryJoin": "Ireland"
    },
    {
        "ru": "Остров Мэн",
        "lt": "Meno sala",
        "tr": "Man Adası",
        "en": "Isle of Man",
        "flag": "🇮🇲",
        "code": "IM",
        "dialCode": "+44",
        "mask": "99999 999999",
        "maskWithDialCode": "##### ######",
        "name": "+44",
        "key": 101,
        "dialCodeWithOutPlusSign": "44",
        "countryJoin": "Guernsey / Isle of Man / Jersey / United Kingdom"
    },
    {
        "ru": "Израиль",
        "lt": "Izraelis",
        "tr": "İsrail",
        "en": "Israel",
        "flag": "🇮🇱",
        "code": "IL",
        "dialCode": "+972",
        "mask": "999-999-9999",
        "maskWithDialCode": "###-###-####",
        "name": "+972",
        "key": 102,
        "dialCodeWithOutPlusSign": "972",
        "countryJoin": "Israel"
    },
    {
        "ru": "Италия",
        "lt": "Italijoje",
        "tr": "İtalya",
        "en": "Italy",
        "flag": "🇮🇹",
        "code": "IT",
        "dialCode": "+39",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+39",
        "key": 103,
        "dialCodeWithOutPlusSign": "39",
        "countryJoin": "Italy"
    },
    {
        "ru": "Ямайка",
        "lt": "Jamaika",
        "tr": "Jamaika",
        "en": "Jamaica",
        "flag": "🇯🇲",
        "code": "JM",
        "dialCode": "+1876",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1876",
        "key": 104,
        "dialCodeWithOutPlusSign": "1876",
        "countryJoin": "Jamaica"
    },
    {
        "ru": "Япония",
        "lt": "Japonija",
        "tr": "Japonya",
        "en": "Japan",
        "flag": "🇯🇵",
        "code": "JP",
        "dialCode": "+81",
        "mask": "999-9999-9999",
        "maskWithDialCode": "###-####-####",
        "name": "+81",
        "key": 105,
        "dialCodeWithOutPlusSign": "81",
        "countryJoin": "Japan"
    },
    {
        "ru": "Джерси",
        "lt": "Džersis",
        "tr": "Jersey",
        "en": "Jersey",
        "flag": "🇯🇪",
        "code": "JE",
        "dialCode": "+44",
        "mask": "99999 999999",
        "maskWithDialCode": "##### ######",
        "name": "+44",
        "key": 106,
        "dialCodeWithOutPlusSign": "44",
        "countryJoin": "Guernsey / Isle of Man / Jersey / United Kingdom"
    },
    {
        "ru": "Иордания",
        "lt": "Jordanija",
        "tr": "Ürdün",
        "en": "Jordan",
        "flag": "🇯🇴",
        "code": "JO",
        "dialCode": "+962",
        "mask": "99 9999 9999",
        "maskWithDialCode": "## #### ####",
        "name": "+962",
        "key": 107,
        "dialCodeWithOutPlusSign": "962",
        "countryJoin": "Jordan"
    },
    {
        "ru": "Казахстан",
        "lt": "Kazachstanas",
        "tr": "Kazakistan",
        "en": "Kazakhstan",
        "flag": "🇰🇿",
        "code": "KZ",
        "dialCode": "+7",
        "mask": "(999) 999 9999",
        "maskWithDialCode": "(###) ### ####",
        "name": "+7",
        "key": 108,
        "dialCodeWithOutPlusSign": "7",
        "countryJoin": "Kazakhstan / Russia"
    },
    {
        "ru": "Кения",
        "lt": "Kenija",
        "tr": "Kenya",
        "en": "Kenya",
        "flag": "🇰🇪",
        "code": "KE",
        "dialCode": "+254",
        "mask": "9999 999999",
        "maskWithDialCode": "#### ######",
        "name": "+254",
        "key": 109,
        "dialCodeWithOutPlusSign": "254",
        "countryJoin": "Kenya"
    },
    {
        "ru": "Кирибати",
        "lt": "Kiribatis",
        "tr": "Kiribati",
        "en": "Kiribati",
        "flag": "🇰🇮",
        "code": "KI",
        "dialCode": "+686",
        "mask": "99999999",
        "maskWithDialCode": "########",
        "name": "+686",
        "key": 110,
        "dialCodeWithOutPlusSign": "686",
        "countryJoin": "Kiribati"
    },
    {
        "ru": "Корея, Корейская Народно-Демократическая Республика",
        "lt": "Korėja, Korėjos Liaudies Demokratinė Respublika",
        "tr": "Kore, Kore Demokratik Halk Cumhuriyeti",
        "en": "Korea, Democratic People's Republic of Korea",
        "flag": "🇰🇵",
        "code": "KP",
        "dialCode": "+850",
        "mask": "(9) 99999",
        "maskWithDialCode": "(#) #####",
        "name": "+850",
        "key": 111,
        "dialCodeWithOutPlusSign": "850",
        "countryJoin": "Korea, Democratic People's Republic of Korea"
    },
    {
        "ru": "Корея, Республика Южная Корея",
        "lt": "Korėja, Pietų Korėjos Respublika",
        "tr": "Güney Kore Cumhuriyeti",
        "en": "Korea, Republic of South Korea",
        "flag": "🇰🇷",
        "code": "KR",
        "dialCode": "+82",
        "mask": "999-9999-9999",
        "maskWithDialCode": "###-####-####",
        "name": "+82",
        "key": 112,
        "dialCodeWithOutPlusSign": "82",
        "countryJoin": "Korea, Republic of South Korea"
    },
    {
        "ru": "Косово",
        "lt": "Kosovas",
        "tr": "Kosova",
        "en": "Kosovo",
        "flag": "🇽🇰",
        "code": "XK",
        "dialCode": "+383",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+383",
        "key": 113,
        "dialCodeWithOutPlusSign": "383",
        "countryJoin": "Kosovo"
    },
    {
        "ru": "Кувейт",
        "lt": "Kuveitas",
        "tr": "Kuveyt",
        "en": "Kuwait",
        "flag": "🇰🇼",
        "code": "KW",
        "dialCode": "+965",
        "mask": "999 99999",
        "maskWithDialCode": "### #####",
        "name": "+965",
        "key": 114,
        "dialCodeWithOutPlusSign": "965",
        "countryJoin": "Kuwait"
    },
    {
        "ru": "Киргизия",
        "lt": "Kirgizija",
        "tr": "Kırgızistan",
        "en": "Kyrgyzstan",
        "flag": "🇰🇬",
        "code": "KG",
        "dialCode": "+996",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+996",
        "key": 115,
        "dialCodeWithOutPlusSign": "996",
        "countryJoin": "Kyrgyzstan"
    },
    {
        "ru": "Лаос",
        "lt": "Laosas",
        "tr": "Laos",
        "en": "Laos",
        "flag": "🇱🇦",
        "code": "LA",
        "dialCode": "+856",
        "mask": "999 99 999 999",
        "maskWithDialCode": "### ## ### ###",
        "name": "+856",
        "key": 116,
        "dialCodeWithOutPlusSign": "856",
        "countryJoin": "Laos"
    },
    {
        "ru": "Латвия",
        "lt": "Latvijoje",
        "tr": "Letonya",
        "en": "Latvia",
        "flag": "🇱🇻",
        "code": "LV",
        "dialCode": "+371",
        "mask": "99 999 999",
        "maskWithDialCode": "## ### ###",
        "name": "+371",
        "key": 117,
        "dialCodeWithOutPlusSign": "371",
        "countryJoin": "Latvia"
    },
    {
        "ru": "Ливан",
        "lt": "Libanas",
        "tr": "Lübnan",
        "en": "Lebanon",
        "flag": "🇱🇧",
        "code": "LB",
        "dialCode": "+961",
        "mask": "99 999 999",
        "maskWithDialCode": "## ### ###",
        "name": "+961",
        "key": 118,
        "dialCodeWithOutPlusSign": "961",
        "countryJoin": "Lebanon"
    },
    {
        "ru": "Лесото",
        "lt": "Lesotas",
        "tr": "Lesotho",
        "en": "Lesotho",
        "flag": "🇱🇸",
        "code": "LS",
        "dialCode": "+266",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+266",
        "key": 119,
        "dialCodeWithOutPlusSign": "266",
        "countryJoin": "Lesotho"
    },
    {
        "ru": "Либерия",
        "lt": "Liberija",
        "tr": "Liberya",
        "en": "Liberia",
        "flag": "🇱🇷",
        "code": "LR",
        "dialCode": "+231",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+231",
        "key": 120,
        "dialCodeWithOutPlusSign": "231",
        "countryJoin": "Liberia"
    },
    {
        "ru": "Ливийская арабская джамахирия",
        "lt": "Libijos arabų Jamahiriya",
        "tr": "Libya Arap Jamahiriya",
        "en": "Libyan Arab Jamahiriya",
        "flag": "🇱🇾",
        "code": "LY",
        "dialCode": "+218",
        "mask": "999-9999999",
        "maskWithDialCode": "###-#######",
        "name": "+218",
        "key": 121,
        "dialCodeWithOutPlusSign": "218",
        "countryJoin": "Libyan Arab Jamahiriya"
    },
    {
        "ru": "Лихтенштейн",
        "lt": "Lichtenšteinas",
        "tr": "Lihtenştayn",
        "en": "Liechtenstein",
        "flag": "🇱🇮",
        "code": "LI",
        "dialCode": "+423",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+423",
        "key": 122,
        "dialCodeWithOutPlusSign": "423",
        "countryJoin": "Liechtenstein"
    },
    {
        "ru": "Литва",
        "lt": "Lietuva",
        "tr": "Litvanya",
        "en": "Lithuania",
        "flag": "🇱🇹",
        "code": "LT",
        "dialCode": "+370",
        "mask": "(9-999) 9999",
        "maskWithDialCode": "(#-###) ####",
        "name": "+370",
        "key": 123,
        "dialCodeWithOutPlusSign": "370",
        "countryJoin": "Lithuania"
    },
    {
        "ru": "Люксембург",
        "lt": "Liuksemburgas",
        "tr": "Lüksemburg",
        "en": "Luxembourg",
        "flag": "🇱🇺",
        "code": "LU",
        "dialCode": "+352",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+352",
        "key": 124,
        "dialCodeWithOutPlusSign": "352",
        "countryJoin": "Luxembourg"
    },
    {
        "ru": "Macao",
        "lt": "Makao",
        "tr": "Macao",
        "en": "Macao",
        "flag": "🇲🇴",
        "code": "MO",
        "dialCode": "+853",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+853",
        "key": 125,
        "dialCodeWithOutPlusSign": "853",
        "countryJoin": "Macao"
    },
    {
        "ru": "Македония",
        "lt": "Makedonija",
        "tr": "Makedonya",
        "en": "Macedonia",
        "flag": "🇲🇰",
        "code": "MK",
        "dialCode": "+389",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+389",
        "key": 126,
        "dialCodeWithOutPlusSign": "389",
        "countryJoin": "Macedonia"
    },
    {
        "ru": "Мадагаскар",
        "lt": "Madagaskaras",
        "tr": "Madagaskar",
        "en": "Madagascar",
        "flag": "🇲🇬",
        "code": "MG",
        "dialCode": "+261",
        "mask": "999 99 999 99",
        "maskWithDialCode": "### ## ### ##",
        "name": "+261",
        "key": 127,
        "dialCodeWithOutPlusSign": "261",
        "countryJoin": "Madagascar"
    },
    {
        "ru": "Малави",
        "lt": "Malavis",
        "tr": "Malawi",
        "en": "Malawi",
        "flag": "🇲🇼",
        "code": "MW",
        "dialCode": "+265",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+265",
        "key": 128,
        "dialCodeWithOutPlusSign": "265",
        "countryJoin": "Malawi"
    },
    {
        "ru": "Малайзия",
        "lt": "Malaizija",
        "tr": "Malezya",
        "en": "Malaysia",
        "flag": "🇲🇾",
        "code": "MY",
        "dialCode": "+60",
        "mask": "999-999 9999",
        "maskWithDialCode": "###-### ####",
        "name": "+60",
        "key": 129,
        "dialCodeWithOutPlusSign": "60",
        "countryJoin": "Malaysia"
    },
    {
        "ru": "Мальдивы",
        "lt": "Maldyvai",
        "tr": "Maldivler",
        "en": "Maldives",
        "flag": "🇲🇻",
        "code": "MV",
        "dialCode": "+960",
        "mask": "999-9999",
        "maskWithDialCode": "###-####",
        "name": "+960",
        "key": 130,
        "dialCodeWithOutPlusSign": "960",
        "countryJoin": "Maldives"
    },
    {
        "ru": "Мали",
        "lt": "Malis",
        "tr": "Mali",
        "en": "Mali",
        "flag": "🇲🇱",
        "code": "ML",
        "dialCode": "+223",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+223",
        "key": 131,
        "dialCodeWithOutPlusSign": "223",
        "countryJoin": "Mali"
    },
    {
        "ru": "Мальта",
        "lt": "Malta",
        "tr": "Malta",
        "en": "Malta",
        "flag": "🇲🇹",
        "code": "MT",
        "dialCode": "+356",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+356",
        "key": 132,
        "dialCodeWithOutPlusSign": "356",
        "countryJoin": "Malta"
    },
    {
        "ru": "Маршалловы острова",
        "lt": "Maršalo salos",
        "tr": "Marşal Adaları",
        "en": "Marshall Islands",
        "flag": "🇲🇭",
        "code": "MH",
        "dialCode": "+692",
        "mask": "999-9999",
        "maskWithDialCode": "###-####",
        "name": "+692",
        "key": 133,
        "dialCodeWithOutPlusSign": "692",
        "countryJoin": "Marshall Islands"
    },
    {
        "ru": "Мартиника",
        "lt": "Martinika",
        "tr": "Martinik",
        "en": "Martinique",
        "flag": "🇲🇶",
        "code": "MQ",
        "dialCode": "+596",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+596",
        "key": 134,
        "dialCodeWithOutPlusSign": "596",
        "countryJoin": "Martinique"
    },
    {
        "ru": "Мавритания",
        "lt": "Mauritanija",
        "tr": "Moritanya",
        "en": "Mauritania",
        "flag": "🇲🇷",
        "code": "MR",
        "dialCode": "+222",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+222",
        "key": 135,
        "dialCodeWithOutPlusSign": "222",
        "countryJoin": "Mauritania"
    },
    {
        "ru": "Маврикий",
        "lt": "Mauricijus",
        "tr": "Mauritius",
        "en": "Mauritius",
        "flag": "🇲🇺",
        "code": "MU",
        "dialCode": "+230",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+230",
        "key": 136,
        "dialCodeWithOutPlusSign": "230",
        "countryJoin": "Mauritius"
    },
    {
        "ru": "Майотта",
        "lt": "Majotas",
        "tr": "Mayotte",
        "en": "Mayotte",
        "flag": "🇾🇹",
        "code": "YT",
        "dialCode": "+262",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+262",
        "key": 137,
        "dialCodeWithOutPlusSign": "262",
        "countryJoin": "Mayotte"
    },
    {
        "ru": "Мексика",
        "lt": "Meksika",
        "tr": "Meksika",
        "en": "Mexico",
        "flag": "🇲🇽",
        "code": "MX",
        "dialCode": "+52",
        "mask": "999-999 9999",
        "maskWithDialCode": "###-### ####",
        "name": "+52",
        "key": 138,
        "dialCodeWithOutPlusSign": "52",
        "countryJoin": "Mexico"
    },
    {
        "ru": "Микронезия, Федеративные Штаты Микронезии",
        "lt": "Mikronezijos Federacinės Mikronezijos valstybės",
        "tr": "Mikronezya, Mikronezya Federal Devletleri",
        "en": "Micronesia, Federated States of Micronesia",
        "flag": "🇫🇲",
        "code": "FM",
        "dialCode": "+691",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+691",
        "key": 139,
        "dialCodeWithOutPlusSign": "691",
        "countryJoin": "Micronesia, Federated States of Micronesia"
    },
    {
        "ru": "Молдова",
        "lt": "Moldovoje",
        "tr": "Moldova",
        "en": "Moldova",
        "flag": "🇲🇩",
        "code": "MD",
        "dialCode": "+373",
        "mask": "9999 99 999",
        "maskWithDialCode": "#### ## ###",
        "name": "+373",
        "key": 140,
        "dialCodeWithOutPlusSign": "373",
        "countryJoin": "Moldova"
    },
    {
        "ru": "Монако",
        "lt": "Monakas",
        "tr": "Monako",
        "en": "Monaco",
        "flag": "🇲🇨",
        "code": "MC",
        "dialCode": "+377",
        "mask": "99 99 99 99 99",
        "maskWithDialCode": "## ## ## ## ##",
        "name": "+377",
        "key": 141,
        "dialCodeWithOutPlusSign": "377",
        "countryJoin": "Monaco"
    },
    {
        "ru": "Монголия",
        "lt": "Mongolija",
        "tr": "Moğolistan",
        "en": "Mongolia",
        "flag": "🇲🇳",
        "code": "MN",
        "dialCode": "+976",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+976",
        "key": 142,
        "dialCodeWithOutPlusSign": "976",
        "countryJoin": "Mongolia"
    },
    {
        "ru": "Черногория",
        "lt": "Juodkalnija",
        "tr": "Karadağ",
        "en": "Montenegro",
        "flag": "🇲🇪",
        "code": "ME",
        "dialCode": "+382",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+382",
        "key": 143,
        "dialCodeWithOutPlusSign": "382",
        "countryJoin": "Montenegro"
    },
    {
        "ru": "Монсеррат",
        "lt": "Montserratas",
        "tr": "Montserrat",
        "en": "Montserrat",
        "flag": "🇲🇸",
        "code": "MS",
        "dialCode": "+1664",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1664",
        "key": 144,
        "dialCodeWithOutPlusSign": "1664",
        "countryJoin": "Montserrat"
    },
    {
        "ru": "Марокко",
        "lt": "Marokas",
        "tr": "Fas",
        "en": "Morocco",
        "flag": "🇲🇦",
        "code": "MA",
        "dialCode": "+212",
        "mask": "9999-999999",
        "maskWithDialCode": "####-######",
        "name": "+212",
        "key": 145,
        "dialCodeWithOutPlusSign": "212",
        "countryJoin": "Morocco"
    },
    {
        "ru": "Мозамбик",
        "lt": "Mozambikas",
        "tr": "Mozambik",
        "en": "Mozambique",
        "flag": "🇲🇿",
        "code": "MZ",
        "dialCode": "+258",
        "mask": "99 999 9999",
        "maskWithDialCode": "## ### ####",
        "name": "+258",
        "key": 146,
        "dialCodeWithOutPlusSign": "258",
        "countryJoin": "Mozambique"
    },
    {
        "ru": "Мьянма",
        "lt": "Mianmaras",
        "tr": "Myanmar",
        "en": "Myanmar",
        "flag": "🇲🇲",
        "code": "MM",
        "dialCode": "+95",
        "mask": "99 999 9999",
        "maskWithDialCode": "## ### ####",
        "name": "+95",
        "key": 147,
        "dialCodeWithOutPlusSign": "95",
        "countryJoin": "Myanmar"
    },
    {
        "ru": "Намибия",
        "lt": "Namibija",
        "tr": "Namibya",
        "en": "Namibia",
        "flag": "🇳🇦",
        "code": "NA",
        "dialCode": "+264",
        "mask": "999 999 99999",
        "maskWithDialCode": "### ### #####",
        "name": "+264",
        "key": 148,
        "dialCodeWithOutPlusSign": "264",
        "countryJoin": "Namibia"
    },
    {
        "ru": "Науру",
        "lt": "Nauru",
        "tr": "Nauru",
        "en": "Nauru",
        "flag": "🇳🇷",
        "code": "NR",
        "dialCode": "+674",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+674",
        "key": 149,
        "dialCodeWithOutPlusSign": "674",
        "countryJoin": "Nauru"
    },
    {
        "ru": "Непал",
        "lt": "Nepalas",
        "tr": "Nepal",
        "en": "Nepal",
        "flag": "🇳🇵",
        "code": "NP",
        "dialCode": "+977",
        "mask": "999-9999999",
        "maskWithDialCode": "###-#######",
        "name": "+977",
        "key": 150,
        "dialCodeWithOutPlusSign": "977",
        "countryJoin": "Nepal"
    },
    {
        "ru": "Нидерланды",
        "lt": "Nyderlandai",
        "tr": "Hollanda",
        "en": "Netherlands",
        "flag": "🇳🇱",
        "code": "NL",
        "dialCode": "+31",
        "mask": "99 99999999",
        "maskWithDialCode": "## ########",
        "name": "+31",
        "key": 151,
        "dialCodeWithOutPlusSign": "31",
        "countryJoin": "Netherlands"
    },
    {
        "ru": "Новая Каледония",
        "lt": "Naujoji Kaledonija",
        "tr": "Yeni Kaledonya",
        "en": "New Caledonia",
        "flag": "🇳🇨",
        "code": "NC",
        "dialCode": "+687",
        "mask": "99.99.99",
        "maskWithDialCode": "##.##.##",
        "name": "+687",
        "key": 152,
        "dialCodeWithOutPlusSign": "687",
        "countryJoin": "New Caledonia"
    },
    {
        "ru": "Новая Зеландия",
        "lt": "Naujoji Zelandija",
        "tr": "Yeni Zelanda",
        "en": "New Zealand",
        "flag": "🇳🇿",
        "code": "NZ",
        "dialCode": "+64",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+64",
        "key": 153,
        "dialCodeWithOutPlusSign": "64",
        "countryJoin": "New Zealand"
    },
    {
        "ru": "Никарагуа",
        "lt": "Nikaragva",
        "tr": "Nikaragua",
        "en": "Nicaragua",
        "flag": "🇳🇮",
        "code": "NI",
        "dialCode": "+505",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+505",
        "key": 154,
        "dialCodeWithOutPlusSign": "505",
        "countryJoin": "Nicaragua"
    },
    {
        "ru": "Нигер",
        "lt": "Nigeris",
        "tr": "Nijer",
        "en": "Niger",
        "flag": "🇳🇪",
        "code": "NE",
        "dialCode": "+227",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+227",
        "key": 155,
        "dialCodeWithOutPlusSign": "227",
        "countryJoin": "Niger"
    },
    {
        "ru": "Нигерия",
        "lt": "Nigerija",
        "tr": "Nijerya",
        "en": "Nigeria",
        "flag": "🇳🇬",
        "code": "NG",
        "dialCode": "+234",
        "mask": "9999 999 9999",
        "maskWithDialCode": "#### ### ####",
        "name": "+234",
        "key": 156,
        "dialCodeWithOutPlusSign": "234",
        "countryJoin": "Nigeria"
    },
    {
        "ru": "Niue",
        "lt": "Niue",
        "tr": "Niue",
        "en": "Niue",
        "flag": "🇳🇺",
        "code": "NU",
        "dialCode": "+683",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+683",
        "key": 157,
        "dialCodeWithOutPlusSign": "683",
        "countryJoin": "Niue"
    },
    {
        "ru": "Остров Норфолк",
        "lt": "Norfolko sala",
        "tr": "Norfolk Adası",
        "en": "Norfolk Island",
        "flag": "🇳🇫",
        "code": "NF",
        "dialCode": "+672",
        "mask": "9 99999",
        "maskWithDialCode": "# #####",
        "name": "+672",
        "key": 158,
        "dialCodeWithOutPlusSign": "672",
        "countryJoin": "Norfolk Island"
    },
    {
        "ru": "Северные Марианские острова",
        "lt": "Šiaurinės Marianos salos",
        "tr": "Kuzey Mariana Adaları",
        "en": "Northern Mariana Islands",
        "flag": "🇲🇵",
        "code": "MP",
        "dialCode": "+1670",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1670",
        "key": 159,
        "dialCodeWithOutPlusSign": "1670",
        "countryJoin": "Northern Mariana Islands"
    },
    {
        "ru": "Норвегия",
        "lt": "Norvegija",
        "tr": "Norveç",
        "en": "Norway",
        "flag": "🇳🇴",
        "code": "NO",
        "dialCode": "+47",
        "mask": "999 99 999",
        "maskWithDialCode": "### ## ###",
        "name": "+47",
        "key": 160,
        "dialCodeWithOutPlusSign": "47",
        "countryJoin": "Bouvet Island / Norway / Svalbard and Jan Mayen"
    },
    {
        "ru": "Оман",
        "lt": "Omanas",
        "tr": "Umman",
        "en": "Oman",
        "flag": "🇴🇲",
        "code": "OM",
        "dialCode": "+968",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+968",
        "key": 161,
        "dialCodeWithOutPlusSign": "968",
        "countryJoin": "Oman"
    },
    {
        "ru": "Пакистан",
        "lt": "Pakistanas",
        "tr": "Pakistan",
        "en": "Pakistan",
        "flag": "🇵🇰",
        "code": "PK",
        "dialCode": "+92",
        "mask": "9999 9999999",
        "maskWithDialCode": "#### #######",
        "name": "+92",
        "key": 162,
        "dialCodeWithOutPlusSign": "92",
        "countryJoin": "Pakistan"
    },
    {
        "ru": "Palau",
        "lt": "Palau",
        "tr": "Palau",
        "en": "Palau",
        "flag": "🇵🇼",
        "code": "PW",
        "dialCode": "+680",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+680",
        "key": 163,
        "dialCodeWithOutPlusSign": "680",
        "countryJoin": "Palau"
    },
    {
        "ru": "Палестинская территория, оккупированная",
        "lt": "Palestinos teritorija, okupuota",
        "tr": "Filistin Arazisi, İşgal altında",
        "en": "Palestinian Territory, Occupied",
        "flag": "🇵🇸",
        "code": "PS",
        "dialCode": "+970",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+970",
        "key": 164,
        "dialCodeWithOutPlusSign": "970",
        "countryJoin": "Palestinian Territory, Occupied"
    },
    {
        "ru": "Панама",
        "lt": "Panama",
        "tr": "Panama",
        "en": "Panama",
        "flag": "🇵🇦",
        "code": "PA",
        "dialCode": "+507",
        "mask": "9999-9999",
        "maskWithDialCode": "####-####",
        "name": "+507",
        "key": 165,
        "dialCodeWithOutPlusSign": "507",
        "countryJoin": "Panama"
    },
    {
        "ru": "Папуа - Новая Гвинея",
        "lt": "Papua Naujoji Gvinėja",
        "tr": "Papua Yeni Gine",
        "en": "Papua New Guinea",
        "flag": "🇵🇬",
        "code": "PG",
        "dialCode": "+675",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+675",
        "key": 166,
        "dialCodeWithOutPlusSign": "675",
        "countryJoin": "Papua New Guinea"
    },
    {
        "ru": "Парагвай",
        "lt": "Paragvajus",
        "tr": "Paraguay",
        "en": "Paraguay",
        "flag": "🇵🇾",
        "code": "PY",
        "dialCode": "+595",
        "mask": "9999 999999",
        "maskWithDialCode": "#### ######",
        "name": "+595",
        "key": 167,
        "dialCodeWithOutPlusSign": "595",
        "countryJoin": "Paraguay"
    },
    {
        "ru": "Перу",
        "lt": "Peru",
        "tr": "Peru",
        "en": "Peru",
        "flag": "🇵🇪",
        "code": "PE",
        "dialCode": "+51",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+51",
        "key": 168,
        "dialCodeWithOutPlusSign": "51",
        "countryJoin": "Peru"
    },
    {
        "ru": "Филиппины",
        "lt": "Filipinai",
        "tr": "Filipinler",
        "en": "Philippines",
        "flag": "🇵🇭",
        "code": "PH",
        "dialCode": "+63",
        "mask": "9999 999 9999",
        "maskWithDialCode": "#### ### ####",
        "name": "+63",
        "key": 169,
        "dialCodeWithOutPlusSign": "63",
        "countryJoin": "Philippines"
    },
    {
        "ru": "Польша",
        "lt": "Lenkija",
        "tr": "Polonya",
        "en": "Poland",
        "flag": "🇵🇱",
        "code": "PL",
        "dialCode": "+48",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+48",
        "key": 170,
        "dialCodeWithOutPlusSign": "48",
        "countryJoin": "Poland"
    },
    {
        "ru": "Португалия",
        "lt": "Portugalija",
        "tr": "Portekiz",
        "en": "Portugal",
        "flag": "🇵🇹",
        "code": "PT",
        "dialCode": "+351",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+351",
        "key": 171,
        "dialCodeWithOutPlusSign": "351",
        "countryJoin": "Portugal"
    },
    {
        "ru": "Пуэрто-Рико",
        "lt": "Puerto Rikas",
        "tr": "Porto Riko",
        "en": "Puerto Rico",
        "flag": "🇵🇷",
        "code": "PR",
        "dialCode": "+1939",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1939",
        "key": 172,
        "dialCodeWithOutPlusSign": "1939",
        "countryJoin": "Puerto Rico"
    },
    {
        "ru": "Катар",
        "lt": "Kataras",
        "tr": "Katar",
        "en": "Qatar",
        "flag": "🇶🇦",
        "code": "QA",
        "dialCode": "+974",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+974",
        "key": 173,
        "dialCodeWithOutPlusSign": "974",
        "countryJoin": "Qatar"
    },
    {
        "ru": "Румыния",
        "lt": "Rumunija",
        "tr": "Romanya",
        "en": "Romania",
        "flag": "🇷🇴",
        "code": "RO",
        "dialCode": "+40",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+40",
        "key": 174,
        "dialCodeWithOutPlusSign": "40",
        "countryJoin": "Romania"
    },
    {
        "ru": "Россия",
        "lt": "Rusija",
        "tr": "Rusya",
        "en": "Russia",
        "flag": "🇷🇺",
        "code": "RU",
        "dialCode": "+7",
        "mask": "(999) 999-99-99",
        "maskWithDialCode": "(###) ###-##-##",
        "name": "+7",
        "key": 175,
        "dialCodeWithOutPlusSign": "7",
        "countryJoin": "Kazakhstan / Russia"
    },
    {
        "ru": "Руанда",
        "lt": "Ruanda",
        "tr": "Ruanda",
        "en": "Rwanda",
        "flag": "🇷🇼",
        "code": "RW",
        "dialCode": "+250",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+250",
        "key": 176,
        "dialCodeWithOutPlusSign": "250",
        "countryJoin": "Rwanda"
    },
    {
        "ru": "Сен-Бартельми",
        "lt": "Šventasis Barthelemy",
        "tr": "Saint Barthelemy",
        "en": "Saint Barthelemy",
        "flag": "🇧🇱",
        "code": "BL",
        "dialCode": "+590",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+590",
        "key": 177,
        "dialCodeWithOutPlusSign": "590",
        "countryJoin": "Guadeloupe / Saint Barthelemy / Saint Martin"
    },
    {
        "ru": "Святой Елены, Вознесения и Тристан-да-Кунья",
        "lt": "Šventoji Helena, Ascension ir Tristan Da Cunha",
        "tr": "Saint Helena, Yükseliş ve Tristan Da Cunha",
        "en": "Saint Helena, Ascension and Tristan Da Cunha",
        "flag": "🇸🇭",
        "code": "SH",
        "dialCode": "+290",
        "mask": "99999",
        "maskWithDialCode": "#####",
        "name": "+290",
        "key": 178,
        "dialCodeWithOutPlusSign": "290",
        "countryJoin": "Saint Helena, Ascension and Tristan Da Cunha"
    },
    {
        "ru": "Сент-Китс и Невис",
        "lt": "Sent Kitsas ir Nevis",
        "tr": "Saint Kitts ve Nevis",
        "en": "Saint Kitts and Nevis",
        "flag": "🇰🇳",
        "code": "KN",
        "dialCode": "+1869",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1869",
        "key": 179,
        "dialCodeWithOutPlusSign": "1869",
        "countryJoin": "Saint Kitts and Nevis"
    },
    {
        "ru": "Сент-Люсия",
        "lt": "Sent Lusija",
        "tr": "Saint Lucia",
        "en": "Saint Lucia",
        "flag": "🇱🇨",
        "code": "LC",
        "dialCode": "+1758",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1758",
        "key": 180,
        "dialCodeWithOutPlusSign": "1758",
        "countryJoin": "Saint Lucia"
    },
    {
        "ru": "Святой мартин",
        "lt": "Sent Martinas",
        "tr": "Aziz Martin",
        "en": "Saint Martin",
        "flag": "🇲🇫",
        "code": "MF",
        "dialCode": "+590",
        "mask": "9999 99 99 99",
        "maskWithDialCode": "#### ## ## ##",
        "name": "+590",
        "key": 181,
        "dialCodeWithOutPlusSign": "590",
        "countryJoin": "Guadeloupe / Saint Barthelemy / Saint Martin"
    },
    {
        "ru": "Сен-Пьер и Микелон",
        "lt": "Sent Pjeras ir Mikelonas",
        "tr": "Saint Pierre ve Miquelon",
        "en": "Saint Pierre and Miquelon",
        "flag": "🇵🇲",
        "code": "PM",
        "dialCode": "+508",
        "mask": "999 99 99",
        "maskWithDialCode": "### ## ##",
        "name": "+508",
        "key": 182,
        "dialCodeWithOutPlusSign": "508",
        "countryJoin": "Saint Pierre and Miquelon"
    },
    {
        "ru": "Святой Винсент и Гренадины",
        "lt": "Sent Vinsentas ir Grenadinai",
        "tr": "Saint Vincent ve Grenadinler",
        "en": "Saint Vincent and the Grenadines",
        "flag": "🇻🇨",
        "code": "VC",
        "dialCode": "+1784",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1784",
        "key": 183,
        "dialCodeWithOutPlusSign": "1784",
        "countryJoin": "Saint Vincent and the Grenadines"
    },
    {
        "ru": "Самоа",
        "lt": "Samoa",
        "tr": "Samoa",
        "en": "Samoa",
        "flag": "🇼🇸",
        "code": "WS",
        "dialCode": "+685",
        "mask": "99 99999",
        "maskWithDialCode": "## #####",
        "name": "+685",
        "key": 184,
        "dialCodeWithOutPlusSign": "685",
        "countryJoin": "Samoa"
    },
    {
        "ru": "Сан-Марино",
        "lt": "San Marinas",
        "tr": "San Marino",
        "en": "San Marino",
        "flag": "🇸🇲",
        "code": "SM",
        "dialCode": "+378",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+378",
        "key": 185,
        "dialCodeWithOutPlusSign": "378",
        "countryJoin": "San Marino"
    },
    {
        "ru": "Сан-Томе и Принсипи",
        "lt": "San Tomė ir Principe",
        "tr": "Sao Tome ve Principe",
        "en": "Sao Tome and Principe",
        "flag": "🇸🇹",
        "code": "ST",
        "dialCode": "+239",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+239",
        "key": 186,
        "dialCodeWithOutPlusSign": "239",
        "countryJoin": "Sao Tome and Principe"
    },
    {
        "ru": "Саудовская Аравия",
        "lt": "Saudo Arabija",
        "tr": "Suudi Arabistan",
        "en": "Saudi Arabia",
        "flag": "🇸🇦",
        "code": "SA",
        "dialCode": "+966",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+966",
        "key": 187,
        "dialCodeWithOutPlusSign": "966",
        "countryJoin": "Saudi Arabia"
    },
    {
        "ru": "Сенегал",
        "lt": "Senegalas",
        "tr": "Senegal",
        "en": "Senegal",
        "flag": "🇸🇳",
        "code": "SN",
        "dialCode": "+221",
        "mask": "99 999 99 99",
        "maskWithDialCode": "## ### ## ##",
        "name": "+221",
        "key": 188,
        "dialCodeWithOutPlusSign": "221",
        "countryJoin": "Senegal"
    },
    {
        "ru": "Сербия",
        "lt": "Serbija",
        "tr": "Sırbistan",
        "en": "Serbia",
        "flag": "🇷🇸",
        "code": "RS",
        "dialCode": "+381",
        "mask": "999 9999999",
        "maskWithDialCode": "### #######",
        "name": "+381",
        "key": 189,
        "dialCodeWithOutPlusSign": "381",
        "countryJoin": "Serbia"
    },
    {
        "ru": "Сейшельские острова",
        "lt": "Seišeliai",
        "tr": "Seyşeller",
        "en": "Seychelles",
        "flag": "🇸🇨",
        "code": "SC",
        "dialCode": "+248",
        "mask": "9 999 999",
        "maskWithDialCode": "# ### ###",
        "name": "+248",
        "key": 190,
        "dialCodeWithOutPlusSign": "248",
        "countryJoin": "Seychelles"
    },
    {
        "ru": "Сьерра-Леоне",
        "lt": "Siera Leonė",
        "tr": "Sierra Leone",
        "en": "Sierra Leone",
        "flag": "🇸🇱",
        "code": "SL",
        "dialCode": "+232",
        "mask": "(999) 999999",
        "maskWithDialCode": "(###) ######",
        "name": "+232",
        "key": 191,
        "dialCodeWithOutPlusSign": "232",
        "countryJoin": "Sierra Leone"
    },
    {
        "ru": "Сингапур",
        "lt": "Singapūras",
        "tr": "Singapur",
        "en": "Singapore",
        "flag": "🇸🇬",
        "code": "SG",
        "dialCode": "+65",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+65",
        "key": 192,
        "dialCodeWithOutPlusSign": "65",
        "countryJoin": "Singapore"
    },
    {
        "ru": "Словакия",
        "lt": "Slovakija",
        "tr": "Slovakya",
        "en": "Slovakia",
        "flag": "🇸🇰",
        "code": "SK",
        "dialCode": "+421",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+421",
        "key": 193,
        "dialCodeWithOutPlusSign": "421",
        "countryJoin": "Slovakia"
    },
    {
        "ru": "Словения",
        "lt": "Slovėnija",
        "tr": "Slovenya",
        "en": "Slovenia",
        "flag": "🇸🇮",
        "code": "SI",
        "dialCode": "+386",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+386",
        "key": 194,
        "dialCodeWithOutPlusSign": "386",
        "countryJoin": "Slovenia"
    },
    {
        "ru": "Соломоновы острова",
        "lt": "Saliamono salos",
        "tr": "Solomon Adaları",
        "en": "Solomon Islands",
        "flag": "🇸🇧",
        "code": "SB",
        "dialCode": "+677",
        "mask": "99 99999",
        "maskWithDialCode": "## #####",
        "name": "+677",
        "key": 195,
        "dialCodeWithOutPlusSign": "677",
        "countryJoin": "Solomon Islands"
    },
    {
        "ru": "Сомали",
        "lt": "Somalis",
        "tr": "Somali",
        "en": "Somalia",
        "flag": "🇸🇴",
        "code": "SO",
        "dialCode": "+252",
        "mask": "9 9999999",
        "maskWithDialCode": "# #######",
        "name": "+252",
        "key": 196,
        "dialCodeWithOutPlusSign": "252",
        "countryJoin": "Somalia"
    },
    {
        "ru": "Южная Африка",
        "lt": "pietų Afrika",
        "tr": "Güney Afrika",
        "en": "South Africa",
        "flag": "🇿🇦",
        "code": "ZA",
        "dialCode": "+27",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+27",
        "key": 197,
        "dialCodeWithOutPlusSign": "27",
        "countryJoin": "South Africa"
    },
    {
        "ru": "южный Судан",
        "lt": "Pietų Sudanas",
        "tr": "Güney Sudan",
        "en": "South Sudan",
        "flag": "🇸🇸",
        "code": "SS",
        "dialCode": "+211",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+211",
        "key": 198,
        "dialCodeWithOutPlusSign": "211",
        "countryJoin": "South Sudan"
    },
    {
        "ru": "Испания",
        "lt": "Ispanija",
        "tr": "ispanya",
        "en": "Spain",
        "flag": "🇪🇸",
        "code": "ES",
        "dialCode": "+34",
        "mask": "999 99 99 99",
        "maskWithDialCode": "### ## ## ##",
        "name": "+34",
        "key": 199,
        "dialCodeWithOutPlusSign": "34",
        "countryJoin": "Spain"
    },
    {
        "ru": "Шри-Ланка",
        "lt": "Šri Lanka",
        "tr": "Sri Lanka",
        "en": "Sri Lanka",
        "flag": "🇱🇰",
        "code": "LK",
        "dialCode": "+94",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+94",
        "key": 200,
        "dialCodeWithOutPlusSign": "94",
        "countryJoin": "Sri Lanka"
    },
    {
        "ru": "Судан",
        "lt": "Sudanas",
        "tr": "Sudan",
        "en": "Sudan",
        "flag": "🇸🇩",
        "code": "SD",
        "dialCode": "+249",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+249",
        "key": 201,
        "dialCodeWithOutPlusSign": "249",
        "countryJoin": "Sudan"
    },
    {
        "ru": "Суринам",
        "lt": "Surinamas",
        "tr": "Surinam",
        "en": "Suriname",
        "flag": "🇸🇷",
        "code": "SR",
        "dialCode": "+597",
        "mask": "999-9999",
        "maskWithDialCode": "###-####",
        "name": "+597",
        "key": 202,
        "dialCodeWithOutPlusSign": "597",
        "countryJoin": "Suriname"
    },
    {
        "ru": "Шпицберген и Ян Майен",
        "lt": "Svalbardas ir Janas Mayenas",
        "tr": "Svalbard ve Jan Mayen",
        "en": "Svalbard and Jan Mayen",
        "flag": "🇸🇯",
        "code": "SJ",
        "dialCode": "+47",
        "mask": "999 99 999",
        "maskWithDialCode": "### ## ###",
        "name": "+47",
        "key": 203,
        "dialCodeWithOutPlusSign": "47",
        "countryJoin": "Bouvet Island / Norway / Svalbard and Jan Mayen"
    },
    {
        "ru": "Свазиленд",
        "lt": "Svazilandas",
        "tr": "Svaziland",
        "en": "Swaziland",
        "flag": "🇸🇿",
        "code": "SZ",
        "dialCode": "+268",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+268",
        "key": 204,
        "dialCodeWithOutPlusSign": "268",
        "countryJoin": "Swaziland"
    },
    {
        "ru": "Швеция",
        "lt": "Švedija",
        "tr": "İsveç",
        "en": "Sweden",
        "flag": "🇸🇪",
        "code": "SE",
        "dialCode": "+46",
        "mask": "999-999 99 99",
        "maskWithDialCode": "###-### ## ##",
        "name": "+46",
        "key": 205,
        "dialCodeWithOutPlusSign": "46",
        "countryJoin": "Sweden"
    },
    {
        "ru": "Швейцария",
        "lt": "Šveicarija",
        "tr": "İsviçre",
        "en": "Switzerland",
        "flag": "🇨🇭",
        "code": "CH",
        "dialCode": "+41",
        "mask": "999 999 99 99",
        "maskWithDialCode": "### ### ## ##",
        "name": "+41",
        "key": 206,
        "dialCodeWithOutPlusSign": "41",
        "countryJoin": "Switzerland"
    },
    {
        "ru": "Сирийская Арабская Республика",
        "lt": "Sirijos Arabų Respublika",
        "tr": "Suriye Arap Cumhuriyeti",
        "en": "Syrian Arab Republic",
        "flag": "🇸🇾",
        "code": "SY",
        "dialCode": "+963",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+963",
        "key": 207,
        "dialCodeWithOutPlusSign": "963",
        "countryJoin": "Syrian Arab Republic"
    },
    {
        "ru": "Тайвань",
        "lt": "Taivanas",
        "tr": "Tayvan",
        "en": "Taiwan",
        "flag": "🇹🇼",
        "code": "TW",
        "dialCode": "+886",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+886",
        "key": 208,
        "dialCodeWithOutPlusSign": "886",
        "countryJoin": "Taiwan"
    },
    {
        "ru": "Таджикистан",
        "lt": "Tadžikistanas",
        "tr": "Tacikistan",
        "en": "Tajikistan",
        "flag": "🇹🇯",
        "code": "TJ",
        "dialCode": "+992",
        "mask": "999 99 9999",
        "maskWithDialCode": "### ## ####",
        "name": "+992",
        "key": 209,
        "dialCodeWithOutPlusSign": "992",
        "countryJoin": "Tajikistan"
    },
    {
        "ru": "Танзания, Объединенная Республика Танзания",
        "lt": "Tanzanija, Jungtinė Tanzanijos Respublika",
        "tr": "Tanzanya, Tanzanya Birleşik Cumhuriyeti",
        "en": "Tanzania, United Republic of Tanzania",
        "flag": "🇹🇿",
        "code": "TZ",
        "dialCode": "+255",
        "mask": "9999 999 999",
        "maskWithDialCode": "#### ### ###",
        "name": "+255",
        "key": 210,
        "dialCodeWithOutPlusSign": "255",
        "countryJoin": "Tanzania, United Republic of Tanzania"
    },
    {
        "ru": "Таиланд",
        "lt": "Tailandas",
        "tr": "Tayland",
        "en": "Thailand",
        "flag": "🇹🇭",
        "code": "TH",
        "dialCode": "+66",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+66",
        "key": 211,
        "dialCodeWithOutPlusSign": "66",
        "countryJoin": "Thailand"
    },
    {
        "ru": "Восточный Тимор",
        "lt": "Timoras-Leste",
        "tr": "Timor-Leste",
        "en": "Timor-Leste",
        "flag": "🇹🇱",
        "code": "TL",
        "dialCode": "+670",
        "mask": "9999 9999",
        "maskWithDialCode": "#### ####",
        "name": "+670",
        "key": 212,
        "dialCodeWithOutPlusSign": "670",
        "countryJoin": "Timor-Leste"
    },
    {
        "ru": "Идти",
        "lt": "Eiti",
        "tr": "Gitmek",
        "en": "Togo",
        "flag": "🇹🇬",
        "code": "TG",
        "dialCode": "+228",
        "mask": "99 99 99 99",
        "maskWithDialCode": "## ## ## ##",
        "name": "+228",
        "key": 213,
        "dialCodeWithOutPlusSign": "228",
        "countryJoin": "Togo"
    },
    {
        "ru": "Токелау",
        "lt": "Tokelau",
        "tr": "Tokelau",
        "en": "Tokelau",
        "flag": "🇹🇰",
        "code": "TK",
        "dialCode": "+690",
        "mask": "9999",
        "maskWithDialCode": "####",
        "name": "+690",
        "key": 214,
        "dialCodeWithOutPlusSign": "690",
        "countryJoin": "Tokelau"
    },
    {
        "ru": "Тонга",
        "lt": "Tonga",
        "tr": "Tonga",
        "en": "Tonga",
        "flag": "🇹🇴",
        "code": "TO",
        "dialCode": "+676",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+676",
        "key": 215,
        "dialCodeWithOutPlusSign": "676",
        "countryJoin": "Tonga"
    },
    {
        "ru": "Тринидад и Тобаго",
        "lt": "Trinidadas ir Tobagas",
        "tr": "Trinidad ve Tobago",
        "en": "Trinidad and Tobago",
        "flag": "🇹🇹",
        "code": "TT",
        "dialCode": "+1868",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1868",
        "key": 216,
        "dialCodeWithOutPlusSign": "1868",
        "countryJoin": "Trinidad and Tobago"
    },
    {
        "ru": "Тунис",
        "lt": "Tunisas",
        "tr": "Tunus",
        "en": "Tunisia",
        "flag": "🇹🇳",
        "code": "TN",
        "dialCode": "+216",
        "mask": "99 999 999",
        "maskWithDialCode": "## ### ###",
        "name": "+216",
        "key": 217,
        "dialCodeWithOutPlusSign": "216",
        "countryJoin": "Tunisia"
    },
    {
        "ru": "индейка",
        "lt": "Turkija",
        "tr": "Türkiye",
        "en": "Turkey",
        "flag": "🇹🇷",
        "code": "TR",
        "dialCode": "+90",
        "mask": "(999) 999 9999",
        "maskWithDialCode": "(###) ### ####",
        "name": "+90",
        "key": 218,
        "dialCodeWithOutPlusSign": "90",
        "countryJoin": "Turkey"
    },
    {
        "ru": "Туркменистан",
        "lt": "Turkmėnistanas",
        "tr": "Türkmenistan",
        "en": "Turkmenistan",
        "flag": "🇹🇲",
        "code": "TM",
        "dialCode": "+993",
        "mask": "9 99 999999",
        "maskWithDialCode": "# ## ######",
        "name": "+993",
        "key": 219,
        "dialCodeWithOutPlusSign": "993",
        "countryJoin": "Turkmenistan"
    },
    {
        "ru": "Острова Теркс и Кайкос",
        "lt": "Terkso ir Kaikoso salos",
        "tr": "Turks ve Caicos Adaları",
        "en": "Turks and Caicos Islands",
        "flag": "🇹🇨",
        "code": "TC",
        "dialCode": "+1649",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1649",
        "key": 220,
        "dialCodeWithOutPlusSign": "1649",
        "countryJoin": "Turks and Caicos Islands"
    },
    {
        "ru": "Тувалу",
        "lt": "Tuvalu",
        "tr": "Tuvalu",
        "en": "Tuvalu",
        "flag": "🇹🇻",
        "code": "TV",
        "dialCode": "+688",
        "mask": "999999",
        "maskWithDialCode": "######",
        "name": "+688",
        "key": 221,
        "dialCodeWithOutPlusSign": "688",
        "countryJoin": "Tuvalu"
    },
    {
        "ru": "Уганда",
        "lt": "Uganda",
        "tr": "Uganda",
        "en": "Uganda",
        "flag": "🇺🇬",
        "code": "UG",
        "dialCode": "+256",
        "mask": "9999 999999",
        "maskWithDialCode": "#### ######",
        "name": "+256",
        "key": 222,
        "dialCodeWithOutPlusSign": "256",
        "countryJoin": "Uganda"
    },
    {
        "ru": "Украина",
        "lt": "Ukraina",
        "tr": "Ukrayna",
        "en": "Ukraine",
        "flag": "🇺🇦",
        "code": "UA",
        "dialCode": "+380",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+380",
        "key": 223,
        "dialCodeWithOutPlusSign": "380",
        "countryJoin": "Ukraine"
    },
    {
        "ru": "Объединенные Арабские Эмираты",
        "lt": "Jungtiniai Arabų Emyratai",
        "tr": "Birleşik Arap Emirlikleri",
        "en": "United Arab Emirates",
        "flag": "🇦🇪",
        "code": "AE",
        "dialCode": "+971",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+971",
        "key": 224,
        "dialCodeWithOutPlusSign": "971",
        "countryJoin": "United Arab Emirates"
    },
    {
        "ru": "объединенное Королевство",
        "lt": "Jungtinė Karalystė",
        "tr": "Birleşik Krallık",
        "en": "United Kingdom",
        "flag": "🇬🇧",
        "code": "GB",
        "dialCode": "+44",
        "mask": "99999 999999",
        "maskWithDialCode": "##### ######",
        "name": "+44",
        "key": 225,
        "dialCodeWithOutPlusSign": "44",
        "countryJoin": "Guernsey / Isle of Man / Jersey / United Kingdom"
    },
    {
        "ru": "Соединенные Штаты",
        "lt": "Jungtinės Valstijos",
        "tr": "Amerika Birleşik Devletleri",
        "en": "United States",
        "flag": "🇺🇸",
        "code": "US",
        "dialCode": "+1",
        "mask": "(999) 999-9999",
        "maskWithDialCode": "(###) ###-####",
        "name": "+1",
        "key": 226,
        "dialCodeWithOutPlusSign": "1",
        "countryJoin": "Canada / United States"
    },
    {
        "ru": "Уругвай",
        "lt": "Urugvajus",
        "tr": "Uruguay",
        "en": "Uruguay",
        "flag": "🇺🇾",
        "code": "UY",
        "dialCode": "+598",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+598",
        "key": 227,
        "dialCodeWithOutPlusSign": "598",
        "countryJoin": "Uruguay"
    },
    {
        "ru": "Узбекистан",
        "lt": "Uzbekistanas",
        "tr": "Özbekistan",
        "en": "Uzbekistan",
        "flag": "🇺🇿",
        "code": "UZ",
        "dialCode": "+998",
        "mask": "9 99 999 99 99",
        "maskWithDialCode": "# ## ### ## ##",
        "name": "+998",
        "key": 228,
        "dialCodeWithOutPlusSign": "998",
        "countryJoin": "Uzbekistan"
    },
    {
        "ru": "Вануату",
        "lt": "Vanuatu",
        "tr": "Vanuatu",
        "en": "Vanuatu",
        "flag": "🇻🇺",
        "code": "VU",
        "dialCode": "+678",
        "mask": "999 9999",
        "maskWithDialCode": "### ####",
        "name": "+678",
        "key": 229,
        "dialCodeWithOutPlusSign": "678",
        "countryJoin": "Vanuatu"
    },
    {
        "ru": "Венесуэла, Боливарианская Республика Венесуэла",
        "lt": "Venesuela, Venesuelos Bolivaro Respublika",
        "tr": "Venezuela, Bolivarcı Venezuela Cumhuriyeti",
        "en": "Venezuela, Bolivarian Republic of Venezuela",
        "flag": "🇻🇪",
        "code": "VE",
        "dialCode": "+58",
        "mask": "9999-9999999",
        "maskWithDialCode": "####-#######",
        "name": "+58",
        "key": 230,
        "dialCodeWithOutPlusSign": "58",
        "countryJoin": "Venezuela, Bolivarian Republic of Venezuela"
    },
    {
        "ru": "Вьетнам",
        "lt": "Vietnamas",
        "tr": "Vietnam",
        "en": "Vietnam",
        "flag": "🇻🇳",
        "code": "VN",
        "dialCode": "+84",
        "mask": "999 999 99 99",
        "maskWithDialCode": "### ### ## ##",
        "name": "+84",
        "key": 231,
        "dialCodeWithOutPlusSign": "84",
        "countryJoin": "Vietnam"
    },
    {
        "ru": "Уоллис и Футуна",
        "lt": "Volis ir Futuna",
        "tr": "Wallis ve Futuna",
        "en": "Wallis and Futuna",
        "flag": "🇼🇫",
        "code": "WF",
        "dialCode": "+681",
        "mask": "99 99 99",
        "maskWithDialCode": "## ## ##",
        "name": "+681",
        "key": 232,
        "dialCodeWithOutPlusSign": "681",
        "countryJoin": "Wallis and Futuna"
    },
    {
        "ru": "Йемен",
        "lt": "Jemenas",
        "tr": "Yemen",
        "en": "Yemen",
        "flag": "🇾🇪",
        "code": "YE",
        "dialCode": "+967",
        "mask": "999 999 999",
        "maskWithDialCode": "### ### ###",
        "name": "+967",
        "key": 233,
        "dialCodeWithOutPlusSign": "967",
        "countryJoin": "Yemen"
    },
    {
        "ru": "Замбия",
        "lt": "Zambija",
        "tr": "Zambiya",
        "en": "Zambia",
        "flag": "🇿🇲",
        "code": "ZM",
        "dialCode": "+260",
        "mask": "999 9999999",
        "maskWithDialCode": "### #######",
        "name": "+260",
        "key": 234,
        "dialCodeWithOutPlusSign": "260",
        "countryJoin": "Zambia"
    },
    {
        "ru": "Zimbabve",
        "lt": "Zimbabvė",
        "tr": "Zimbabve",
        "en": "Zimbabwe",
        "flag": "🇿🇼",
        "code": "ZW",
        "dialCode": "+263",
        "mask": "999 999 9999",
        "maskWithDialCode": "### ### ####",
        "name": "+263",
        "key": 235,
        "dialCodeWithOutPlusSign": "263",
        "countryJoin": "Zimbabwe"
    }
]
const uniqueCountries = [];
const seenCodes = new Set();

allCountries.forEach(country => {
    if (!seenCodes.has(country.dialCode)) {
        seenCodes.add(country.dialCode);
        uniqueCountries.push(country);
    }
});
export default uniqueCountries