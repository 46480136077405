import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import user from './reducers/userReducer';
import dashboard from './reducers/getDashboardReducer';
import userWithoutAuth from './reducers/userWithOutAuthReducer.js';
import userProfileReview from './reducers/userProfileReviewReducer.js';
import setUserProfileReview from './reducers/setUserProfileReviewReducer.js';
import getDropDown from './reducers/getDropDownReducer.js';
import getPartner from './reducers/getPartnerReducer.js';
import setCheckOutReducers from './reducers/setCheckOutReducers.js';
import getPlanReducer from './reducers/getPlanReducer.js';
//reducer
const reducer = combineReducers({
  user,
  userWithoutAuth,
  userProfileReview,
  setUserProfileReview,
  getDropDown,
  getPartner,
  setCheckOutReducers,
  getPlanReducer,
  dashboard
});

// store
const store = configureStore({
  reducer,
});
export default store;