import { useEffect, useState } from "react";
import SliderUserDetailComp from "../../atom/silderUserDetailComp/sliderUserDetailComp";
import { apiRequest } from "../../../services";
import * as env from '../../../config/env.js';
import { useSelector } from "react-redux";

const ViewProfileLeft = () => {
    const [data,setData] = useState([]);
    const userDetails = useSelector(state => state?.user?.user);
    useEffect(() => {
        try {
            apiRequest("get", `${env.GETFEATURECANDIDATE}/${userDetails.userId}`).then((response) => {
                if(response.status === 200 && response.data.data && response.data.data.length){
                    setData(response.data.data);
                }
            }).catch((error) => {
                console.error('error in getting feature candidate ',error);
            })
        }catch(error) {
            console.error('error in getting feature candidate ',error);
        }
    },[userDetails])
    return(
        <>
            {data && data.length ? 
                <>
                    <h4 className="mb-[10px] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular md:text-[30px] leading-[1.2] mb-[12px] text-[24px] text-stroke">Featured Candidates</h4>
                    <SliderUserDetailComp
                        userDetails={data}
                        classWrapper={'mx-[-15px] grid grid-cols-1'}
                        isLike={true}
                        userInfo={userDetails}
                    />
                </>
                : ''
            }
        </>
    )
}

export default ViewProfileLeft;