import moment from "moment";
import Button from "../../atom/button";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
const Profile = (props) => {
    const userDetails = useSelector(state => state?.user?.user);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [age, setAge] = useState(null);
    useEffect(() => {
        const calculateAge = (dateOfBirth) => {
          const dob = moment(dateOfBirth);
          const now = moment();
          return now.diff(dob, 'years');
        };
    
        const dob = userDetails?.horoscopeDateOfBirth || new Date();
        const age = calculateAge(dob);
        setAge(age);
      }, []);
    return(
        <div>
            <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] mb-[20px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">My Profile</h2>
            <div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Basic Info</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Age</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeDateOfBirth ? `${age} Years` : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Height</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicHeight ? dropDown['height']?.option.find(e => e.key === Number(userDetails?.basicHeight))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Religion</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicReligion ? dropDown['religion']?.option.find(e => e.key === Number(userDetails?.basicReligion))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Caste</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicCaste ? dropDown['caste']?.option.find(e => e.key === Number(userDetails?.basicCaste))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Mother Tongue</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicMotherTongue ? dropDown['motherTongue']?.option.find(e => e.key === Number(userDetails?.basicMotherTongue))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Disability</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicDisability ? dropDown['disability']?.option.find(e => e.key === Number(userDetails?.basicDisability))?.name : '-'}</p>
                        </div>
                        {
                            userDetails?.basicDisability === 2 ? 
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Details of Disability</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px] truncate">{userDetails?.basicDisabilityDetail ? userDetails?.basicDisabilityDetail : '-'}</p>
                                </div>
                            : null
                        }
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Profile Created For</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicProfileCreatedFor ? dropDown['profileCreatorFor']?.option.find(e => e.key === Number(userDetails?.basicProfileCreatedFor))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Body Type</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicBodyType ? dropDown['body']?.option.find(e => e.key === Number(userDetails?.basicBodyType))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Weight</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicWeight ? `${dropDown['weight']?.option.find(e => e.key === Number(userDetails?.basicWeight))?.name}` : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Marital Status</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicMaritalStatus ? dropDown['maritalStatus']?.option.find(e => e.key === Number(userDetails?.basicMaritalStatus))?.name : '-'}</p>
                        </div>
                        {
                            userDetails?.basicHaveAChildren && Number(userDetails?.basicMaritalStatus) !== 1 ? 
                            <div className="px-[15px]">
                                <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Have a Children</h5>
                                <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicHaveAChildren ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.basicHaveAChildren))?.name : '-'}</p>
                            </div> : null
                        }
                        {
                            userDetails?.basicMaritalStatus && userDetails?.basicNumberOfChildrens && userDetails?.basicHaveAChildren && Number(userDetails?.basicMaritalStatus) !== 1 && Number(userDetails?.basicHaveAChildren) === 1 ? 
                            <div className="px-[15px]">
                                <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">No. of Childrens</h5>
                                <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicHaveAChildren}</p>
                            </div> : 
                            null
                        }
                        {
                            userDetails?.basicMaritalStatus && userDetails?.basicNumberOfChildrens && Number(userDetails?.basicMaritalStatus) !== 1 && Number(userDetails?.basicHaveAChildren) === 1 ? 
                            <div className="px-[15px]">
                                <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">{dropDown?.['maritalStatus']?.option.find(e => e.key === Number(userDetails?.basicMaritalStatus)).name} Remark</h5>
                                <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicMaritalStatus === 2 ? userDetails?.basicWidowedRemark || '-' : userDetails?.basicMaritalStatus === 3 ? userDetails?.basicDivorcedRemark || '-' : userDetails?.basicMaritalStatus === 4 ? userDetails?.basicAwaitingDivorcedRemark || '-' : '-'}</p>
                            </div> : 
                            null
                        }
                    </div>
                </div>

                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">About Me</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.aboutMe ? userDetails?.aboutMe : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Location Preference</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Country</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.locationCountry ? userDetails?.locationCountry : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">State</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.locationState ? userDetails?.locationState : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">District/City</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.locationCity ? userDetails?.locationCity : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Resident/Visa Status</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.locationResident ?  dropDown['resident']?.option.find(e => e.key === Number(userDetails?.locationResident))?.name : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Horoscope Details</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Star</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeStar ? dropDown['star']?.option.find(e => e.key === Number(userDetails?.horoscopeStar))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Gor</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.basicGor ? userDetails?.basicGor : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Manglik</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeManglik ? dropDown['manglik']?.option.find(e => e.key === Number(userDetails?.horoscopeManglik))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Horoscope Match is Neccessary?</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeMatch ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.horoscopeMatch))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Believe in Horoscope?</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeBelieveInHoroscope ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.horoscopeBelieveInHoroscope))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Date of Birth</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeDateOfBirth ? moment(userDetails?.horoscopeDateOfBirth).format('DD-MM-YYYY') : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Time of Birth</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeTimeOfBirth ? userDetails?.horoscopeTimeOfBirth : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">State of Birth</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeStateOfBirth ? userDetails?.horoscopeStateOfBirth : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">City of Birth</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.horoscopeCityOfBirth ? userDetails?.horoscopeCityOfBirth : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Education & Career</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Highest Qualification</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationHighestQualification ? dropDown['degree']?.option.find(e => e.key === Number(userDetails?.educationHighestQualification))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Additional Degree</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationAdditionalDegree ? dropDown['degree']?.option.find(e => e.key === Number(userDetails?.educationAdditionalDegree))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">College/Institute</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationCollege ? userDetails?.educationCollege : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Education Subject</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationSubject ? userDetails?.educationSubject : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Education in Detail</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationInDetail ? userDetails?.educationInDetail : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Occupation</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationOccupation ? dropDown['occupation']?.option.find(e => e.key === Number(userDetails?.educationOccupation))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Employeed In</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationEmployeedIn ? dropDown['employedIn']?.option.find(e => e.key === Number(userDetails?.educationEmployeedIn))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Organization</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationOrganization ? userDetails?.educationOrganization : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Occupation in Detail</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationOccupationDetail ? userDetails?.educationOccupationDetail : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Annual Income</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.educationAnnualIncome ? dropDown['annualIncome']?.option.find(e => e.key === Number(userDetails?.educationAnnualIncome))?.name : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">About Family</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Father's Occupation</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyFatherOccupation ? dropDown['fatherOccupation']?.option.find(e => e.key === Number(userDetails?.familyFatherOccupation))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Mother's Occupation</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyMotherOccupation ? dropDown['motherOccupation']?.option.find(e => e.key === Number(userDetails?.familyMotherOccupation))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Sister(s)</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familySister ? `${dropDown['sisterBrother']?.option.find(e => e.key === Number(userDetails?.familySister))?.name} Sisters ${userDetails?.familyMarriedSister ? `of which ${dropDown['sisterBrotherMarried']?.option.find(e => e.key === Number(userDetails?.familyMarriedSister))?.name}`  : ''}`  : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Brother(s)</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyBrother ? `${dropDown['sisterBrother']?.option.find(e => e.key === Number(userDetails?.familyBrother))?.name} Brothers ${userDetails?.familyMarriedBrother ? `of which ${dropDown['sisterBrotherMarried']?.option.find(e => e.key === Number(userDetails?.familyMarriedBrother))?.name}`  : ''}`  : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Status</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyStatus ? dropDown['familyStatus']?.option.find(e => e.key === Number(userDetails?.familyStatus))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Type</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyType ? dropDown['familyType']?.option.find(e => e.key === Number(userDetails?.familyType))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Values</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyValues ? dropDown['familyValues']?.option.find(e => e.key === Number(userDetails?.familyValues))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Income</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyIncome ? dropDown['annualIncome']?.option.find(e => e.key === Number(userDetails?.familyIncome))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Living Country</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyLivingCountry ? userDetails?.familyLivingCountry : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Living State</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyLivingState ? userDetails?.familyLivingState : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Family Living In</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyLivingIn ? userDetails?.familyLivingIn : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">You Live With Your Family?</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.familyLiveWithFamily ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.familyLiveWithFamily))?.name : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Lifestyle</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Eating Habits</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.lifeStyleEatingHabits ? dropDown['eatingHabits']?.option.find(e => e.key === Number(userDetails?.lifeStyleEatingHabits))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Smoking Habits</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.lifeStyleSmokingHabits ? dropDown['smokingHabits']?.option.find(e => e.key === Number(userDetails?.lifeStyleSmokingHabits))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Drinking Habits</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.lifeStyleDrinkingHabits ? dropDown['drinkingHabits']?.option.find(e => e.key === Number(userDetails?.lifeStyleDrinkingHabits))?.name : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Assets</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">Houses- {userDetails?.lifeStyleOwnsHouse ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.lifeStyleOwnsHouse))?.name : 'No'} <br/> Cars- {userDetails?.lifeStyleOwnsCar ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetails?.lifeStyleOwnsCar))?.name : 'No'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Languages Known</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetails?.lifeStyleLanguages ? dropDown['languagesknown']?.option.filter(language => userDetails?.lifeStyleLanguages.includes(language.key)).map(language => language.name).join(", ") : '-'}</p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-between mb-[10px]">
                        <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Interest & More</h4>
                        <Button 
                            class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                            buttonName={'Edit'}
                            onClick={(event) => props.onClick ? props.onClick(event) : ''}
                        />
                    </div>
                    <div className="grid lg:grid-cols-1 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px]">
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">
                                {[
                                    userDetails?.interestHobbies && userDetails?.interestHobbies.length ? dropDown['hobbies']?.option.filter(e => userDetails?.interestHobbies.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interest && userDetails?.interest.length ? dropDown['interest']?.option.filter(e => userDetails?.interest.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestMusic && userDetails?.interestMusic.length ? dropDown['music']?.option.filter(e => userDetails?.interestMusic.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestFavouriteRead && userDetails?.interestFavouriteRead.length ? dropDown['favouriteRead']?.option.filter(e => userDetails?.interestFavouriteRead.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestDressStyle && userDetails?.interestDressStyle.length ? dropDown['dressStyle']?.option.filter(e => userDetails?.interestDressStyle.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestFavouriteTVShows && userDetails?.interestFavouriteTVShows.length ? dropDown['favouriteTvShows']?.option.filter(e => userDetails?.interestFavouriteTVShows.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestPreferredMovies && userDetails?.interestPreferredMovies.length ? dropDown['preferredMovies']?.option.filter(e => userDetails?.interestPreferredMovies.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestSportsFitness && userDetails?.interestSportsFitness.length ? dropDown['sportsFitness']?.option.filter(e => userDetails?.interestSportsFitness.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestFavouriteCusine && userDetails?.interestFavouriteCusine.length ? dropDown['favouriteCuisine']?.option.filter(e => userDetails?.interestFavouriteCusine.includes(e.key)).map(ele => ele.name).join(", ") : '',
                                    userDetails?.interestFavouriteVacationDestination && userDetails?.interestFavouriteVacationDestination.length ? dropDown['favouriteVacationDestination']?.option.filter(e => userDetails?.interestFavouriteVacationDestination.includes(e.key)).map(ele => ele.name).join(", ") : ''
                                ].filter(Boolean).join(", ") || '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Profile;