import React, { createContext, useState, useEffect } from 'react';

const ClientWidthContext = createContext();

export const ClientWidthProvider = ({ children }) => {
    const [clientWidth, setClientWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setClientWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <ClientWidthContext.Provider value={clientWidth}>
            {children}
        </ClientWidthContext.Provider>
    );
};

export default ClientWidthContext;