import { apiRequest } from '../../services/index.js';
import { getUser } from '../reducers/userReducer.js';
import * as env from '../../config/env.js';

// Actions
export const getUserAction = (id) => async dispatch => {
    try {
        await apiRequest("get", `${env.USER}/${id}`).then((response) => {
            if(response.status === 200){
                dispatch(getUser(response.data.data));
            }
        }).catch((error) => {
            console.error('Get user Action Error',error);
        });
    } catch (e) {
        console.error('user get Error Action',e.message);
    }
};