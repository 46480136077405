import React, { useEffect, useState } from "react";
import DropDown from "../atom/dropDown.jsx";
import CheckBox from "../molecules/checkBox/checkBox.jsx";
import RadioButton from "../molecules/radio/radio.jsx";
import CustomInput from "../molecules/customInput.jsx";
import useValidation from "../../utils/validation.js";
function AtomComponent() {
    const  { checkErrors } = useValidation();
    const [formDataRefund,setFormDataRefund] = useState({refundAmount: {type: 'text',name: 'candidate name',value: '',rules: "required|min:3"}});
    const [ddValue] = useState([1,2]);
    const [checkBox,setCheckbox] = useState(false);
    const [checkBox1,setCheckbox1] = useState(true);
    const [radioButton] = useState('CheckBox text');
    const [radioButton1] = useState('CheckBox text1');
    useEffect(() => {
        setCheckbox(checkBox);
    }, [checkBox]);;
    useEffect(() => {
        setCheckbox1(checkBox1);
    }, [checkBox1]);
    return (
        <div>
            <DropDown
                options={[
                    { name: 'Option 1', key: 1 }, 
                    { name: 'Option 2', key: 2 }, 
                    { name: 'Option 3', key: 3 }
                ]}
                onUpdate={() => {}}
                multiSelect={false}
                selectedValue={ddValue}
                labelName={'Profile for *'}
                labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block'}
                selectClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                wrapperClass={'mb-[12px]'}
                optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                optionsWrapper={'bg-white'}
            />
            <CheckBox
                modelValue={checkBox}
                isDisabled={false}
                isReadonly={false}
                isDirectFocus={false}
                isRequired={false}
                isOutline={true}
                onClick={(value) => {setCheckbox(value.event.target.checked);}}
                label={'CheckBox'}
            />
            <CheckBox
                modelValue={checkBox1}
                isDisabled={false}
                isReadonly={false}
                isDirectFocus={false}
                isRequired={false}
                isOutline={true}
                onClick={(value) => {setCheckbox1(value.event.target.checked);}}
                label={'CheckBox text'}
            />
            <RadioButton
                inputId={radioButton}
                modelValue={radioButton}
                isDisabled={false}
                isReadonly={false}
                isDirectFocus={false}
                isRequired={false}
                isOutline={true}
                name="options"
                onClick={(value) => {}}
                label={'CheckBox text'}
            />
            <RadioButton
                inputId={radioButton1}
                modelValue={radioButton1}
                isDisabled={false}
                isReadonly={false}
                isDirectFocus={false}
                isRequired={false}
                isOutline={true}
                name="options"
                onClick={(value) => {}}
                label={'CheckBox text1'}
            />
            <CustomInput
                class={'text-[#931217] text-sm xl:text-xl lg:text-lg md:text-base'}
                label="Mobile No."
                inputId="inputId"
                type="text"
                modelValue={''}
                placeHolder="Enter text"
                isDisabled={false}
                isReadonly={false}
                isDirectFocus={false}
                isRequired={false}
                height="50px"
                width="200px"
                isOutline={true}
                onChange={(value,event) => {
                    formDataRefund.refundAmount.value = value;
                    checkErrors({
                        'field': formDataRefund.refundAmount,
                        'name': 'candidate name',
                        'validations': formDataRefund.refundAmount.rules,
                        'type': formDataRefund.refundAmount.type,
                        'event': event,
                        'value':value
                    });
                    setFormDataRefund({...formDataRefund});
                }}
            />
            {formDataRefund.refundAmount.error && 
                <div>
                    <small className="red">{formDataRefund.refundAmount.error}</small>
                </div>
            }
        </div>
    );
}

export default AtomComponent;