import { useState,useEffect } from "react";
import Button from "../../atom/button.jsx";
import * as env from '../../../config/env.js';
import { useAuth } from '../../../route/auth.jsx';
import Spinner from "../../atom/spinner/spinner.jsx";
import Eye from "../../../assets/images/png/eye.png";
import { ToastContainer, toast } from "react-toastify";
import { apiRequest } from "../../../services/index.js";
import useValidation from "../../../utils/validation.js";
import CustomInput from "../../molecules/customInput.jsx";
import Hidden from "../../../assets/images/png/hidden.png";

const ChangePassword = ({form,submitForm,userDetails}) => {
    //Auth
    const { logout } = useAuth();
    //validation
    const  { checkErrors,checkAllFields } = useValidation();
    //useState
    const [isLoading,setIsLoading] = useState(false);
    const [confirmationErr,setConfirmationErr] = useState('');
    const [togglePassword,setTogglePassword] = useState(false);
    const [toggleNewConfirm,setToggleNewConfirm] = useState('');
    const [toggleNewPassword,setToggleNewPassword] = useState('');
    const [formData,setFormData] = useState({
        currentPassword:form.currentPassword,
        newPassword:form.newPassword,
        confirmNewPassword:form.confirmNewPassword
    });
    //function
    const handleToggle = () => {
        setTogglePassword(!togglePassword);
        if(!togglePassword){
            document.getElementById('createPassword').type = 'text';
        }else{
            document.getElementById('createPassword').type = 'password';
        }
    };
    const handleToggleNewPassword = () => {
        setToggleNewPassword(!toggleNewPassword);
        if(!toggleNewPassword){
            document.getElementById('createNewPassword').type = 'text';
        }else{
            document.getElementById('createNewPassword').type = 'password';
        }
    };
    const handleToggleConfirm = () => {
        setToggleNewConfirm(!toggleNewConfirm);
        if(!toggleNewConfirm){
            document.getElementById('confirmNewPassword').type = 'text';
        }else{
            document.getElementById('confirmNewPassword').type = 'password';
        }
    };
    const handleSelect = (e,id) => {
        if(id === 'currentPassword'){
            checkErrors({
                'field': formData.currentPassword,
                'name': 'current password',
                'validations': formData.currentPassword.rules,
                'event': e.event,
                'value': e.value
            });
            setFormData({ ...formData, currentPassword: {...formData.currentPassword,value:e.value} });
        }else if(id === 'newPassword'){
            checkErrors({
                'field': formData.newPassword,
                'name': 'new password',
                'validations': formData.newPassword.rules,
                'event': e.event,           
                'value': e.value
            });
            setFormData({ ...formData, newPassword: {...formData.newPassword,value:e.value} });
            updateConfirmation();
        }else if(id === 'confirmNewPassword'){
            setFormData({ ...formData, confirmNewPassword: {...formData.confirmNewPassword,value:e.value} });
        }
    };
    const updateConfirmation = () => {
        if(formData.confirmNewPassword.value === ''){
            return;
        }else{
            if(formData.confirmNewPassword.value === ''){
                setConfirmationErr('The confirm password field is required');
            }else if(formData.newPassword.value !== formData.confirmNewPassword.value){
                setConfirmationErr('The confirm password confirmation does not match');
            }else{
                setConfirmationErr('');
            }
        }
    };
    const confirmationPassValidation = () => {
        let err = ""
        if(formData.confirmNewPassword.value === ''){
            err = 'The confirm password field is required';
        }else if(formData.newPassword.value !== formData.confirmNewPassword.value){
            err = 'The confirm password confirmation does not match';
        }
        setConfirmationErr(err);

        return err;
    };
    const handleSubmit = () => {
        checkAllFields(formData).then((valid)=>{
            const passwordMatched = confirmationPassValidation();
            setFormData({...formData});
            if(valid && passwordMatched === ''){
                setIsLoading(true);
                let userDetailObj = {
                    userId:userDetails?.userId,
                    newPassword:formData.confirmNewPassword.value,
                    currentPassword:formData.currentPassword.value
                };
                try {
                    apiRequest("post", env.CHANGEPASSWORD, userDetailObj).then((response) => {
                        if(response.status === 200){
                            toast.success('Updated successfully', {
                                position: 'top-right',
                            });
                            setTimeout(() => {
                                setIsLoading(false);
                                logout();
                            },1000);
                        }else{
                            setIsLoading(false);
                            toast.error('Please try it again', { position: 'top-right' });
                        }
                        setFormData(prev => ({
                            ...prev,
                            currentPassword: {...formData.currentPassword,value:''},
                            confirmNewPassword: {...formData.confirmNewPassword,value:''},
                            newPassword: {...formData.newPassword,value:''}
                        }));
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                        }else {
                            toast.error('Please try it again', { position: 'top-right' });
                        }
                        console.error('Error in api change password',error);
                    })
                } catch (error) {
                    console.error('Error in change password',error);
                    setIsLoading(false);
                    toast.error('Please try it again', { position: 'top-right' });
                }
            }
        });
    };
    //useEffect
    useEffect(() => {
        const isConfirmNewPasswordFocused = document.activeElement === document.getElementById('confirmNewPassword');
        if(isConfirmNewPasswordFocused){
            confirmationPassValidation();
        }
    },[formData?.confirmNewPassword?.value]);

    return(
        <>
            <ToastContainer />
            {isLoading ? <Spinner /> : null}
            <div className="bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px]">
                <p className="font-family-Quicksand-Regular text-[18px] tracking-[0.5px] text-[#262626] text-stroke-4 leading-[30px] pb-[15px] mb-[20px] border-b-[#b7892d] border-b">Your Password Must Have minimum of 6 characters. We recommend you choose an alphanumeric password E.g.:Matril123</p>
                <div className="md:max-w-[320px] w-full">
                    <div className="mb-[16px] relative">
                        <CustomInput
                            inputId="createPassword"
                            placeHolder={'Enter Current Password'}
                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[43px] rounded-[10px]'}
                            class={'font-family-Quicksand-Bold text-[18px] text-[#931217] mb-[6px] leading-[normal] block'}
                            type="password"
                            label="Current Password *"
                            modelValue={formData?.currentPassword?.value ? formData?.currentPassword?.value : ''}
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onKeyUp={(e) => handleSelect(e,'currentPassword')}
                            onPaste={(event) => {
                                event.event.preventDefault();
                            }}
                            onKeyPress={((value) => {
                                if(value.event.code === "Space"){
                                    value.event.preventDefault();
                                }
                            })}
                        />
                        <div className="w-[20px] h-[20px] absolute cursor-pointer top-[40px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggle();}}>
                            {
                                !togglePassword ? 
                                <img src={Eye} alt="eye" /> :
                                <img src={Hidden} alt="Hidden" />
                            }
                        </div>
                        {formData.currentPassword.error && 
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.currentPassword.error}</small>
                        }
                    </div>
                    <div className="mb-[16px] relative">
                        <CustomInput
                            inputId="createNewPassword"
                            placeHolder={'Enter New Password'}
                            label="New Password *"
                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[43px] rounded-[10px]'}
                            class={'font-family-Quicksand-Bold text-[18px] text-[#931217] mb-[6px] leading-[normal] block'}
                            type="password"
                            modelValue={formData?.newPassword?.value ? formData?.newPassword?.value : ''}
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onKeyUp={(e) => handleSelect(e,'newPassword')}
                            onPaste={(event) => {
                                event.event.preventDefault();
                            }}
                            onKeyPress={((value) => {
                                if(value.event.code === "Space"){
                                    value.event.preventDefault();
                                }
                            })}
                        />
                        <div className="w-[20px] h-[20px] absolute cursor-pointer top-[40px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggleNewPassword();}}>
                            {
                                !toggleNewPassword ? 
                                <img src={Eye} alt="eye" /> :
                                <img src={Hidden} alt="Hidden" />
                            }
                        </div>
                        {formData.newPassword.error && 
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.newPassword.error}</small>
                        }
                    </div>
                    <div className="relative">
                        <CustomInput
                            inputId="confirmNewPassword"
                            placeHolder={'Enter Confirm New Password'}
                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[43px] rounded-[10px]'}
                            label="Confirm New Password *"
                            class={'font-family-Quicksand-Bold text-[18px] text-[#931217] mb-[6px] leading-[normal] block'}
                            type="password"
                            modelValue={formData?.confirmNewPassword?.value ? formData?.confirmNewPassword?.value : ''}
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            isOutline={true}
                            onKeyUp={(e) => handleSelect(e,'confirmNewPassword')}
                            onPaste={(event) => {
                                event.event.preventDefault();
                            }}
                            onKeyPress={((value) => {
                                if(value.event.code === "Space"){
                                    value.event.preventDefault();
                                }
                            })}
                        />
                        <div className="w-[20px] h-[20px] absolute cursor-pointer top-[40px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggleConfirm();}}>
                            {
                                !toggleNewConfirm ? 
                                <img src={Eye} alt="eye" /> :
                                <img src={Hidden} alt="Hidden" />
                            }
                        </div>
                        {confirmationErr && 
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{confirmationErr}</small>
                        }
                    </div>
                    <div className="mb-[10px] mt-[30px]">
                        <Button
                            class='block leading-[normal] ml-auto text-[#fff] cursor-pointer py-[8px] px-[22.5px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Change Password'}
                            onClick={() => handleSubmit()}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;