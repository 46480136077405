import Cookies from 'js-cookie';
import Button from "../../../atom/button";
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import * as env from '../../../../config/env.js';
import { apiRequest } from "../../../../services";
import Spinner from "../../../atom/spinner/spinner";
import { useEffect, useRef, useState } from "react";
import { useAuth } from '../../../../route/auth.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from "react-toastify";
import CustomInput from "../../../molecules/customInput.jsx";
import eidticon from '../../../../assets/images/png/eidticon.png'
import allCountries from "../../../atom/countryCode/countryCode.jsx";
import { getUserAction } from "../../../../stores/action/userAction.js";
import { getUserWithoutAuthentication } from "../../../../stores/action/userWithOutAuthAction";
import useValidation from '../../../../utils/validation.js';
import close from '../../../../assets/images/png/close.png';
import priority from '../../../../assets/images/png/priority_low.png';
import { getUserWithoutAuth } from '../../../../stores/reducers/userWithOutAuthReducer.js';

const Otp = ({ phoneNumber, email, countryCode,otpMessage }) => {
    const [formData,setFormData] = useState({
        mobile:{value: "",rules:"",name: "mobile",error: ""},
    });
    const [formDataObj,setFormDataObj] = useState({
        email: {value:'',rules:"",name: "Email",error: ""}
    });
    const typingTimeoutRef = useRef(null);
    const [typedCharacters, setTypedCharacters] = useState('');
    const [countryArray,setCountryArray] = useState(allCountries);
    const dropdownRef = useRef(null);
    const selectDropDown = useRef(null);
    const [isOpen,setIsOpen] = useState(false);
    const  { checkErrors,checkAllFields } = useValidation();
    const [countryObject,setCountryObject] = useState({
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [isInputFocused, setIsInputFocused] = useState(true);

    const { login } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(60); // Initial timer value
    const [isDisabled, setIsDisabled] = useState(true); // Button disabled state
    const userProfileReview = useSelector(state => state.userWithoutAuth);
    const [otpError, setOtpError] = useState('');
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit,setIsEdit] = useState('');
    const inputs = useRef([]);
    const debounceTimeout = useRef(null);
    const isSubmitting = useRef(false);
    useEffect(() => {
        if(otpMessage){
            toast.success(otpMessage, { position: 'top-right' });
        }
    },[otpMessage])
    useEffect(() => {
        let timer;
        if (isDisabled) {
            // Start the timer if the button is disabled
            timer = setInterval(() => {
                setSeconds(prevSeconds => {
                    if (prevSeconds <= 1) {
                        clearInterval(timer);
                        setIsDisabled(false); // Enable the button when the timer ends
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer); // Clear timer on component unmount
    }, [isDisabled]);
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^\d?$/.test(value)) { // Allows only single digit or empty
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < otp.length - 1 && value) {
                inputs.current[index + 1].focus();
            }

            setOtp(prevOtp => {
                const updatedOtp = [...prevOtp];
                updatedOtp[index] = value;
                if (updatedOtp.join('').length === otp.length) {
                    setOtpError('');
                }
                return updatedOtp;
            });
        }
    };

    const handleBackspace = (e, index) => {
        if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
            return e.preventDefault();
        }
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            if (newOtp[index] === '' && index > 0) {
                inputs.current[index - 1].focus();
            }
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };

    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (paste.length === 6 && /^[0-9]{6}$/.test(paste)) {
            const newOtp = paste.split('');
            setOtp(newOtp);
            inputs.current[5].focus();
            if(newOtp.length === 6 && isNaN(Number(newOtp))){
                debounceSubmit(newOtp);
            }
        }
        e.preventDefault();
    };
    const handleSubmitOnLast = (index,event) => {
        if(index === 5 && event.key >= '0' && event.key <= '9'){
            debounceSubmit();
        }
    };
    const debounceSubmit = (value) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            handleSubmit(value);
        }, 600);
    };
    const handleSubmit = (value) => {
        if (isSubmitting.current) return;
        const check = value && value.length ? value.join('') : otp.join('');
        if (check.length < 6) {
            setOtpError("Please enter correct 6 digit otp");
        } else if (check.length === 6 && otpError === '' && check?.length && !isNaN(Number(check[5]))) {
            isSubmitting.current = true;
            setIsLoading(true);
            let verifyOtp
            if(userProfileReview?.userDetailWithoutAuth?.isMobileNotVerified === true){
                verifyOtp = {
                    method: "phone",
                    phoneNumber: userProfileReview?.userDetailWithoutAuth?.phone,
                    countryCode: userProfileReview?.userDetailWithoutAuth?.countryCode,
                    otp: Number(check),
                    isUserRegister:true
                };
            }else{
                verifyOtp = {
                    method: "email",
                    email: userProfileReview?.userDetailWithoutAuth?.email,
                    otp: Number(check),
                    isUserRegister:true
                };
            }            
            apiRequest("post", env.VERIFYOTP, verifyOtp)
                .then(async(res) => {
                    if (res.status === 200) {
                        toast.success(res.data.message, { position: 'top-right' });
                        if(res.data.message === 'Mobile number otp verified'){
                            let detailUpdate = { ...userProfileReview.userDetailWithoutAuth };
                            detailUpdate.isMobileNotVerified = false;
                            await dispatch(getUserWithoutAuthentication(detailUpdate));
                            setSeconds(60);
                            setIsDisabled(true); // Disable the button when OTP is resent
                            setIsLoading(false);
                            isSubmitting.current = false;
                            setOtp(['', '', '', '', '', '']);
                            inputs.current[0].focus();
                        }else{
                            setOtp(['', '', '', '', '', '']);
                            isSubmitting.current = false;
                            try {
                                let loginData = {
                                    email: userProfileReview?.userDetailWithoutAuth.email,
                                    loginType:'email',
                                    password:userProfileReview?.userDetailWithoutAuth.password
                                }
                                apiRequest("post", env.LOGIN, loginData).then(async(response) => {
                                    if(response?.status === 200){
                                        if(response?.data && Object?.keys(response.data)?.length){
                                            localStorage.setItem('userId',response?.data?.id);
                                            localStorage.setItem('token',response.data.jwtToken);
                                            localStorage.setItem('refreshToken',response.data.refreshToken);
                                            Cookies.set('sessionId', response.data.sessionId, { expires: 1 });
                                            await dispatch(getUserAction(response?.data?.id || ''));
                                            toast.success(response.data.message, {
                                                position: 'top-right',
                                            });
                                            login();
                                            setIsLoading(false);
                                        }else{
                                            setIsLoading(false);
                                        }
                                    }else{
                                        toast.error('Please try it again', {
                                            position: 'top-right',
                                        });
                                        navigate('/login');
                                        setIsLoading(false);
                                    }
                                }).catch(async(error) => {
                                    console.error('login Error',error);
                                    navigate('/login');
                                    setIsLoading(false);
                                })
                            } catch (error) {
                                console.error('error',error);
                                navigate('/login');
                                setIsLoading(false);
                            }
                        }
                    } else {
                        toast.error('Please try it again', { position: 'top-right' });
                        isSubmitting.current = false;
                        setIsLoading(false);
                        setOtp(['', '', '', '', '', '']);
                        inputs.current[0].focus();
                    }
                })
                .catch(error => {
                    console.error('error',error);
                    toast.error(error?.response?.data?.message || 'Verification failed', { position: 'top-right' });
                    isSubmitting.current = false;
                    setIsLoading(false);
                    setOtp(['', '', '', '', '', '']);
                    inputs.current[0].focus();
                });
        }
    };

    const handleResendOtp = () => {
        setIsLoading(true);
        setSeconds(60);
        setIsDisabled(true);
        let sendOtp;
        if(userProfileReview?.userDetailWithoutAuth?.isMobileNotVerified === true){
            sendOtp = {
                method: "phone",
                phoneNumber: userProfileReview?.userDetailWithoutAuth?.phone || phoneNumber,
                countryCode: userProfileReview?.userDetailWithoutAuth?.countryCode || countryCode
            };
        }else{
            sendOtp = {
                method: "email",
                email: userProfileReview?.userDetailWithoutAuth?.email,
                isEditEmail:false
            };
        }
        apiRequest("post", env.SENDOTP, sendOtp)
            .then(res => {
                if (res.status === 200) {
                    toast.success('Otp resend successfully', { position: 'top-right' });
                } else {
                    toast.error('Please try it again', { position: 'top-right' });
                }
                setIsLoading(false);
            })
            .catch(error => {
                toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
                setIsLoading(false);
            });
    };
    const handleEdit = () => {
        if (userProfileReview?.userDetailWithoutAuth?.isMobileNotVerified === true) {
            setIsEdit(true);
            let findCountry = allCountries.find(e => e.dialCode === userProfileReview?.userDetailWithoutAuth?.countryCode)
            setCountryObject(findCountry);
            setFormData({
                ...formData,
                mobile: { 
                    ...formData.mobile, 
                    value: userProfileReview?.userDetailWithoutAuth?.phone || "",
                    rules:`required|min:7|max:15|regex:^[0-9]+$`,
                    error:'',
                },
            });
        } else if (userProfileReview?.userDetailWithoutAuth?.isEmailNotVerified === true) {
            setIsEdit(false);
            setFormDataObj({
                ...formDataObj,
                email: {
                    ...formDataObj.email,
                    value: userProfileReview?.userDetailWithoutAuth?.email || "",
                    rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",
                    error: ""
                }
            });
        }
    };
    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleOutsideClick = (event) => {
        if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleCountryCode = (ele) => {
        setCountryObject(ele);
        setIsOpen(false);
    };
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    const handleReset = () => {
        setFormData({
            ...formData,
            mobile: { 
                ...formData.mobile, 
                value: "",
                rules:'',
                error:''
            },
        });
        setFormDataObj({
            ...formDataObj,
            email: {
                ...formDataObj.email,
                value: "",
                rules:'',
                error: ""
            }
        });
        setIsEdit('');
    };
    const handleSave = () => {
        formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
        checkAllFields(formData).then((valid)=>{
            if(valid){
                try {
                    setIsLoading(true);
                    let object = {
                        userId:userProfileReview?.userDetailWithoutAuth.id,
                        type:'phone',
                        phone:formData.mobile.value,
                        countryCode:countryObject.dialCode,
                        sessionId:userProfileReview?.userDetailWithoutAuth?.sessionId,
                        refreshToken:userProfileReview?.userDetailWithoutAuth?.refreshToken,
                        jwtToken:userProfileReview?.userDetailWithoutAuth?.jwtToken
                    }                    
                    apiRequest("post", env.EDITPHONEOREMAIL, object)
                    .then(async (res) => {
                        if (res.status === 200) {
                            setIsDisabled(true);
                            setSeconds(60);
                            let upadateWithOutAuth = {
                                ...userProfileReview.userDetailWithoutAuth,
                                phone:formData.mobile.value,
                                countryCode:countryObject.dialCode
                            }                  
                            await dispatch(getUserWithoutAuth(upadateWithOutAuth));
                            setIsEdit('');
                            toast.success('Mobile updated successfully, and otp sent successfully', { position: 'top-right' });
                        } else {
                            toast.error('Please try it again', { position: 'top-right' });
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
                        setIsLoading(false);
                    });
                } catch (error) {
                    console.error('Error in editing phone number');   
                }
            }
        });
    };
    const handleSaveEmail = () => {
        checkAllFields(formDataObj).then((valid)=>{
            if(valid){
                try {
                    setIsLoading(true);
                    let object = {
                        userId:userProfileReview?.userDetailWithoutAuth.id,
                        type:'email',
                        email:formDataObj.email.value,
                        sessionId:userProfileReview?.userDetailWithoutAuth?.sessionId,
                        refreshToken:userProfileReview?.userDetailWithoutAuth?.refreshToken,
                        jwtToken:userProfileReview?.userDetailWithoutAuth?.jwtToken
                    }
                    apiRequest("post", env.EDITPHONEOREMAIL, object)
                    .then(async (res) => {
                        if (res.status === 200) {
                            setIsDisabled(true);
                            setSeconds(60);
                            let upadateWithOutAuth = {
                                ...userProfileReview.userDetailWithoutAuth,
                                email:formDataObj.email.value
                            }                  
                            await dispatch(getUserWithoutAuth(upadateWithOutAuth));
                            setIsEdit('');
                            toast.success('Email updated successfully, and otp sent successfully', { position: 'top-right' });
                        } else {
                            toast.error('Please try it again', { position: 'top-right' });
                        }
                        setIsLoading(false);
                    })
                    .catch(error => {
                        toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
                        setIsLoading(false);
                    });
                } catch (error) {
                    console.error('Error in editing phone number');   
                }
            }
        });
    };
    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.focus();
        }
    }, [isOpen]);
    return (
        <>
            <ToastContainer />
            {isLoading && <Spinner />}
            <div className="px-[15px] xms:px-[10px]">
                <h2 className="pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url('../../assets/images/png/title_shape.png')]">
                    Enter OTP
                </h2>
                <p className="text-center leading-[normal] text-[#262626] font-family-Quicksand-Regular mt-[27px] text-stroke-4 text-[16px]">We have sent OTP on your {userProfileReview?.userDetailWithoutAuth?.isMobileNotVerified === true ? 'mobile number' : 'email'}</p>
                <div className='mt-[27px]'>
                    {
                        isEdit !== true && isEdit !== false ?  
                        <p className='leading-[normal] text-[#262626] font-family-Quicksand-Regular text-stroke-4 text-[16px] justify-center items-center flex'>
                            {userProfileReview?.userDetailWithoutAuth?.isMobileNotVerified ? `${userProfileReview?.userDetailWithoutAuth?.countryCode} ${userProfileReview?.userDetailWithoutAuth?.phone}` : userProfileReview?.userDetailWithoutAuth?.email}
                            <img className='ml-2 cursor-pointer' alt='edit' onClick={() => handleEdit()} src={eidticon} />
                        </p>
                        : isEdit === true ? 
                            <div className='justify-center items-center flex'>
                                <div className="md:w-[40%] relative">
                                    <div className="flex relative">
                                        <div ref={selectDropDown} className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode();}}>
                                            {countryObject.name} 
                                            <span className="dropDown_arrow"></span>
                                        </div>
                                        {
                                            isOpen ?
                                                <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                    <input type='text' onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                                    <ul>
                                                        {countryArray.length ? countryArray.map((ele,index) => 
                                                            <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode(ele);}}>
                                                                {ele.name} {ele?.countryJoin}
                                                            </li>
                                                        ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                    </ul>
                                                </div>
                                            : null
                                        }
                                        <CustomInput
                                            inputId="mobilenumber"
                                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius pl-[16px] pr-[65px] py-[5px] h-[42px]'}
                                            class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                            type="number"
                                            min="1"
                                            modelValue={formData.mobile.value ? formData.mobile.value.trim() : ''}
                                            placeHolder="Enter mobile no"
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onChange={(value,event) => {
                                                formData.mobile.value = value;
                                                formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
                                                checkErrors({
                                                    'field': formData.mobile,
                                                    'name': 'mobile',
                                                    'validations': formData.mobile.rules,
                                                    'type': formData.mobile.type,
                                                    'event': event,
                                                    'value':value
                                                });
                                                setFormData({...formData});
                                            }}
                                            onKeyPress={(event)=>{
                                                if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                    event.event.preventDefault();
                                                }
                                            }}
                                            onKeyDown={(event)=>{
                                                if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                    event.event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className='absolute right-[15px] top-[14px] flex items-center'>
                                        <img onClick={() => handleSave()} src={priority} className='cursor-pointer' alt='priority' />
                                        <img onClick={() => handleReset()} className='ml-2 cursor-pointer' src={close} alt='close' />
                                    </div>
                                    {formData.mobile.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.mobile.error}</small>
                                    }
                                </div>
                            </div>
                        : isEdit === false ? 
                            <div className='justify-center items-center flex'>
                                <div className="md:w-[40%] relative">
                                    <CustomInput
                                        inputId="emailId"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[65px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        type="text"
                                        modelValue={formDataObj?.email?.value || ''}
                                        placeHolder="Enter email id"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formDataObj.email.value = value;
                                            checkErrors({
                                                'field': formDataObj.email,
                                                'name': 'email',
                                                'validations': formDataObj.email.rules,
                                                'type': formDataObj.email.type,
                                                'event': event,
                                                'value':value
                                            });
                                            setFormDataObj({...formDataObj});
                                        }}
                                        onBlur={() => {
                                            const blur = {...formDataObj}
                                            blur.email.value = blur?.email?.value?.trim();
                                            setFormDataObj(blur);
                                        }}
                                    />
                                    <div className='absolute right-[15px] top-[14px] flex items-center'>
                                        <img onClick={() => handleSaveEmail()} src={priority} className='cursor-pointer' alt='priority' />
                                        <img onClick={() => handleReset()} className='ml-2 cursor-pointer' src={close} alt='close' />
                                    </div>
                                    {formDataObj.email.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formDataObj.email.error}</small>
                                    }
                                </div> 
                            </div>
                        
                        : ''
                    }
                </div>
                <div className={isEdit === true || isEdit === false ? 'opacity-40 pointer-events-none' : ''}>
                    <div className="text-center mt-[25px]">
                        {otp.map((data, index) => (
                            <input
                                key={index}
                                type="number"
                                value={data}
                                onChange={(e) => handleChange(e, index)}
                                onKeyDown={(e) => handleBackspace(e, index)}
                                onKeyUp={(e) => handleSubmitOnLast(index,e)}
                                onPaste={handlePaste}
                                onKeyPress={(event)=>{
                                    if(event.charCode === 45 || event.charCode === 43 || event.charCode === 46 || event.key === 'e' || event.key === 'E'){
                                        event.preventDefault();
                                    }
                                }}
                                onInput={(e) => {
                                    const value = e.target.value;
                                    if (/^\d?$/.test(value)) { // Allows only single digit or empty
                                        handleChange(e, index);
                                    } else {
                                        e.target.value = value.slice(0, 1); // Keep only the first valid digit
                                    }
                                }}
                                ref={(ref) => inputs.current[index] = ref}
                                maxLength="1"
                                className="py-[5px] xms:px-[16px] px-[5px] bg-white text-stroke ms:w-[65px] ms:h-[65px] xms:w-[45px] xms:h-[45px] w-[35px] h-[35px] font-family-Quicksand-Bold text-[18px] text-[#931217] text-center inline lg:mx-[15px] md:mx-[10px] mx-[3px] rounded-[15px]"
                            />
                        ))}
                    </div>
                    {otpError && (
                        <div className="text-center mt-[10px]">
                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{otpError}</small>
                        </div>
                    )}
                    <div className="mt-[30px] mb-[25px]">
                        <Button
                            class="w-[125px] h-[45px] bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] font-family-Quicksand-Medium text-center block mx-auto"
                            buttonName="Continue"
                            onClick={() => handleSubmit()}
                        />
                    </div>
                    <div className="mb-[10px] text-center">
                        {/* <Button
                            class="hover:underline font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal] block mx-auto"
                            onClick={handleResendOtp}
                            buttonName="Resend OTP"
                        /> */}
                        <Button
                            class={`${isDisabled ? 'opacity-30 pointer-events-none' : 'hover:underline'} font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal] block mx-auto my-[20px]`}
                            onClick={() => handleResendOtp()}
                            buttonName="Resend OTP"
                        />
                        {isDisabled && <div className='mb-[15px]'>Resend available in: {seconds}s</div>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Otp;