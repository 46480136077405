import { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import useCustomComposable from '../../../utils/composables/index.js';
import "./index.css"

const RangeSlider = ({ range, maxRange, minRange, rangeValue }) => {
    const { makeUniqueId } = useCustomComposable();
    const [rangeSliderWrapper] = useState('prefix-' + makeUniqueId(5));
    const [maxRangeSliderWrapper] = useState('prefix-' + makeUniqueId(4));
    const [minRangeSliderWrapper] = useState('prefix-' + makeUniqueId(3));
    const [borderRangeSliderWrapper] = useState('prefix-' + makeUniqueId(2));
    const [filter, setFilter] = useState(range ? range : [0, 0]);
    const [max] = useState(maxRange ? maxRange : 0);
    const [min] = useState(minRange ? minRange : 10);
    const [leftValue,setLeftValue] = useState('');
    const [rightValue,setRightValue] = useState('');

    useEffect(() => {
        handleRange(range);
    }, [rangeSliderWrapper]);
    useEffect(() => {
        if (range && range.length) {
            setFilter(range); // Update filter state when range prop changes
        }
    }, [range]);
    
    const handleRange = (newValues) => {
        setTimeout(() => {
            const thumbLeft = document?.querySelector(`.${rangeSliderWrapper} .example-thumb-0`) || '';
            const thumbRight = document?.querySelector(`.${rangeSliderWrapper} .example-thumb-1`) || '';
            
            if (thumbLeft && thumbLeft?.style?.left) {
                document.getElementById(`${minRangeSliderWrapper}`).style.left = thumbLeft?.style?.left;
                const valueFromAriaValueNowLeft = thumbLeft.getAttribute('aria-valuenow');
                setLeftValue(valueFromAriaValueNowLeft)
            }
            if (thumbRight && thumbRight?.style?.left) {
                document.getElementById(`${maxRangeSliderWrapper}`).style.left = `${Number(thumbRight?.style?.left.split('px')[0]) + 8}px`;
                const valueFromAriaValueNowRight = thumbRight.getAttribute('aria-valuenow');
                setRightValue(valueFromAriaValueNowRight)
            }
            const maxRange = document?.getElementById(`${maxRangeSliderWrapper}`)?.getBoundingClientRect();
            const minRange = document?.getElementById(`${minRangeSliderWrapper}`)?.getBoundingClientRect();
            document.getElementById(`${borderRangeSliderWrapper}`).style.width = `${maxRange.left - minRange.right}px`;
            document.getElementById(`${borderRangeSliderWrapper}`).style.left = `${Number(thumbLeft?.style?.left.split('px')[0]) + 15}px`;
            if (newValues) {
                setFilter(newValues);
                rangeValue(newValues);
            }
        });
    };

    return (
        <>
            <ReactSlider
                className={`horizontal-slider ${rangeSliderWrapper}`}
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={filter}
                min={min}
                max={max}
                ariaLabel={['Lower thumb', 'Upper thumb']}
                ariaValuetext={state => `Thumb value ${state.valueNow}`}
                step={1}
                onChange={(newValues) => handleRange(newValues)}
                renderThumb={(props, state) => {
                    const { key, ...otherProps } = props;
                    return <div key={state.index} {...otherProps}>{state.valueNow}</div>;
                }}
            />
            <div className="bg-[#eab7a4] h-[4px] rounded-[3px] absolute bottom-[calc(50%-30px)] translate-y-[-50%] w-full">
                <span id={minRangeSliderWrapper} className={`top-[-7px] w-[15px] h-[15px] absolute bg-[#911317] rounded-full cursor-pointer`}></span>
                <span id={borderRangeSliderWrapper} className="absolute h-[1px] bg-[#931217] h-[4px]"></span>
                <span id={maxRangeSliderWrapper} className={`top-[-7px] w-[15px] h-[15px] absolute bg-[#911317] rounded-full cursor-pointer`}></span>
            </div>
            <div className="flex w-full absolute top-[45px]">
                <span className='text-[20px] font-family-Quicksand-Medium text-stroke text-left w-full inline-block'>{leftValue || 18}</span>
                <span className='text-[20px] font-family-Quicksand-Medium text-stroke text-right w-full inline-block'>{rightValue || 70}</span>
            </div>
            <div className="flex justify-between">
                <span className='text-[20px] font-family-Quicksand-Medium text-stroke'>{min}</span>
                <span className='text-[20px] font-family-Quicksand-Medium text-stroke'>{max}</span>
            </div>
        </>
    )
};

export default RangeSlider;
