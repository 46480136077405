import { useEffect, useState } from "react";
import Button from "../../atom/button.jsx";
import ViewProfileComp from "./viewProfileComp.jsx";
import cancelImage from '../../../assets/images/png/cross.png';
import rightImage from '../../../assets/images/png/right.png';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from "react-redux";
import moment from "moment";

const ViewProfileDetail = ({userDetailobject,handleUnlock}) => {
    const [age, setAge] = useState(null);
    const [activeClass,setActiveClass] = useState(1);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const education = [
        {
            name:'Highest Qualification',
            value:userDetailobject?.educationHighestQualification ? dropDown?.['degree']?.option.find(e => e.key === Number(userDetailobject?.educationHighestQualification))?.name : '-'
        },
        {
            name:'Additional Degree',
            value:userDetailobject?.educationAdditionalDegree ? dropDown?.['degree']?.option.find(e => e.key === Number(userDetailobject?.educationAdditionalDegree))?.name : '-'
        },
        {
            name:'College/Institute',
            value:userDetailobject?.educationCollege ? userDetailobject?.educationCollege : '-'
        },
        {
            name:'Education Subject',
            value:userDetailobject?.educationSubject || '-'
        },
        {
            name:'Education in Detail',
            value:userDetailobject?.educationInDetail || '-'
        },
        {
            name:'Employeed In',
            value:userDetailobject?.educationEmployeedIn ? dropDown['employedIn']?.option.find(e => e.key === Number(userDetailobject?.educationEmployeedIn))?.name : '-'
        },
        {
            name:'Occupation',
            value:userDetailobject?.educationOccupation ? dropDown['occupation']?.option.find(e => e.key === Number(userDetailobject?.educationOccupation))?.name : '-'
        },
        {
            name:'Occupation in Detail',
            value:userDetailobject?.educationOccupationDetail ? userDetailobject?.educationOccupationDetail : '-'
        },
        {
            name:'Organization',
            value:userDetailobject?.educationOrganization ? userDetailobject?.educationOrganization : '-'
        },
        {
            name:'Annual Income',
            value:userDetailobject?.educationAnnualIncome ? dropDown['annualIncome']?.option.find(e => e.key === Number(userDetailobject?.educationAnnualIncome))?.name : '-'
        }
    ];
    const aboutFamily = [
        {
            name:`Father's Occupation`,
            value:userDetailobject?.familyFatherOccupation ? dropDown['fatherOccupation']?.option.find(e => e.key === Number(userDetailobject?.familyFatherOccupation))?.name : '-'
        },
        {
            name:`Mother's Occupation`,
            value:userDetailobject?.familyMotherOccupation ? dropDown['motherOccupation']?.option.find(e => e.key === Number(userDetailobject?.familyMotherOccupation))?.name : '-'
        },
        {
            name:'Sister(s)',
            value:userDetailobject?.familySister ? `${dropDown['sisterBrother']?.option.find(e => e.key === Number(userDetailobject?.familySister))?.name} Sisters ${userDetailobject?.familyMarriedSister ? `of which ${dropDown['sisterBrotherMarried']?.option.find(e => e.key === Number(userDetailobject?.familyMarriedSister))?.name}`  : ''}`  : '-'
        },
        {
            name:'Brother(s)',
            value:userDetailobject?.familyBrother ? `${dropDown['sisterBrother']?.option.find(e => e.key === Number(userDetailobject?.familyBrother))?.name} Brothers ${userDetailobject?.familyMarriedBrother ? `of which ${dropDown['sisterBrotherMarried']?.option.find(e => e.key === Number(userDetailobject?.familyMarriedBrother))?.name}`  : ''}`  : '-'
        },
        {
            name:'Family Status',
            value:userDetailobject?.familyStatus ? dropDown['familyStatus']?.option.find(e => e.key === Number(userDetailobject?.familyStatus))?.name : '-'
        },
        {
            name:'Family Type',
            value:userDetailobject?.familyType ? dropDown['familyType']?.option.find(e => e.key === Number(userDetailobject?.familyType))?.name : '-'
        },
        {
            name:'Family Values',
            value:userDetailobject?.familyValues ? dropDown['familyValues']?.option.find(e => e.key === Number(userDetailobject?.familyValues))?.name : '-'
        },
        {
            name:'Family Living Country',
            value:userDetailobject?.familyLivingCountry ? userDetailobject?.familyLivingCountry : '-'
        },
        {
            name:'Family Living State',
            value:userDetailobject?.familyLivingState ? userDetailobject?.familyLivingState : '-'
        },
        {
            name:'Family Living In',
            value:userDetailobject?.familyLivingIn ? userDetailobject?.familyLivingIn : '-'
        },
        {
            name:'You Live With Your Family?',
            value:userDetailobject?.familyLiveWithFamily ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetailobject?.familyLiveWithFamily))?.name : '-'
        },
        {
            name:'Family Income',
            value:userDetailobject?.familyIncome ? dropDown['annualIncome']?.option.find(e => e.key === Number(userDetailobject?.familyIncome))?.name : '-'
        }
    ];
    const lifeStyle = [
        {
            name:'Eating Habits',
            value:userDetailobject?.lifeStyleEatingHabits ? dropDown['eatingHabits']?.option.find(e => e.key === Number(userDetailobject?.lifeStyleEatingHabits))?.name : '-'
        },
        {
            name:'Smoking Habits',
            value:userDetailobject?.lifeStyleSmokingHabits ? dropDown['smokingHabits']?.option.find(e => e.key === Number(userDetailobject?.lifeStyleSmokingHabits))?.name : '-'
        },
        {
            name:'Drinking Habits',
            value:userDetailobject?.lifeStyleDrinkingHabits ? dropDown['drinkingHabits']?.option.find(e => e.key === Number(userDetailobject?.lifeStyleDrinkingHabits))?.name : '-'
        },
        {
            name:'Assets',
            value:`Houses- ${userDetailobject?.lifeStyleOwnsHouse ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetailobject?.lifeStyleOwnsHouse))?.name : 'No'} <br> Cars- ${userDetailobject?.lifeStyleOwnsCar ? dropDown['yesNo']?.option.find(e => e.key === Number(userDetailobject?.lifeStyleOwnsCar))?.name : 'No'}`
        },
        {
            name:'Languages Known',
            value:userDetailobject?.lifeStyleLanguages && userDetailobject?.lifeStyleLanguages?.length ? dropDown['languagesknown']?.option.filter(language => userDetailobject?.lifeStyleLanguages.includes(language.key)).map(language => language.name).join(", ") : '-'
        },
        {
            name:'Any Disability',
            value:userDetailobject?.basicDisability ? `Disability - ${dropDown['disability']?.option.find(e => e.key === Number(userDetailobject?.basicDisability))?.name}` : '-'
        }
    ];
    const interestMore = [
        {
            name:'',
            value:[
                userDetailobject?.interestHobbies && userDetailobject?.interestHobbies.length ?  dropDown['hobbies']?.option.filter(e => userDetailobject?.interestHobbies.includes(e.key)).map(ele => ele.name).join(", ")  : '',
                userDetailobject?.interest && userDetailobject?.interest.length ? dropDown['interest']?.option.filter(e => userDetailobject?.interest.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestMusic && userDetailobject?.interestMusic.length ? dropDown['music']?.option.filter(e => userDetailobject?.interestMusic.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestFavouriteRead && userDetailobject?.interestFavouriteRead.length ? dropDown['favouriteRead']?.option.filter(e => userDetailobject?.interestFavouriteRead.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestDressStyle && userDetailobject?.interestDressStyle.length ? dropDown['dressStyle']?.option.filter(e => userDetailobject?.interestDressStyle.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestFavouriteTVShows && userDetailobject?.interestFavouriteTVShows.length ? dropDown['favouriteTvShows']?.option.filter(e => userDetailobject?.interestFavouriteTVShows.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestPreferredMovies && userDetailobject?.interestPreferredMovies.length ? dropDown['preferredMovies']?.option.filter(e => userDetailobject?.interestPreferredMovies.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestSportsFitness && userDetailobject?.interestSportsFitness.length ? dropDown['sportsFitness']?.option.filter(e => userDetailobject?.interestSportsFitness.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestFavouriteCusine && userDetailobject?.interestFavouriteCusine.length ? dropDown['favouriteCuisine']?.option.filter(e => userDetailobject?.interestFavouriteCusine.includes(e.key)).map(ele => ele.name).join(", ") : '',
                userDetailobject?.interestFavouriteVacationDestination && userDetailobject?.interestFavouriteVacationDestination.length ? dropDown['favouriteVacationDestination']?.option.filter(e => userDetailobject?.interestFavouriteVacationDestination.includes(e.key)).map(ele => ele.name).join(", ") : ''
            ].filter(Boolean).join(", ") || '-'
        }
    ];
    const partnerPreference = [
        {
            name:'Age',
            value:userDetailobject?.isageMatchWithPF === true ? `${dropDown?.['age']?.option?.find(item => item.key === userDetailobject?.partnerAgeMin)?.name} - ${dropDown?.['age']?.option?.find(item => item.key === userDetailobject?.partnerAgeMax)?.name} Year` : ''
        },
        {
            name:'Height',
            value:userDetailobject?.isheightMatchWithPF === true ? `${dropDown?.['height']?.option?.find(item => item.key === userDetailobject?.partnerHeightMin)?.name} - ${dropDown?.['height']?.option?.find(item => item.key === userDetailobject?.partnerHeightMax)?.name}` : ''
        },
        {
            name:'Caste',
            value:userDetailobject?.iscasteMatchWithPF === true ? dropDown?.['caste']?.option?.filter(item => userDetailobject?.partnerCaste?.includes(item.key))?.map(item => item.name)?.join(', ') : ''
        },
        {
            name:'Religion',
            value:userDetailobject?.isreligionMatchWithPF === true ? dropDown?.['religion']?.option?.filter(item => userDetailobject?.partnerReligion?.includes(item.key))?.map(item => item.name)?.join(', ') : ''
        },
        {
            name:'Income',
            value:userDetailobject?.isincomeMatchWithPF === true ? dropDown?.['annualIncome']?.option?.find(item => item.key === userDetailobject?.partnerIncome)?.name : ''
        },
        {
            name:'Country',
            value:userDetailobject?.isLocationCountryMatchWithPF === true ? userDetailobject?.partnerLocationCountry.join(', ') : ''
        }
    ];
    const aboutMe = [
        {
            value:userDetailobject?.aboutMe
        }
    ];
    const handleClick = (value) => {
        setActiveClass(value);
    };

    useEffect(() => {
        const calculateAge = (dateOfBirth) => {
          const dob = moment(dateOfBirth);
          const now = moment();
          return now.diff(dob, 'years');
        };
    
        const dob = userDetailobject?.horoscopeDateOfBirth || new Date();
        const age = calculateAge(dob);
        setAge(age);


    }, [userDetailobject]);
    return(
        <>
            {
                userDetailobject?.isProfileUnlock === false ? 
                <div className="text-end mb-[20px]">
                    <Button
                        class={'bg-[#ed1c24] inline-block text-white font-family-Quicksand-SemiBold text-[18px] rounded-[50px] pr-[28px] pl-[25px] py-[10px] leading-[1.5] border border-[transparent]'}
                        buttonName={'Unlock Contact'}
                        onClick={() =>  handleUnlock()}
                    />
                </div>
                : null
            }
            <div>
                <div className="px-[22px] pt-[20px] pb-[10px] rounded-[12px] bg-[#f0e1b9] mb-[25px]">
                    <div className="sm:text-left text-center pb-[12px] border-b border-b-[#b7892d] mb-[12px] sm:grid sm:grid-cols-2 block items-center">
                        <span className="sm:col-span-2 inline-block sm:w-[fit-content] text-white bg-[#b7892d] leading-[normal] tracking-[0.4px] mb-[5px] px-[7px] pt-[2px] pb-[4px] font-family-Quicksand-Bold text-[14px] uppercase rounded-[7px] text-stroke">{userDetailobject.profileId}</span>
                        <h4 className="sm:col-span-2 font-family-Quicksand-Bold text-[20px] text-[#931217] mb-[3px] leading-[normal]">{userDetailobject?.firstName}</h4>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal]">Profile Created by {userDetailobject?.basicProfileCreatedFor ? dropDown?.['profileCreatorFor']?.option?.find(ele => ele.key === Number(userDetailobject.basicProfileCreatedFor))?.name  : '-'}</p>
                        <span className="text-end text-stroke text-[#931217] text-[18px] leading-[normal] font-family-Quicksand-SemiBold">{userDetailobject?.matchScore}/6 <small className="text-[14px]">Matches</small></span>
                    </div>
                    <div className="sm:text-left text-center grid sm:grid-cols-3 grid-cols-1">
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.horoscopeDateOfBirth ? `${age} Years` : '-'} | {userDetailobject?.basicHeight ? dropDown?.['height']?.option.find(e => e.key === Number(userDetailobject?.basicHeight))?.name : '-'}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.educationOccupation ? dropDown['occupation']?.option.find(e => e.key === Number(userDetailobject?.educationOccupation))?.name : ''}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.educationHighestQualification ? dropDown?.['degree']?.option.find(e => e.key === Number(userDetailobject?.educationHighestQualification))?.name : '-'}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.basicReligion ? dropDown['religion']?.option.find(e => e.key === Number(userDetailobject?.basicReligion))?.name : '-'}, {userDetailobject?.basicCaste ? dropDown?.['caste']?.option.find(e => e.key === Number(userDetailobject?.basicCaste))?.name : '-'}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.educationAnnualIncome ? `${dropDown['annualIncome']?.option.find(e => e.key === Number(userDetailobject?.educationAnnualIncome))?.name}  - Annually` : '-'}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px]"></p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.locationCity || '-'}, {userDetailobject?.locationState || '-'}, {userDetailobject?.locationCountry || '-'}</p>
                        <p className="font-family-Quicksand-Medium text-[14px] text-[#262626] text-stroke leading-[normal] mb-[12px] word-break-[break-word]">{userDetailobject?.basicMaritalStatus ? dropDown['maritalStatus']?.option.find(e => e.key === Number(userDetailobject?.basicMaritalStatus))?.name : '-'}</p>
                    </div>
                </div>
            </div>
            <div className="flex overflow-auto border-[1px] border-[#b7892d] rounded-[50px] ms:justify-between mb-[25px]">
                <Button
                    class={`${activeClass === 1 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'About Me'}
                    onClick={() => handleClick(1)}
                />
                <Button
                    class={`${activeClass === 2 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'Education  & Career'}
                    onClick={() => handleClick(2)}
                />
                <Button
                    class={`${activeClass === 3 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'About Family'}
                    onClick={() => handleClick(3)}
                />
                <Button
                    class={`${activeClass === 4 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'Life Style'}
                    onClick={() => handleClick(4)}
                />
                <Button
                    class={`${activeClass === 5 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'Interest & More'}
                    onClick={() => handleClick(5)}
                />
                <Button
                    class={`${activeClass === 6 ? 'bg-[#ed1c24] text-[#fff]' : 'text-[#b7892d]'} xl:min-w-[auto] min-w-[160px]  pt-[9px] pb-[11px] px-[12px] text-[14px] font-family-Quicksand-Bold leading-[normal] inline-block rounded-[50px]`}
                    buttonName={'Partner preference'}
                    onClick={() => handleClick(6)}
                />
            </div>
            <div>
                {
                    activeClass === 1 ? 
                        <>
                            <ViewProfileComp 
                                viewProfileDetail={aboutMe}
                                title={'About Me'}
                                classWrapper={'pt-[25px] pb-[25px] px-[30px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid grid-cols-1'}
                                valueClass={'text-stroke'}
                            />
                            <ViewProfileComp 
                                viewProfileDetail={education}
                                title={'Education & Career'}
                                classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                                fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                                valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                            />
                            <ViewProfileComp 
                                viewProfileDetail={aboutFamily}
                                title={'About Family'}
                                classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                                fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                                valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                            />
                            <ViewProfileComp 
                                viewProfileDetail={lifeStyle}
                                title={'Life Style'}
                                classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                                fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                                valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                            />
                            <ViewProfileComp 
                                viewProfileDetail={interestMore}
                                title={'Interest & More'}
                                classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid grid-cols-1 sm:text-left text-center'}
                                fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                                valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                            />
                            <ViewProfileComp 
                                viewProfileDetail={interestMore}
                                title="Partner Preference"
                                classWrapper="pt-[25px] pb-[20px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid grid-cols-1"
                            >
                                <div className="col-span-2 mb-[20px] w-[100px] h-[100px] md:mr-0 md:ml-auto ml-auto mr-auto">
                                    <CircularProgressbarWithChildren value={partnerPreference?.filter(e => e.value)?.length ? (partnerPreference?.filter(e => e.value)?.length / 6) * 100 : 0} 
                                        styles={
                                            {
                                                path: {
                                                    stroke: `#ed1c24`,
                                                },
                                                trail: {
                                                    stroke: '#e1b923',
                                                },
                                            }
                                        }
                                    >
                                        <span className="text-center font-family-Quicksand-Bold text-[14px] text-[#262626] leading-[normal]">{partnerPreference?.filter(e => e.value)?.length || 0} to 6 <br /> Matches</span>
                                    </CircularProgressbarWithChildren>
                                </div>
                                <div className="grid grid-cols-4">
                                    <div className="sm:col-span-3 col-span-2 sm:px-[15px] px-0">
                                        <h5 className="font-family-Quicksand-Bold sm:text-[20px] text-[16px] text-[#931217] my-[15px] leading-[normal]">Her Preferences</h5>
                                        {partnerPreference && partnerPreference.length ? partnerPreference.map((e, index) => (
                                            <div key={index} className="grid md:grid-cols-2 grid-cols-1">
                                                <h5 className="font-family-Quicksand-Bold text-[16px] text-[#931217] mb-[7px] leading-[normal]">{e.name}</h5>
                                                <p className="font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] truncate">{e.value ? e.value : '-'}</p>
                                            </div>
                                        )) : null}
                                    </div>
                                    <div className="sm:col-span-1 col-span-2 sm:px-[15px] px-0">
                                        <h5 className="font-family-Quicksand-Bold sm:text-[20px] text-[16px] text-[#931217] my-[15px] leading-[normal] sm:text-left text-right">Matched You</h5>
                                        {partnerPreference && partnerPreference.length ? partnerPreference.map((e, index) => (
                                            <div key={index} className="md:h-[27px]">
                                                {
                                                    e.value ? 
                                                    <div className="grid grid-cols-1">
                                                        <img src={rightImage} alt="rightImage" className="mt-[2px] ml-auto sm:ml-0" />
                                                        <p className="opacity-0">s</p>
                                                    </div> : 
                                                    <div className="grid grid-cols-1">
                                                        <img src={cancelImage} alt="cancelImage" className="mt-[2px] ml-auto sm:ml-0" />
                                                        <p className="opacity-0">s</p>
                                                    </div>
                                                }
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                            </ViewProfileComp>
                        </>
                    : activeClass === 2 ?
                        <ViewProfileComp 
                            viewProfileDetail={education}
                            title={'Education & Career'}
                            classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                            fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                            valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                        />
                    : activeClass === 3 ?
                        <ViewProfileComp 
                            viewProfileDetail={aboutFamily}
                            title={'About Family'}
                            classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                            fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                            valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                        />
                    : activeClass === 4 ?
                        <ViewProfileComp 
                            viewProfileDetail={lifeStyle}
                            title={'Life Style'}
                            classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid md:grid-cols-4 grid-cols-1 sm:text-left text-center'}
                            fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                            valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                        />
                    : activeClass === 5 ?
                        <ViewProfileComp 
                            viewProfileDetail={interestMore}
                            title={'Interest & More'}
                            classWrapper={'pt-[25px] pb-[5px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] sm:text-left text-center'}
                            fieldClass={'mb-[20px] sm:px-[15px] px-0'}
                            valueClass={'font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] word-break-[break-word]'}
                        />
                    : activeClass === 6 ?
                        <ViewProfileComp 
                            viewProfileDetail={interestMore}
                            title="Partner Preference"
                            classWrapper="pt-[25px] pb-[20px] px-[15px] bg-[#f0e1b9] rounded-[12px] mb-[20px] grid grid-cols-1"
                        >
                            <div className="col-span-2 mb-[20px] w-[100px] h-[100px] md:mr-0 md:ml-auto ml-auto mr-auto">
                                <CircularProgressbarWithChildren value={partnerPreference?.filter(e => e.value)?.length ? (partnerPreference?.filter(e => e.value)?.length / 6) * 100 : 0} 
                                    styles={
                                        {
                                            path: {
                                                stroke: `#ed1c24`,
                                            },
                                            trail: {
                                                stroke: '#e1b923',
                                            },
                                        }
                                    }
                                >
                                    <span className="text-center font-family-Quicksand-Bold text-[14px] text-[#262626] leading-[normal]">{partnerPreference?.filter(e => e.value)?.length || 0} to 6 <br /> Matches</span>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="grid grid-cols-4">
                                <div className="sm:col-span-3 col-span-2 sm:px-[15px] px-0">
                                    <h5 className="font-family-Quicksand-Bold sm:text-[20px] text-[16px] text-[#931217] my-[15px] leading-[normal]">Her Preferences</h5>
                                    {partnerPreference && partnerPreference.length ? partnerPreference.map((e, index) => (
                                        <div key={index} className="grid md:grid-cols-2 grid-cols-1">
                                            <h5 className="font-family-Quicksand-Bold text-[16px] text-[#931217] mb-[7px] leading-[normal]">{e.name}</h5>
                                            <p className="font-family-Quicksand-Medium text-[18px] text-[#262626] leading-[normal] truncate">{e.value ? e.value : '-'}</p>
                                        </div>
                                    )) : null}
                                </div>
                                <div className="sm:col-span-1 col-span-2 sm:px-[15px] px-0">
                                    <h5 className="font-family-Quicksand-Bold text-[20px] text-[#931217] my-[15px] leading-[normal] sm:text-left text-right">Matched You</h5>
                                    {partnerPreference && partnerPreference.length ? partnerPreference.map((e, index) => (
                                        <div key={index} className="md:h-[27px]">
                                            {
                                                e.value ? 
                                                <div className="grid grid-cols-1">
                                                    <img src={rightImage} alt="rightImage" className="mt-[2px] ml-auto sm:ml-0" />
                                                    <p className="opacity-0">s</p>
                                                </div> : 
                                                <div className="grid grid-cols-1">
                                                    <img src={cancelImage} alt="cancelImage" className="mt-[2px] ml-auto sm:ml-0" />
                                                    <p className="opacity-0">s</p>
                                                </div>
                                            }
                                        </div>
                                    )) : null}
                                </div>
                            </div>
                        </ViewProfileComp>
                    : null
                }
            </div>
        </>
    )
};

export default ViewProfileDetail;