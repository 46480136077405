import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'getPartner',
    initialState: {
        getPartner: null,
    },
    reducers: {
        getPartner: (state, action) => {
            state.getPartner = action.payload;
        },
    },
});

export const { getPartner } = slice.actions;
export default slice.reducer;