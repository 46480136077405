import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'userWithoutAuth',
    initialState: {
        userDetailWithoutAuth: null,
    },
    reducers: {
        getUserWithoutAuth: (state, action) => {
            state.userDetailWithoutAuth = action.payload;
        },
    },
});

export const { getUserWithoutAuth } = slice.actions;
export default slice.reducer;