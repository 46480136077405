import Button from "../../atom/button";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import useValidation from "../../../utils/validation.js";
import CustomInput from "../../molecules/customInput.jsx";
import allCountries from "../../atom/countryCode/countryCode.jsx";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from "moment";

const ContactDetails = ({isEdit,userDetailobject,handleEditContactDetail,handleUnlock,handleCancelContactDetail,handleSubmitContactDetail,isContactDetailEdit,handleSaveContactDetail}) => {
    const timePickerRef = useRef(null);
    const timePickerRefMax = useRef(null);
    const user = useSelector(state => state?.user?.user);
    const dropdownRef = useRef(null);
    const typingTimeoutRef = useRef(null);
    const [typedCharacters, setTypedCharacters] = useState('');
    const [isOpen,setIsOpen] = useState(false);
    const [isOpen2,setIsOpen2] = useState(false);
    const [isToggle,setIsToggle] = useState(isContactDetailEdit);
    const  { checkErrors,checkAllFields } = useValidation();
    const [countryArray,setCountryArray] = useState(allCountries);
    const [countryArray2,setCountryArray2] = useState(allCountries);
    const [isInputFocused, setIsInputFocused] = useState(true);
    const [countryObject,setCountryObject] = useState(user?.alternateMobile1?.countryCode ? allCountries.find(e => e.dialCode === user?.alternateMobile1?.countryCode) :{
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [countryObject2,setCountryObject2] = useState(user?.alternateMobile2?.countryCode ? allCountries.find(e => e.dialCode === user?.alternateMobile2?.countryCode) : {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [formData,setFormData] = useState({
        alternateMobile1:{value: user?.alternateMobile1?.mobileNumber ? user?.alternateMobile1?.mobileNumber : '',rules:"",name: "alternate mobile 1",error: "",countryCode:user?.alternateMobile1?.countryCode ? user?.alternateMobile1?.countryCode : '+91'},
        alternateMobile2:{value: user?.alternateMobile2?.mobileNumber ? user?.alternateMobile2?.mobileNumber : '',rules:"",name: "alternate mobile 2",error: "",countryCode:user?.alternateMobile2?.countryCode ? user?.alternateMobile2?.countryCode : '+91'},
        convenientTimeMax:{value: user?.convenientTimeMax ? user?.convenientTimeMax : '' ,rules:"",name: "convenient time max",error: ""},
        convenientTimeMin:{value: user?.convenientTimeMin ? user?.convenientTimeMin : '' ,rules:"",name: "convenient time min",error: ""}
    });

    useEffect(() => {
        setIsToggle(isContactDetailEdit);
    },[isContactDetailEdit])
    
    const handleEdit = () => {
        setIsToggle(true);
        handleEditContactDetail();
    };
    const handleCountryCode = (ele) => {
        setCountryObject(ele);
        setIsOpen(false);
        setFormData({ ...formData, alternateMobile1: {...formData.alternateMobile1,countryCode:ele.dialCode}});
        setCountryArray(allCountries);
    };
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleCountryCode2 = (ele) => {
        setCountryObject2(ele);
        setIsOpen2(false);
        setFormData({ ...formData, alternateMobile2: {...formData.alternateMobile2,countryCode:ele.dialCode}});
        setCountryArray2(allCountries);
    };
    const handleToggleCountryCode2 = () => {
        setIsOpen2(!isOpen2);
        setCountryArray2(allCountries);
        setIsInputFocused(true);
    };
    const handleOutsideClick2 = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen2(false);
        }
    };
    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleInputChange2 = (e) => {
        setCountryArray2(allCountries);
        setCountryArray2(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleCancel = () => {
        setIsToggle(false);
        
        setFormData(prev => ({
            ...prev,
            alternateMobile1:{value: user?.alternateMobile1?.mobileNumber ? user?.alternateMobile1?.mobileNumber : '',rules:"",name: "alternate mobile 1",error: "",countryCode:user?.alternateMobile1?.countryCode ? user?.alternateMobile1?.countryCode : '+91'},   
            alternateMobile2:{value: user?.alternateMobile2?.mobileNumber ? user?.alternateMobile2?.mobileNumber : '',rules:"",name: "alternate mobile 2",error: "",countryCode:user?.alternateMobile2?.countryCode ? user?.alternateMobile2?.countryCode : '+91'},
            convenientTimeMax:{value: user?.convenientTimeMax ? user?.convenientTimeMax : '' ,rules:"",name: "convenient time max",error: ""},
            convenientTimeMin:{value: user?.convenientTimeMin ? user?.convenientTimeMin : '' ,rules:"",name: "convenient time min",error: ""}
        }));
        handleCancelContactDetail();
    }
    const handleSubmit = () => {
        if(formData.alternateMobile1.value){
            formData.alternateMobile1.rules = `min:7|max:15|regex:^[0-9]+$`
        }else{
            formData.alternateMobile1.rules = ''
            formData.alternateMobile1.error = '';
        }
        if(formData.alternateMobile2.value){
            formData.alternateMobile2.rules = `min:7|max:15|regex:^[0-9]+$`
        }else{
            formData.alternateMobile2.rules = ''
            formData.alternateMobile2.error = '';
        }
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid && formData.convenientTimeMax.error === '' && formData.convenientTimeMin.error === ''){
                handleSubmitContactDetail(formData);
            }
        });
    };

    const handleChange = (value,e,id) => {
        const updatedFormData = { ...formData };

        if (id === "convenientTimeMax") {
            updatedFormData.convenientTimeMax.value = value?.format('HH:mm');
        } else {
            updatedFormData.convenientTimeMin.value = value?.format('HH:mm');
        }

        // Reset errors
        updatedFormData.convenientTimeMin.error = '';
        updatedFormData.convenientTimeMax.error = '';

        // Validate the times
        const minTime = updatedFormData.convenientTimeMin.value;
        const maxTime = updatedFormData.convenientTimeMax.value;
        if (minTime && maxTime) {
            if (minTime > maxTime) {
                updatedFormData.convenientTimeMin.error = 'The min time should be smaller than max time';
                updatedFormData.convenientTimeMax.error = 'The max time should be greater than min time';
            }
        } else if (minTime || maxTime) {
            if (!minTime) {
                updatedFormData.convenientTimeMin.error = 'The min time is required if max time is entered';
            }
            if (!maxTime) {
                updatedFormData.convenientTimeMax.error = 'The max time is required if min time is entered';
            }
        }
        if (timePickerRef?.current) {
            timePickerRef?.current.onVisibleChange(false);
        }
        if (timePickerRefMax?.current) {
            timePickerRefMax?.current.onVisibleChange(false);
        }
        setFormData(updatedFormData);
    };
    
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick2);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick2);
        };
    }, []);

    useEffect(() => {
        if(handleSaveContactDetail === true){
            handleSubmit();
        }
    },[handleSaveContactDetail])
    const getInitialTime = (timeString) => {
        return timeString ? moment(timeString, 'HH:mm') : null;
    };
    const getInitialTimes = (timeString) => {
        return timeString ? moment(timeString, 'HH:mm') : null;
    };
    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    useEffect(() => {
        if (isOpen || isOpen2) {
            dropdownRef.current.focus();
        }
    }, [isOpen,isOpen2]);
    return(
        <div className="mt-[20px]">
            {!isEdit ? <div className="flex items-center justify-between mb-[10px]">
                <h4 className="text-[#ed1c24] ms:leading-[1.2] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] text-[24px]">Contact Details</h4>
                {
                    !isToggle ? 
                    <Button 
                        class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                        buttonName={'Edit'}
                        onClick={() => handleEdit()}
                    /> : null
                }
            </div>:null}
            {
                !isEdit ? 
                <div className="grid grid-cols-1 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                    <div className="px-[15px] break-all">
                        <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Email</h5>
                        <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{user?.email}</p>
                    </div>
                    <div className="px-[15px]">
                        <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Phone</h5>
                        <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{user?.mobile.countryCode} {user?.mobile.mobileNumber}</p>
                    </div>
                    <div className="px-[15px]">
                        <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Alternate Mobile No. 1</h5>
                        {!isToggle ? 
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{user?.alternateMobile1 && user?.alternateMobile1.mobileNumber ? `${user?.alternateMobile1.countryCode} ${user?.alternateMobile1.mobileNumber}` : '-'}</p>
                            : 
                            <>
                                <div className="flex relative mt-[5px] mb-[5px]">
                                    <div  className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode();}}>
                                        {countryObject?.name} 
                                        <span className="dropDown_arrow"></span>
                                    </div>
                                    {
                                        isOpen ?
                                            <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                                <ul>
                                                    {countryArray.length ? countryArray.map((ele,index) => 
                                                        <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode(ele);}}>
                                                            {ele?.name} {ele?.countryJoin}
                                                        </li>
                                                    ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                </ul>
                                            </div>
                                        : null
                                    }
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        type="number"
                                        modelValue={formData.alternateMobile1.value}
                                        placeHolder="Enter mobile no"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.alternateMobile1.value = value;
                                            if (formData.alternateMobile1.value) {
                                                formData.alternateMobile1.rules = `min:7|max:15|regex:^[0-9]+$`;
                                            } else {
                                                formData.alternateMobile1.rules = '';
                                                formData.alternateMobile1.error = '';
                                            }
                                            checkErrors({
                                                'field': formData.alternateMobile1,
                                                'name': 'alternate mobile 1',
                                                'validations': formData.alternateMobile1.rules,
                                                'type': formData.alternateMobile1.type,
                                                'event': event,
                                                'value':value
                                            });
                                            setFormData({...formData});
                                        }}
                                        onKeyPress={(event)=>{
                                            if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(event)=>{
                                            if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                {formData.alternateMobile1.error && 
                                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.alternateMobile1.error}</small>
                                }
                            </>
                        }
                    </div>
                    <div className="px-[15px]">
                        <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Alternate Mobile No. 2</h5>
                        {!isToggle ? 
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{user?.alternateMobile2 && user?.alternateMobile2.mobileNumber ? `${user?.alternateMobile2.countryCode} ${user?.alternateMobile2.mobileNumber}` : '-'}</p>
                            : 
                            <>
                                <div className="flex relative mt-[5px] mb-[5px]">
                                    <div  className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode2();}}>
                                        {countryObject2?.name}
                                        <span className="dropDown_arrow"></span>
                                    </div>
                                    {
                                        isOpen2 ?
                                            <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange2} placeholder="Search..." />
                                                <ul>
                                                    {countryArray2.length ? countryArray2.map((ele,index) => 
                                                        <li className={`${countryObject2?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode2(ele);}}>
                                                            {ele.name} {ele?.countryJoin}
                                                        </li>
                                                    ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                </ul>
                                            </div>
                                        : null
                                    }
                                    <CustomInput
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        type="number"
                                        modelValue={formData.alternateMobile2.value}
                                        placeHolder="Enter mobile no"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.alternateMobile2.value = value;
                                            if (formData.alternateMobile2.value) {
                                                formData.alternateMobile2.rules = `min:7|max:15|regex:^[0-9]+$`;
                                            } else {
                                                formData.alternateMobile2.rules = '';
                                                formData.alternateMobile2.error = '';
                                            }
                                            checkErrors({
                                                'field': formData.alternateMobile2,
                                                'name': 'alternate mobile 2',
                                                'validations': formData.alternateMobile2.rules,
                                                'type': formData.alternateMobile2.type,
                                                'event': event,
                                                'value':value
                                            });
                                            setFormData({...formData});
                                        }}
                                        onKeyPress={(event)=>{
                                            if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                        onKeyDown={(event)=>{
                                            if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                event.event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                                {formData.alternateMobile2.error && 
                                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.alternateMobile2.error}</small>
                                }
                            </>
                        }
                    </div>
                    <div className="px-[15px]">
                        <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Convenient Time to Contact</h5>
                        {!isToggle ? 
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{formData.convenientTimeMin.value && formData.convenientTimeMax.value ? `${formData.convenientTimeMin.value} - ${formData.convenientTimeMax.value}` : '-'}</p>
                            : 
                            <div className="flex">
                                <div className="w-full md:max-w-[170px]">
                                    <TimePicker
                                        ref={timePickerRef}
                                        showSecond={false}
                                        defaultValue={getInitialTime(formData?.convenientTimeMin?.value)}
                                        onChange={(value) => handleChange(value, '', 'convenientTimeMin')}
                                        format="HH:mm"
                                        placeholder="HH:mm"
                                        use12Hours={false}
                                        className="w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] h-[41px] mb-[5px] mt-[5px] border border-[#ced4da] rounded-[10px]"
                                    />
                                    {formData.convenientTimeMin.error && 
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.convenientTimeMin.error}</small>
                                    }
                                </div>
                                <span className="mt-[10px] mx-[5px] block text-center font-family-Quicksand-Bold text-[#931217] text-[18px]">to</span>
                                <div className="w-full md:max-w-[170px]">
                                    <TimePicker
                                        ref={timePickerRefMax}
                                        showSecond={false}
                                        defaultValue={getInitialTimes(formData?.convenientTimeMax?.value)}
                                        onChange={(value) => handleChange(value, '', 'convenientTimeMax')}
                                        format="HH:mm"
                                        placeholder="HH:mm"
                                        use12Hours={false}
                                        className="w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] h-[41px] mb-[5px] mt-[5px] border border-[#ced4da] rounded-[10px]"
                                    />
                                    {formData.convenientTimeMax.error && 
                                        <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.convenientTimeMax.error}</small>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
                : 
                userDetailobject ?
                    userDetailobject?.isProfileUnlock === true ? 
                    <div className="grid grid-cols-1 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                        <div className="px-[15px] break-all">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Email</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetailobject?.email}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Phone</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetailobject?.mobile?.countryCode} {userDetailobject?.mobile?.mobileNumber}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Alternate Mobile No. 1</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetailobject?.alternateMobile1 && userDetailobject?.alternateMobile1?.mobileNumber ? `${userDetailobject?.alternateMobile1?.countryCode} ${userDetailobject?.alternateMobile1?.mobileNumber}` : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Alternate Mobile No. 2</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetailobject?.alternateMobile2 && userDetailobject?.alternateMobile2?.mobileNumber ? `${userDetailobject?.alternateMobile2?.countryCode} ${userDetailobject?.alternateMobile2?.mobileNumber}` : '-'}</p>
                        </div>
                        <div className="px-[15px]">
                            <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Convenient Time to Contact</h5>
                            <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{userDetailobject.convenientTimeMin && userDetailobject.convenientTimeMax ? `${userDetailobject.convenientTimeMin} - ${userDetailobject.convenientTimeMax}` : '-'}</p>
                        </div>
                    </div> :
                    <div className="bg-[#f0e1b9] rounded-[12px] mb-[20px] pt-[12px] pb-[10px] px-[12px] text-center">
                        <Button
                            class='bg-[#ed1c24] inline-block text-white font-family-Quicksand-SemiBold text-[18px] rounded-[50px] pr-[28px] pl-[25px] py-[10px] leading-[1.5] border border-[transparent]'
                            buttonName={'Unlock Contact'}
                            onClick={() =>  handleUnlock()}
                        />
                    </div>
                : ''
            }
            {
                isToggle ?
                    <div className="flex justify-end">
                        <Button
                            class='leading-[normal] text-[#ee1c24] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Cancel'}
                            onClick={() =>  handleCancel()}
                        />
                        <Button
                            class='leading-[normal] text-[#fff] ml-[20px] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Save'}
                            onClick={() => handleSubmit()}
                        />
                    </div> 
                : ''
            }
        </div>
    )
}

export default ContactDetails;