import { useEffect, useState } from "react";
import Button from "../../../atom/button.jsx";
import * as env from '../../../../config/env.js';
import { ToastContainer, toast } from 'react-toastify';
import Eye from "../../../../assets/images/png/eye.png";
import Spinner from "../../../atom/spinner/spinner.jsx";
import { apiRequest } from "../../../../services/index.js";
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
import Hidden from "../../../../assets/images/png/hidden.png";
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCustomComposable from "../../../../utils/composables/index.js";

const ResetPassword = () => {
    const { focusNextInput } = useCustomComposable();
    const { id,token } = useParams();
    const navigate = useNavigate();
    
    const [isLoading,setIsLoading] = useState(true);
    const [validLink,setValidLink] = useState(true);
    const [confirmationErr,setConfirmationErr] = useState('');
    const [toggleNewConfirm,setToggleNewConfirm] = useState('');
    const [toggleNewPassword,setToggleNewPassword] = useState('');
    const  { checkErrors,checkAllFields } = useValidation();
    const [formData,setFormData] = useState({
        newPassword: {value: '',rules:"required | regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "new password",error: ""},
        confirmNewPassword: {value: '',rules:"",name: "confirm new password",error: ""}
    });
    
    // useEffect    
    useEffect(() => {
        try {
            setIsLoading(true);
            let forgotPassword = {
                userId:id ? id : '12',
                token:token ? token : '12'
            }
            apiRequest("post", env.VALIDATERESETPASSWORD, forgotPassword).then((response) => {
                if(response.status === 200){
                    // toast.success(response.data.message, {
                    //     position: 'top-right',
                    // });
                    setValidLink(true);
                    setIsLoading(false);
                }else{
                    setValidLink(false);
                    setIsLoading(false);
                }
            }).catch((error) => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, {
                        position: 'top-right',
                    });
                }
                console.error('Error in forgot password',error);
                setValidLink(false);
                setIsLoading(false);
            });
        }catch (error) {
            console.error('forgot password error',error);
            setValidLink(false);
            setIsLoading(false);
        }
    },[token,id]);

    useEffect(() => {
        const isConfirmNewPasswordFocused = document.activeElement === document.getElementById('confirmNewPassword');
        if(isConfirmNewPasswordFocused){
            confirmationPassValidation();
        }
    },[formData.confirmNewPassword.value]);

    // funtion
    const handleResetPassword = () => {
        checkAllFields(formData).then((valid)=>{
            const passwordMatched = confirmationPassValidation();
            setFormData({...formData});
            if(valid && passwordMatched === ''){
                try {
                    setIsLoading(true);
                    let resetPassword = {
                        userId:id ? id : '12',
                        password:formData.confirmNewPassword.value,
                        token:token ? token : '12'
                    }
                    apiRequest("post", env.RESETPASSWORD, resetPassword).then((response) => {
                        if(response.status === 200){
                            toast.success(response.data.message, {
                                position: 'top-right',
                            });
                            setTimeout(() => {
                                navigate('/login');
                                setIsLoading(false);
                            }, 1000);
                        }else{
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                        console.error('Error in forgot password',error);
                        setIsLoading(false);
                    });
                }catch (error) {
                    console.error('forgot password error',error);
                    setIsLoading(false);
                }
            }
        });
    };
    const handleToggleNewPassword = () => {
        setToggleNewPassword(!toggleNewPassword);
        if(!toggleNewPassword){
            document.getElementById('createNewPassword').type = 'text';
        }else{
            document.getElementById('createNewPassword').type = 'password';
        }
    };
    const handleSelect = (value,event,id) => {
        if(id === 'newPassword'){
            checkErrors({
                'field': formData.newPassword,
                'name': 'new password',
                'validations': formData.newPassword.rules,
                'event': event,           
                'value': value
            });
            setFormData({ ...formData, newPassword: {...formData.newPassword,value:value} });
            updateConfirmation();
        }else if(id === 'confirmNewPassword'){
            setFormData({ ...formData, confirmNewPassword: {...formData.confirmNewPassword,value:value} });
            
        }
    };
    const updateConfirmation = () => {
        if(formData.confirmNewPassword.value === ''){
            return;
        }else{
            if(formData.confirmNewPassword.value === ''){
                setConfirmationErr('The confirm password field is required');
            }else if(formData.newPassword.value !== formData.confirmNewPassword.value){
                setConfirmationErr('The confirm password confirmation does not match');
            }else{
                setConfirmationErr('');
            }
        }
    };
    const confirmationPassValidation = (e) => {
        let err = ""
        if(formData.confirmNewPassword.value === ''){
            err = 'The confirm password field is required';
        }else if(formData.newPassword.value !== formData.confirmNewPassword.value){
            err = 'The confirm password confirmation does not match';
        }
        setConfirmationErr(err);

        return err;
    };
    const handleToggleConfirm = () => {
        setToggleNewConfirm(!toggleNewConfirm);
        if(!toggleNewConfirm){
            document.getElementById('confirmNewPassword').type = 'text';
        }else{
            document.getElementById('confirmNewPassword').type = 'password';
        }
    };
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };

    return(
        <>
            <ToastContainer />
            {
                isLoading ? 
                <>
                    <Spinner /> 
                    <div className="py-[250px]"></div>
                </>
                : 
                (
                    validLink ?
                    <div className={'xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] xxms:px-[22px] xms:px-[30px] mx-auto'}>
                        <div className={`mx-auto xxms:my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] pt-[38px] lg:pb-[45px] pb-[38px] after:bg-no-repeat xxms:after:bg-[length:100px] sm:after:bg-auto xxms:after:w-[100px] xxms:after:h-[100px] sm:after:w-[150px] sm:after:h-[150px] xxms:after:bottom-[-18px] xxms:after:left-[-15px] sm:after:bottom-[-24px] after:rotate-180 sm:after:left-[-40px] after:bg-top after:absolute after:bg-[url("../../assets/images/png/border_design.png")] before:bg-no-repeat xxms:before:w-[100px] xxms:before:h-[100px] xxms:before:bg-[length:100px] sm:before:w-[150px] sm:before:h-[150px] xxms:before:top-[-10px] xxms:before:right-[-18px] sm:before:top-[-24px] sm:before:right-[-40px] before:bg-top before:absolute before:bg-[url("../../assets/images/png/border_design.png")] sm:before:bg-auto`}>
                            <div className={`relative w-full mx-auto`}>
                                <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Reset Password</h2>
                                {/* <p className="xxms:px-[10px] mt-[28px] font-family-Quicksand-Medium text-[16px] block text-stroke text-center">Enter your Correct email address and we'll send you a link to reset your password.</p> */}
                                <form onSubmit={(event) => {event.preventDefault(); handleResetPassword();}} className={'xxms:px-[15px] md:px-[0px] mt-[20px] max-w-[540px] w-full mx-auto'}>
                                    <div className="relative">
                                        <div className="mb-[16px] relative">
                                            <CustomInput
                                                inputId="createNewPassword"
                                                placeHolder={'Enter New Password'}
                                                label="New Password *"
                                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[44px] py-[5px] h-[42px]'}
                                                class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                                type="password"
                                                modelValue={formData?.newPassword?.value ? formData?.newPassword?.value : ''}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onChange={(value,event) => handleSelect(value,event,'newPassword')}
                                                onPaste={(event) => {
                                                    event.event.preventDefault();
                                                }}
                                                onBlur={() => {
                                                    const blur = {...formData}
                                                    blur.newPassword.value = blur?.newPassword?.value?.trim();
                                                    setFormData(blur);
                                                }}
                                                onKeyPress={((value) => {
                                                    handleKeyPress(value, 'confirmNewPassword');
                                                    if(value.event.code === "Space"){
                                                        value.event.preventDefault();
                                                    }
                                                })}
                                            />
                                            <div className="w-[20px] h-[20px] absolute cursor-pointer top-[40px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggleNewPassword();}}>
                                                {
                                                    !toggleNewPassword ? 
                                                    <img src={Eye} alt="eye" /> :
                                                    <img src={Hidden} alt="Hidden" />
                                                }
                                            </div>
                                            {formData.newPassword.error && 
                                                <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.newPassword.error}</small>
                                            }
                                        </div>
                                        <div className="relative">
                                            <CustomInput
                                                inputId="confirmNewPassword"
                                                placeHolder={'Enter Confirm New Password'}
                                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[44px] py-[5px] h-[42px]'}
                                                class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                                label="Confirm New Password *"
                                                type="password"
                                                modelValue={formData?.confirmNewPassword?.value ? formData?.confirmNewPassword?.value : ''}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onChange={(value,event) => handleSelect(value,'','confirmNewPassword')}
                                                onPaste={(event) => {
                                                    event.event.preventDefault();
                                                }}
                                                onBlur={() => {
                                                    const blur = {...formData}
                                                    blur.confirmNewPassword.value = blur?.confirmNewPassword?.value?.trim();
                                                    setFormData(blur);
                                                }}
                                                onKeyPress={((value) => {
                                                    if(value.event.code === "Space"){
                                                        value.event.preventDefault();
                                                    }
                                                })}
                                            />
                                            <div className="w-[20px] h-[20px] absolute cursor-pointer top-[40px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggleConfirm();}}>
                                                {
                                                    !toggleNewConfirm ? 
                                                    <img src={Eye} alt="eye" /> :
                                                    <img src={Hidden} alt="Hidden" />
                                                }
                                            </div>
                                            {confirmationErr && 
                                                <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{confirmationErr}</small>
                                            }
                                        </div>
                                    </div>
                                    <div className="pt-[26px]">
                                        <Button 
                                            class={'bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[7.5px] px-[28px] leading-[normal] pb-[7.5px] font-family-Quicksand-SemiBold text-center block mx-auto'}
                                            buttonName={'Submit'}
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> : 
                    <div className={'xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] xxms:px-[22px] xms:px-[30px] mx-auto'}>
                        <div className={`mx-auto xxms:my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] pt-[38px] lg:pb-[45px] pb-[38px] after:bg-no-repeat xxms:after:bg-[length:100px] sm:after:bg-auto xxms:after:w-[100px] xxms:after:h-[100px] sm:after:w-[150px] sm:after:h-[150px] xxms:after:bottom-[-18px] xxms:after:left-[-15px] sm:after:bottom-[-24px] after:rotate-180 sm:after:left-[-40px] after:bg-top after:absolute after:bg-[url("../../assets/images/png/border_design.png")] before:bg-no-repeat xxms:before:w-[100px] xxms:before:h-[100px] xxms:before:bg-[length:100px] sm:before:w-[150px] sm:before:h-[150px] xxms:before:top-[-10px] xxms:before:right-[-18px] sm:before:top-[-24px] sm:before:right-[-40px] before:bg-top before:absolute before:bg-[url("../../assets/images/png/border_design.png")] sm:before:bg-auto`}>
                            <div className={`relative w-full mx-auto`}>
                                <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Reset Password</h2>
                                <p className="xxms:px-[10px] mt-[28px] font-family-Quicksand-Medium text-[16px] block text-stroke text-center">Your link is expired.</p>
                                <h5 className={'mt-4 text-[#931217] xxms:text-[16px] ms:text-[20px] font-family-Quicksand-Bold text-center'}> 
                                    <Link to="/forgot-password" className={'text-[#ed1c24] underline underline-offset-[6px]'}>Forgot Password</Link>
                                </h5>
                            </div>
                        </div>
                    </div>
                )   
            }
        </>
    )
};

export default ResetPassword;