import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'checkout',
    initialState: {
        checkOut: null,
    },
    reducers: {
        setCheckOutReducers: (state, action) => {
            state.checkOut = action.payload;
        },
    },
});

export const { setCheckOutReducers } = slice.actions;
export default slice.reducer;