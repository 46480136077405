import moment from "moment";
import { useEffect, useState } from "react";
import Pagination from "./pagination";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import * as env from '../../config/env.js';
import male from "../../assets/images/png/male.png";
import female from "../../assets/images/png/female.png";
import unlock from "../../assets/images/png/unlock.png";
import locked from '../../assets/images/png/locked.png';
import like from '../../assets/images/png/Like-red1.png';
import disLike from '../../assets/images/png/disLike.png';

const MyLikes = ({userDetails,classWrapper,isLike,matchesCount,page,imgClass,getMyLikeOrLikerData,userId,handleLikeDisLike,isRecentlyViewed,name}) => {
    const itemsPerPage = env.LIMIT;
    const [currentPage,setCurrentPage] = useState(page);
    const [totalPages,setTotalPages] = useState(Math.ceil(matchesCount / itemsPerPage));
    useEffect(() => {
        setTotalPages(totalPages);
    },[totalPages]);
    useEffect(() => {
        setCurrentPage(page);
    },[page]);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            const mutualMatchesElement = name === 'mutualMatches' ? document.getElementById("mutualMatches") : '';
            if (mutualMatchesElement) {
                mutualMatchesElement.scrollIntoView({
                    behavior: "smooth",  // Optional: Smooth scrolling behavior
                    block: "start"       // Optional: Aligns the top of the element to the top of the viewport
                });
                getMyLikeOrLikerData((page * env.LIMIT) - env.LIMIT ,env.LIMIT,page);
            }else {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"  // Optional: Smooth scrolling behavior
                });
                getMyLikeOrLikerData((page * env.LIMIT) - env.LIMIT ,env.LIMIT,page);
            }
        }
    };
    const [likeOrLikers,setLikeOrLikers] = useState(userDetails);
    const calculateAge = (dateOfBirth) => {
        const dob = moment(dateOfBirth);
        const now = moment();
        return now.diff(dob, 'years');
    };
    const handleLike = (action,id,e,ind) => {
        e.stopPropagation();
        handleLikeDisLike(action,id,(page * env.LIMIT) - env.LIMIT ,env.LIMIT,page,ind);
    };
    useEffect(() => {
        setLikeOrLikers(userDetails);
    },[userDetails]);
    const getImageUrl = (photoUrls, size) => {
        const photo = photoUrls.find(photo => photo[size]);
        return photo ? photo[size] : null;
    };
    return(
        <>
            <div className={classWrapper}>
                {likeOrLikers.map((e,ind) => 
                        <div key={ind} className="px-[15px] relative pt-[10px]">
                            <div className={`relative ${isLike ? '' : ""}`} >
                                
                                {
                                    e?.profileMainPhoto && e?.profileMainPhoto?.length ? 
                                    <img className={`${imgClass ? imgClass : 'h-[250px] sm:h-[225px] sm:w-[225px]'} rounded-[12px] w-[260px] mx-auto object-cover object-position-top-center`} src={getImageUrl(e?.profileMainPhoto?.[0], "250x260")} alt={'profile'} />
                                    : e?.gender === 1 ? <img className={`${imgClass ? imgClass : 'h-[250px] sm:h-[225px] sm:w-[225px]'} rounded-[12px] w-[260px] mx-auto object-cover object-position-top-center`} src={male} alt={'male'} /> : <img className={`${imgClass ? imgClass : 'h-[250px] sm:h-[225px] sm:w-[225px]'} rounded-[12px] w-[260px] mx-auto object-cover object-position-top-center`} src={female} alt={'female'} />
                                }
                            </div>
                            <div className="lg:block flex items-center ms:justify-between justify-center lg:mt-0 mt-[15px]">
                                <span className="lg:absolute ms:mr-0 mr-[20px] text-white bg-[#b7892d] xl:left-[40px] left-[25px] top-[20px] z-10 text-[14px] font-family-Quicksand-Bold rounded-[7px] pt-[2px] pb-[4px] px-[7px]">{e.profileId}</span>
                                {
                                    isLike 
                                    ? null : 
                                    (
                                        e?.isProfileLike === true ?
                                        <img onClick={(event) => handleLike('disLike',e.userId,event,ind)} className="w-[30px] h-[30px] cursor-pointer lg:absolute bg-no-repeat xl:right-[40px] right-[25px] top-[18px] z-10" src={like} alt="like" /> 
                                        : <img onClick={(event) => handleLike('like',e.userId,event,ind)} className="w-[30px] h-[30px] cursor-pointer lg:absolute bg-no-repeat xl:right-[40px] right-[25px] top-[18px] z-10" src={disLike} alt="dislike" />
                                    )
                                }
                            </div>
                            <div className="py-[15px] mb-[10px] text-center sm:text-left">
                                <div className="sm:flex w-full sm:items-center sm:justify-between">
                                    <div className="truncate pr-[5px]">
                                        <h4 className="leading-[normal] text-[#931217] text-[20px] font-family-Quicksand-Bold lg:truncate sm:max-w-[195px]">{e?.firstName}</h4>
                                        <p className="mt-[4px] leading-[normal] font-family-Quicksand-Medium text-stroke-2 text-[14px] truncate sm:max-w-[190px]">Profile Created by {e?.basicProfileCreatedFor ? dropDown?.['profileCreatorFor']?.option.find(ele => ele.key === Number(e.basicProfileCreatedFor))?.name : '-'}</p >
                                    </div> 
                                    <div className="flex items-center justify-center sm:block sm:mt-0 mt-[12px]">
                                        <span className="sm:text-right sm:mr-0 sm:block mr-[8px] leading-[normal] font-family-Quicksand-Medium text-stroke text-[#931217] text-[18px]">{name === "mutualMatches" ? 6 : e.matchScore}/6</span>
                                        <p className="leading-[normal] font-family-Quicksand-Medium text-[14px] text-stroke-2 text-[#931217]">Matched</p>
                                    </div>
                                </div>
                                <div>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px] truncate">{e?.horoscopeDateOfBirth ? `${calculateAge(e?.horoscopeDateOfBirth)} yrs` : '-'} | {e?.basicHeight ? dropDown?.['height']?.option.find(ele => ele.key === Number(e?.basicHeight))?.name : '-'} | {e?.basicMotherTongue ? dropDown?.['motherTongue']?.option.find(ele => ele.key === Number(e?.basicMotherTongue))?.name : '-'}</p>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 truncate">{e?.basicReligion ? dropDown?.['religion']?.option?.find(ele => ele.key === Number(e?.basicReligion))?.name : '-'}, {e?.basicCaste ? dropDown?.['caste']?.option?.find(ele => ele.key === Number(e?.basicCaste))?.name : '-'}</p>
                                    <p className="font-family-Quicksand-Medium text-[16px] leading-[normal] text-stroke-2 mt-[15px] truncate">{e.locationCity}, {e.locationState}, {e.locationCountry}</p>
                                    <div className="flex items-center justify-center sm:justify-start mt-[16px] mb-[10px]">
                                        <img className="pr-[10px]" src={e?.isProfileUnlock || isRecentlyViewed ? unlock : locked} alt="unlock" />
                                        <Link to={`/author/${userId}/${e?.userId}`} className="relative after:bg-[#ed1c24] after:h-[2px] after:w-[22px] after:absolute after:bottom-[4px] after:ml-[10px] block font-family-Quicksand-SemiBold text-[18px] leading-[normal] text-[#ed1c24]">View Profile</Link >
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {isLike ? '' : totalPages > 1 ? <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} /> : ''}
        </>
    )
}

export default MyLikes;