import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import PublicRoute from '../src/route/publicRoute.jsx';
import PrivateRoute from '../src/route/privateRoute.jsx';
import Plans from './components/templates/plan/plan.jsx';
import Spinner from './components/atom/spinner/spinner.jsx';
import { AuthProvider,useAuth } from '../src/route/auth.jsx';
import Footer from "./components/templates/footer/footer.jsx";
import Likes from "../src/components/templates/likes/likes.jsx";
import {getUserAction} from '../src/stores/action/userAction.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "../src/components/templates/header/header.jsx";
import Search from "../src/components/templates/search/search.jsx";
import Settings from "./components/templates/settings/settings.jsx";
import { SearchProvider } from './components/atom/SearchContext.jsx';
import AtomComponent from "../src/components/templates/atomComponent.jsx";
import DashBoard from "../src/components/templates/dashBoard/dashBoard.jsx";
import {getDropDownAction} from '../src/stores/action/getDropDownAction.js';
import MyMatches from "../src/components/templates/myMatches/myMatches.jsx";
import { ClientWidthProvider } from '../src/components/atom/clientWidth.jsx';
import ViewProfile from "./components/templates/viewProfile/viewProfile.jsx";
import PageNotFound from './components/templates/pageNotFound/pageNotFound.jsx';
import FormStep from './components/templates/authentication/signUp/formstep.jsx';
import SignUp from "../src/components/templates/authentication/signUp/signUp.jsx";
import SignIn from "../src/components/templates/authentication/signIn/signIn.jsx";
import TransactionHistory from "./components/templates/settings/transactionHistory.jsx";
import MembershipAddOn from './components/templates/membershipAddOn/membershipAddOn.jsx';
import ProfileReview from './components/templates/profileUnderReview/profileUnderReview.jsx';
import ResetPassword from './components/templates/authentication/resetPassword/resetPassword.jsx';
import ForgotPassword from "../src/components/templates/authentication/forgotPassword/forgotPassword.jsx";
import CheckOut from './components/templates/checkOut/checkOut.jsx';
import { getDashboardDetail } from './stores/action/geDashboard.js';


function App() {
    // dispatch
    const dispatch = useDispatch();

    //useState
    const [loading, setLoading] = useState(false);
    //auth
    const { isAuthenticated } = useAuth();

    //useEffect
    useEffect(() => {
        const fetchData = async () => {
            if (isAuthenticated) {
                const userId = localStorage.getItem('userId');
                setLoading(true);
    
                const actions = [];
                if (userId) {
                    actions.push(dispatch(getUserAction(userId)));
                    actions.push(dispatch(getDashboardDetail(userId)));
                }
                actions.push(dispatch(getDropDownAction()));
    
                await Promise.allSettled(actions);
                setLoading(false);
            }else{
                setLoading(true);
                await dispatch(getDropDownAction());
                setLoading(false);
            }
        };
        fetchData();
    }, [isAuthenticated, dispatch]);
    
    return (
        <>
            {loading ? <Spinner /> : null}
            {
                !loading ?
                <div className="App">
                    <ClientWidthProvider>
                        <SearchProvider>
                            <BrowserRouter>
                                <Header 
                                    isAuthenticated={isAuthenticated}
                                />
                                <Routes>
                                    <Route
                                        path="/"
                                        element={<PrivateRoute state={'DashBoard'} element={<DashBoard />} />}
                                        state={'DashBoard'}
                                    />
                                    <Route
                                        path="/membership"
                                        element={<PrivateRoute state={'Membership'} element={<Plans />} />}
                                        state={'membership'}
                                    />
                                    <Route
                                        path="/membership-add-on"
                                        element={<PrivateRoute state={'Membership Add On'} element={<MembershipAddOn />} />}
                                        state={'membershipAddOn'}
                                    />
                                    <Route
                                        path="/checkout"
                                        element={<PrivateRoute state={'Checkout'} element={<CheckOut />} />}
                                        state={'checkout'}
                                    />
                                    <Route
                                        path="/atom"
                                        element={<PrivateRoute element={<AtomComponent />} />}
                                    />
                                    <Route
                                        path="/matches"
                                        element={<PrivateRoute state={'Matches'} element={<MyMatches />} />}
                                        state={'Matches'}
                                    />
                                    <Route
                                        path="/likes"
                                        element={<PrivateRoute state={'Likes'} element={<Likes />} />}
                                        state={'Likes'}
                                    />
                                    <Route
                                        path="/search"
                                        element={<PrivateRoute state={'Search'} element={<Search />} />}
                                        state={'Search'}
                                    />
                                    <Route
                                        path="/author/:currentUserId/:id"
                                        element={<PrivateRoute state={'Author'} element={<ViewProfile />} />}
                                        state={'Author'}
                                    />
                                    <Route
                                        path="/settings"
                                        element={<PrivateRoute state={'Settings'} element={<Settings />} />}
                                        state={'Settings'}
                                    />
                                    <Route
                                        path="/transaction-history"
                                        element={<PrivateRoute state={'Transaction'} element={<TransactionHistory check={true} />} />}
                                        state={'Transaction'}
                                    />
                                    <Route
                                        path="/step-form"
                                        element={<PrivateRoute state={'Form'} element={<FormStep />} />}
                                        state={'Form'}
                                    />
                                    <Route
                                        path="/profile-under-review"
                                        element={<PrivateRoute state={'Profile Under Review'} element={<ProfileReview />} />}
                                        state={'Profile Under Review'}
                                    />
                                    <Route path="*" state={'404'} element={<PrivateRoute state={'404'} element={<PageNotFound />} />} />
                                    <Route
                                        path="/register"
                                        element={<PublicRoute state={'Register'} element={<SignUp />} />}
                                        state={'Register'}
                                    />
                                    <Route
                                        path="/login"
                                        element={<PublicRoute state={'Login'} element={<SignIn />} />}
                                        state={'Login'}
                                    />
                                    <Route
                                        path="/forgot-password"
                                        element={<PublicRoute state={'Forgot Password'} element={<ForgotPassword />} />}
                                        state={'Forgot Password'}
                                    />
                                    <Route
                                        path="/reset-password/:id/:token"
                                        element={<PublicRoute state={'Reset Password'} element={<ResetPassword />} />}
                                        state={'Reset Password'}
                                    />
                                </Routes>
                                <Footer />
                            </BrowserRouter>
                        </SearchProvider>
                    </ClientWidthProvider>
                </div>
                : null
            }
        </>
    );
}

const AppWrapper = () => (
    <AuthProvider>
        <App />
    </AuthProvider>
);

export default AppWrapper;