import {en} from "./composables/en.js";

function useValidation() {
    function checkErrors({field = {}, name = "", validations = '', type = "string", value = null}) {
        return new Promise((resolve, reject) => {
            try {
                let rules = validations !== '' ? validations.split("|").map((x) => x.trim()) : [];
                let valid = true;
                let typeMsg = type === "number" ? "digits" : "characters";

                if (rules.length) {
                    // REQUIRED
                    if (rules.includes("required")) {
                        if (!value || (typeof value === "string" && value.trim() === "")) {
                            valid = false;
                            field.error = `The ${name.toLowerCase()} ${en.generalErrorMessage.fieldIsRequired}`;
                        }
                    }

                    // REGEX
                    const regexRule = rules.find((x) => x.includes("regex"));
                    if (regexRule && valid) {
                        const regex = new RegExp(regexRule.split(":").pop().trim() || "");
                        if (!regex.test(value)) {
                            valid = false;                            
                            field.error = (name?.toLocaleLowerCase() === en.password?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.newPassword?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.confirmPassword?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.currentPassword?.toLocaleLowerCase()) && (value.toString().length < 6)
                                ? `The ${name?.toLocaleLowerCase()} field must be at least 6 characters`
                                : (name?.toLocaleLowerCase() === en.password?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.newPassword?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.confirmPassword?.toLocaleLowerCase() || name?.toLocaleLowerCase() === en.currentPassword?.toLocaleLowerCase()) && (value.toString().length >= 6)
                                ? `The ${name?.toLocaleLowerCase()} must be at least 6 characters long, and contain at least 1 alphabet,1 uppercase and 1 numeric`
                                : name === en.email
                                ? en.authErrorMessage.emailError
                                : name === en.firstName || name === en.lastName
                                ? en.authErrorMessage.validCharacters
                                : `The ${name.toLowerCase()} field must be a valid ${name.toLowerCase()}`;
                        }
                    }

                    // MIN
                    const minRule = rules.find((x) => x.includes("min"));
                    if (minRule && valid) {
                        const min = Number(minRule.split(":").pop());
                        if ((typeof value === "string" && value.length < min) || (typeof value === "number" && value < min)) {
                            valid = false;
                            field.error = name === en.phoneNumber
                                ? en.companyErrorMessage.phoneNumberValid
                                : `The ${name.toLowerCase()} field must be at least ${min} ${typeMsg}`;
                        }
                    }

                    // MAX
                    const maxRule = rules.find((x) => x.includes("max"));
                    if (maxRule && valid) {
                        const max = Number(maxRule.split(":").pop());
                        if ((typeof value === "string" && value.length > max) || (typeof value === "number" && value > max)) {
                            valid = false;
                            field.error = `The ${name.toLowerCase()} must be less than ${max} ${typeMsg}`;
                        }
                    }

                    // CONFIRMATION
                    const confirmationRule = rules.find((x) => x.includes("confirmation"));
                    if (confirmationRule && valid) {
                        const confirm = confirmationRule.split(":").pop().trim() || "";
                        if (value !== confirm) {
                            valid = false;
                            field.error = `${en.authErrorMessage.confirmPasswordValid}`;
                        }
                    }

                    // NO ERRORS
                    if (valid) {
                        field.error = "";
                    }
                }
                resolve(valid);
            } catch (error) {
                reject(error);
            }
        });
    }

    function checkAllFields(formData) {
        return new Promise((resolve, reject) => {
            try {
                let valid = true;
                Promise.all(Object.keys(formData).map(async (key) => {
                    const res = await checkErrors({
                        field: formData[key],
                        name: formData[key].name,
                        validations: formData[key].rules,
                        type: formData[key].type,
                        value: formData[key].value // assuming value is directly available in formData
                    });
                    if (!res) {
                        valid = false;
                    }
                })).then(() => {
                    resolve(valid);
                }).catch(error => {
                    console.error("ERROR in check validation: ", error);
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    return {
        checkAllFields,
        checkErrors
    };
}

export default useValidation;
