const Footer = () => {
    return(
        <>
            <footer className={`bg-[#8b4c1d] after:rotate-180 after:z-0 relative pt-[135px] pb-[65px] bg-no-repeat after:bg-no-repeat after:bg-bottom after:bg-[url('../../assets/images/png/header_bottom_bg.png')] after:w-[100%] after:h-[100%] after:absolute after:bottom-[1px]`}>
                <div className='md:max-w-[1140px] mx-auto relative z-10 px-[15px]'>
                    <div className='grid md:grid-cols-3 grid-cols-1 pt-[48px] border-t border-t-[#ae8261]'>
                        <div className='md:col-span-2'>
                            <p className='md:text-left text-center font-family-Quicksand-Regular text-stroke-2 text-[18px] text-white leading-[normal]'> © {new Date().getFullYear()} Rishtamatrimony.com | All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;