import { useState } from "react";
import Button from "../../atom/button";
import { useSelector } from "react-redux";
import DropDown from "../../atom/dropDown.jsx";
import * as env from '../../../config/env.js';
import Autocomplete from "react-google-autocomplete";
import useValidation from "../../../utils/validation";
import CustomInput from "../../molecules/customInput.jsx";
import CheckBox from "../../molecules/checkBox/checkBox.jsx";
import Spinner from "../../atom/spinner/spinner.jsx";
import useCustomComposable from "../../../utils/composables";

const AdvanceSearch = ({handleApply,handleReset}) => {
    const { focusOnDropdown,focusNextInput } = useCustomComposable();
    const  { checkErrors,checkAllFields } = useValidation();
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [isSpinner,setIsSpinner] = useState(false);
    const [filter,setFilter] = useState({
        basicReligion: {value: '',rules:"required",name: "religion",error: ""},
        basicCaste: {value: '',rules:"required",name: "caste",error: ""},
        basicMotherTongue: {value: '' ,rules:"required",name: "mother tongue",error: ""},
        basicAgeMax: {value: '' ,rules:"",name: "max age",error: ""},
        basicAgeMin: {value: '' ,rules:"",name: "min age",error: ""},
        basicHeightMax:{value: '',rules:"",name: "max height",error: ""},
        basicHeightMin:{value: '',rules:"",name: "min height",error: ""},
        basicMaritalStatus:{value: '',rules:"required",name: "marital status",error: ""},
        educationHighestQualification:{value: '',rules:"required",name: "education",error: ""},
        locationCountry:{value: '',rules:"required",name: "country",error: ""},
        locationState:{value: '',rules:"required",name: "state",error: ""},
        locationCity:{value: '',rules:"required",name: "city",error: ""},
        saveSearch:{value: '',rules:"",name: "save search as",error: ""}
    });
    const handleChange = (value) => {
        if(!(value.target.value)){
            checkErrors({
                'field': filter.locationState,
                'name': 'state',
                'validations': filter.locationState.rules,                
                'value': ''
            });
            checkErrors({
                'field': filter.locationCity,
                'name': 'city',
                'validations': filter.locationCity.rules,                
                'value': ''
            });
            checkErrors({
                'field': filter.locationCountry,
                'name': 'country',
                'validations': filter.locationCountry.rules,                
                'value': ''
            });
            setFilter(prevFormData => ({
                ...prevFormData,
                locationState: { ...filter.locationState, value: '' },
                locationCity: { ...filter.locationCity, value: '' },
                locationCountry: { ...filter.locationCountry, value: '' }
            }));
        }
    };
    const handleAgeValidation = (e,id) => {
        const updatedFormData = { ...filter };
        const value = e.key;

        if (id === 'basicAgeMax') {
            updatedFormData.basicAgeMax.value = value;
            updatedFormData.basicAgeMax.error = '';
        } else if (id === 'basicAgeMin') {
            updatedFormData.basicAgeMin.value = value;
            updatedFormData.basicAgeMin.error = '';
        }
        const minAge = updatedFormData.basicAgeMin.value;
        const maxAge = updatedFormData.basicAgeMax.value;

        if (minAge && maxAge) {
            if (parseInt(minAge) > parseInt(maxAge)) {
                updatedFormData.basicAgeMin.error = 'The min age should be smaller than max age';
                updatedFormData.basicAgeMax.error = 'The max age should be greater than min age';
            }else{
                updatedFormData.basicAgeMin.error = '';
                updatedFormData.basicAgeMax.error = '';
            }
        }

        // Run validation for the changed field
        if (id === 'basicAgeMax') {
            checkErrors({
                'field': updatedFormData.basicAgeMax,
                'name': 'max age',
                'validations': updatedFormData.basicAgeMax.rules,
                'value': value
            });
            handleDropDown('minheight');
        } else if (id === 'basicAgeMin') {
            checkErrors({
                'field': updatedFormData.basicAgeMin,
                'name': 'min age',
                'validations': updatedFormData.basicAgeMin.rules,
                'value': value
            });
            handleDropDown('minage');
        }
        // Update the state
        setFilter({
            ...updatedFormData
        });
    };
    const handleHeightValidation = (e,id) => {
        const updatedFormData = { ...filter };
        const value = e.key;

        if (id === 'basicHeightMax') {
            updatedFormData.basicHeightMax.value = value;
            updatedFormData.basicHeightMax.error = '';
        } else if (id === 'basicHeightMin') {
            updatedFormData.basicHeightMin.value = value;
            updatedFormData.basicHeightMin.error = '';
        }
        const minAge = updatedFormData.basicHeightMin.value;
        const maxAge = updatedFormData.basicHeightMax.value;

        if (minAge && maxAge) {
            if (parseInt(minAge) > parseInt(maxAge)) {
                updatedFormData.basicHeightMin.error = 'The min height should be smaller than max height';
                updatedFormData.basicHeightMax.error = 'The max height should be greater than min height';
            }else{
                updatedFormData.basicHeightMin.error = '';
                updatedFormData.basicHeightMax.error = '';
            }
        }

        // Run validation for the changed field
        if (id === 'basicHeightMax') {
            checkErrors({
                'field': updatedFormData.basicHeightMax,
                'name': 'max height',
                'validations': updatedFormData.basicHeightMax.rules,
                'value': value
            });
            handleDropDown('religion');
        } else if (id === 'basicHeightMin') {
            checkErrors({
                'field': updatedFormData.basicHeightMin,
                'name': 'min height',
                'validations': updatedFormData.basicHeightMin.rules,
                'value': value
            });
            handleDropDown('maxheight');
        }
        // Update the state
        setFilter({
            ...updatedFormData
        });
    };
    const handleSelect = (e,id) => {
        if(id === 'basicReligion'){
            checkErrors({
                'field': filter.basicReligion,
                'name': 'religion',
                'validations': filter.basicReligion.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicReligion: {...filter.basicReligion ,value:e.key}});
            handleDropDown('caste');
        }else if(id === 'basicCaste'){
            checkErrors({
                'field': filter.basicCaste,
                'name': 'caste',
                'validations': filter.basicCaste.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicCaste: {...filter.basicCaste ,value:e.key}});
            handleDropDown('mothertongue');
        }else if(id === 'basicMotherTongue'){
            checkErrors({
                'field': filter.basicMotherTongue,
                'name': 'mother tongue',
                'validations': filter.basicMotherTongue.rules,
                'value': e.key
            });
            setFilter({ ...filter, basicMotherTongue: {...filter.basicMotherTongue ,value:e.key}});
            handleDropDown('location');
        }else if (id === 'martialStatus') {
            const value = Number(e.event.target.value);
            let newMartialStatusCheckedValues = [...filter.basicMaritalStatus?.value];
            const isIncludes = newMartialStatusCheckedValues.includes(value);
            if (isIncludes) {
                newMartialStatusCheckedValues = newMartialStatusCheckedValues.filter((item) => item !== value);
            } else {
                newMartialStatusCheckedValues.push(value);
            }
            checkErrors({
                'field': filter.basicMaritalStatus,
                'name': 'marital status',
                'validations': filter.basicMaritalStatus.rules,
                'value': newMartialStatusCheckedValues.length ? newMartialStatusCheckedValues : ''
            });
            setFilter({ ...filter, basicMaritalStatus: {...filter.basicMaritalStatus ,value:newMartialStatusCheckedValues.length ? newMartialStatusCheckedValues : ''}});
        }else if(id === 'educationHighestQualification'){
            checkErrors({
                'field': filter.educationHighestQualification,
                'name': 'education',
                'validations': filter.educationHighestQualification.rules,
                'value': e.key
            });
            setFilter({ ...filter, educationHighestQualification: {...filter.educationHighestQualification ,value:e.key}});
            handleDropDown('inputId');
        }else if(id === 'location'){
            const addressComponents = e?.address_components;
            let state = '';
            let country = '';
            let city = '';
    
            // Loop through address components and extract the relevant information
            addressComponents?.forEach(component => {
                const types = component.types;
                if (types.includes('administrative_area_level_1')) {
                    state = component.long_name; // or component.short_name
                }
                if (types.includes('country')) {
                    country = component.long_name; // or component.short_name
                }
                if (types.includes('locality') || types.includes('postal_town')) {
                    city = component.long_name; // or component.short_name
                }
            });
            checkErrors({
                'field': filter.locationState,
                'name': 'state',
                'validations': filter.locationState.rules,                
                'value': state
            });
            checkErrors({
                'field': filter.locationCity,
                'name': 'city',
                'validations': filter.locationCity.rules,                
                'value': city
            });
            checkErrors({
                'field': filter.locationCountry,
                'name': 'country',
                'validations': filter.locationCountry.rules,                
                'value': country
            });
            setFilter(prevFormData => ({
                ...prevFormData,
                locationState: { ...filter.locationState, value: state },
                locationCity: { ...filter.locationCity, value: city },
                locationCountry: { ...filter.locationCountry, value: country }
            }));
        }else if(id === 'saveSearch'){
            checkErrors({
                'field': filter.saveSearch,
                'name': 'save search as',
                'validations': filter.saveSearch.rules,
                'value': e
            });
            setFilter({ ...filter, saveSearch: {...filter.saveSearch ,value:e}});
        }
    };

    const handleResetFilter = () => {
        setIsSpinner(true);
        setFilter({
            basicReligion: {value: '',rules:"required",name: "religion",error: ""},
            basicCaste: {value: '',rules:"required",name: "caste",error: ""},
            basicMotherTongue: {value: '' ,rules:"required",name: "mother tongue",error: ""},
            basicAgeMax: {value: '' ,rules:"",name: "max age",error: ""},
            basicAgeMin: {value: '' ,rules:"",name: "min age",error: ""},
            basicHeightMax:{value: '',rules:"",name: "max height",error: ""},
            basicHeightMin:{value: '',rules:"",name: "min height",error: ""},
            basicMaritalStatus:{value: '',rules:"required",name: "marital status",error: ""},
            educationHighestQualification:{value: '',rules:"required",name: "education",error: ""},
            locationCountry:{value: '',rules:"required",name: "country",error: ""},
            locationState:{value: '',rules:"required",name: "state",error: ""},
            locationCity:{value: '',rules:"required",name: "city",error: ""}
        });
        setTimeout(() => {
            setIsSpinner(false);
        });
        handleReset({});
    };
    const handleApplyFilter = (obj) => {
        const updatedFormData = { ...filter };
        if (!filter.basicAgeMin.value) {
            updatedFormData.basicAgeMin.error = 'The min age field is required';
        }
        if (!filter.basicAgeMax.value) {
            updatedFormData.basicAgeMax.error = 'The max age field is required';
        }
        if (!filter.basicHeightMin.value) {
            updatedFormData.basicHeightMin.error = 'The min height field is required';
        }
        if (!filter.basicHeightMax.value) {
            updatedFormData.basicHeightMax.error = 'The max height field is required';
        }
        setFilter(updatedFormData);
        checkAllFields(filter).then((valid)=>{
            if(valid && filter.basicHeightMax.error === '' && filter.basicHeightMin.error === '' && filter.basicAgeMax.error === '' && filter.basicAgeMin.error === ''){
                handleApply(filter,'advance');
            }
        });
    };
    const handleDropDown = (nextInputId) => {
        focusOnDropdown(nextInputId);
    };
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };
    return(
        <>
            {   isSpinner ? 
                    <Spinner /> : 
                    <div className="bg-[#F0E1BA] sm:p-[30px] p-[20px] rounded-[10px] grid grid lg:grid-cols-2 grid-cols-1">
                    <div>
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['age']?.option}
                            onUpdate={(e) => handleAgeValidation(e,'basicAgeMin')}
                            multiSelect={false}
                            selectedValue={filter?.basicAgeMin?.value ? [filter?.basicAgeMin?.value] : []}
                            labelName={'Age*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative mt-[11px] lg:pr-[15px]'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="minage"
                        />
                        {filter?.basicAgeMin?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicAgeMin.error}</small>
                        }
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['age']?.option}
                            onUpdate={(e) => handleAgeValidation(e,'basicAgeMax')}
                            multiSelect={false}
                            selectedValue={filter?.basicAgeMax?.value ? [filter?.basicAgeMax?.value] : []}
                            labelName={'to'}
                            labelClass={'text-[#931217] text-[16px] px-[5px] py-[8px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative lg:pr-[15px]'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="maxage"
                        />
                        {filter?.basicAgeMax?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicAgeMax.error}</small>
                        }
                    </div>
                    <div className="lg:pl-[15px]">
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['height']?.option}
                            onUpdate={(e) => handleHeightValidation(e,'basicHeightMin')}
                            multiSelect={false}
                            selectedValue={filter?.basicHeightMin?.value ? [filter?.basicHeightMin?.value] : []}
                            labelName={'Height*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative mt-[11px]'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="minheight"
                        />
                        {filter?.basicHeightMin?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicHeightMin.error}</small>
                        }
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['height']?.option}
                            onUpdate={(e) => handleHeightValidation(e,'basicHeightMax')}
                            multiSelect={false}
                            selectedValue={filter?.basicHeightMax?.value ? [filter?.basicHeightMax?.value] : []}
                            labelName={'to'}
                            labelClass={'text-[#931217] text-[16px] px-[5px] py-[8px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="maxheight"
                        />
                        {filter?.basicHeightMax?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicHeightMax.error}</small>
                        }
                    </div>
                    <div className="lg:col-span-2 mt-[11px]">
                        <h4 className="text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]">Marital Status*</h4>
                        <div className="grid lg:grid-cols-3 grid-cols-1">
                            {dropDown?.['maritalStatus']?.option.map((option, index) => (
                                <div key={index}>
                                    <CheckBox
                                        modelValue={option.key || false}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onClick={(value) => handleSelect(value,'martialStatus')}
                                        label={option.name || ''}
                                        inputClass={'styled-check opacity-0 absolute'}
                                        labelClass={'leading-[1.2] text-[16px] text-stroke-2 text-[#262626] font-family-Quicksand-Medium cursor-pointer relative'}
                                        checkBoxClassWrapper={'my-[8px]'}
                                        checked={filter.basicMaritalStatus?.value?.includes(option?.key) || false}
                                    />
                                </div>
                            ))}
                        </div>
                        {filter?.basicMaritalStatus?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicMaritalStatus.error}</small>
                        }
                    </div>
                    <div className="mt-[11px] lg:pr-[15px]">
                        <DropDown
                            options={dropDown?.['religion']?.option}
                            onUpdate={(e) => handleSelect(e,'basicReligion')}
                            multiSelect={false}
                            selectedValue={filter?.basicReligion?.value ? [filter?.basicReligion?.value] : []}
                            labelName={'Religion*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="religion"
                        />
                        {filter?.basicReligion?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicReligion.error}</small>
                        }
                    </div>
                    <div className="mt-[11px] lg:pl-[15px]">
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['caste']?.option}
                            onUpdate={(e) => handleSelect(e,'basicCaste')}
                            multiSelect={false}
                            selectedValue={filter?.basicCaste?.value ? [filter?.basicCaste?.value] : []}
                            labelName={'Caste*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="caste"
                        />
                        {filter?.basicCaste?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicCaste.error}</small>
                        }
                    </div>
                    <div className="mt-[11px] lg:pr-[15px]">
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['motherTongue']?.option}
                            multiSelect={false}
                            onUpdate={(e) => handleSelect(e,'basicMotherTongue')}
                            selectedValue={filter?.basicMotherTongue?.value ? [filter?.basicMotherTongue?.value] : []}
                            labelName={'Mother Tongue*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] lg:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="mothertongue"
                        />
                        {filter?.basicMotherTongue?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.basicMotherTongue.error}</small>
                        }
                    </div>
                    <div className='mt-[11px] lg:pl-[15px]'>
                        <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'>City, State, Country *</label>
                        <Autocomplete
                            apiKey={env.GOOGLEAPIKEY}
                            onChange={(e) => handleChange(e)}
                            onPlaceSelected={(place) => {handleSelect(place,'location')}}
                            types={['(regions)']}
                            fields={['address_components']}
                            className='border w-full border-[#ced4da] bg-white text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'
                            placeholder={'Enter city country state'}
                            defaultValue={filter?.locationCity?.value && filter?.locationState?.value && filter?.locationCountry?.value ? `${filter?.locationCity?.value}, ${filter?.locationState?.value}, ${filter?.locationCountry?.value}` : ''}
                            id="location"
                            onKeyPress={(e) => {
                                let event = {
                                    event:e
                                }
                                handleKeyPress(event, 'education')
                            }}
                        />
                        { filter?.locationCountry?.error && filter?.locationCity?.error && filter?.locationState?.error ?
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">The city state country field is required</small>
                            :filter?.locationCountry?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small> 
                            :filter?.locationCity?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                            :filter?.locationState?.error ? <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">please select the valid address</small>
                            :null
                        }
                    </div>
                    <div className="mt-[11px] lg:col-span-2">
                        <DropDown
                            isInputSearch={true}
                            options={dropDown?.['degree']?.option}
                            onUpdate={(e) => handleSelect(e,'educationHighestQualification')}
                            multiSelect={false}
                            selectedValue={filter?.educationHighestQualification?.value ? [filter?.educationHighestQualification?.value] : []}
                            labelName={'Education*'}
                            labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}                
                            selectClass={'after:w-0 border border-[#ced4da] after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            wrapperClass={'relative'}
                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                            optionsWrapper={'bg-white'}
                            focusInd="education"
                        />
                        {filter?.educationHighestQualification?.error &&
                            <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{filter.educationHighestQualification.error}</small>
                        }
                    </div>
                    <div className="lg:col-span-2 grid mt-[11px]">
                        <CustomInput
                            class={'lg:col-span-2 text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}
                            inputClass={'lg:col-span-2 border border-[#ced4da] bg-white text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                            label="Save Search as"
                            inputId="inputId"
                            type="text"
                            modelValue={filter?.saveSearch?.value ? filter.saveSearch.value : ''}
                            placeHolder="Enter Name Here"
                            isDisabled={false}
                            isReadonly={false}
                            isDirectFocus={false}
                            isRequired={false}
                            height="50px"
                            width="200px"
                            isOutline={true}
                            onChange={(value) => handleSelect(value,'saveSearch')}
                        />
                    </div>
                    <div className="lg:col-span-2 ml-auto mt-[37px] mb-[10px]">
                        <Button
                            class={'px-[29px] bg-[#ed1c24] leading-[20.7px] py-[12px] text-white font-family-Quicksand-SemiBold text-[18px] rounded-[35px] inline-block'}
                            buttonName={'Reset'}
                            onClick={() => handleResetFilter()}
                        />
                        <Button 
                            class={'px-[29px] bg-[#ed1c24] leading-[20.7px] ml-[20px] py-[12px] text-white font-family-Quicksand-SemiBold text-[18px] rounded-[35px] inline-block'}
                            buttonName={'Submit'}
                            onClick={() => handleApplyFilter(filter)}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default AdvanceSearch;