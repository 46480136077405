import { useEffect, useRef, useState } from "react";
import Button from "../../atom/button.jsx";
import * as env from '../../../config/env.js';
import { useAuth } from '../../../route/auth.jsx';
import Spinner from "../../atom/spinner/spinner.jsx";
import { ToastContainer, toast } from "react-toastify";
import { apiRequest } from "../../../services/index.js";
import useValidation from "../../../utils/validation.js";
import CustomInput from "../../molecules/customInput.jsx";

const EditEmail = ({form,submitForm,userDetails}) => {
    //Auth
    const { logout } = useAuth();
    //validation
    const  { checkErrors,checkAllFields } = useValidation();
    //useRef
    const inputs = useRef([]);
    const isSubmitting = useRef(false);
    const debounceTimeout = useRef(null);
    //useState
    const [formData,setFormData] = useState({
        email:form.email
    });
    const [otpError, setOtpError] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [isOtpScreen,setIsOtpScreen] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '', '']);

    const [seconds, setSeconds] = useState(60); // Initial timer value
    const [isDisabled, setIsDisabled] = useState(true); // Button disabled state

    useEffect(() => {
        let timer;
        if (isDisabled) {
            // Start the timer if the button is disabled
            timer = setInterval(() => {
                setSeconds(prevSeconds => {
                    if (prevSeconds <= 1) {
                        clearInterval(timer);
                        setIsDisabled(false); // Enable the button when the timer ends
                        return 0;
                    }
                    return prevSeconds - 1;
                });
            }, 1000);
        }

        return () => clearInterval(timer); // Clear timer on component unmount
    }, [isDisabled]);
    //function
    const handleSelect = (e) => {
        checkErrors({
            'field': formData.email,
            'name': 'email',
            'validations': formData.email.rules,                
            'value': e.target.value
        });
        setFormData({ ...formData, email: {...formData.email,value:e.target.value} });
    };
    const handleSubmit = () => {
        setFormData({...formData});
        checkAllFields(formData).then((valid)=>{
            if(valid){
                try {
                    setIsLoading(true);
                    let checkEmail = {
                        isCheck:true,
                        newEmail:formData?.email?.value,
                        userId:userDetails?.userId
                    }
                    apiRequest("post", env.EDITEMAIL, checkEmail).then(res => {
                        if(res.status === 200){
                            let sendOtp = {
                                method: "email",
                                email: formData?.email?.value,
                                isEditEmail:true,
                                userId:userDetails?.userId
                            };
                            apiRequest("post", env.SENDOTP, sendOtp).then(res => {
                                if (res.status === 200) {
                                    toast.success('Otp send successfully', { position: 'top-right' });
                                    setIsOtpScreen(true);
                                } else {
                                    toast.error('Please try it again', { position: 'top-right' });
                                }
                                setIsLoading(false);
                            })
                            .catch(error => {
                                toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
                                setIsLoading(false);
                            });
                        }else{
                            setIsLoading(false);
                            toast.error('Please try it again', { position: 'top-right' });
                        }
                    }).catch(error => {
                        toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
                        setIsLoading(false);
                    });
                } catch (error) {
                    console.error('Error for sending the otp',error);
                    setIsLoading(false);
                };
            }
        });
    };
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^\d?$/.test(value)) { // Allows only single digit or empty
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (index < otp.length - 1 && value) {
                inputs.current[index + 1].focus();
            }

            setOtp(prevOtp => {
                const updatedOtp = [...prevOtp];
                updatedOtp[index] = value;
                if (updatedOtp.join('').length === otp.length) {
                    setOtpError('');
                }
                return updatedOtp;
            });
        }
    };
    const handleBackspace = (e, index) => {
        if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
            return e.preventDefault();
        }
        if (e.key === 'Backspace') {
            const newOtp = [...otp];
            if (newOtp[index] === '' && index > 0) {
                inputs.current[index - 1].focus();
            }
            newOtp[index] = '';
            setOtp(newOtp);
        }
    };
    const handleSubmitOnLast = (index,event) => {
        if(index === 5 && event.key >= '0' && event.key <= '9'){
            debounceSubmit();
        }
    };
    const debounceSubmit = (value) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            handleSubmitOtp(value);
        }, 500);
    };
    const handleSubmitOtp = (value) => {
        if (isSubmitting.current) return;
        const check = value && value.length ? value.join('') : otp.join('');
        if (check.length < 6) {
            setOtpError("Please enter correct 6 digit otp");
        } else if (check.length === 6 && otpError === '' && check?.length && !isNaN(Number(check[5]))) {
            isSubmitting.current = true;
            setIsLoading(true);
            let verifyOtp = {
                method: "email",
                email: formData?.email?.value,
                otp: Number(check),
                isUserRegister:false
            };
            try {
                apiRequest("post", env.VERIFYOTP, verifyOtp).then(async(response) => {
                    if (response.status === 200) {
                        let checkEmail = {
                            isCheck:false,
                            newEmail:formData?.email?.value,
                            userId:userDetails?.userId
                        }
                        apiRequest("post", env.EDITEMAIL, checkEmail).then(res => {
                            if(res.status === 200){
                                toast.success('Updated successfully', { position: 'top-right' });
                                setIsOtpScreen(false);
                                setTimeout(() => {
                                    setIsLoading(false);
                                    isSubmitting.current = false;
                                    logout();
                                },1000);
                            }else{
                                setIsLoading(false);
                                isSubmitting.current = false;
                                toast.error('Please try it again', { position: 'top-right' });
                            }
                        }).catch(error => {
                            toast.error(error?.response?.data?.message || 'Please try it again', { position: 'top-right' });
                            setIsLoading(false);
                            isSubmitting.current = false;
                        });
                    }else{
                        toast.error('Please try it again', { position: 'top-right' });
                        setIsLoading(false);
                        isSubmitting.current = false;
                    }
                }).catch((error) => {
                    toast.error(error?.response?.data?.message || 'Verification failed', { position: 'top-right' });
                    console.error('Error in verfiying Otp',error);
                    setIsLoading(false);
                    isSubmitting.current = false;
                })
            } catch (error) {
                console.error('Error in verfiying Otp',error);
                setIsLoading(false);
                isSubmitting.current = false;
            }
        }
    }
    const handlePaste = (e) => {
        const paste = e.clipboardData.getData('text');
        if (paste.length === 6 && /^[0-9]{6}$/.test(paste)) {
            const newOtp = paste.split('');
            setOtp(newOtp);
            inputs.current[5].focus();
            if(newOtp.length === 6 && isNaN(Number(newOtp))){
                debounceSubmit(newOtp);
            }
        }
        e.preventDefault();
    };
    const handleResendOtp = () => {
        setSeconds(60);
        setIsDisabled(true); // Disable the button when OTP is resent
        setIsLoading(true);
        let sendOtp = {
            method: "email",
            email: formData?.email?.value,
            isEditEmail:true,
            userId:userDetails?.userId
        };
        apiRequest("post", env.SENDOTP, sendOtp).then(res => {
            if (res.status === 200) {
                toast.success('Otp resend successfully', { position: 'top-right' });
            } else {
                toast.error('Please try it again', { position: 'top-right' });
            }
            setIsLoading(false);
        }).catch(error => {
            toast.error(error?.response?.data?.message || 'Resend failed', { position: 'top-right' });
            setIsLoading(false);
        });
    };
    return(
        <>
            <ToastContainer />
            {isLoading ? <Spinner /> : null}
            <div className="bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px] relative"> 
                <p className="tracking-[0.5px] text-stroke-4 pb-[15px] leading-[30px] mb-[30px] border-b-[#b7892d] border-b font-family-Quicksand-Bold text-[18px] text-[#931217]">A valid email id will be used to send Your partner search mailers, member to member communication mailers and Special offers</p>
                <div className={`${!isOtpScreen ? 'flex xl:items-center items-end justify-between mb-[11px] xl:flex-row flex-col' : 'text-center'}`}>
                    {
                        !isOtpScreen ? 
                            <>
                                <div className='xl:max-w-[522px] w-full relative'>
                                    <CustomInput
                                        placeHolder={'Enter email'}
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px] rounded-[10px]'}
                                        class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                                        type="text"
                                        modelValue={formData?.email?.value ? formData?.email?.value : ''}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(_, e) => handleSelect(e)}
                                    />
                                    {formData.email.error && 
                                        <small className="text-[#ff0000] absolute left-0 bottom-[-28px] text-[16px] font-family-Quicksand-Bold">{formData.email.error}</small>
                                    }
                                </div>
                                <Button
                                    class='xl:mt-0 mt-[20px] leading-[normal] text-[#fff] cursor-pointer py-[7.5px] px-[23px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                                    buttonName={'Update'}
                                    onClick={() => handleSubmit()}
                                />
                            </>
                        : 
                        <>
                            <div className='w-full relative text-center flex justify-center'>
                                {otp.map((data, index) => (
                                    <input
                                        key={index}
                                        type="number"
                                        value={data}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleBackspace(e, index)}
                                        onKeyUp={(e) => handleSubmitOnLast(index,e)}
                                        onPaste={handlePaste}
                                        onKeyPress={(event)=>{
                                            if(event.charCode === 45 || event.charCode === 43 || event.charCode === 46 || event.key === 'e' || event.key === 'E'){
                                                event.preventDefault();
                                            }
                                        }}
                                        onInput={(e) => {
                                            const value = e.target.value;
                                            if (/^\d?$/.test(value)) { // Allows only single digit or empty
                                                handleChange(e, index);
                                            } else {
                                                e.target.value = value.slice(0, 1); // Keep only the first valid digit
                                            }
                                        }}
                                        ref={(ref) => inputs.current[index] = ref}
                                        maxLength="1"
                                        className={`${index === 5 ? '' : 'lg:mr-[10px] mr-[10px]'} py-[5px] xms:px-[16px] px-[5px] bg-white text-stroke xms:w-[55px] xms:h-[55px] w-[35px] h-[35px] font-family-Quicksand-Bold text-[18px] text-[#931217] text-center inline xms:rounded-[15px] rounded-[10px]`}
                                    />
                                ))}
                                {otpError && (
                                    <div className="text-center absolute bottom-[-25px]">
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{otpError}</small>
                                    </div>
                                )}
                            </div>
                            <div>
                                <Button
                                    class={`${isDisabled ? 'opacity-30 pointer-events-none' : 'hover:underline'} font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal] block mx-auto my-[20px]`}
                                    onClick={() => handleResendOtp()}
                                    buttonName="Resend OTP"
                                />
                                {isDisabled && <div className='mb-[15px]'>Resend available in: {seconds}s</div>}
                                <Button
                                    class='xl:mt-0 mt-[20px] leading-[normal] text-[#fff] cursor-pointer py-[7.5px] px-[23px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                                    buttonName={'Verify'}
                                    onClick={() => handleSubmitOtp()}
                                />
                            </div>
                        </>        
                    }
                </div>
            </div>
        </>
    )
};

export default EditEmail;