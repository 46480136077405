import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../route/auth';
import { getDropDownAction } from '../stores/action/getDropDownAction';
import { getUserAction } from '../stores/action/userAction';
import { useState } from 'react';
import Spinner from '../components/atom/spinner/spinner';

const PrivateRoute = ({ element,state }) => {
    const { isAuthenticated } = useAuth();
    const userProfileStatus = useSelector(state => state?.user?.user?.userProfileStatus);
    const userProfile = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchUserData = async () => {
            document.title = `${state} - Rishta Matrimony`;
            if (localStorage.getItem('userId') && userProfile?.userId) {
                if (localStorage.getItem('userId') !== userProfile?.userId) {
                    setLoading(true);
                    await dispatch(getUserAction(localStorage.getItem('userId')));
                    await dispatch(getDropDownAction());
                    setLoading(false);
                }
            }
        };

        fetchUserData();
    }, [state, dispatch, userProfile?.userId]);
    useEffect(() => {
        if (userProfileStatus) {
            switch (userProfileStatus) {
                case 1: // Pending
                    navigate("/step-form");
                    break;
                case 2: // InProgress
                    navigate("/step-form");
                    break;
                case 3: // InReview
                    navigate("/profile-under-review");
                    break;
                case 5: // Rejected
                    navigate("/step-form");
                    break;
                case 4: // Approved
                    if(location.pathname === '/profile-under-review' || location.pathname === '/deactive-profile'){
                        navigate('/');
                    }
                    break;
                case 6:
                    break;
                default:
                    // No navigation needed for approved or default status
                    break;
            }
        }
    }, [userProfileStatus, navigate,location?.pathname]);
    if (loading) {
        // Optionally, you can return a loading indicator here
        return <Spinner/>;
    }
    if (!isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return element;
};

export default PrivateRoute;