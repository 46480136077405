import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'dashboard',
    initialState: {
        dashboard: null,
    },
    reducers: {
        getDashboard: (state, action) => {
            state.dashboard = action.payload;
        },
    },
});

export const { getDashboard } = slice.actions;
export default slice.reducer;