import { Link } from "react-router-dom";

const PageNotFound = () => {
    return(
        <>
            <div className="px-[15px] lg:flex-col lg:items-center lg:flex text-center lg:w-full xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] max-w-full mx-auto lg:pt-[175px] lg:pb-[175px] md:pt-[120px] md:pb-[120px] pt-[140px] pb-[140px] lg:justify-center">
                <p className="py-[15px] xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] text-[22px] text-[#ed1c24]">The Page You Requested Could Not Found</p>
                <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[100px] lg:text-[60px] text-[50px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>404</h2>
                <Link to="/" className={'text-[#ed1c24] font-bold py-[15px] block'}>Back To Homepage</Link>
            </div>
        </>
    );
};

export default PageNotFound;