import React, { useContext, useEffect, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor'
import Popup from './popUp';
import { Slider } from '@mui/material';
import ClientWidthContext from './clientWidth';

const ImageUpload = ({ onImageUpload, buttonName, labelClass }) => {
    const [cropImage, setCropImage] = useState('');
    const [error, setError] = useState(null);
    const cropRef = useRef(null);
    const [width,setWidth] = useState(350);
    const [height,setHeight] = useState(350);
    const [slideValue, setSlideValue] = useState(10);
    const clientWidth = useContext(ClientWidthContext);

    const base64ToFile = (base64String, filename) => {
        const arr = base64String.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };
    

    const handleFileChange = (event) => {
        const files = event.target.files;
        const fileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const maxSize = 4 * 1024 * 1024; // 4 MB
        if (!fileTypes.includes(files[0].type)) {
            setError('Please select a valid image file (jpg, png).');
            return;
        }

        if (files[0].size > maxSize) {
            setError('File size should be less than 4 MB.');
            return;
        }

        setError(null); // Clear any previous errors
        setCropImage(event.target.files[0]);
        event.target.value = '';
    };
    const handleCropImage = async () => {
        if (cropRef) {
            const dataUrl = cropRef.current.getImage().toDataURL();
            const croppedImageFile = base64ToFile(dataUrl, 'cropped-image.jpg');
            const newEvent = { target: { files: [croppedImageFile] } };
            const newImagePreviews = [];
            newImagePreviews.push({
                url: newEvent,
                isDefault: false
            });
            if (onImageUpload) {
                onImageUpload([...newImagePreviews]);
            }
            setCropImage('');
        }
    };
    const handleClose = () => {
        setCropImage('');
        const file = document?.getElementById('fileInput')?.files;
        if(file && file.length){
            const emptyFileList = new DataTransfer().files
            document.getElementById('fileInput').files = emptyFileList;
        }
    };

    useEffect(() => {
        if(clientWidth < 480){
            setWidth(210);
            setHeight(210);
        }else {
            setWidth(350);
            setHeight(350);
        }
    },[clientWidth]);

    return (
        <div className='text-center'>
            <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                onChange={(event) => handleFileChange(event)}
                id="fileInput"
                className='hidden'
            />
            <label htmlFor="fileInput" className={labelClass ? labelClass : ''}>
                {buttonName ? buttonName : ''}
            </label>
            {error && <small className="w-full bottom-[6px] absolute text-[#ff0000] text-[16px] font-family-Quicksand-Bold left-[50%] translate-x-[-50%]">{error}</small>}
            {cropImage && !error ? (
                <Popup
                    onClose={() => handleClose()}
                    onConfirm={handleCropImage}
                    closeBtnName={'close'}
                    submitBtnName={'Crop & Upload Image'}
                    submitClass={'font-family-sans-serif text-[#fff] bg-[#ed1c24] border-[#ed1c24] rounded-[50px] ms:px-[20px] px-[10px] py-[8px] leading-[1.5] text-[1rem] border'}
                    closeClass={'font-family-sans-serif text-[#fff] ml-[0.25rem] bg-[#e1b923] border-[#e1b923] rounded-[50px] ms:px-[20px] px-[10px] py-[8px] leading-[1.5] text-[1rem] border'}
                    classWrapper={'bg-[#ead7ab] m-[10px] md:px-[35px] md:pt-[25px] px-[15px] py-[15px] rounded-[20px] shadow-lg relative'}
                >
                    <div>
                        <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")] md:mt-0 mt-[15px]'}>Upload & Crop Image</h2>
                        <div className='xms:pt-[40px] pt-[30px] xms:pb-[28px] pb-0'>
                            <div className='xms:h-[400px] h-[260px] xms:w-auto w-[260px] mx-auto relative'>
                                <AvatarEditor
                                    ref={cropRef}
                                    image={cropImage}
                                    style={{ border: "2px solid #fff",margin:"0 auto" }}
                                    width={width}
                                    height={height}
                                    color={[0, 0, 0, 0.72]}
                                    scale={slideValue / 10}
                                    rotate={0}
                                />
                            </div>
                            <Slider
                                min={10}
                                max={50}
                                sx={{
                                    margin: clientWidth < 480 ? "0 auto" : "15px auto 0px",
                                    width: "80%",
                                    color: "#756b55"
                                }}
                                size="medium"
                                defaultValue={slideValue}
                                value={slideValue}
                                onChange={(e) => setSlideValue(e.target.value)}
                            />
                        </div>
                    </div>
                </Popup> 
            ) : null}
        </div>
    );
};

export default ImageUpload;
