import Otp from "./otp.jsx";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../atom/button.jsx";
import * as env from '../../../../config/env.js';
import DropDown from "../../../atom/dropDown.jsx";
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../../atom/spinner/spinner.jsx";
import Eye from "../../../../assets/images/png/eye.png";
import { apiRequest } from '../../../../services/index.js';
import { useLocation, useNavigate } from 'react-router-dom';
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
import Hidden from "../../../../assets/images/png/hidden.png";
import allCountries from "../../../atom/countryCode/countryCode.jsx";
import { getUserWithoutAuthentication } from "../../../../stores/action/userWithOutAuthAction.js";
import useCustomComposable from "../../../../utils/composables/index.js"


const SignUp = () => {
    const { focusOnDropdown,focusNextInput } = useCustomComposable();
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);    
    const location = useLocation();
    const navigate = useNavigate();
    const  { checkErrors,checkAllFields } = useValidation();
    const [otpMessage,setOtpMessage] = useState('');
    const [confirmationErr,setConfirmationErr] = useState('');
    const [toggleNewConfirm,setToggleNewConfirm] = useState('');
    const [typedCharacters, setTypedCharacters] = useState('');
    const [isInputFocused, setIsInputFocused] = useState(true);
    const typingTimeoutRef = useRef(null);
    const dispatch = useDispatch();
    //useState
    const [initialData, setInitialData] = useState({ isRoute: false });
    const [countryArray,setCountryArray] = useState(allCountries);
    const [togglePassword,setTogglePassword] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [countryObject,setCountryObject] = useState({
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [isOpen,setIsOpen] = useState(false);
    const [formData,setFormData] = useState({
        firstName: {type: 'text',name: 'first name',value: '',rules: "required|max:50"},
        lastName: {type: 'text',name: 'last name',value: '',rules: "required|max:50"},
        email: {value: "",rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "email",error: ""},
        password: {value: "",rules:"required|  regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "password",error: ""},
        mobile:{value: "",rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
        basicProfileCreatedFor:{value: "",rules:"required",name: "profile",error: ""},
        gender:{value: "",rules:"required",name: "gender",error: ""},
        confirmNewPassword: {value: '',rules:"required",name: "confirm password",error: ""}
    });

    //REF
    const dropdownRef = useRef(null);
    const selectDropDown = useRef(null); 

    //useEffect
    useEffect(() => {
        if (location.state && location.state.isRoute) {
            setInitialData({
                isRoute: location.state.isRoute
            });
            // Clear the state by navigating to the same route without state
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const isConfirmNewPasswordFocused = document.activeElement === document.getElementById('confirmNewPassword');
        if(isConfirmNewPasswordFocused){
            confirmationPassValidation();
        }
    },[formData?.confirmNewPassword?.value]);

    //function
    const handleSignUp = () => {
        formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
        checkAllFields(formData).then((valid)=>{
            const passwordMatched = confirmationPassValidation();
            setFormData({...formData});
            if(valid && passwordMatched === ''){
                setIsLoading(true);
                let signUp = {
                    email:formData.email.value ? formData.email.value.trim() : '',
                    password:formData.password.value,
                    mobileNumber:formData.mobile.value,
                    countryCode:countryObject.dialCode,
                    gender:formData.gender.value,
                    profileFor:formData.basicProfileCreatedFor.value,
                    firstName:formData.firstName.value ? formData.firstName.value.trim() : '',
                    lastName:formData.lastName.value ? formData.lastName.value.trim() : ''
                }
                try {
                    apiRequest("post", env.REGISTER, signUp).then(async(response) => {
                        if(response.status === 200){
                            const userInfo = {
                                email:formData.email.value,
                                phone:formData.mobile.value,
                                countryCode:countryObject.dialCode,
                                isMobileNotVerified:true,
                                isEmailNotVerified:true,
                                password:formData.password.value,
                                id:response?.data?.id,
                                jwtToken:response?.data?.jwtToken,
                                message:response?.data?.message,
                                refreshToken:response?.data?.refreshToken,
                                sessionId:response?.data?.sessionId
                            }
                            await dispatch(getUserWithoutAuthentication(userInfo));
                            setOtpMessage('Otp sent successfully');
                            setInitialData({
                                isRoute: true
                            });
                            setIsLoading(false);
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        setIsLoading(false);
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                        console.error('Error',error);
                    });
                } catch (error) {
                    console.error('Register Error',error);
                    setIsLoading(false);
                }
            }
        });
    };
    const handleToggleConfirm = () => {
        setToggleNewConfirm(!toggleNewConfirm);
        if(!toggleNewConfirm){
            document.getElementById('confirmNewPassword').type = 'text';
        }else{
            document.getElementById('confirmNewPassword').type = 'password';
        }
    };
    const handleToggle = () => {
        setTogglePassword(!togglePassword);
        if(!togglePassword){
            document.getElementById('createPassword').type = 'text';
        }else{
            document.getElementById('createPassword').type = 'password';
        }
    };
    const handleCountryCode = (ele) => {
        setCountryObject(ele);
        setIsOpen(false);
    };
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleOutsideClick = (event) => {
        if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleSelect = (e,id) => {
        if(id === 'profile'){
            checkErrors({
                'field': formData.basicProfileCreatedFor,
                'name': 'profile',
                'validations': formData.basicProfileCreatedFor.rules,
                'value': e.key
            });
            setFormData({ ...formData, basicProfileCreatedFor: {...formData.basicProfileCreatedFor ,value:e.key} })
            handleDropDown('firstname')
        }else if(id === 'gender'){
            checkErrors({
                'field': formData.gender,
                'name': 'gender',
                'validations': formData.gender.rules,
                'value': e.key
            });
            setFormData({ ...formData, gender: {...formData.gender ,value:e.key} })
            handleDropDown('mobilenumber')
        }else if(id === 'password'){
            checkErrors({
                'field': formData.password,
                'name': 'password',
                'validations': formData.password.rules,
                'event': e.event,           
                'value': e.value
            });
            setFormData({ ...formData, password: {...formData.password,value:e.value} });
            updateConfirmation();
        }else if(id === 'confirmNewPassword'){
            checkErrors({
                'field': formData.confirmNewPassword,
                'name': 'confirm password',
                'validations': formData.confirmNewPassword.rules,
                'event': e.event,           
                'value': e.value
            });
            setFormData({ ...formData, confirmNewPassword: {...formData.confirmNewPassword,value:e.value} });
        }
    };
    const updateConfirmation = () => {
        if(formData.confirmNewPassword.value === ''){
            return;
        }else{
            if(formData.confirmNewPassword.value === ''){
                setConfirmationErr('The confirm password field is required');
            }else if(formData.password.value !== formData.confirmNewPassword.value){
                setConfirmationErr('The confirm password confirmation does not match');
            }else{
                setConfirmationErr('');
            }
        }
    };
    const confirmationPassValidation = () => {
        let err = ""
        if(formData.confirmNewPassword.value === ''){
            err = 'The confirm password field is required';
        }else if(formData.password.value !== formData.confirmNewPassword.value){
            err = 'The confirm password confirmation does not match';
        }
        setConfirmationErr(err);

        return err;
    };
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };
    const handleDropDown = (nextInputId) => {
        focusOnDropdown(nextInputId);
    };
    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    
    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.focus();
        }
    }, [isOpen]);
    return(
        <>
            {isLoading ? <Spinner /> : null}
            <div className={'xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] px-[22px] xms:px-[30px] mx-auto'}>
                
                <div className={`mx-auto my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] py-[36px] after:bg-no-repeat xxms:after:bg-[length:100px] sm:after:bg-auto xxms:after:w-[100px] xxms:after:h-[100px] sm:after:w-[150px] sm:after:h-[150px] xxms:after:bottom-[-18px] xxms:after:left-[-15px] sm:after:bottom-[-24px] after:rotate-180 sm:after:left-[-40px] after:bg-top after:absolute after:bg-[url("../../assets/images/png/border_design.png")] before:bg-no-repeat xxms:before:w-[100px] xxms:before:h-[100px] xxms:before:bg-[length:100px] sm:before:w-[150px] sm:before:h-[150px] xxms:before:top-[-10px] xxms:before:right-[-18px] sm:before:top-[-24px] sm:before:right-[-40px] before:bg-top before:absolute before:bg-[url("../../assets/images/png/border_design.png")] sm:before:bg-auto`}>
                {
                    !initialData.isRoute ? 
                    <>
                        <ToastContainer />
                        <div className={`relative max-w-[540px] w-full mx-auto`}>
                            <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] ms:text-[34px] text-[30px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Sign up</h2>
                            <form onSubmit={(event) => {event.preventDefault(); handleSignUp();}} className={'px-[15px] md:px-[0px] mt-[25px] mb-[30px] after:absolute after:border-b-[1px] after:w-full after:border-[#b7892d] xxms:after:left-[0px] md:after:left-[inherit]'}>
                                <div className="mb-[12px]">
                                    <DropDown
                                        options={dropDown?.['profileCreatorFor']?.option}
                                        onUpdate={(e) => handleSelect(e,'profile')}
                                        multiSelect={false}
                                        selectedValue={formData?.basicProfileCreatedFor?.value ? [formData?.basicProfileCreatedFor?.value] : []}
                                        labelName={'Profile for *'}
                                        labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                        wrapperClass={''}
                                        optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                        optionsWrapper={'bg-white'}
                                    />
                                    {formData?.basicProfileCreatedFor?.error &&
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicProfileCreatedFor.error}</small>
                                    }
                                </div>
                                <div className="mb-[12px] relative">
                                    <CustomInput
                                        inputId="firstname"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="First Name *"
                                        type="text"
                                        modelValue={formData.firstName.value ? formData.firstName.value?.trim() : ''}
                                        placeHolder="Enter first name"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.firstName.value = value ? value?.trim() : '';
                                            checkErrors({
                                                'field': formData.firstName,
                                                'name': 'first name',
                                                'validations': formData.firstName.rules,
                                                'type': formData.firstName.type,
                                                'event': event,
                                                'value':value?.trim()
                                            });
                                            setFormData({...formData});
                                        }}
                                        onKeyPress={(event) => handleKeyPress(event, 'lastname')}
                                    />
                                    {formData.firstName.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.firstName.error}</small>
                                    }
                                </div>
                                <div className="mb-[12px] relative">
                                    <CustomInput
                                        inputId="lastname"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Last Name *"
                                        type="text"
                                        modelValue={formData.lastName.value ? formData.lastName.value.trim() : ''}
                                        placeHolder="Enter last name"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.lastName.value = value ? value.trim() : '';
                                            checkErrors({
                                                'field': formData.lastName,
                                                'name': 'last name',
                                                'validations': formData.lastName.rules,
                                                'type': formData.lastName.type,
                                                'event': event,
                                                'value':value ? value.trim() : ''
                                            });
                                            setFormData({...formData});
                                        }}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.lastName.value = blur?.lastName?.value?.trim();
                                            setFormData(blur);
                                        }}
                                        onKeyPress={(event) => handleKeyPress(event, 'email')}
                                    />
                                    {formData.lastName.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.lastName.error}</small>
                                    }
                                </div>
                                <div className="mb-[12px] relative">
                                    <CustomInput
                                        inputId="email"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Email ID *"
                                        type="text"
                                        modelValue={formData.email.value ? formData.email.value.trim() : ''}
                                        placeHolder="Enter email id"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.email.value = value ? value.trim() : '';
                                            checkErrors({
                                                'field': formData.email,
                                                'name': 'email',
                                                'validations': formData.email.rules,
                                                'type': formData.email.type,
                                                'event': event,
                                                'value':value ? value.trim() : ''
                                            });
                                            setFormData({...formData});
                                        }}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.email.value = blur?.email?.value?.trim();
                                            setFormData(blur);
                                        }}
                                        onKeyPress={(event) => handleKeyPress(event, 'gender')}
                                    />
                                    {formData.email.error && 
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.email.error}</small>
                                    }
                                </div>
                                <div className="md:flex">
                                    <div className="md:w-[40%] mb-[12px] md:pr-[15px] md:relative">
                                        <DropDown
                                            options={dropDown?.['gender']?.option}
                                            onUpdate={(e) => handleSelect(e,'gender')}
                                            multiSelect={false}
                                            selectedValue={formData?.gender?.value ? [formData?.gender?.value] : []}
                                            labelName={'Gender *'}
                                            labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                            selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[20px] md:after:right-[20px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                            wrapperClass={''}
                                            optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                            optionsWrapper={'bg-white'}
                                            focusInd="gender"
                                        />
                                        {formData?.gender?.error &&
                                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.gender.error}</small>
                                        }
                                    </div>

                                    <div className="mb-[12px] md:w-[60%] md:pl-[15px]">
                                        <label className="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]">
                                            Mobile No. *
                                        </label>
                                        <div className="flex relative">
                                            <div ref={selectDropDown} className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode();}}>
                                                {countryObject.name} 
                                                <span className="dropDown_arrow"></span>
                                            </div>
                                            {
                                                isOpen ?
                                                    <div onKeyDown={handleKeyDown} ref={dropdownRef} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                        <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                                        <ul>
                                                            {countryArray.length ? countryArray.map((ele,index) => 
                                                                <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode(ele);}}>
                                                                    {ele.name} {ele?.countryJoin}
                                                                </li>
                                                            ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                        </ul>
                                                    </div>
                                                : null
                                            }
                                            <CustomInput
                                                inputId="mobilenumber"
                                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[42px]'}
                                                class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                                type="number"
                                                min="1"
                                                modelValue={formData.mobile.value ? formData.mobile.value.trim() : ''}
                                                placeHolder="Enter mobile no"
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onChange={(value,event) => {
                                                    formData.mobile.value = value;
                                                    formData.mobile.rules = `required|min:7|max:15|regex:^[0-9]+$`
                                                    checkErrors({
                                                        'field': formData.mobile,
                                                        'name': 'mobile',
                                                        'validations': formData.mobile.rules,
                                                        'type': formData.mobile.type,
                                                        'event': event,
                                                        'value':value
                                                    });
                                                    setFormData({...formData});
                                                }}
                                                onKeyPress={(event)=>{
                                                    handleKeyPress(event, 'createPassword')
                                                    if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                        event.event.preventDefault();
                                                    }
                                                }}
                                                onKeyDown={(event)=>{
                                                    if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                        event.event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData.mobile.error && 
                                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.mobile.error}</small>
                                        }
                                    </div>
                                </div>
                                <div className="relative mb-[12px]">
                                    <CustomInput
                                        inputId="createPassword"
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Create Password *"
                                        type="password"
                                        modelValue={formData?.password?.value || ''}
                                        placeHolder="Enter password"
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onChange={(value,event) => {
                                            formData.password.value = value;
                                            checkErrors({
                                                'field': formData.password,
                                                'name': 'password',
                                                'validations': formData.password.rules,
                                                'type': formData.password.type,
                                                'event': event,
                                                'value':value
                                            });
                                            setFormData({...formData});
                                        }}
                                        onKeyPress={((value) => {
                                            handleKeyPress(value, 'confirmNewPassword')
                                            if(value.event.code === "Space"){
                                                value.event.preventDefault();
                                            }
                                        })}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.password.value = blur?.password?.value?.trim();
                                            setFormData(blur);
                                        }}
                                        onPaste={(event) => {
                                            event.event.preventDefault();
                                        }}
                                    />
                                    <div className="w-[20px] h-[20px] absolute cursor-pointer top-[44px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggle();}}>
                                        {
                                            !togglePassword ? 
                                            <img src={Eye} alt="eye" /> :
                                            <img src={Hidden} alt="Hidden" />
                                        }
                                    </div>
                                    {formData.password.error &&
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.password.error}</small>
                                    }
                                </div>
                                <div className="relative">
                                    <CustomInput
                                        inputId="confirmNewPassword"
                                        placeHolder={'Enter Confirm Password'}
                                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[42px]'}
                                        class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                        label="Confirm Password *"
                                        type="password"
                                        modelValue={formData?.confirmNewPassword?.value ? formData?.confirmNewPassword?.value : ''}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onKeyUp={(e) => handleSelect(e,'confirmNewPassword')}
                                        onPaste={(event) => {
                                            event.event.preventDefault();
                                        }}
                                        onBlur={() => {
                                            const blur = {...formData}
                                            blur.confirmNewPassword.value = blur?.confirmNewPassword?.value?.trim();
                                            setFormData(blur);
                                        }}
                                        onKeyPress={((value) => {
                                            if(value.event.code === "Space"){
                                                value.event.preventDefault();
                                            }
                                        })}
                                    />
                                    <div className="w-[20px] h-[20px] absolute cursor-pointer top-[44px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggleConfirm();}}>
                                        {
                                            !toggleNewConfirm ? 
                                            <img src={Eye} alt="eye" /> :
                                            <img src={Hidden} alt="Hidden" />
                                        }
                                    </div>
                                    {
                                        confirmationErr || formData?.confirmNewPassword?.error?    
                                        <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{confirmationErr || formData?.confirmNewPassword?.error}</small> :
                                        null
                                    }
                                </div>
                                <div className="py-[30px]">
                                    <Button 
                                        class={'w-[125px] h-[45px] bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] font-family-Quicksand-Medium text-center block mx-auto'}
                                        buttonName={'Sign Up'}
                                    />
                                </div>
                            </form>
                            <div>
                                <h5 className={'text-[#931217] xxms:text-[16px] ms:text-[20px] font-family-Quicksand-Bold text-center'}> 
                                    Already a Member? <Link to="/login" className={'text-[#ed1c24] underline underline-offset-[6px]'}>Login</Link>
                                </h5>
                            </div>
                        </div>
                    </>
                    : 
                        <Otp
                            countryCode={countryObject?.dialCode || ''}
                            email={formData?.email?.value || ''}
                            phoneNumber={formData?.mobile?.value || ''}
                            otpMessage={otpMessage}
                        />
                }
                    </div> 
                
            </div>
        </>
    )
}

export default SignUp;