import { apiRequest } from '../../services/index.js';
import { getDropDown } from '../reducers/getDropDownReducer.js';
import * as env from '../../config/env.js';

// Actions
export const getDropDownAction = () => async dispatch => {
    try {
        await apiRequest("get", `${env.GETDROPDOWN}`).then((response) => {
            if (response.status === 200) {
                if (response?.data?.data && response?.data?.data?.length) {
                    let inputArray = response?.data?.data;
                    const outputObject = {};

                    inputArray.forEach(item => {
                        outputObject[item.key] = item;
                    });

                    dispatch(getDropDown(outputObject));
                }
            }
        }).catch((error) => {
            console.error('Get user Action Error', error);
        });
    } catch (e) {
        console.error('user get Error Action', e.message);
    }
};