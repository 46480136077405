import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from "react";
import { useDispatch } from 'react-redux';
import Button from "../../../atom/button.jsx";
import 'react-toastify/dist/ReactToastify.css';
import * as env from '../../../../config/env.js';
import {useAuth} from "../../../../route/auth.jsx";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Spinner from "../../../atom/spinner/spinner.jsx";
import Eye from "../../../../assets/images/png/eye.png";
import { apiRequest } from '../../../../services/index.js';
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
import Hidden from "../../../../assets/images/png/hidden.png";
import CheckBox from "../../../molecules/checkBox/checkBox.jsx";
import allCountries from "../../../atom/countryCode/countryCode.jsx";
import {getUserAction} from '../../../../stores/action/userAction.js';
import {getUserWithoutAuthentication} from '../../../../stores/action/userWithOutAuthAction.js';
import useCustomComposable from '../../../../utils/composables/index.js';
// import { useSelector } from 'react-redux';

const SignIn = () => {
    const { focusNextInput } = useCustomComposable();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { login } = useAuth();

    //userDetail
    // const userProfileReview = useSelector(state => state.userWithoutAuth);

    //validation
    const  { checkErrors,checkAllFields } = useValidation();

    const typingTimeoutRef = useRef(null);
    //useState
    const [typedCharacters, setTypedCharacters] = useState('');
    const [togglePassword,setTogglePassword] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(true);
    const [isLoading,setIsLoading] = useState(false);
    const [formData,setFormData] = useState({
        email: {value: localStorage.getItem('email') ? localStorage.getItem('email') : '',rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""},
        password: {value: "",rules:"required",name: "Password",error: ""},
        isCheck: {value: false,rules:"",name: "stay logged in",error: ""},
        mobile:{value: localStorage.getItem('phone') ? localStorage.getItem('phone') : '',rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
        rmId:{value: localStorage.getItem('rmId') ? localStorage.getItem('rmId') : '',rules:"required|min:3|max:20",name: "rmId",error: ""}
    });
    const [isLoginWith,setIsLoginWith] = useState(1);
    const [countryObject,setCountryObject] = useState(localStorage.getItem('countryCode') ? allCountries.find(e => e.dialCode === localStorage.getItem('countryCode')) || {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    } : {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [isOpen,setIsOpen] = useState(false);
    const [countryArray,setCountryArray] = useState(allCountries);

    //ref
    const dropdownRef = useRef(null);
    const selectDropDown = useRef(null);

    //function
    const handleSignIn = () => {
        if(isLoginWith === 1){
            delete formData.mobile;
            delete formData.rmId;
            setFormData({...formData});
        }else if(isLoginWith === 2){
            delete formData.email;
            delete formData.rmId;
            setFormData({...formData});
        }else{
            delete formData.email;
            delete formData.mobile;
            setFormData({...formData});
        }
        checkAllFields(formData).then(async(valid)=>{
            setFormData({...formData});
            if(valid){
                setIsLoading(true);
                let loginData;
                if(isLoginWith === 1){
                    loginData = {
                        email: formData.email.value,
                        loginType:'email',
                        password:formData.password.value
                    }
                }else if(isLoginWith === 2){
                    loginData = {
                        mobile: formData.mobile.value,
                        loginType:'phone',
                        password:formData.password.value,
                        countryCode:countryObject.dialCode
                    }
                }else{
                    loginData = {
                        rmid: formData.rmId.value,
                        loginType:'rmid',
                        password:formData.password.value
                    }
                }

                try {
                    apiRequest("post", env.LOGIN, loginData).then(async(response) => {
                        if(response?.status === 200){
                            if(response?.data && Object?.keys(response.data)?.length){
                                localStorage.setItem('userId',response?.data?.id);
                                localStorage.setItem('token',response.data.jwtToken);
                                localStorage.setItem('refreshToken',response.data.refreshToken);
                                Cookies.set('sessionId', response.data.sessionId, { expires: 1 });
                                if(formData?.isCheck?.value){
                                    if(isLoginWith === 1){
                                        localStorage.setItem('email',formData.email.value);
                                    }else if(isLoginWith === 2){
                                        localStorage.setItem('phone',formData.mobile.value);
                                        localStorage.setItem('countryCode',countryObject.dialCode);
                                    }else{
                                        localStorage.setItem('rmId',formData.rmId.value);
                                    }
                                }
                                await dispatch(getUserAction(response?.data?.id || ''));
                                toast.success(response.data.message, {
                                    position: 'top-right',
                                });
                                login();
                            }
                            
                        }else{
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                            const setDetail = {
                                email: {value: formData?.email?.value ? formData.email.value : '' ,rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""},
                                password: {value: formData?.password?.value || '',rules:"required",name: "Password",error: ""},
                                isCheck: {value: false,rules:"",name: "stay logged in",error: ""},
                                mobile:{value: formData?.mobile?.value ? formData.mobile.value : '',rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
                                rmId:{value: formData?.rmId?.value ? formData.rmId.value : '',rules:"required|min:3|max:20",name: "rmId",error: ""}
                            }
                            setFormData({...setDetail});
                        }
                        setIsLoading(false);
                    }).catch(async(error) => {
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }else {
                            toast.error('Please try it again', {
                                position: 'top-right',
                            });
                        }
                        if(error?.response?.data?.isMobileNotVerified || error?.response?.data?.isEmailNotVerified){
                            navigate('/register', { state: { isRoute:true }});
                            await dispatch(getUserWithoutAuthentication({...error?.response?.data,password:formData?.password?.value}));
                        }
                        const setDetail = {
                            email: {value: formData?.email?.value ? formData.email.value : '' ,rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""},
                            password: {value: formData?.password?.value || '',rules:"required",name: "Password",error: ""},
                            isCheck: {value: false,rules:"",name: "stay logged in",error: ""},
                            mobile:{value: formData?.mobile?.value ? formData.mobile.value : '',rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
                            rmId:{value: formData?.rmId?.value ? formData.rmId.value : '',rules:"required|min:3|max:20",name: "rmId",error: ""}
                        }
                        setFormData({...setDetail});
                        console.error('login user',error);
                        setIsLoading(false);
                    });
                } catch (error) {
                    const setDetail = {
                        email: {value: formData?.email?.value ? formData.email.value : '' ,rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""},
                        password: {value: formData?.password?.value || '',rules:"required",name: "Password",error: ""},
                        isCheck: {value: false,rules:"",name: "stay logged in",error: ""},
                        mobile:{value: formData?.mobile?.value ? formData.mobile.value : '',rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
                        rmId:{value: formData?.rmId?.value ? formData.rmId.value : '',rules:"required|min:3|max:20",name: "rmId",error: ""}
                    }
                    setFormData({...setDetail});
                    console.error('login',error);
                    setIsLoading(false);
                    toast.error('Please try it again', {
                        position: 'top-right',
                    });
                }
            }
        });
    };

    const handleToggle = () => {
        setTogglePassword(!togglePassword);
        if(!togglePassword){
            document.getElementById('password').type = 'text';
        }else{
            document.getElementById('password').type = 'password';
        }
    };

    const handleSelect = (e) => {
        setFormData({ ...formData, isCheck: {...formData.isCheck,value:!e.value} });
    };

    
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    

    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    
    const handleCountryCode = (ele) => {
        setCountryObject(ele);
        setIsOpen(false);
    };
    const toggleLoginMethod = (e) => {
        setIsLoginWith(e);
        const setDetail = {
            email: {value: localStorage.getItem('email') ? localStorage.getItem('email') : '',rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""},
            password: {value: formData?.password?.value || '',rules:"required",name: "Password",error: ""},
            isCheck: {value: false,rules:"",name: "stay logged in",error: ""},
            mobile:{value: localStorage.getItem('phone') ? localStorage.getItem('phone') : '',rules:"required|min:7|max:15|regex:^[0-9]+$",name: "mobile",error: ""},
            rmId:{value: localStorage.getItem('rmId') ? localStorage.getItem('rmId') : '',rules:"required|min:3|max:20",name: "rmId",error: ""}
        }
        setFormData({...setDetail});
    };

    const handleOutsideClick = (event) => {
        if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };


    // useEffect
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.focus();
        }
    }, [isOpen]);
    const handleKeyPress = (event, nextInputId) => {
        focusNextInput(event, nextInputId);
    };
    return(
        <>
            <ToastContainer />
            {isLoading ? <Spinner /> : null}
            <div className={'xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] xxms:px-[22px] xms:px-[30px] mx-auto'}>
                <div className={`mx-auto xxms:my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] pb-[50px] pt-[38px] after:bg-no-repeat xxms:after:bg-[length:100px] sm:after:bg-auto xxms:after:w-[100px] xxms:after:h-[100px] sm:after:w-[150px] sm:after:h-[150px] xxms:after:bottom-[-18px] xxms:after:left-[-15px] sm:after:bottom-[-24px] after:rotate-180 sm:after:left-[-40px] after:bg-top after:absolute after:bg-[url("../../assets/images/png/border_design.png")] before:bg-no-repeat xxms:before:w-[100px] xxms:before:h-[100px] xxms:before:bg-[length:100px] sm:before:w-[150px] sm:before:h-[150px] xxms:before:top-[-10px] xxms:before:right-[-18px] sm:before:top-[-24px] sm:before:right-[-40px] before:bg-top before:absolute before:bg-[url("../../assets/images/png/border_design.png")] sm:before:bg-auto`}>
                    <div className={`relative max-w-[540px] w-full mx-auto`}>
                        <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Welcome! Please Login</h2>
                        <div className="flex justify-center mt-9 mb-4">
                            <button type="button" className={`xms:mx-2 px-4 py-2 text-[18px] ${isLoginWith === 1 ? 'bg-[#ed1c24] text-white' : 'bg-[#ead7ab] text-[#ed1c24]'} rounded`} onClick={() => toggleLoginMethod(1)}>
                                Email
                            </button>
                            <button type="button" className={`xms:mx-2 px-4 py-2 text-[18px] ${isLoginWith === 2 ? 'bg-[#ed1c24] text-white' : 'bg-[#ead7ab] text-[#ed1c24]'} rounded`} onClick={() => toggleLoginMethod(2)}>
                                Mobile
                            </button>
                            <button type="button" className={`xms:mx-2 px-4 py-2 text-[18px] ${isLoginWith === 3 ? 'bg-[#ed1c24] text-white' : 'bg-[#ead7ab] text-[#ed1c24]'} rounded`} onClick={() => toggleLoginMethod(3)}>
                                RMID
                            </button>
                        </div>
                        <form onSubmit={(event) => {event.preventDefault(); handleSignIn();}} className={'xxms:px-[15px] md:px-[0px] mt-[25px] mb-[20px] after:absolute after:border-b-[1px] after:w-full after:border-[#b7892d] xxms:after:left-[0px] md:after:left-[inherit]'}>
                            {
                                isLoginWith === 1 ?
                                    <div className="lg:mb-[22px] mb-[12px] relative">
                                        <CustomInput
                                            inputId="emailId"
                                            inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                            class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                            label="Email ID *"
                                            type="text"
                                            modelValue={formData?.email?.value || ''}
                                            placeHolder="Enter email id"
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onChange={(value,event) => {
                                                formData.email.value = value;
                                                checkErrors({
                                                    'field': formData.email,
                                                    'name': 'email',
                                                    'validations': formData.email.rules,
                                                    'type': formData.email.type,
                                                    'event': event,
                                                    'value':value
                                                });
                                                setFormData({...formData});
                                            }}
                                            onBlur={() => {
                                                const blur = {...formData}
                                                blur.email.value = blur?.email?.value?.trim();
                                                setFormData(blur);
                                            }}
                                            onKeyPress={(event) => handleKeyPress(event, 'password')}
                                        />
                                        {formData.email.error && 
                                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.email.error}</small>
                                        }
                                    </div> 
                                : isLoginWith === 2 ?
                                    <div className="lg:mb-[22px] mb-[12px] relative">
                                        <label className="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]">
                                            Mobile No. *
                                        </label>
                                        <div className="flex relative">
                                            <div ref={selectDropDown} className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => {event.preventDefault(); handleToggleCountryCode();}}>
                                                {countryObject.name} 
                                                <span className="dropDown_arrow"></span>
                                            </div>
                                            {
                                                isOpen ?
                                                    <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                                        <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                                        <ul>
                                                            {countryArray.length ? countryArray.map((ele,index) => 
                                                                <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => {event.preventDefault(); handleCountryCode(ele);}}>
                                                                    {ele.name} {ele?.countryJoin}
                                                                </li>
                                                            ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                                        </ul>
                                                    </div>
                                                : null
                                            }
                                            <CustomInput
                                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[42px]'}
                                                class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                                type="number"
                                                modelValue={formData?.mobile?.value}
                                                placeHolder="Enter mobile no"
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onChange={(value,event) => {
                                                    formData.mobile.value = value;
                                                    checkErrors({
                                                        'field': formData.mobile,
                                                        'name': 'mobile',
                                                        'validations': formData.mobile.rules,
                                                        'type': formData.mobile.type,
                                                        'event': event,
                                                        'value':value
                                                    });
                                                    setFormData({...formData});
                                                }}
                                                onKeyPress={(event)=>{
                                                    handleKeyPress(event, 'password')
                                                    if(event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E'){
                                                        event.event.preventDefault();
                                                    }
                                                }}
                                                onKeyDown={(event)=>{
                                                    if(event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown'){
                                                        event.event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData.mobile.error && 
                                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.mobile.error}</small>
                                        }
                                    </div> 
                                : 
                                    <div className="lg:mb-[22px] mb-[12px] relative">
                                        <label className="text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]">
                                            RmId *
                                        </label>
                                        <div className="flex relative">
                                            <CustomInput
                                                placeHolder={'Enter rmId'}
                                                inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                                class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                                type="text"
                                                modelValue={formData?.rmId?.value ? formData?.rmId?.value : ''}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onChange={(value, event) => {
                                                    formData.rmId.value = value;
                                                    checkErrors({
                                                        'field': formData.rmId,
                                                        'name': 'rmId',
                                                        'validations': formData.rmId.rules,
                                                        'type': formData.rmId.type,
                                                        'event': event,
                                                        'value':value
                                                    });
                                                    setFormData({...formData});
                                                }}
                                                onKeyPress={(event)=>{
                                                    handleKeyPress(event, 'password')
                                                    if(event.event.charCode === 32){
                                                        event.event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                        {formData?.rmId?.error &&
                                            <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.rmId.error}</small>
                                        }
                                    </div> 
                            }
                            <div className="mb-[12px] relative">
                                <CustomInput
                                    inputId="password"
                                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] pl-[16px] pr-[45px] py-[5px] h-[42px]'}
                                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    label="Password *"
                                    type="password"
                                    modelValue={formData?.password?.value || ''}
                                    placeHolder="Enter password"
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    isOutline={true}
                                    onChange={(value,event) => {
                                        formData.password.value = value;
                                        checkErrors({
                                            'field': formData.password,
                                            'name': 'password',
                                            'validations': formData.password.rules,
                                            'type': formData.password.type,
                                            'event': event,
                                            'value':value
                                        });
                                        setFormData({...formData});
                                    }}
                                    onPaste={(event) => {
                                        event.event.preventDefault();
                                    }}
                                    onBlur={() => {
                                        const blur = {...formData}
                                        blur.password.value = blur?.password?.value?.trim();
                                        setFormData(blur);
                                    }}
                                    onKeyPress={((value) => {
                                        if(value.event.code === "Space"){
                                            value.event.preventDefault();
                                        }
                                    })}
                                />
                                <div className="w-[20px] h-[20px] absolute cursor-pointer top-[44px] right-[15px]" onClick={(event) => {event.preventDefault(); handleToggle();}}>
                                    {
                                        !togglePassword ? 
                                        <img src={Eye} alt="eye" /> :
                                        <img src={Hidden} alt="Hidden" />
                                    }
                                </div>
                                {formData.password.error &&
                                    <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.password.error}</small>
                                }
                            </div>
                            <div className="xms:flex xms:justify-between">
                                <div>
                                    <CheckBox
                                        modelValue={formData?.isCheck?.value}
                                        isDisabled={false}
                                        isReadonly={false}
                                        isDirectFocus={false}
                                        isRequired={false}
                                        isOutline={true}
                                        onClick={(value) => handleSelect(value)}
                                        label={'Stay Logged in'}
                                        inputClass={'styled-checkbox opacity-0 absolute'}
                                        labelClass={'text-[16px] text-stroke text-[#262626] font-family-Quicksand-Medium cursor-pointer relative'}
                                        checked={formData?.isCheck?.value}
                                        isCheckBy={true}
                                    />
                                </div>
                                <div className="xms:mt-0 xxms:mt-1">
                                    <p>
                                        <Link to={'/forgot-password'} className={'text-[#931217] text-[18px] font-family-Quicksand-Bold inline-block leading-[normal]'}>Forgot Password?</Link>
                                    </p>
                                </div>
                            </div>
                            <div className="py-[15px]">
                                <Button 
                                    class={'bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[7.5px] px-[28px] leading-[normal] pb-[7.5px] font-family-Quicksand-SemiBold text-center block mx-auto'}
                                    buttonName={'Login'}
                                />
                            </div>
                        </form>
                        <div>
                            <h5 className={'text-[#931217] xxms:text-[16px] ms:text-[20px] font-family-Quicksand-Bold text-center'}> 
                                New on Rishta? <Link to="/register" className={'text-[#ed1c24] underline underline-offset-[6px]'}>Sign up</Link>
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SignIn;