import React, { useState, useRef, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import useCustomComposable from '../../utils/composables/index.js';
import ClientWidthContext from '../atom/clientWidth.jsx';

const CustomDropdown = ({ isOpen, position, options,isInputSearch, onClose, selectDropDown, onOptionClick, multiSelect, uniqueId, optionsClass, optionsWrapper, selectedValue }) => {
    const clientWidth = useContext(ClientWidthContext);
    const dropdownRef = useRef(null);
    const [dropdownPosition, setDropdownPosition] = useState({});
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [focusedIndex, setFocusedIndex] = useState(1);
    const optionRefs = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                onClose();
                setFocusedIndex(-1);
                setSearchValue('');
            }
        };

        const handleKeyDown = (event) => {
            if (isOpen) {
                switch (event.key) {
                    case 'ArrowDown':
                        event.preventDefault();
                        setFocusedIndex((prevIndex) => Math.min(prevIndex + 1, filteredOptions && filteredOptions.length ? filteredOptions.length - 1 : 0));
                        break;
                    case 'ArrowUp':
                        event.preventDefault();
                        setFocusedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                        break;
                    case 'Enter':
                        if (focusedIndex >= 0) {
                            handleOptionClick(filteredOptions[focusedIndex]);
                        }
                        break;
                    case 'Tab':
                        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                            onClose();
                            setFocusedIndex(-1);
                            setSearchValue('');
                        }
                        break;
                    default:
                        break;
                }
            }else {
                setFocusedIndex(-1); // Reset focusedIndex when dropdown is closed
            }
        };

        if (isOpen) {
            const calculateDropdownPosition = () => {
                if(dropdownRef.current){
                    const { height: dropdownHeight } = dropdownRef.current.getBoundingClientRect();
                    const { top, left, bottom, width } = document.getElementById(uniqueId).getBoundingClientRect();
                    let newTop;
                    if (bottom + dropdownHeight > window.innerHeight && top - dropdownHeight > 0) {
                        newTop = top - dropdownHeight;
                    } else {
                        newTop = bottom;
                    }
                    setDropdownPosition({ position: 'absolute', top: newTop + window.scrollY, left: left, width: width });
                }
            };
            if (clientWidth >= 768) {
                calculateDropdownPosition();
                // if (dropdownRef.current) {
                //     const { height } = document.getElementById(uniqueId).getBoundingClientRect();
                //     const { top, left, bottom, width } = document.getElementById(uniqueId).getBoundingClientRect();
                //     let newTop;
                //     if (bottom + height > window.innerHeight) {
                //         newTop = top - height;
                //     } else {
                //         newTop = bottom;
                //     }
                //     setDropdownPosition({ position: 'absolute', top: newTop + window.scrollY, left: left, width: width });
                // }
            } else {
                setDropdownPosition({ position: 'fixed', bottom: '0', left: '0', width: '100%' });
            }
            if(searchValue){
                const filtered = options.filter(option =>
                    option.name.toLowerCase().includes(searchValue.toLowerCase())
                );
                setFilteredOptions(filtered);
            }else{
                setFilteredOptions(options);
            }
            document.addEventListener('mousedown', handleClickOutside);
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
            setFocusedIndex(-1); // Reset focusedIndex when dropdown is closed
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, selectDropDown, position, options, onClose, uniqueId, clientWidth, focusedIndex]);

    const handleOptionClick = (option) => {
        if (multiSelect) {
            onOptionClick(option.key, searchValue);
        } else {
            onOptionClick([option.key]);
            onClose();
            setFocusedIndex(-1);
            setSearchValue('');
        }
    };

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
        const filtered = options.filter(option =>
            option.name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredOptions(filtered);
        setFocusedIndex(-1);
    };

    useEffect(() => {
        if (focusedIndex >= 0 && optionRefs.current && optionRefs.current[focusedIndex]) {
            optionRefs.current[focusedIndex].scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [focusedIndex, filteredOptions]);

    return isOpen ? (
        ReactDOM.createPortal(
            <div>
                {clientWidth < 768 ? <div className='z-10 h-[100dvh] w-full top-0 left-0 opacity-100 fixed bg-[#000000b3]'></div> : ''}
                <div ref={dropdownRef} className={'z-20 bg-white rounded-t-[12px] md:rounded-[12px] pt-[20px] pb-[15px]'} style={{ position: dropdownPosition.position, bottom: dropdownPosition.bottom, top: dropdownPosition.top, left: dropdownPosition.left, width: dropdownPosition.width, boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)' }}>
                    {
                        isInputSearch === true ?
                        <div className='mx-[20px]'>
                            <input type='text' className='w-full border-[1px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                        </div> : null
                    }
                    <ul className={`${optionsWrapper} max-h-[240px] overflow-x-auto`}>
                        {filteredOptions.length ? filteredOptions.map((option, index) => (
                            <li
                                key={index}
                                ref={(el) => optionRefs.current[index] = el}
                                onClick={() => handleOptionClick(option)}
                                className={`${optionsClass} truncate cursor-pointer px-[20px] py-[5px] ${focusedIndex === index ? 'bg-[#f6ecc8]' : (multiSelect ? selectedValue.includes(option.key) : selectedValue[0] === option.key) ? 'bg-[#e8d79c]' : ''}`}
                            >
                                {option.name}
                            </li>
                        )) : <li className='px-[20px] py-[5px]'>No data found</li>}
                    </ul>
                </div>
            </div>,
            document.body
        )
    ) : null;
};

const Dropdown = ({ options, multiSelect, onUpdate, selectedValue, id, labelName, labelClass, selectClass, wrapperClass, optionsClass, optionsWrapper,focusInd,isInputSearch }) => {
    const { makeUniqueId } = useCustomComposable();
    const [isOpen, setIsOpen] = useState(false);
    const [position, setPosition] = useState({});
    const [selectedOption, setSelectedOption] = useState(selectedValue ? selectedValue : []);
    const [selectedOptions, setSelectedOptions] = useState(selectedValue ? selectedValue : []); // State to store selected options in case of multi-select
    const [uniqueId] = useState(id ? id : makeUniqueId(6));
    const focusId = focusInd ? focusInd : makeUniqueId(4);
    const selectDropDown = useRef(null);

    useEffect(() => {
        if (selectedValue?.length > -1) {
            if (multiSelect) {
                setSelectedOptions(selectedValue);
            } else {
                setSelectedOption(selectedValue);
            }
        }
    }, [selectedValue, multiSelect]);

    const handleClick = (e) => {
        setIsOpen(!isOpen);
        setPosition(e.target.getBoundingClientRect());
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOptionClick = (element, value) => {
        if (multiSelect) {
            setSelectedOptions([...selectedOptions, element]);
            const selectOption = [...selectedOptions, element];
            let optionArray = [];
            if (selectOption && selectOption.length) {
                optionArray = options.filter(e => selectOption.includes(e.key));
            }
            onUpdate(optionArray);
        } else {
            setSelectedOption(element);
            const selectOption = element;
            let optionArray = [];
            if (selectOption && selectOption.length) {
                optionArray = options.find(e => selectOption.includes(e.key));
            }
            onUpdate(optionArray);
            setIsOpen(false);
        }
    };

    const handleOption = (ele) => {
        const filterOption = selectedOptions.filter(e => !(e === ele.key));
        setSelectedOptions(filterOption);
        const selectOption = filterOption;
        let optionArray = [];
        if (selectOption && selectOption.length) {
            optionArray = options.filter(e => selectOption.includes(e.key));
        }
        onUpdate(optionArray);
    };

    return (
        <div className={wrapperClass ? wrapperClass : 'relative dsfsfds'}>
            {labelName ? <label className={labelClass ? labelClass : ''}>{labelName}</label> : ''}
            <div ref={selectDropDown} className={`${selectClass ? selectClass : ''} cursor-pointer ${multiSelect? 'pr-[30px]' : ''} relative ${isOpen ? 'focus_dropDown' : ''}`} onClick={handleClick} id={uniqueId}>
                <input readOnly className='absolute z-[-2] width-full-webkit' id={focusId} onFocus={handleClick} />
                {multiSelect ?
                    (selectedOptions.length ? (
                        options.filter(e => selectedOptions.includes(e.key)).map((ele, index) =>
                            <div onClick={(event) => { event.preventDefault() }} key={index} className='border border-[#00000080] text-[#262626] text-[16px] rounded-[5px] px-[10px] py-[4px] mr-[3.75px] my-[3.75px]'>
                                <span className='text-[#262626] text-[16px] font-family-Quicksand-SemiBold'>{ele.name}</span>
                                <span onClick={(event) => { event.stopPropagation(); handleOption(ele); }} className='ml-[8px]'>x</span>
                            </div>
                        )
                    ) : "Select") :
                    (selectedOption && selectedOption.length ? <span className='truncate sm:max-w-[fit-content] dropdown_value text-[#262626] text-[18px] font-family-Quicksand-Medium text-stroke-2'>{options.find(e => selectedOption.includes(e.key))?.name}</span> : "Select")}
            </div>
            <CustomDropdown
                isOpen={isOpen}
                position={position}
                options={multiSelect ? selectedOptions && selectedOptions.length ? options.filter(e => !selectedOptions.includes(e.key)) : options : options}
                onClose={handleClose}
                onOptionClick={handleOptionClick}
                multiSelect={multiSelect}
                uniqueId={uniqueId}
                optionsClass={optionsClass ? optionsClass : ''}
                optionsWrapper={optionsWrapper ? optionsWrapper : ''}
                selectedValue={multiSelect ? selectedOptions : selectedOption}
                selectDropDown={selectDropDown}
                isInputSearch={isInputSearch}
            />
        </div>
    );
};

export default Dropdown;