import React, { useEffect } from "react";
import "./index.css";

const Spinner = () => {
    useEffect(() => {
        // Function to prevent key events
        const blockKeyEvents = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        // Add event listeners to block key events
        document.addEventListener("keydown", blockKeyEvents, true);
        document.addEventListener("keypress", blockKeyEvents, true);
        document.addEventListener("keyup", blockKeyEvents, true);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener("keydown", blockKeyEvents, true);
            document.removeEventListener("keypress", blockKeyEvents, true);
            document.removeEventListener("keyup", blockKeyEvents, true);
        };
    }, []);

    return (
        <div className="loader_main">
            <div className="spinner"></div>
        </div>
    );
};

export default Spinner;