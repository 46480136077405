import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
    name: 'userProfileReview',
    initialState: {
        userProfileReview: null,
    },
    reducers: {
        getUserProfileReview: (state, action) => {
            state.userProfileReview = action.payload;
        },
    },
});

export const { getUserProfileReview } = slice.actions;
export default slice.reducer;