import { useState } from "react";
import Button from "../../../atom/button.jsx";
import { useNavigate } from "react-router-dom";
import * as env from '../../../../config/env.js';
import { ToastContainer, toast } from 'react-toastify';
import Spinner from "../../../atom/spinner/spinner.jsx";
import { apiRequest } from "../../../../services/index.js";
import useValidation from "../../../../utils/validation.js";
import CustomInput from "../../../molecules/customInput.jsx";
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const  { checkErrors,checkAllFields } = useValidation();
    const [formData,setFormData] = useState({
        email: {value: "",rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "Email",error: ""}
    });
    const handleForgotPassword = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                try {
                    setIsLoading(true);
                    let forgotPassword = {
                        email:formData.email.value
                    }
                    apiRequest("post", env.FORGOTPASSWORD, forgotPassword).then((response) => {
                        if(response.status === 200){
                            toast.success(response.data.message, {
                                position: 'top-right',
                            });
                            setTimeout(() => {
                                navigate('/login');
                                setIsLoading(false);
                            }, 800);
                        }else{
                            setIsLoading(false);
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            toast.error(error.response.data.message, {
                                position: 'top-right',
                            });
                        }
                        console.error('Error in forgot password',error);
                        setIsLoading(false);
                    });
                }catch (error) {
                    console.error('forgot password error',error);
                    setIsLoading(false);
                }
            }
        });
    };
    return(
        <>
            <ToastContainer />
            {isLoading ? <Spinner /> : null}
            <div className={'xl:max-w-[1140px] lg:max-w-[960px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] xxms:px-[22px] xms:px-[30px] mx-auto'}>
                <div className={`mx-auto xxms:my-[60px] md:my-[125px] max-w-[1070px] bg-[#ead7ab] relative rounded-[25px] pt-[38px] lg:pb-[45px] pb-[38px] after:bg-no-repeat xxms:after:bg-[length:100px] sm:after:bg-auto xxms:after:w-[100px] xxms:after:h-[100px] sm:after:w-[150px] sm:after:h-[150px] xxms:after:bottom-[-18px] xxms:after:left-[-15px] sm:after:bottom-[-24px] after:rotate-180 sm:after:left-[-40px] after:bg-top after:absolute after:bg-[url("../../assets/images/png/border_design.png")] before:bg-no-repeat xxms:before:w-[100px] xxms:before:h-[100px] xxms:before:bg-[length:100px] sm:before:w-[150px] sm:before:h-[150px] xxms:before:top-[-10px] xxms:before:right-[-18px] sm:before:top-[-24px] sm:before:right-[-40px] before:bg-top before:absolute before:bg-[url("../../assets/images/png/border_design.png")] sm:before:bg-auto`}>
                    <div className={`relative w-full mx-auto`}>
                        <h2 className={'pb-[23px] relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] ms:text-[30px] xms:text-[26px] xxms:text-[22px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Forgot Password?</h2>
                        <p className="xxms:px-[10px] mt-[28px] font-family-Quicksand-Medium text-[16px] block text-stroke text-center">Enter your Correct email address and we'll send you a link to reset your password.</p>
                        <form onSubmit={(event) => {event.preventDefault(); handleForgotPassword();}} className={'xxms:px-[15px] md:px-[0px] mt-[20px] max-w-[540px] w-full mx-auto'}>
                            <div className="relative">
                                <CustomInput
                                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    label="Email ID *"
                                    type="text"
                                    modelValue={formData?.email?.value || ''}
                                    placeHolder="Enter email id"
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    isOutline={true}
                                    onChange={(value,event) => {
                                        formData.email.value = value;
                                        checkErrors({
                                            'field': formData.email,
                                            'name': 'email',
                                            'validations': formData.email.rules,
                                            'type': formData.email.type,
                                            'event': event,
                                            'value':value
                                        });
                                        setFormData({...formData});
                                    }}
                                    onBlur={() => {
                                        const blur = {...formData}
                                        blur.email.value = blur?.email?.value?.trim();
                                        setFormData(blur);
                                    }}
                                />
                                {formData.email.error && 
                                    <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{formData.email.error}</small>
                                }
                            </div>
                            <div className="pt-[26px]">
                                <Button 
                                    class={'bg-[#ed1c24] text-[#fff] text-[18px] rounded-[50px] pt-[7.5px] px-[28px] leading-[normal] pb-[7.5px] font-family-Quicksand-SemiBold text-center block mx-auto'}
                                    buttonName={'Submit'}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;