import Alerts from "./alerts";
import EditEmail from "./editEmail";
import Button from "../../atom/button";
import { useSelector } from "react-redux";
import DeleteProfile from "./deleteProfile";
import { useEffect, useState } from "react";
import ChangePassword from "./changePassword";
// import AddTestimonials from "./addTestimonials";
import DeactiveProfile from "./deactiveProfile";
import TransactionHistory from "./transactionHistory";
import { useLocation, useNavigate } from "react-router-dom";

const Settings = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [activeClass,setActiveClass] = useState(queryParams.get('tab') || 'alerts');
    const [name,setName] = useState('');
    const userDetails = useSelector(state => state?.user?.user);
    const [formData] = useState({});
    const [form,setForm] = useState({
        matchAlertMails: {value: formData?.matchAlertMails ? formData?.matchAlertMails : false,rules:"",name: "match alert mails",error: ""},
        memberAlertMails: {value: formData?.memberAlertMails ? formData?.memberAlertMails : false,rules:"",name: "member alert mails",error: ""},
        managerResourceAlertMails: {value: formData?.managerResourceAlertMails ? formData?.managerResourceAlertMails : false,rules:"",name: "manager resource alert mails",error: ""},
        smsAlertMails: {value: formData?.smsAlertMails ? formData?.smsAlertMails : false,rules:"",name: "sms alert mails",error: ""},
        deactivateProfile: {value: formData?.deactivateProfile ? formData?.deactivateProfile : '',rules:"required",name: "days",error: ""},
        profileDeleteReasons: {value: formData?.profileDeleteReasons ? formData?.profileDeleteReasons : 1,rules:"",name: "profile delete reasons",error: ""},
        profileDeleteOtherReasons: {value: formData?.profileDeleteOtherReasons ? formData?.profileDeleteOtherReasons : '',rules:"",name: "profile delete other reasons",error: ""},
        deleteReasons: {value: formData?.deleteReasons ? formData?.deleteReasons : '',rules:"",name: "delete reason",error: ""},
        email: {value: userDetails?.email ? userDetails.email : '',rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "email",error: ""},
        currentPassword: {value: formData?.currentPassword ? formData?.currentPassword : '',rules:"required| regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "current password",error: ""},
        newPassword: {value: formData?.newPassword ? formData?.newPassword : '',rules:"required| regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "new password",error: ""},
        confirmNewPassword: {value: formData?.confirmNewPassword ? formData?.confirmNewPassword : '',rules:"",name: "confirm new password",error: ""}
    });
    const handleClick = (value) => {
        navigate(`/settings?tab=${value}`)
        setActiveClass(value);
        setForm({
            matchAlertMails: {value: formData?.matchAlertMails ? formData?.matchAlertMails : false,rules:"",name: "match alert mails",error: ""},
            memberAlertMails: {value: formData?.memberAlertMails ? formData?.memberAlertMails : false,rules:"",name: "member alert mails",error: ""},
            managerResourceAlertMails: {value: formData?.managerResourceAlertMails ? formData?.managerResourceAlertMails : false,rules:"",name: "manager resource alert mails",error: ""},
            smsAlertMails: {value: formData?.smsAlertMails ? formData?.smsAlertMails : false,rules:"",name: "sms alert mails",error: ""},
            deactivateProfile: {value: formData?.deactivateProfile ? formData?.deactivateProfile : '',rules:"required",name: "days",error: ""},
            profileDeleteReasons: {value: formData?.profileDeleteReasons ? formData?.profileDeleteReasons : 1,rules:"",name: "profile delete reasons",error: ""},
            profileDeleteOtherReasons: {value: formData?.profileDeleteOtherReasons ? formData?.profileDeleteOtherReasons : '',rules:"",name: "profile delete other reasons",error: ""},
            deleteReasons: {value: formData?.deleteReasons ? formData?.deleteReasons : '',rules:"",name: "delete reason",error: ""},
            email: {value: userDetails?.email ? userDetails.email : '',rules:"required | regex: \\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+",name: "email",error: ""},
            currentPassword: {value: formData?.currentPassword ? formData?.currentPassword : '',rules:"required| regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "current password",error: ""},
            newPassword: {value: formData?.newPassword ? formData?.newPassword : '',rules:"required| regex: ^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$ | min:6",name: "new password",error: ""},
            confirmNewPassword: {value: formData?.confirmNewPassword ? formData?.confirmNewPassword : '',rules:"",name: "confirm new password",error: ""}
        })
    };
    const handleReset = () => {
    };
    const handleSubmit = (value) => {
        setForm(prev => ({
            ...prev,
            ...value
        }));
    };
    useEffect(() => {
        if(!(queryParams.get('tab'))){
            navigate(`/settings?tab=alerts`)
        }
        const tabNames = {
            alerts: 'Alerts & Update',
            'transaction-history': 'Transaction History',
            'deactive-profile': 'Deactive Profile',
            'delete-profile': 'Delete Profile',
            email: 'Edit Email Address',
            password: 'Change Password'
        };

        const defaultTab = 'alerts';
        const tabName = tabNames[queryParams.get('tab') || 'alerts'] || tabNames[defaultTab];
        setName(tabName);
        setActiveClass(queryParams.get('tab'))
    }, [activeClass, location.search]);

    return(
        <div className="lg:w-full xl:max-w-[1140px] lg:max-w-[960px] max-w-full mx-auto xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px]">
            <div className="flex md:flex-row flex-col px-[15px]">
                <div className="xl:max-w-[350px] md:max-w-[270px] w-full">
                    <h2 className="text-[30px] mb-[12px] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular leading-[1.2]">Settings</h2>
                    <div className="bg-[#ead7ab] overflow-auto md:block flex rounded-[12px] md:px-[20px] py-[10px] px-[5px]"> 
                        <Button
                            class={`${activeClass === 'alerts' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Alerts & Update'}
                            onClick={() => handleClick('alerts')}
                        />
                        <Button
                            class={`${activeClass === 'transaction-history' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Transaction History'}
                            onClick={() => handleClick('transaction-history')}
                        />
                        <Button
                            class={`${activeClass === 'deactive-profile' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Deactive Profile'}
                            onClick={() => handleClick('deactive-profile')}
                        />
                        <Button
                            class={`${activeClass === 'delete-profile' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Delete Profile'}
                            onClick={() => handleClick('delete-profile')}
                        />
                        <Button
                            class={`${activeClass === 'email' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Edit Email Address'}
                            onClick={() => handleClick('email')}
                        />
                        <Button
                            class={`${activeClass === 'password' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Change Password'}
                            onClick={() => handleClick('password')}
                        />
                        {/* <Button
                            class={`${activeClass === 'Add Testimonials' ? 'bg-[#ed1c24] text-white' : 'bg-[#f6ecc8] text-[#931217]'} md:min-w-[auto] min-w-[200px] hover:bg-[#ed1c24] hover:text-white w-full text-left leading-[normal] rounded-[10px] md:my-[10px] md:mx-0 mx-[5px] h-[54px] md:px-[20px] text-[18px] font-family-Quicksand-Bold md:block flex items-center justify-center`}
                            buttonName={'Add Testimonials'}
                            onClick={() => handleClick('Add Testimonials')}
                        /> */}
                    </div>
                </div>
                <div className="xl:max-w-[760px] md:max-w-[calc(100%-270px)] max-w-[760px] w-full md:pl-[30px] md:mt-0 mt-[20px]">
                    <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[43px] md:text-[34px] text-[30px] leading-[1.2] mb-[18px]">{name}</h2>
                    {
                        activeClass === 'alerts' ? 
                            <Alerts 
                                form={form}
                                resetForm={handleReset}
                                submitForm={handleSubmit}
                                userDetails={userDetails}
                            /> :
                        activeClass === 'transaction-history' ?
                            <TransactionHistory />:
                        activeClass === 'deactive-profile' ?
                            <DeactiveProfile
                                form={form}
                                resetForm={handleReset}
                                submitForm={handleSubmit}
                            />:
                        activeClass === 'delete-profile' ?
                            <DeleteProfile
                                form={form}
                                resetForm={handleReset}
                                submitForm={handleSubmit}
                            />:
                        activeClass === 'email' ?
                            <EditEmail 
                                form={form}
                                resetForm={handleReset}
                                submitForm={handleSubmit}
                                userDetails={userDetails}
                            />:
                        activeClass === 'password' ?
                            <ChangePassword 
                                form={form}
                                resetForm={handleReset}
                                submitForm={handleSubmit}
                                userDetails={userDetails}
                            />:
                        // activeClass === 'Add Testimonials' ?
                        //     <AddTestimonials/>
                        //     :
                        null
                    }
                </div>
            </div>
        </div>
    )
};

export default Settings;