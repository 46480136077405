import moment from 'moment';
import { useSelector } from 'react-redux';
import Button from '../../../atom/button.jsx';
import DropDown from '../../../atom/dropDown.jsx';
import React, { useEffect, useRef, useState } from 'react';
import CustomInput from "../../../molecules/customInput.jsx";
import useValidation from "../../../../utils/validation.js";
import allCountries from "../../../atom/countryCode/countryCode.jsx";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import TimePicker from 'rc-time-picker';
import RadioButton from '../../../molecules/radio/radio.jsx';
import CheckBox from '../../../molecules/checkBox/checkBox.jsx';
const Step1 = ({ form,nextStep,userProfileStatus }) => {
    //useSelecter
    const timePickerRef = useRef(null);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const [formData,setFormData] = useState({
        alternateMobile1:form.alternateMobile1,
        alternateMobile2:form.alternateMobile2,
        horoscopeDateOfBirth:form.horoscopeDateOfBirth,
        basicMotherTongue:form.basicMotherTongue,
        basicReligion:form.basicReligion,
        horoscopeBelieveInHoroscope:form.horoscopeBelieveInHoroscope,
        horoscopeMatch:form.horoscopeMatch,
        horoscopeCityOfBirth:form.horoscopeCityOfBirth,
        horoscopeStateOfBirth:form.horoscopeStateOfBirth,
        horoscopeTimeOfBirth:form.horoscopeTimeOfBirth,
        basicCaste:form.basicCaste,
        basicGor:form.basicGor,
        horoscopeManglik:form.horoscopeManglik,
        basicWillingToMarry:form.basicWillingToMarry
    });
    // validation
    const  { checkErrors,checkAllFields } = useValidation();
    const [isInputFocused, setIsInputFocused] = useState(true);
    //useRef
    const dropdownRef = useRef(null);
    const selectDropDown = useRef(null);
    const dropdownRef2 = useRef(null);
    const selectDropDown2 = useRef(null);
    const typingTimeoutRef = useRef(null);
    //useState
    const [isOpen,setIsOpen] = useState(false);
    const [isOpen2,setIsOpen2] = useState(false);
    const [typedCharacters, setTypedCharacters] = useState('');
    const [countryArray,setCountryArray] = useState(allCountries);
    const [countryArray2,setCountryArray2] = useState(allCountries);
    const [countryObject,setCountryObject] = useState(formData?.alternateMobile1?.countryCode ? countryArray.find(e => e.dialCode === formData?.alternateMobile1?.countryCode) : {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });
    const [countryObject2,setCountryObject2] = useState(formData?.alternateMobile2?.countryCode ? countryArray2.find(e => e.dialCode === formData?.alternateMobile2?.countryCode) :  {
        "ru": "Индия",
        "lt": "Indija",
        "tr": "Hindistan",
        "en": "India",
        "flag": "🇮🇳",
        "code": "IN",
        "dialCode": "+91",
        "mask": "99999 99999",
        "maskWithDialCode": "##### #####",
        "name": "+91",
        "key": 96,
        "dialCodeWithOutPlusSign": "91",
        "countryJoin": "India"
    });

    //function
    const handleToggleCountryCode = () => {
        setIsOpen(!isOpen);
        setCountryArray(allCountries);
        setIsInputFocused(true);
    };
    const handleInputChange = (e) => {
        setCountryArray(allCountries);
        setCountryArray(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleInputChange2 = (e) => {
        setCountryArray2(allCountries);
        setCountryArray2(prevCountryArray => {
            let country = prevCountryArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase()) || option.countryJoin.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return country;
        });
    };
    const handleCountryCode = (ele) => {
        setCountryObject({...ele});
        setIsOpen(false);
        setFormData({ ...formData, alternateMobile1: {...formData.alternateMobile1,countryCode:ele.dialCode}});
    };
    const handleToggleCountryCode2 = () => {
        setIsOpen2(!isOpen2);
        setCountryArray2(allCountries);
        setIsInputFocused(true);
    };
    const handleCountryCode2 = (ele) => {
        setCountryObject2({...ele});
        setIsOpen2(false);
        setFormData({ ...formData, alternateMobile2: {...formData.alternateMobile2,countryCode:ele.dialCode}});
    };
    const handleCheck = () => {
        checkAllFields(formData).then((valid)=>{
            setFormData({...formData});
            if(valid){
                nextStep(formData);
            }
        });
    };
    const handleSelect = (e,id) => {
        if(id === 'birth'){
            checkErrors({
                'field': formData.horoscopeDateOfBirth,
                'name': 'date of birth',
                'validations': formData.horoscopeDateOfBirth.rules,                
                'value': e[0]
            });
            setFormData({ ...formData, horoscopeDateOfBirth: {...formData.horoscopeDateOfBirth,value:moment(e[0]).format('YYYY-MM-DD')} });
        }else if(id === 'motherTongue'){
            checkErrors({
                'field': formData.basicMotherTongue,
                'name': 'mother tongue',
                'validations': formData.basicMotherTongue.rules,
                'value': e.key
            });
            setFormData({ ...formData, basicMotherTongue: {...formData.basicMotherTongue ,value:e.key} })
        }else if(id === 'religion'){
            checkErrors({
                'field': formData.basicReligion,
                'name': 'religion',
                'validations': formData.basicReligion.rules,
                'value': e.key
            });
            setFormData({ ...formData, basicReligion: {...formData.basicReligion ,value:e.key} })
        }else if(id === 'horoscopeBelieveInHoroscope'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.horoscopeBelieveInHoroscope,
                'name': 'believe',
                'validations': formData.horoscopeBelieveInHoroscope.rules,                
                'value': values
            });
            setFormData({ ...formData, horoscopeBelieveInHoroscope: {...formData.horoscopeBelieveInHoroscope ,value:values}});
        }else if(id === 'horoscopeMatch'){
            const values = e.value ? e.value.split('+')[0] : '';
            checkErrors({
                'field': formData.horoscopeMatch,
                'name': 'match',
                'validations': formData.horoscopeMatch.rules,                
                'value': values
            });
            setFormData({ ...formData, horoscopeMatch: {...formData.horoscopeMatch ,value:values}});
        }else if(id === 'horoscopeStateOfBirth'){
            checkErrors({
                'field': formData.horoscopeStateOfBirth,
                'name': 'state of birth',
                'validations': formData.horoscopeStateOfBirth.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, horoscopeStateOfBirth: {...formData.horoscopeStateOfBirth,value:e.target.value} });
        }else if(id === 'horoscopeCityOfBirth'){
            checkErrors({
                'field': formData.horoscopeCityOfBirth,
                'name': 'city of birth',
                'validations': formData.horoscopeCityOfBirth.rules,                
                'value': e.target.value
            });
            setFormData({ ...formData, horoscopeCityOfBirth: {...formData.horoscopeCityOfBirth,value:e.target.value} });
        }else if(id === 'horoscopeTimeOfBirth'){
            checkErrors({
                'field': formData.horoscopeTimeOfBirth,
                'name': 'time of birth',
                'validations': formData.horoscopeTimeOfBirth.rules,                
                'value': e?.format('HH:mm') || ''
            });
            setFormData({ ...formData, horoscopeTimeOfBirth: {...formData.horoscopeTimeOfBirth,value:e?.format('HH:mm') || ''} });
            if (timePickerRef?.current) {
                timePickerRef?.current.onVisibleChange(false);
            }
        }else if(id === 'caste'){
            checkErrors({
                'field': formData.basicCaste,
                'name': 'caste',
                'validations': formData.basicCaste.rules,                
                'value': e.key
            });
            setFormData({ ...formData, basicCaste: {...formData.basicCaste,value:e.key} });
        }else if(id === 'manglik'){
            checkErrors({
                'field': formData.horoscopeManglik,
                'name': 'manglik',
                'validations': formData.horoscopeManglik.rules,                
                'value': e.key
            });
            setFormData({ ...formData, horoscopeManglik: {...formData.horoscopeManglik,value:e.key} });
        }else if(id === 'gor'){
            checkErrors({
                'field': formData.basicGor,
                'name': 'gor',
                'validations': formData.basicGor.rules,                
                'value': e?.target?.value?.trim()
            });
            setFormData({ ...formData, basicGor: {...formData.basicGor,value:e?.target?.value?.trim()} });
        }else if(id === 'basicWillingToMarry'){
            setFormData({ ...formData, basicWillingToMarry: {...formData.basicWillingToMarry,value:!e.value} });
        }
    };
    const handleOutsideClick = (event) => {
        if (selectDropDown.current && !selectDropDown.current.contains(event.target) && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleOutsideClick2 = (event) => {
        if (selectDropDown2.current && !selectDropDown2.current.contains(event.target) && dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setIsOpen2(false);
        }
    };

    const handleKeyDown = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    const handleKeyDown2 = (event) => {
        if (!isInputFocused) return;
        // Prevent the default behavior that might cause outer scroll
        event.preventDefault();
    
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }
    
        setTypedCharacters(prev => prev + event.key);
    
        const matchIndex = countryArray.findIndex((ele) => 
            ele.dialCodeWithOutPlusSign.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.name.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase()) || ele.countryJoin.toLowerCase().startsWith((typedCharacters + event.key).toLowerCase())
        );
    
        if (matchIndex !== -1) {
            const matchElement = dropdownRef2.current.querySelectorAll('li')[matchIndex];    
            if (matchElement) {
                const dropdown = dropdownRef2.current;
                const dropdownRect = dropdown.getBoundingClientRect();
                const matchRect = matchElement.getBoundingClientRect();
    
                // Calculate the new scrollTop to center the matched item
                const offset = matchRect.top - dropdownRect.top - (dropdownRect.height / 2) + (matchRect.height / 2);
                dropdown.scrollTop += offset;
            }
        }
    
        typingTimeoutRef.current = setTimeout(() => {
            setTypedCharacters('');
        }, 1000);
    };
    // useEffect
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick2);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick2);
        };
    }, []);
    useEffect(() => {
        setFormData({
            alternateMobile1:form.alternateMobile1,
            alternateMobile2:form.alternateMobile2,
            horoscopeDateOfBirth:form.horoscopeDateOfBirth,
            basicMotherTongue:form.basicMotherTongue,
            basicReligion:form.basicReligion,
            horoscopeBelieveInHoroscope:form.horoscopeBelieveInHoroscope,
            horoscopeMatch:form.horoscopeMatch,
            horoscopeCityOfBirth:form.horoscopeCityOfBirth,
            horoscopeStateOfBirth:form.horoscopeStateOfBirth,
            horoscopeTimeOfBirth:form.horoscopeTimeOfBirth,
            basicCaste:form.basicCaste,
            basicGor:form.basicGor,
            horoscopeManglik:form.horoscopeManglik,
            basicWillingToMarry:form.basicWillingToMarry
        });
    },[form]);
    useEffect(() => {
        if (isOpen) {
            dropdownRef.current.focus();
        }
    }, [isOpen]);
    useEffect(() => {
        if (isOpen2) {
            dropdownRef2.current.focus();
        }
    }, [isOpen2]);
    const getInitialTimes = (timeString) => {
        return timeString ? moment(timeString, 'HH:mm') : null;
    };
    return (
        <>
            <p className='lg:col-span-2 font-family-Quicksand-Medium text-[16px] text-[#262626] text-center text-stroke-2 mb-[20px]'>Please provide us with your basic details</p>
            <div className='lg:pr-[15px] mb-4'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Date of Birth *</label>
                <Flatpickr
                    options={{
                        maxDate: userProfileStatus?.gender === 2 ? moment(moment().subtract(18, 'years').toDate()).format('YYYY-MM-DD') : moment(moment().subtract(21, 'years').toDate()).format('YYYY-MM-DD'),
                        enableTime: false,
                        dateFormat: 'd-m-Y', // Format to display date and time
                        static: true
                    }}
                    value={formData?.horoscopeDateOfBirth?.value ? moment(formData.horoscopeDateOfBirth.value).format('DD-MM-YYYY') : ''}
                    placeholder="DD-MM-YYYY"
                    onChange={date => handleSelect(date,'birth')}
                    className='custom-date-container w-full bg-[transparent] px-[16px] py-[5px] h-[41px] z-[1] cursor-pointer'
                />
                {formData?.horoscopeDateOfBirth?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.horoscopeDateOfBirth.error}</small>
                }
            </div>
            <div className="mb-4 lg:pl-[15px]">
                <label className='text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'>Time of Birth</label>
                <TimePicker
                    ref={timePickerRef}
                    showSecond={false}
                    defaultValue={getInitialTimes(formData?.horoscopeTimeOfBirth?.value)}
                    onChange={(value) => handleSelect(value,'horoscopeTimeOfBirth')}
                    format="HH:mm"
                    placeholder="HH:mm"
                    use12Hours={false}
                    className="w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[6px] py-[5px] h-[41px] rounded-[10px]"
                />
            </div>
            <div className='mb-4 lg:pr-[15px]'>
                <CustomInput
                    placeHolder="Enter State of Birth"
                    label="State of Birth"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.horoscopeStateOfBirth?.value ? formData?.horoscopeStateOfBirth?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'horoscopeStateOfBirth')}
                />
            </div>
            <div className='mb-4 lg:pl-[15px]'>
                <CustomInput
                    placeHolder="Enter City of Birth"
                    label="City of Birth"
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.horoscopeCityOfBirth?.value ? formData?.horoscopeCityOfBirth?.value : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'horoscopeCityOfBirth')}
                />
            </div>
            <div className='mb-4 lg:pr-[15px]'>
                <DropDown
                    options={dropDown?.['religion']?.option}
                    onUpdate={(e) => handleSelect(e,'religion')}
                    multiSelect={false}
                    selectedValue={formData?.basicReligion?.value ? [formData?.basicReligion?.value] : []}
                    labelName={'Religion *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.basicReligion?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicReligion.error}</small>
                }
            </div>
            <div className='mb-4 lg:pl-[15px]'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['caste']?.option}
                    onUpdate={(e) => handleSelect(e,'caste')}
                    multiSelect={false}
                    selectedValue={formData?.basicCaste?.value ? [formData?.basicCaste?.value] : ''}
                    labelName={'Caste *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.basicCaste?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicCaste.error}</small>
                }
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <DropDown
                    isInputSearch={true}
                    options={dropDown?.['motherTongue']?.option}
                    onUpdate={(e) => handleSelect(e,'motherTongue')}
                    multiSelect={false}
                    selectedValue={formData?.basicMotherTongue?.value ? [formData?.basicMotherTongue?.value] : []}
                    labelName={'Mother Tongue *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.basicMotherTongue?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicMotherTongue.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4 flex items-center'>
                <CheckBox
                    modelValue={formData?.basicWillingToMarry?.value}
                    isDisabled={false}
                    isReadonly={false}
                    label={'Willing to marry from other communities also'}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onClick={(value) => handleSelect(value,'basicWillingToMarry')}
                    inputClass={'xms:w-[20px] w-[35px] inline min-h-[20px] cursor-pointer mr-[10px]'}
                    labelClass={'leading-[normal] cursor-pointer font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                    checked={formData?.basicWillingToMarry?.value}
                    isCheckBy={true}
                />
            </div>
            <div className='lg:pr-[15px] mb-4'>
                <CustomInput
                    label="Gor *"
                    placeHolder={'Enter Gor'}
                    inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[12px] py-[5px] h-[41px] rounded-[10px]'}
                    class={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    type="text"
                    modelValue={formData?.basicGor?.value ? formData?.basicGor?.value?.trim() : ''}
                    isDisabled={false}
                    isReadonly={false}
                    isDirectFocus={false}
                    isRequired={false}
                    isOutline={true}
                    onChange={(_, e) => handleSelect(e,'gor')}
                />
                {formData?.basicGor?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.basicGor.error}</small>
                }
            </div>
            <div className='lg:pl-[15px] mb-4'>
                {/* <div className="grid lg:grid-cols-3 grid-cols-1">
                    {dropDown?.['manglik']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+manglik`}
                                modelValue={`${option.key}+manglik`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="manglik"
                                onClick={(value) => handleSelect(value, 'manglik')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={`${formData?.horoscopeManglik?.value}+manglik` === `${option.key}+manglik` || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div> */}
                <DropDown
                    options={dropDown?.['manglik']?.option}
                    onUpdate={(e) => handleSelect(e,'manglik')}
                    multiSelect={false}
                    selectedValue={formData?.horoscopeManglik?.value ? [formData?.horoscopeManglik?.value] : ''}
                    labelName={'Manglik *'}
                    labelClass={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}
                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[10px] px-[12px] py-[6px] h-[41px]'}
                    wrapperClass={'relative'}
                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                    optionsWrapper={'bg-white'}
                />
                {formData?.horoscopeManglik?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.horoscopeManglik.error}</small>
                }
            </div>
            <div className='mb-4 lg:pr-[15px]'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Horoscope Match is Neccessary?</label>
                <div className="grid lg:grid-cols-3 grid-cols-1">
                    {dropDown?.['yesNo']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+horoscopeMatch`}
                                modelValue={`${option.key}+horoscopeMatch`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="horoscopeMatch"
                                onClick={(value) => handleSelect(value, 'horoscopeMatch')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={`${formData?.horoscopeMatch?.value}+horoscopeMatch` === `${option.key}+horoscopeMatch` || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='mb-4 lg:pl-[15px]'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Believe in Horoscope?</label>
                <div className="grid lg:grid-cols-3 grid-cols-1">
                    {dropDown?.['yesNo']?.option.map((option, index) => (
                        <div key={index}>
                            <RadioButton
                                inputId={`${option.key}+believeInHoroscope`}
                                modelValue={`${option.key}+believeInHoroscope`}
                                isDisabled={false}
                                isReadonly={false}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                name="believeInHoroscope"
                                onClick={(value) => handleSelect(value, 'horoscopeBelieveInHoroscope')}
                                label={option.name}
                                class={'mt-[6px] ml-[10px] mb-[6px] leading-[normal] font-family-Quicksand-Medium text-[16px] text-[#262626] text-stroke'}
                                checked={`${formData?.horoscopeBelieveInHoroscope?.value}+believeInHoroscope` === `${option.key}+believeInHoroscope` || false}
                                inputClass={`w-[20px] inline min-h-[30px] cursor-pointer`}
                                radioClassWrapper={'lg:mt-[5px] lg:mb-[2px]'}
                            />
                        </div>
                    ))}
                </div>
            </div>
            <div className='lg:pr-[15px] lg:mb-0 mb-4'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Alternate Mobile No. 1</label>
                <div className="flex relative">
                    <div ref={selectDropDown} className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => { event.preventDefault(); handleToggleCountryCode(); }}>
                        {countryObject.name}
                        <span className="dropDown_arrow"></span>
                    </div>
                    {
                        isOpen ?
                            <div ref={dropdownRef} onKeyDown={handleKeyDown} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange} placeholder="Search..." />
                                <ul>
                                    {countryArray.length ? countryArray.map((ele, index) =>
                                        <li className={`${countryObject?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => { event.preventDefault(); handleCountryCode(ele); }}>
                                            {ele.name} {ele?.countryJoin}
                                        </li>
                                    ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                </ul>
                            </div>
                            : null
                    }
                    <CustomInput
                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[41px]'}
                        type="number"
                        modelValue={formData?.alternateMobile1?.value ? formData.alternateMobile1.value : ''}
                        placeHolder="Enter mobile no"
                        isDisabled={false}
                        isReadonly={false}
                        isDirectFocus={false}
                        isRequired={false}
                        isOutline={true}
                        onChange={(value, event) => {
                            formData.alternateMobile1.value = value;
                            if (formData.alternateMobile1.value) {
                                formData.alternateMobile1.rules = `min:7|max:15|regex:^[0-9]+$`;
                            } else {
                                formData.alternateMobile1.rules = '';
                                formData.alternateMobile1.error = '';
                            }
                            checkErrors({
                                'field': formData.alternateMobile1,
                                'name': 'alternate mobile 1',
                                'validations': formData.alternateMobile1.rules,
                                'type': formData.alternateMobile1.type,
                                'event': event,
                                'value': value
                            });
                            setFormData({ ...formData });
                        }}
                        onKeyPress={(event) => {
                            if (event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E') {
                                event.event.preventDefault();
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown') {
                                event.event.preventDefault();
                            }
                        }}
                    />
                </div>
                {formData?.alternateMobile1?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.alternateMobile1.error}</small>
                }
            </div>
            <div className='lg:pl-[15px]'>
                <label className={'text-[#931217] text-stroke-2 text-[18px] font-family-Quicksand-Bold block leading-[normal] mb-[6px]'}>Alternate Mobile No. 2</label>
                <div ref={selectDropDown2} className="flex relative">
                    <div className="cursor-pointer flex items-center rounded-tl-[8px] px-[10px] bg-[#f0eeee] rounded-bl-[8px]" onClick={(event) => { event.preventDefault(); handleToggleCountryCode2(); }}>
                        {countryObject2.name}
                        <span className="dropDown_arrow"></span>
                    </div>
                    {
                        isOpen2 ?
                            <div ref={dropdownRef2} onKeyDown={handleKeyDown2} className="focus_dropdown bg-white top-[42px] absolute z-10 w-full rounded-[12px] max-h-[240px] overflow-x-auto">
                                <input onFocus={() => setIsInputFocused(false)} onBlur={() => setIsInputFocused(true)} type='text' className='w-[-webkit-fill-available] mt-[20px] border-[1px] mx-[20px] mb-[10px] rounded-[20px] px-[10px]' onChange={handleInputChange2} placeholder="Search..." />
                                <ul>
                                    {countryArray2.length ? countryArray2.map((ele, index) =>
                                        <li className={`${countryObject2?.en === ele?.en ? 'bg-[#f6ecc8]' : ''} cursor-pointer hover:bg-[#f6ecc8] px-[20px] py-[5px] text-[18px] font-family-Quicksand-Regular color-[262626]`} key={index} onClick={(event) => { event.preventDefault(); handleCountryCode2(ele); }}>
                                            {ele.name} {ele?.countryJoin}
                                        </li>
                                    ) : <li className='px-[20px] py-[5px]'>No data found</li>}
                                </ul>
                            </div>
                            : null
                    }
                    <CustomInput
                        inputClass={'w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular border-radius px-[16px] py-[5px] h-[41px]'}
                        type="number"
                        modelValue={formData?.alternateMobile2?.value ? formData.alternateMobile2.value : ''}
                        placeHolder="Enter mobile no"
                        isDisabled={false}
                        isReadonly={false}
                        isDirectFocus={false}
                        isRequired={false}
                        isOutline={true}
                        onChange={(value, event) => {
                            formData.alternateMobile2.value = value;
                            if (formData.alternateMobile2.value) {
                                formData.alternateMobile2.rules = `min:7|max:15|regex:^[0-9]+$`;
                            } else {
                                formData.alternateMobile2.rules = '';
                                formData.alternateMobile2.error = '';
                            }
                            checkErrors({
                                'field': formData.alternateMobile2,
                                'name': 'alternate mobile 2',
                                'validations': formData.alternateMobile2.rules,
                                'type': formData.alternateMobile2.type,
                                'event': event,
                                'value': value
                            });
                            setFormData({ ...formData });
                        }}
                        onKeyPress={(event) => {
                            if (event.event.charCode === 45 || event.event.charCode === 43 || event.event.charCode === 46 || event.event.key === 'e' || event.event.key === 'E') {
                                event.event.preventDefault();
                            }
                        }}
                        onKeyDown={(event) => {
                            if (event.event.key === 'ArrowUp' || event.event.key === 'ArrowDown') {
                                event.event.preventDefault();
                            }
                        }}
                    />
                </div>
                {formData?.alternateMobile2?.error &&
                    <small className="leading-[normal] text-[#ff0000] text-[16px] font-family-Quicksand-Bold block">{formData.alternateMobile2.error}</small>
                }
            </div>
            <div className='lg:col-span-2 mt-4 lg:mt-[46px] lg:mb-[8px]'>
                <Button
                    onClick={handleCheck} 
                    buttonName={'Continue'}
                    class={'block leading-[normal] mx-auto bg-[#ed1c24] rounded-[50px] text-[white] font-family-Quicksand-Medium text-[18px] py-[10px] px-[30px]'}
                />
            </div>
        </>
    );
};

export default Step1;