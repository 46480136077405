module.exports.LIMIT = 20;
module.exports.API_URI = process.env.REACT_APP_APIURL;
module.exports.PAYMENTID = process.env.REACT_APP_PAYMENTID;
module.exports.GOOGLEAPIKEY = 'AIzaSyBK50nwI5Spu_S3dgn6fXCp-lR8ue_ouo4';

module.exports.GENERATETOKEN =  "api/v1/genrateToken";

// API's
module.exports.LOGIN = "api/v1/login";
module.exports.USER = "api/v1/getUser";
module.exports.LOGOUT = "api/v1/logout";
module.exports.GETPLAN = "api/v1/getPlan";
module.exports.SENDOTP = "api/v1/sendOtp";
module.exports.VERIFYOTP = "api/v1/verifyOtp";
module.exports.EDITEMAIL = "api/v1/editEmail";
module.exports.REGISTER = "api/v1/registerUser";
module.exports.MYMATCHES = "api/v1/getMyMatches";
module.exports.GETDROPDOWN = "api/v1/getDropDown";
module.exports.MYLIKEPROFILE = "api/v1/likeAction";
module.exports.BASICSEARCH = "api/v1/advanceSearch";
module.exports.BASICSEARCH2 = "api/v2/advanceSearch";
module.exports.GETDASHBOARD = "api/v1/getDashboard";
module.exports.DELETEPROFILE = "api/v1/deleteProfile";
module.exports.RESETPASSWORD = "api/v1/resetPassword";
module.exports.GETTWOWAYMATCH = "api/v1/get2wayMatch";
module.exports.UNLOCKPROFILE = "api/v1/unlockProfile";
module.exports.GETTRANSACTION = "api/v1/getTransaction";
module.exports.CHANGEPASSWORD = "api/v1/changePassword";
module.exports.FORGOTPASSWORD = "api/v1/forgotPassword";
module.exports.GETMYLIKEPROFILE = "api/v1/myLikesProfile";
module.exports.EDITUSERPROFILE = "api/v1/editUserProfile";
module.exports.ACTIVEPROFILE = "api/v1/activateUserProfile";
module.exports.DEACTIVEPROFILE = "api/v1/deactivateProfile";
module.exports.DELETESAVESEARCH = "api/v1/deleteSaveSearch";
module.exports.GETMUTUALMATCHES = "api/v1/getMutualMatches";
module.exports.PROFILEREVIEW = "api/v1/getUserProfileReview";
module.exports.GETPROFILEDETAIL =  "api/v1/getProfileDetail";
module.exports.GETUSERIDFROMRMID = "api/v1/getUserIdFromRmid";
module.exports.GETALERTANDUPDATE = "api/v1/getAlertAndUpdate";
module.exports.SETALERTANDUPDATE = "api/v1/setAlertAndUpdate";
module.exports.SETCANDIDATEPHOTOS = "api/v1/setcandidatePhoto";
module.exports.GETFEATURECANDIDATE = "api/v1/featureCandidate";
module.exports.GETESTIMATEPLANDATE = "api/v1/estimatePlanDate";
module.exports.SETPROFILEREVIEW = "api/v1/setUserProfileReview";
module.exports.SETPROFILEREVIEW2 = "api/v2/setUserProfileReview";
module.exports.SETPROFILEREVIEW3 = "api/v3/setUserProfileReview";
module.exports.CREATEPAYMENTORDER = "api/v1/createPaymentOrder";
module.exports.SUCCESSPAYMENTORDER = "api/v1/successPaymentOrder";
module.exports.GETMYMATCHESFILTER = "api/v1/getFilterByMyMatches";
module.exports.APPROVEDPROFILEREVIEW = "api/v1/approveUserProfile";
module.exports.EDITPHONEOREMAIL = "api/v1/editEmailAndPhoneAtVerify";
module.exports.SETPARTNERPREFERENCE = "api/v1/setPartnerPreference";
module.exports.SETPARTNERPREFERENCE2 = "api/v2/setPartnerPreference";
module.exports.GETPARTNERPREFERENCE = "api/v1/getPartnerPreference";
module.exports.VALIDATERESETPASSWORD = "api/v1/validateResetPassword";
module.exports.GETLISTOFMYSAVEDSEARCH = "api/v1/getListOfMySavedSearch";
module.exports.GETSEARCHFROMSAVEDSEARCH = "api/v1/getSearchFromSavedSearch";
module.exports.GETRECENTLYVIEWEDPROFILE = "api/v1/getRecentlyViewedProfile";
module.exports.GETRECOMMENDATIONBYRELATIONSHIPMANAGER = "api/v1/getRecommendationByRelationshipManager";