import axios from 'axios';
import Cookies from 'js-cookie';
import * as env from '../config/env.js';

const apiHost = env.API_URI;
export const axiosInstance = axios.create({ baseURL: apiHost });
export const axiosInstanceWithFormData = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem('token') || req?.data?.jwtToken || "";
    const storedSessionId = Cookies.get('sessionId') || req?.data?.sessionId || "";
    req.headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'sessionid':storedSessionId
    };
    return req;
}, error => {
    return Promise.reject(error);
});
axiosInstanceWithFormData.interceptors.request.use((req) => {
    const token = localStorage.getItem('token') || "";
    const storedSessionId = Cookies.get('sessionId') || "";
    req.headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'sessionid':storedSessionId
    };
    return req;
}, error => {
    return Promise.reject(error);
});

export const getAuth = async (id, isFirst) => {
    try {
        const storedSessionId = Cookies.get('sessionId') || "";
        if(!storedSessionId){
            localStorage.removeItem('token');
            localStorage.removeItem('refereshToken');
            localStorage.removeItem('userId');
            Cookies.remove('sessionId');
            window.location.reload();
            return null;
        }else{
            const data = {
                refreshToken: id
            };
            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'sessionid':storedSessionId
            };
            const url = env.GENERATETOKEN;

            const response = await axios.post(apiHost + url, data, { headers });
            localStorage.removeItem('token');
            localStorage.setItem('token',response.data.jwtToken);
            return response.data;
        }
    } catch (error) {
        console.error('error', error.response.data);
        throw error.response.data;
    }
};

export const apiRequest = async (type, endPoint, data = {},files) => {
    try {
        let response;
        if (type === 'post' || type === 'patch' || type === 'delete') {
            if(files && files === "files"){
                response = await axiosInstanceWithFormData[type](endPoint, data);
            }else{
                response = await axiosInstance[type](endPoint, data);
            }
        } else if (type === 'get') {
            response = await axiosInstance.get(endPoint, { params: data });
        } else {
            throw new Error(`Unsupported request type: ${type}`);
        }
        return response;
    } catch (err) {
        if (err?.response?.data?.isJwtExpired) {
            const refreshToken = localStorage.getItem('refreshToken') || "";
            await getAuth(refreshToken, !!refreshToken);
            return apiRequest(type, endPoint, data);
        } else if(err?.response?.data?.isJwtExpired === false || err?.response?.data?.isSessionError || err?.response?.data?.isJwtError) {
            let logoutObject = {
                sessionId:Cookies.get('sessionId')
            }
            apiRequest("post", env.LOGOUT, logoutObject).then(async(response) => {
                if(response.status === 200){
                    localStorage.removeItem('token');
                    localStorage.removeItem('refereshToken');
                    localStorage.removeItem('userId');
                    Cookies.remove('sessionId');
                    window.location.reload();
                    console.error('API request error:', err);
                    throw err;
                }
            }).catch(async(error) => {
                localStorage.removeItem('token');
                localStorage.removeItem('refereshToken');
                localStorage.removeItem('userId');
                Cookies.remove('sessionId');
                window.location.reload();
                console.error('API request error:', error);
                throw err;
            });
        }else{
            console.log('Error',err);
            throw err;
        }
    }
};