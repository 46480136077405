const ViewProfileComp = ({viewProfileDetail,title,classWrapper,fieldClass,children,valueClass}) => {
    return(
        <div>
            <h3 className="word-break-[break-word] text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular md:text-[30px] text-[24px] mb-[10px] leading-[1.2] sm:text-left text-center">{title}</h3>
            <div className={classWrapper}>
                {children ? children : 
                    viewProfileDetail && viewProfileDetail.length ? viewProfileDetail.map((e,index) => (
                        <div key={index} className={fieldClass}>
                            {e.name ? <h5 className="font-family-Quicksand-Bold text-[16px] text-[#931217] mb-[3px] leading-[normal] word-break-[break-word]">{e.name}</h5> : null}
                            <p className={valueClass}>
                                {
                                    e.name === 'Assets' ? (
                                        <span dangerouslySetInnerHTML={{ __html: e.value }} />
                                    ) : (
                                        <>
                                            {e.value}
                                        </>
                                    )
                                }
                            </p>
                        </div>
                    )) : null
                }
            </div>
        </div>
    );
};

export default ViewProfileComp;