import React from 'react';

const Popup = ({ onClose, onConfirm, children,closeBtnName,submitBtnName,submitClass,closeClass,classWrapper,mainClassWrapper }) => {
    
    const handleOnClose = () => {
        onClose()
    };
    const handleOnConfirm = () => {
        onConfirm()
    };

    return (
        <div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-20 ${mainClassWrapper}`}>
            <div className={classWrapper ? classWrapper : ''}>
                <button onClick={(event) => {event.preventDefault(); handleOnClose();}} className='cursor-pointer bg-[#b7892d] md:px-[9px] md:pt-[4px] md:pb-[4px] text-[20px] px-[6px] py-[2px] rounded-[50%] font-family-sans-serif md:text-[24px] font-bold leading-[1] text-[#fff] absolute top-[5px] right-[5px]'>×</button>
                {children}
                <div className={`mt-4 flex justify-center`}>

                    {submitBtnName ? 
                    <button className={submitClass} onClick={(event) =>{event.preventDefault(); handleOnConfirm();}}>
                        {submitBtnName}
                    </button> : null}
                    {closeBtnName ? <button className={closeClass} onClick={(event) => {event.preventDefault(); handleOnClose();}}>
                        {closeBtnName}
                    </button> : null}
                </div>
            </div>
        </div>
    );
};

export default Popup;
