import React, { useState, useEffect } from 'react';

const TextArea = (props) => {
    const [value, setValue] = useState(props.modelValue);

    const isValid = () => {
        if (!props.isRequired) {
            return true;
        } else {
            return value.length !== 0 && value.length >= props.minLength && value.length <= props.maxLength;
        }
    };

    useEffect(() => {
        if (props.isDirectFocus) {
            const ele = document.getElementById(props.inputId);
            ele.focus();
        }
    }, [props.isDirectFocus, props.inputId]);
    useEffect(() => {
        setValue(props.modelValue);
    }, [props.modelValue]);

    return (
        <textarea
            className={props.class ? props.class : ''}
            style={{ height: props.height, width: props.width, outline: !props.isOutline ? 'none' : '' }}
            type={props.type}
            value={value}
            id={props.inputId}
            maxLength={props.maxLength}
            placeholder={props.placeHolder}
            disabled={props.isDisabled}
            readOnly={props.isReadonly}
            onChange={(e) => setValue(e.target.value)}
            onFocus={(e) => props.onFocus ? props.onFocus({ value, isValid: isValid(), event: e }): ''}
            onBlur={(e) => props.onBlur ? props.onBlur({ value, isValid: isValid(), event: e }): ''}
            onClick={(e) => props.onClick ? props.onClick({ value, isValid: isValid(), event: e }): ''}
            onKeyPress={(e) => props.onKeyPress ? props.onKeyPress({ value, isValid: isValid(), event: e }): ''}
            onKeyUp={(e) => props.onKeyUp ? props.onKeyUp({ value, isValid: isValid(), event: e }): ''}
            onKeyDown={(e) => props.onKeyDown ? props.onKeyDown({ value, isValid: isValid(), event: e }): ''}
            onMouseUp={(e) => props.onMouseUp ? props.onMouseUp({ value, isValid: isValid(), event: e }): ''}
            onMouseDown={(e) => props.onMouseDown ? props.onMouseDown({ value, isValid: isValid(), event: e }): ''}
        />
    );
};

export default TextArea;
