// src/components/PublicRoute.js
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../route/auth';

const PublicRoute = ({ element,state }) => {
    const { isAuthenticated } = useAuth();
    useEffect(() => {
        document.title = `${state} - Rishta Matrimony`;
    }, [state]);
    return !isAuthenticated ? element : <Navigate to="/" />;
};

export default PublicRoute;