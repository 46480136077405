import { useEffect, useState } from "react";
import Button from "../../atom/button";
import { useSelector } from "react-redux";
import Dropdown from "../../atom/dropDown";
import CustomInput from "../../molecules/customInput.jsx";
import RangeSlider from "../../atom/rangeSlider/slider";
import CheckBox from "../../molecules/checkBox/checkBox";
import useCustomComposable from '../../../utils/composables/index.js';
import Autocomplete from "react-google-autocomplete";
import * as env from '../../../config/env.js';

const MyMatchesFilter = ({ filterObj, handleReset, handleApply }) => {
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const { debounce } = useCustomComposable();

    const initialCheckedValues = (filter, key) =>
        filter ? (typeof filter === 'number' ? [filter] : Array.isArray(filter) ? filter : []) : [];
    const [filters, setFilters] = useState({
        age: !!(filterObj?.lowerAgeLimit && filterObj?.upperAgeLimit),
        caste: !!filterObj?.basicCaste,
        height: !!(filterObj?.basicHeightMax && filterObj?.basicHeightMin),
        income: !!(filterObj?.educationAnnualIncome),
        manglik: !!filterObj?.horoscopeManglik,
        isMatch: false,
        religion: !!filterObj?.basicReligion,
        locationCity: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
        locationCountry: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
        locationState: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
        recently: !!filterObj?.recentlyJoined,
        education: !!filterObj?.educationHighestQualification,
        horoscope: !!filterObj?.horoscopeStar,
        employedIn: !!filterObj?.educationEmployeedIn,
        occupation: !!filterObj?.educationOccupation,
        eatingHabits: !!filterObj?.lifeStyleEatingHabits,
        lifeStyleSmokingHabits: !!filterObj?.lifeStyleSmokingHabits,
        lifeStyleDrinkingHabits: !!filterObj?.lifeStyleDrinkingHabits,
        basicDisability: !!filterObj?.basicDisability,
        motherTongue: !!filterObj?.basicMotherTongue,
        maritalStatus: !!filterObj?.basicMaritalStatus,
    });
    const [filterCastes,setFilterCastes] = useState(dropDown?.['caste']?.option);
    const [filterEducations,setFilterEducations] = useState(dropDown?.['degree']?.option);
    const [filterOccupations,setFilterOccupations] = useState(dropDown?.['occupation']?.option);

    const [searchFilterOccupation,setSearchFilterOccupation] = useState('');
    const [searchFilterCaste,setSearchFilterCaste] = useState('');
    const [searchFilterEducation,setSearchFilterEducation] = useState('');
    const [searchCountryStateCity,setSearchCountryStateCity] = useState({
        locationCountry: {
            value: (filterObj?.locationCountry),
        },
        locationState: {
            value: (filterObj?.locationState),
        },
        locationCity: {
            value: (filterObj?.locationCity),
        }
    });

    const [checkedValues, setCheckedValues] = useState({
        annualIncome: initialCheckedValues(filterObj?.educationAnnualIncome),
        manglik: initialCheckedValues(filterObj?.horoscopeManglik),
        maritalStatus: initialCheckedValues(filterObj?.basicMaritalStatus),
        religion: initialCheckedValues(filterObj?.basicReligion),
        motherTongue: initialCheckedValues(filterObj?.basicMotherTongue),
        caste: initialCheckedValues(filterObj?.basicCaste),
        horoscope: initialCheckedValues(filterObj?.horoscopeStar),
        education: initialCheckedValues(filterObj?.educationHighestQualification),
        employedIn: initialCheckedValues(filterObj?.educationEmployeedIn),
        occupation: initialCheckedValues(filterObj?.educationOccupation),
        eatingHabit: initialCheckedValues(filterObj?.lifeStyleEatingHabits),
        lifeStyleSmokingHabits: initialCheckedValues(filterObj?.lifeStyleSmokingHabits),
        lifeStyleDrinkingHabits: initialCheckedValues(filterObj?.lifeStyleDrinkingHabits),
        basicDisability: initialCheckedValues(filterObj?.basicDisability),
    });

    const [filter, setFilter] = useState({
        height: {
            maxValue: filterObj?.basicHeightMax || null,
            minValue: filterObj?.basicHeightMin || null,
        },
        age: {
            value: filterObj?.upperAgeLimit && filterObj?.lowerAgeLimit ? [filterObj?.lowerAgeLimit,filterObj?.upperAgeLimit] : [18,70]
        },
        education: {
            value: initialCheckedValues(filterObj?.educationHighestQualification),
        },
        caste: {
            value: initialCheckedValues(filterObj?.basicCaste && filterObj?.basicCaste === 'All' ? dropDown?.['caste']?.option.map(e => e.key) : filterObj?.basicCaste),
        },
        employedIn: {
            value: initialCheckedValues(filterObj?.educationEmployeedIn),
        },
        annualIncome: {
            value: initialCheckedValues(filterObj?.educationAnnualIncome),
        },
        occupation: {
            value: initialCheckedValues(filterObj?.educationOccupation),
        },
        religion: {
            value: initialCheckedValues(filterObj?.basicReligion),
        },
        maritalStatus: {
            value: initialCheckedValues(filterObj?.basicMaritalStatus),
        },
        manglik: {
            value: initialCheckedValues(filterObj?.horoscopeManglik),
        },
        motherTongue: {
            value: initialCheckedValues(filterObj?.basicMotherTongue),
        },
        eatingHabit: {
            value: initialCheckedValues(filterObj?.lifeStyleEatingHabits),
        },
        lifeStyleSmokingHabits: {
            value: initialCheckedValues(filterObj?.lifeStyleSmokingHabits),
        },
        lifeStyleDrinkingHabits: {
            value: initialCheckedValues(filterObj?.lifeStyleDrinkingHabits),
        },
        basicDisability: {
            value: initialCheckedValues(filterObj?.basicDisability),
        },
        horoscope: {
            value: initialCheckedValues(filterObj?.horoscopeStar),
        },
        locationCity: {
            value: (filterObj?.locationCity),
        },
        locationCountry: {
            value: (filterObj?.locationCountry),
        },
        locationState: {
            value: (filterObj?.locationState),
        },
        recently:{
            value: (filterObj?.recentlyJoined),
        }
    });

    const handleToggle = (value) => {
        if(value !== 'location'){
            setFilters(prevFilters => ({ ...prevFilters, [value]: !prevFilters[value] }));
            if (value === 'caste') {
                setFilterCastes(dropDown?.['caste']?.option);
            } else if (value === 'education') {
                setFilterEducations(dropDown?.['degree']?.option);
            } else if (value === 'occupation') {
                setFilterOccupations(dropDown?.['occupation']?.option);
            }
        }else {
            setFilters(prevFilters => ({ ...prevFilters, locationCountry: !prevFilters['locationCountry'],locationState: !prevFilters['locationState'],locationCity: !prevFilters['locationCity'] }));
        }
    };
    const heightValidation = () => {
        if(filter?.height){
            if(filter?.height?.minValue > filter?.height?.maxValue){
                filter.height = {
                    ...filter.height,
                    'error':'Min height should be smaller then max height'
                };
            }else if(!filter?.height?.minValue && filter?.height?.maxValue){
                filter.height = {
                    ...filter.height,
                    'error':'Min height field is required'
                };
            }else if(filter?.height?.minValue && !filter?.height?.maxValue){
                filter.height = {
                    ...filter.height,
                    'error':'Max height field is required'
                };
            }else{
                delete filter?.height?.error;
            }
        }
        setFilter({...filter});
    };

    const handleSearch = (id, obj) => {
        if(id === 'recently') {
            filter.recently = {'value':obj.key};
            setFilter({...filter});
            return
        }else if(id === 'age'){
            let setValue = [Number(dropDown['age']?.option?.find(e => Number(e.name) === obj[0])?.key),Number(dropDown['age']?.option.find(e => Number(e.name) === obj[1])?.key)]
            filter.age = {'value':setValue};
            setFilter({...filter});
            return
        }else if(id === 'minHeight'){
            filter.height = {
                ...filter.height, 
                'minValue':obj.key
            }
            setFilter({...filter});
            heightValidation();
            return
        }else if(id === 'maxHeight'){
            filter.height = {
                ...filter.height,
                'maxValue':obj.key
            }
            setFilter({...filter});
            heightValidation();
            return
        }else if(id === 'locationCountry'){
            filter.locationCountry = {'value':obj.key};
            setFilter({...filter});
            return
        }else if(id === 'locationState'){
            filter.locationState = {'value':obj.key};
            setFilter({...filter});
            return
        }else if(id === 'locationCity'){
            filter.locationCity = {'value':obj.key};
            filter.height = {
                ...filter.height,
                'maxValue':obj.key
            }
            setFilter({...filter});
            return
        }

        const value = Number(obj.event.target.value);
        const isIncludes = checkedValues[id]?.includes(value);
        const updatedValues = isIncludes ? checkedValues[id].filter(item => item !== value) : [...(checkedValues[id] || []), value];
        setCheckedValues(prev => ({ ...prev, [id]: updatedValues }));
        if (updatedValues.length) {
            setFilter(prev => ({ ...prev, [id]: { value: updatedValues } }));
        } else {
            setFilter(prev => {
                const { [id]: _, ...rest } = prev;
                return rest;
            });
        }
    };

    const handleResetFilter = () => {
        setFilter({});
        setCheckedValues({
            annualIncome: [],
            manglik: [],
            maritalStatus: [],
            religion: [],
            motherTongue: [],
            caste: [],
            horoscope: [],
            education: [],
            employedIn: [],
            occupation: [],
            eatingHabit: [],
            lifeStyleSmokingHabits:[],
            lifeStyleDrinkingHabits:[],
            basicDisability:[]
        });
        setFilters({
            age: false,
            caste: false,
            height: false,
            income: false,
            manglik: false,
            isMatch: false,
            religion: false,
            locationState: false,
            locationCountry: false,
            locationCity: false,
            recently: false,
            education: false,
            horoscope: false,
            employedIn: false,
            occupation: false,
            eatingHabits: false,
            lifeStyleSmokingHabits:false,
            lifeStyleDrinkingHabits:false,
            basicDisability:false,
            motherTongue: false,
            maritalStatus: false
        });
        handleReset({});
    };

    const handleApplyFilter = (obj) => {
        if (!obj?.height?.error) {
            let newFiltersValue = {...searchCountryStateCity };
            newFiltersValue = {
                ...newFiltersValue,
                locationCountry: { ...newFiltersValue.locationCountry, value: obj?.locationCountry?.value ? obj?.locationCountry?.value : '' },
                locationState: { ...newFiltersValue.locationState, value: obj?.locationState?.value ? obj?.locationState?.value : '' },
                locationCity: { ...newFiltersValue.locationCity, value: obj?.locationCity?.value ? obj?.locationCity?.value : '' }
            };
            setSearchCountryStateCity(prevFormData => ({
                ...prevFormData,
                ...newFiltersValue
            }));
            setFilterOccupations(dropDown?.['occupation']?.option);
            setFilterEducations(dropDown?.['degree']?.option);
            setFilterCastes(dropDown?.['caste']?.option);
            setSearchFilterEducation('');
            setSearchFilterCaste('');
            setSearchFilterOccupation('');
            handleApply(obj);
        }
    };
    const handleSearchCastes = debounce((e) => {
        setFilterCastes(dropDown?.['caste']?.option);
        setSearchFilterCaste(e.target.value);
        setFilterCastes(prevCasteArray => {
            let caste = prevCasteArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return caste;
        });
    }, 100);
    const handleSearchEducation = debounce((e) => {
        setFilterEducations(dropDown?.['degree']?.option);
        setSearchFilterEducation(e.target.value);
        setFilterEducations(prevCasteArray => {
            let caste = prevCasteArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return caste;
        });
    }, 100)
    const handleSearchOccupation = debounce((e) => {
        setFilterOccupations(dropDown?.['occupation']?.option);
        setSearchFilterOccupation(e.target.value);
        setFilterOccupations(prevCasteArray => {
            let caste = prevCasteArray.filter(option =>
                option.name.toLowerCase().includes(e.target.value.toLowerCase())
            );
            return caste;
        });
    }, 100)
    useEffect(() => {
        setFilters(prev => ({
            ...prev,
            caste: !!filterObj?.basicCaste,
            age: !!(filterObj?.lowerAgeLimit && filterObj?.upperAgeLimit),
            motherTongue: !!filterObj?.basicMotherTongue,
            religion: !!filterObj?.basicReligion,
            height: !!(filterObj?.basicHeightMax && filterObj?.basicHeightMin),
            education: !!filterObj?.educationHighestQualification,
            maritalStatus: !!filterObj?.basicMaritalStatus,
            manglik: !!filterObj?.horoscopeManglik,
            eatingHabits: !!filterObj?.lifeStyleEatingHabits,
            lifeStyleSmokingHabits: !!filterObj?.lifeStyleSmokingHabits,
            lifeStyleDrinkingHabits: !!filterObj?.lifeStyleDrinkingHabits,
            basicDisability: !!filterObj?.basicDisability,
            locationCity: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
            locationCountry: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
            locationState: !!(filterObj?.locationCity) || !!(filterObj?.locationCountry) || !!(filterObj?.locationState),
            income: !!(filterObj?.educationAnnualIncome),
        }));
        setFilter({
            height: {
                maxValue: filterObj?.basicHeightMax || null,
                minValue: filterObj?.basicHeightMin || null,
            },
            age: {
                value: filterObj?.upperAgeLimit && filterObj?.lowerAgeLimit ? [filterObj?.lowerAgeLimit,filterObj?.upperAgeLimit] : [18,70]
            },
            education: {
                value: initialCheckedValues(filterObj?.educationHighestQualification),
            },
            caste: {
                value: initialCheckedValues(filterObj?.basicCaste && filterObj?.basicCaste === 'All' ? dropDown?.['caste']?.option.map(e => e.key) : filterObj?.basicCaste),
            },
            employedIn: {
                value: initialCheckedValues(filterObj?.educationEmployeedIn),
            },
            annualIncome: {
                value: initialCheckedValues(filterObj?.educationAnnualIncome),
            },
            occupation: {
                value: initialCheckedValues(filterObj?.educationOccupation),
            },
            religion: {
                value: initialCheckedValues(filterObj?.basicReligion),
            },
            maritalStatus: {
                value: initialCheckedValues(filterObj?.basicMaritalStatus),
            },
            manglik: {
                value: initialCheckedValues(filterObj?.horoscopeManglik),
            },
            motherTongue: {
                value: initialCheckedValues(filterObj?.basicMotherTongue),
            },
            eatingHabit: {
                value: initialCheckedValues(filterObj?.lifeStyleEatingHabits),
            },
            lifeStyleSmokingHabits: {
                value: initialCheckedValues(filterObj?.lifeStyleSmokingHabits),
            },
            lifeStyleDrinkingHabits: {
                value: initialCheckedValues(filterObj?.lifeStyleDrinkingHabits),
            },
            basicDisability: {
                value: initialCheckedValues(filterObj?.basicDisability),
            },
            horoscope: {
                value: initialCheckedValues(filterObj?.horoscopeStar),
            },
            locationCity: {
                value: (filterObj?.locationCity),
            },
            locationCountry: {
                value: (filterObj?.locationCountry),
            },
            locationState: {
                value: (filterObj?.locationState),
            },
            recently:{
                value: (filterObj?.recentlyJoined),
            }
        });
        setSearchCountryStateCity({
            locationCity: {
                value: (filterObj?.locationCity),
            },
            locationCountry: {
                value: (filterObj?.locationCountry),
            },
            locationState: {
                value: (filterObj?.locationState),
            }
        })

        setCheckedValues({
            caste: initialCheckedValues(filterObj?.basicCaste && filterObj?.basicCaste === 'All' ? dropDown?.['caste']?.option.map(e => e.key) : filterObj?.basicCaste),
            religion: initialCheckedValues(filterObj?.basicReligion),
            maritalStatus: initialCheckedValues(filterObj?.basicMaritalStatus),
            manglik: initialCheckedValues(filterObj?.horoscopeManglik),
            motherTongue: initialCheckedValues(filterObj?.basicMotherTongue),
            education: initialCheckedValues(filterObj?.educationHighestQualification),
            eatingHabit: initialCheckedValues(filterObj?.lifeStyleEatingHabits),
            lifeStyleSmokingHabits: initialCheckedValues(filterObj?.lifeStyleSmokingHabits),
            lifeStyleDrinkingHabits: initialCheckedValues(filterObj?.lifeStyleDrinkingHabits),
            basicDisability: initialCheckedValues(filterObj?.basicDisability),
            horoscope: initialCheckedValues(filterObj?.horoscopeStar),
            annualIncome: initialCheckedValues(filterObj?.educationAnnualIncome),
            employedIn: initialCheckedValues(filterObj?.educationEmployeedIn),
            occupation: initialCheckedValues(filterObj?.educationOccupation)
        });
    }, [filterObj,dropDown]);
    const handlePlaceSelected = (place, type) => {
        const addressComponents = place?.address_components || [];
        const getComponent = (type) => addressComponents.find(component => component.types.includes(type));
    
        const countryComponent = getComponent('country');
        const stateComponent = getComponent('administrative_area_level_1');
        const cityComponent = getComponent('locality') || getComponent('administrative_area_level_4') || getComponent('administrative_area_level_3') || getComponent('administrative_area_level_2');
    
        let newFilters = {};
        let newFiltersValue = {...searchCountryStateCity };
    
        if (type === 'country' && countryComponent) {
            newFilters = {
                ...newFilters,
                locationCountry: { ...newFilters.locationCountry, value: countryComponent.long_name },
                locationState: { ...newFilters.locationState, value: '' },
                locationCity: { ...newFilters.locationCity, value: '' },
            };
            newFiltersValue = {
                ...newFiltersValue,
                locationCountry: { ...newFiltersValue.locationCountry, value: countryComponent.long_name },
                locationState: { ...newFiltersValue.locationState, value: '' },
                locationCity: { ...newFiltersValue.locationCity, value: '' },
            };
        } else if (type === 'state' && stateComponent) {
            newFilters = {
                ...newFilters,
                locationCountry: { ...newFilters.locationCountry, value: countryComponent?.long_name || '' },
                locationState: { ...newFilters.locationState, value: stateComponent.long_name },
                locationCity: { ...newFilters.locationCity, value: '' },
            };
            newFiltersValue = {
                ...newFiltersValue,
                locationCountry: { ...newFiltersValue.locationCountry, value: countryComponent?.long_name || '' },
                locationState: { ...newFiltersValue.locationState, value: stateComponent.long_name },
                locationCity: { ...newFiltersValue.locationCity, value: '' },
            };
        } else if (type === 'city') {
            const state = stateComponent?.long_name || '';
            const country = countryComponent?.long_name || '';
            const city = cityComponent?.long_name || '';
            newFilters = {
                ...newFilters,
                locationState: { ...newFilters.locationState, value: state },
                locationCity: { ...newFilters.locationCity, value: city },
                locationCountry: { ...newFilters.locationCountry, value: country },
            };
            newFiltersValue = {
                ...newFiltersValue,
                locationState: { ...newFiltersValue.locationState, value: state },
                locationCity: { ...newFiltersValue.locationCity, value: city },
                locationCountry: { ...newFiltersValue.locationCountry, value: country },
            };
        }
    
        setFilter(prevFormData => ({
            ...prevFormData,
            ...newFilters
        }));
        setSearchCountryStateCity(prevFormData => ({
            ...prevFormData,
            ...newFiltersValue
        }));
    };
    const handleChange = (e,type) => {
        let newFilters = { ...searchCountryStateCity };
        if(type === "city"){
            newFilters = {
                ...newFilters,
                locationCity: { ...newFilters.locationCity, value: e.target.value }
            };
        }else if (type === "state") {
            newFilters = {
                ...newFilters,
                locationState: { ...newFilters.locationState, value: e.target.value }
            };
        }else if (type === "country") {
            newFilters = {
                ...newFilters,
                locationCountry: { ...newFilters.locationCountry, value: e.target.value }
            };
        }
        setSearchCountryStateCity(prevFormData => ({
            ...prevFormData,
            ...newFilters
        }));
        if(!e.target.value){
            let newFilter = { ...filter };
            if(type === "city"){
                newFilter = {
                    ...newFilter,
                    locationCity: { ...newFilter.locationCity, value: e.target.value }
                };
            }else if (type === "state") {
                newFilter = {
                    ...newFilter,
                    locationState: { ...newFilter.locationState, value: e.target.value }
                };
            }else if (type === "country") {
                newFilter = {
                    ...newFilter,
                    locationCountry: { ...newFilter.locationCountry, value: e.target.value }
                };
            }
            setFilter(prevFormData => ({
                ...prevFormData,
                ...newFilter
            }));
        }
    };
    return (
        <div>
            <h3 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular mb-[25px] text-[34px] leading-[1.2]">Filter By</h3>
            <div className="flex justify-between border-b border-b-[#e9d2a4]">
                <Button
                    class='leading-[normal] my-[10px] w-[45%] text-stroke text-[#fff] cursor-pointer xl:py-[8px] xl:px-[31px] py-[5px] px-[15px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                    buttonName={'Apply'}
                    onClick={() => handleApplyFilter(filter)}
                />
                <Button
                    class='leading-[normal] my-[10px] text-[#ee1c24] w-[45%] text-stroke cursor-pointer xl:py-[8px] xl:px-[30px] py-[5px] px-[15px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                    buttonName={'Reset'}
                    onClick={() => handleResetFilter()}
                />
            </div>
            <div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Recently Joined</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('recently')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.recently ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.recently ?
                            <div className="relative">
                                <Dropdown
                                    options={dropDown?.['recentlyJoined'].option}
                                    onUpdate={(e) => handleSearch('recently', e)}
                                    multiSelect={false}
                                    selectedValue={filter?.recently?.value ? filters.recently.value : []}
                                    labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 xxms:after:right-[35px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                    wrapperClass={'mb-[12px]'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                />
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4] relative">
                    <div className={`flex justify-between items-center pt-[15px] ${filters.age ? 'pb-[50px]' : 'pb-[15px]'}`}>
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Age</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('age')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.age ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.age ?
                            <>
                                <RangeSlider
                                    minRange={18}
                                    maxRange={70}
                                    range={filterObj?.upperAgeLimit && filterObj?.lowerAgeLimit ? [Number(dropDown['age']?.option?.find(e => e.key === filterObj?.lowerAgeLimit)?.name),Number(dropDown['age']?.option.find(e => e.key === filterObj?.upperAgeLimit).name)] : [18,70]}
                                    rangeValue={(e) => handleSearch('age', e)}
                                />
                            </>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Height</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('height')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.height ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.height ?
                            <div className="relative">
                                <Dropdown
                                    isInputSearch={true}
                                    options={dropDown['height']?.option}
                                    onUpdate={(e) => handleSearch('minHeight', e)}
                                    multiSelect={false}
                                    selectedValue={filter?.height?.minValue ? [filter.height.minValue] : []}
                                    labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 xxms:after:right-[35px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                    wrapperClass={''}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                />
                                <span className="leading-[1.5] text-[#262626] font-family-Quicksand-Regular text-[20px] text-stroke">To</span>
                                <Dropdown
                                    isInputSearch={true}
                                    options={dropDown['height']?.option}
                                    onUpdate={(e) => handleSearch('maxHeight', e)}
                                    multiSelect={false}
                                    selectedValue={filter?.height?.maxValue ? [filter.height.maxValue] : []}
                                    labelClass={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    selectClass={'after:w-0 after:h-0 after:border-l-[7px] after:border-r-[7px] after:border-solid after:border-l-transparent after:border-r-transparent after:border-b-[7px] after:border-b-[#931217] after:absolute after:rotate-180 xxms:after:right-[35px] md:after:right-[15px] flex items-center text-stroke w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[42px]'}
                                    wrapperClass={'mb-[12px]'}
                                    optionsClass={'text-[18px] font-family-Quicksand-Regular color-[262626]'}
                                    optionsWrapper={'bg-white'}
                                />
                                {filter?.height?.error && 
                                    <small className="text-[#ff0000] text-[16px] font-family-Quicksand-Bold">{filter?.height?.error}</small>
                                }
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Annual Income</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('income')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.income ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.income ?
                            <div className="my-[8px]">
                                {dropDown['annualIncome']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('annualIncome', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.annualIncome && checkedValues.annualIncome.length ? checkedValues.annualIncome.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Marital Status</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('maritalStatus')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.maritalStatus ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.maritalStatus ?
                            <div className="my-[8px]">
                                {dropDown['maritalStatus']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('maritalStatus', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.maritalStatus && checkedValues.maritalStatus.length ? checkedValues.maritalStatus.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Religion</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('religion')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.religion ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.religion ?
                            <div className="my-[8px]">
                                {dropDown['religion']?.option?.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('religion', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.religion && checkedValues.religion.length ? checkedValues.religion.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Mother Tongue</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('motherTongue')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.motherTongue ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.motherTongue ?
                            <div className="my-[8px] max-h-[186px] overflow-y-scroll relative style-scroll-6-px">
                                {dropDown['motherTongue']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('motherTongue', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.motherTongue && checkedValues.motherTongue.length ? checkedValues.motherTongue.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Caste</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('caste')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.caste ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.caste ?
                            <>
                                <CustomInput
                                    inputClass={'font-family-sans-serif text-[100%] leading-[1.15] border-[#931217] border-[2px] rounded-[5px] text-[#000000] p-[5px] text-stroke w-full max-w-full'}
                                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    inputId="inputId"
                                    type="text"
                                    modelValue={searchFilterCaste}
                                    placeHolder="Search cast"
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    height="50px"
                                    width="200px"
                                    isOutline={true}
                                    onChange={(_, event) => handleSearchCastes(event)}
                                />
                                <div className="my-[8px] max-h-[186px] overflow-y-scroll relative style-scroll-6-px">
                                    {filterCastes && filterCastes.length ? filterCastes.map((option, index) =>
                                        <div key={index}>
                                            <CheckBox
                                                modelValue={option.key || false}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onClick={(value) => handleSearch('caste', value)}
                                                label={option.name || ''}
                                                inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                                labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                                checkBoxClassWrapper={'mb-2'}
                                                checked={checkedValues.caste && checkedValues.caste.length ? checkedValues.caste.includes(option.key) : false}
                                            />
                                        </div>
                                    ) : <span className="leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative">no record found</span>}
                                </div>
                            </>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Manglik</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('manglik')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.manglik ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.manglik ?
                            <div className="my-[8px]">
                                {dropDown['manglik']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('manglik', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.manglik && checkedValues.manglik.length ? checkedValues.manglik.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Horoscope</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('horoscope')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.horoscope ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.horoscope ?
                            <div className="my-[8px] max-h-[186px] overflow-y-scroll relative style-scroll-6-px">
                                {dropDown['star']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('horoscope', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.horoscope && checkedValues.horoscope.length ? checkedValues.horoscope.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Location</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('location')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.locationState || !filters.locationCountry || !filters.locationCity ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.locationState || filters.locationCountry || filters.locationCity ?
                            <div className="relative">
                                <Autocomplete
                                    apiKey={env.GOOGLEAPIKEY}
                                    onPlaceSelected={(place) => {handlePlaceSelected(place,'city')}}
                                    types={['(regions)']}
                                    fields={['address_components']}
                                    className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                    placeholder={'Enter a city'}
                                    value={searchCountryStateCity?.locationCity?.value || ''}
                                    onChange={(e) => handleChange(e,'city')}
                                />
                                <Autocomplete
                                    apiKey={env.GOOGLEAPIKEY}
                                    onPlaceSelected={(place) => {handlePlaceSelected(place,'state')}}
                                    options={{
                                        types: ['administrative_area_level_1'],
                                    }}
                                    fields={['address_components']}
                                    className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                    placeholder={'Enter a state'}
                                    value={searchCountryStateCity?.locationState?.value || ''}
                                    onChange={(e) => handleChange(e,'state')}
                                />
                                <Autocomplete
                                    apiKey={env.GOOGLEAPIKEY}
                                    onPlaceSelected={(place) => {handlePlaceSelected(place,'country')}}
                                    options={{
                                        types: ['country']
                                    }}
                                    fields={['address_components']}
                                    className='w-full bg-[#fff] text-[18px] text-[#262626] font-family-Quicksand-Regular rounded-[8px] px-[16px] py-[5px] h-[41px] mb-[12px] mt-[5px] border border-[#ced4da] rounded-[10px]'
                                    placeholder={'Enter a country'}
                                    value={searchCountryStateCity?.locationCountry?.value || ''}
                                    onChange={(e) => handleChange(e,'country')}
                                />
                                
                            </div>
                        : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Education</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('education')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.education ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.education ?
                            <>
                                <CustomInput
                                    inputClass={'font-family-sans-serif text-[100%] leading-[1.15] border-[#931217] border-[2px] rounded-[5px] text-[#000000] p-[5px] text-stroke w-full max-w-full'}
                                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    inputId="inputId"
                                    type="text"
                                    modelValue={searchFilterEducation}
                                    placeHolder="Search education"
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    height="50px"
                                    width="200px"
                                    isOutline={true}
                                    onChange={(_, event) => handleSearchEducation(event)}
                                />
                                <div className="my-[8px] max-h-[186px] overflow-y-scroll relative style-scroll-6-px">
                                    {filterEducations && filterEducations.length ? filterEducations.map((option, index) => (
                                        <div key={index}>
                                            <CheckBox
                                                modelValue={option.key || false}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onClick={(value) => handleSearch('education', value)}
                                                label={option.name || ''}
                                                inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                                labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                                checkBoxClassWrapper={'mb-2'}
                                                checked={checkedValues.education && checkedValues.education.length ? checkedValues.education.includes(option.key) : false}
                                            />
                                        </div>
                                    )) : <span className="leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative">no record found</span>}
                                </div>
                            </>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Employed In</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('employedIn')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.employedIn ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.employedIn ?
                            <div className="my-[8px]">
                                {dropDown['employedIn']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('employedIn', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.employedIn && checkedValues.employedIn.length ? checkedValues.employedIn.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Occupation</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('occupation')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.occupation ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.occupation ?
                            <>
                                <CustomInput
                                    inputClass={'font-family-sans-serif text-[100%] leading-[1.15] border-[#931217] border-[2px] rounded-[5px] text-[#000000] p-[5px] text-stroke w-full max-w-full'}
                                    class={'text-[#931217] text-[18px] font-family-Quicksand-Bold mb-[6px] inline-block leading-[normal]'}
                                    inputId="inputId"
                                    type="text"
                                    modelValue={searchFilterOccupation}
                                    placeHolder="Search occupation"
                                    isDisabled={false}
                                    isReadonly={false}
                                    isDirectFocus={false}
                                    isRequired={false}
                                    height="50px"
                                    width="200px"
                                    isOutline={true}
                                    onChange={(_, event) => handleSearchOccupation(event)}
                                />
                                <div className="my-[8px] max-h-[186px] overflow-y-scroll relative style-scroll-6-px">
                                    {filterOccupations && filterOccupations.length ? filterOccupations.map((option, index) => (
                                        <div key={index}>
                                            <CheckBox
                                                modelValue={option.key || false}
                                                isDisabled={false}
                                                isReadonly={false}
                                                isDirectFocus={false}
                                                isRequired={false}
                                                isOutline={true}
                                                onClick={(value) => handleSearch('occupation', value)}
                                                label={option.name || ''}
                                                inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                                labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                                checkBoxClassWrapper={'mb-2'}
                                                checked={checkedValues.occupation && checkedValues.occupation.length ? checkedValues.occupation.includes(option.key) : false}
                                            />
                                        </div>
                                    )) : <span className="leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative">no record found</span>}
                                </div>
                            </>
                            : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Eating Habits</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('eatingHabits')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.eatingHabits ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.eatingHabits ?
                            <div className="my-[8px]">
                                {dropDown['eatingHabits']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('eatingHabit', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.eatingHabit && checkedValues.eatingHabit.length ? checkedValues.eatingHabit.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Smoking Habits</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('lifeStyleSmokingHabits')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.lifeStyleSmokingHabits ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.lifeStyleSmokingHabits ?
                            <div className="my-[8px]">
                                {dropDown['smokingHabits']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('lifeStyleSmokingHabits', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.lifeStyleSmokingHabits && checkedValues.lifeStyleSmokingHabits.length ? checkedValues.lifeStyleSmokingHabits.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                </div>
                <div className="border-b border-b-[#e9d2a4]">
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Drinking Habits</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('lifeStyleDrinkingHabits')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.lifeStyleDrinkingHabits ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.lifeStyleDrinkingHabits ?
                            <div className="my-[8px]">
                                {dropDown['drinkingHabits']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('lifeStyleDrinkingHabits', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.lifeStyleDrinkingHabits && checkedValues.lifeStyleDrinkingHabits.length ? checkedValues.lifeStyleDrinkingHabits.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                </div>
                <div>
                    <div className="flex justify-between items-center py-[15px]">
                        <h4 className="text-stroke font-family-Quicksand-Bold text-[20px] text-[#931217] leading-[normal]">Disability</h4>
                        <div className="relative w-[15px] h-[15px] mt-[3px] cursor-pointer" onClick={() => handleToggle('basicDisability')}>
                            <span className="translate-x-0 translate-y-[-50%] w-[15px] h-[2px] bg-[#931217] block absolute top-[50%] left-0"></span>
                            {!filters.basicDisability ? <span className="translate-y-0 translate-x-[-50%] w-[2px] h-[15px] bg-[#931217] block absolute top-0 left-[50%]"></span> : null}
                        </div>
                    </div>
                    {
                        filters.basicDisability ?
                            <div className="my-[8px]">
                                {dropDown['disability']?.option.map((option, index) => (
                                    <div key={index}>
                                        <CheckBox
                                            modelValue={option.key || false}
                                            isDisabled={false}
                                            isReadonly={false}
                                            isDirectFocus={false}
                                            isRequired={false}
                                            isOutline={true}
                                            onClick={(value) => handleSearch('basicDisability', value)}
                                            label={option.name || ''}
                                            inputClass={'styled-checkbox styled-checkboxBtn opacity-0 absolute'}
                                            labelClass={'leading-[1.5] text-stroke-4 text-[18px] text-[#262626] font-family-Quicksand-Regular cursor-pointer relative'}
                                            checkBoxClassWrapper={'mb-2'}
                                            checked={checkedValues.basicDisability && checkedValues.basicDisability.length ? checkedValues.basicDisability.includes(option.key) : false}
                                        />
                                    </div>
                                ))}
                            </div>
                        : null
                    }
                </div>
            </div>
            <div className="flex justify-between">
                <Button
                    class='leading-[normal] my-[10px] w-[45%] text-stroke text-[#fff] cursor-pointer xl:py-[8px] xl:px-[31px] py-[5px] px-[15px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                    buttonName={'Apply'}
                    onClick={() => handleApplyFilter(filter)}
                />
                <Button
                    class='leading-[normal] my-[10px] text-[#ee1c24] w-[45%] text-stroke cursor-pointer xl:py-[8px] xl:px-[30px] py-[5px] px-[15px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                    buttonName={'Reset'}
                    onClick={() => handleResetFilter()}
                />
            </div>
        </div>
    );
};

export default MyMatchesFilter;