import { useEffect, useState } from "react";
import CheckBox from "../../molecules/checkBox/checkBox";
import Button from "../../atom/button";
import { apiRequest } from "../../../services";
import * as env from '../../../config/env.js';
import Spinner from "../../atom/spinner/spinner.jsx";
import { toast, ToastContainer } from "react-toastify";

const Alerts = ({form,resetForm,submitForm,userDetails}) => {
    const [isLoading,setIsLoading] = useState(true);
    const [formData,setFormData] = useState({
        matchAlertMails:form.matchAlertMails,
        memberAlertMails:form.memberAlertMails,
        managerResourceAlertMails:form.managerResourceAlertMails,
        smsAlertMails:form.smsAlertMails
    });
    const [prevData,setPrevData] = useState({
        matchAlertMails:form.matchAlertMails,
        memberAlertMails:form.memberAlertMails,
        managerResourceAlertMails:form.managerResourceAlertMails,
        smsAlertMails:form.smsAlertMails
    })
    const handleSelect = (e,id) => {
        if(id === 'matchAlertMails'){
            setFormData({ ...formData, matchAlertMails: {...formData.matchAlertMails,value:!e.value} });
        }
        else if(id === 'memberAlertMails'){
            setFormData({ ...formData, memberAlertMails: {...formData.memberAlertMails,value:!e.value} });
        }
        else if(id === 'managerResourceAlertMails'){
            setFormData({ ...formData, managerResourceAlertMails: {...formData.managerResourceAlertMails,value:!e.value} });
        }
        else if(id === 'smsAlertMails'){
            setFormData({ ...formData, smsAlertMails: {...formData.smsAlertMails,value:!e.value} });
        }
    };
    const handleSubmit = async() => {
        try {
            setIsLoading(true);
            let object = {
                userId:userDetails.userId,
                isMatchAlertMails:formData.matchAlertMails.value,
                isMemberLikeAlertMails:formData.memberAlertMails.value,
                manageResourceAlertMails:formData.managerResourceAlertMails.value,
                isSmsAlertMails:formData.smsAlertMails.value
            };
            const response = await apiRequest("post", `${env.SETALERTANDUPDATE}`,object);
            if (response.status === 200) {
                toast.success('Updated successfully', {position: 'top-right'});
                submitForm({...formData});
                setPrevData({...formData});
                setIsLoading(false);
            }else{
                toast.error('Please try it again', { position: 'top-right' });
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error in updating alert', error);
            setIsLoading(false);
            toast.error('Please try it again', { position: 'top-right' });
        } finally {
            setIsLoading(false);
        }
    };
    const handleReset = () => {
        setFormData({...prevData});
        resetForm({...prevData});
    };
    useEffect(() => {
        const getAlert = async() => {
            if(userDetails){
                try {
                    setIsLoading(true);
                    const response = await apiRequest("get", `${env.GETALERTANDUPDATE}/${userDetails.userId}`);
                    if (response.status === 200 && response.data?.data && Object.keys(response.data?.data).length) {
                        let responseData = response.data?.data;
                        let getAlert = {...formData}
                        getAlert.matchAlertMails.value = responseData?.isMatchAlertMails;
                        getAlert.memberAlertMails.value = responseData?.isMemberLikeAlertMails;
                        getAlert.managerResourceAlertMails.value = responseData?.manageResourceAlertMails;
                        getAlert.smsAlertMails.value = responseData?.isSmsAlertMails;
                        setFormData(getAlert);
                        setPrevData(getAlert);
                    }else{
                        setFormData({
                            matchAlertMails:form.matchAlertMails,
                            memberAlertMails:form.memberAlertMails,
                            managerResourceAlertMails:form.managerResourceAlertMails,
                            smsAlertMails:form.smsAlertMails
                        });
                        setPrevData({
                            matchAlertMails:form.matchAlertMails,
                            memberAlertMails:form.memberAlertMails,
                            managerResourceAlertMails:form.managerResourceAlertMails,
                            smsAlertMails:form.smsAlertMails
                        });
                        setIsLoading(false);
                    }
                } catch (error) {
                    setFormData({
                        matchAlertMails:form.matchAlertMails,
                        memberAlertMails:form.memberAlertMails,
                        managerResourceAlertMails:form.managerResourceAlertMails,
                        smsAlertMails:form.smsAlertMails
                    })
                    setPrevData({
                        matchAlertMails:form.matchAlertMails,
                        memberAlertMails:form.memberAlertMails,
                        managerResourceAlertMails:form.managerResourceAlertMails,
                        smsAlertMails:form.smsAlertMails
                    });
                    console.error('Error in getting alert', error);
                    setIsLoading(false);
                } finally {
                    setIsLoading(false);
                }
            }
        };
        getAlert();
    },[userDetails]);
    return(
        <>
            <ToastContainer />
            {isLoading && <Spinner />}
            {
                !isLoading &&  
                <>
                    <div className="bg-[#f0e1b9] rounded-[15px] md:py-[25px] md:px-[30px] p-[15px]"> 
                        {
                        false&&
                        <div className="md:pb-[20px] pb-[10px] border-b-[2px] border-b-[#b7892d]">
                            <CheckBox
                                checkBoxClassWrapper={'relative'}
                                modelValue={formData?.matchAlertMails?.value}
                                isDisabled={false}
                                isReadonly={false}
                                label={'Match Alert Mails'}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onClick={(value) => handleSelect(value,'matchAlertMails')}
                                inputClass={'setting-styled-checkbox opacity-0 absolute'}
                                labelClass={'cursor-pointer leading-[30px] tracking-[0.5px] font-family-Quicksand-Regular text-[18px] text-[#262626] text-stroke-4'}
                                checked={formData?.matchAlertMails?.value}
                                isCheckBy={true}
                            />
                        </div>
                        }
                        <div className="md:py-[20px] py-[10px] border-b-[2px] border-b-[#b7892d]">
                            <CheckBox
                                checkBoxClassWrapper={'relative'}
                                modelValue={formData?.memberAlertMails?.value}
                                isDisabled={false}
                                isReadonly={false}
                                label={'Members who like you Alert Mails'}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onClick={(value) => handleSelect(value,'memberAlertMails')}
                                inputClass={'setting-styled-checkbox opacity-0 absolute'}
                                labelClass={'cursor-pointer leading-[30px] xms:pr-0 pr-[60px] tracking-[0.5px] font-family-Quicksand-Regular text-[18px] text-[#262626] text-stroke-4'}
                                checked={formData?.memberAlertMails?.value}
                                isCheckBy={true}
                            />
                        </div>
                        <div className="md:py-[20px] py-[10px] border-b-[2px] border-b-[#b7892d]">
                            <CheckBox
                                checkBoxClassWrapper={'relative'}
                                modelValue={formData?.managerResourceAlertMails?.value}
                                isDisabled={false}
                                isReadonly={false}
                                label={'Manager Resource Alert Mails'}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onClick={(value) => handleSelect(value,'managerResourceAlertMails')}
                                inputClass={'setting-styled-checkbox opacity-0 absolute'}
                                labelClass={'cursor-pointer leading-[30px] xms:pr-0 pr-[60px] tracking-[0.5px] font-family-Quicksand-Regular text-[18px] text-[#262626] text-stroke-4'}
                                checked={formData?.managerResourceAlertMails?.value}
                                isCheckBy={true}
                            />
                        </div>
                        <div className="md:pt-[20px] pt-[10px]">
                            <CheckBox
                                checkBoxClassWrapper={'relative'}
                                modelValue={formData?.smsAlertMails?.value}
                                isDisabled={false}
                                isReadonly={false}
                                label={'SMS Alert'}
                                isDirectFocus={false}
                                isRequired={false}
                                isOutline={true}
                                onClick={(value) => handleSelect(value,'smsAlertMails')}
                                inputClass={'setting-styled-checkbox opacity-0 absolute'}
                                labelClass={'cursor-pointer leading-[30px] tracking-[0.5px] font-family-Quicksand-Regular text-[18px] text-[#262626] text-stroke-4'}
                                checked={formData?.smsAlertMails?.value}
                                isCheckBy={true}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-[32px]">
                        <Button
                            class='leading-[normal] text-[#fff] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] bg-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Save'}
                            onClick={() => handleSubmit()}
                        />
                        <Button
                            class='leading-[normal] text-[#ee1c24] ml-[20px] cursor-pointer py-[8px] px-[30px] border-[2px] border-[#ee1c24] rounded-[50px] text-[18px] font-family-Quicksand-SemiBold'
                            buttonName={'Cancel'}
                            onClick={() =>  handleReset()}
                        />
                    </div> 
                </>
            }
        </>
    )
};

export default Alerts;