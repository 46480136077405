import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const MemberShip = () => {
    const dashboardDetail = useSelector(state => state?.dashboard?.dashboard);    
    return(
        <div>
            <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] mb-[19px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">Dashboard</h2>
            <div>
                <div className="ms:flex ms:flex-wrap ms:mx-[-15px]">
                    <div className="ms:px-[15px] ms:w-1/2 ms:my-[20px] mt-[20px] mb-[40px]">
                        <div className="min-h-[165px] md:min-h-full realtive bg-[#f0e1b9] rounded-[12px] px-[25px] pt-[35px] pb-[30px] relative after:bg-no-repeat after:w-[170px] ms:after:w-[250px] after:h-[50px] after:left-[20px] after:top-[-18px] ms:after:bg-left ms:after:bg-center after:absolute after:bg-[url('../../assets/images/png/shape1.png')]">
                            <div className="flex items-center leading-[1.2]">
                                <h5 className="font-family-ZCOOLXiaoWei-Regular md:text-[48px] text-[30px] text-[#b7892d] ">{dashboardDetail?.newLike || 0}</h5>
                                <span className="font-family-Quicksand-Bold text-[#931217] text-[18px] ml-[25px]">New Likes</span>
                            </div>
                            <div className="my-[9px] leading-[normal]">
                                <Link to={'/likes?pageNumber=1&tab=2'} className="text-[18px] font-family-Quicksand-Medium text-stroke text-[#262626]">View All</Link>
                            </div>
                        </div>
                    </div>
                        <div className="ms:px-[15px] ms:w-1/2 ms:my-[20px] mt-[40px] mb-[20px]">
                            <div className="min-h-[165px] md:min-h-full realtive bg-[#f0e1b9] rounded-[12px] px-[25px] pt-[35px] pb-[30px] relative after:bg-no-repeat after:w-[170px] ms:after:w-[250px] after:h-[50px] after:left-[20px] after:top-[-18px] ms:after:bg-left ms:after:bg-center after:absolute after:bg-[url('../../assets/images/png/shape1.png')]">
                                <div className="flex items-center leading-[1.2] md:mb-[0px] mb-[15px]">
                                    <h5 className="font-family-ZCOOLXiaoWei-Regular md:text-[48px] text-[30px] text-[#b7892d] ">{dashboardDetail?.currentCredit || 0}</h5>
                                    <span className="font-family-Quicksand-Bold text-[#931217] text-[18px] ml-[25px]">Current Credits</span>
                                </div>
                                <div className="mt-[4px] leading-[normal]">
                                    <span className="text-[14px] font-family-Quicksand-Medium block leading-[normal] text-stroke text-[#262626]">Only Subscribed members can avail these benefits</span>
                                </div>
                            </div>
                        </div>
                </div>
                <div>
                    <h4 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] mb-[10px] text-[24px] ms:leading-[36px] leading-[normal]">{!(dashboardDetail?.planDetail && dashboardDetail?.featureDetail && dashboardDetail?.relationPlanDetail) ? 'Exclusive personalised match-making service' : 'Your Membership Package'}</h4>
                    <div className="ms:flex ms:flex-wrap ms:mx-[-15px]">
                        {
                            dashboardDetail?.featureDetail ?
                                <div className="ms:px-[15px] ms:w-1/2 mb-[30px]">
                                    <div className="bg-[#ead7ab] rounded-[12px] px-[25px] pt-[22px] pb-[15px]">
                                        <h4 className="tracking-[0.5px] font-family-Quicksand-Bold text-[20px] text-[#931217] mb-[12px] leading-[normal]">{dashboardDetail?.featureDetail?.planName}</h4>
                                        <span className="text-stroke font-family-Quicksand-Medium mb-[8px] text-[18px]">Expiry Date : {moment(dashboardDetail?.featureDetail?.planExpiryDate).format('DD-MM-YYYY')}</span>
                                        <Link to="/membership" className="block my-[10px] text-[#ed1c24] leading-[1.5]">Upgrade Package</Link>
                                    </div>
                                </div>
                            : null
                        }
                        {
                            dashboardDetail?.relationPlanDetail ?
                                <div className="ms:px-[15px] ms:w-1/2 mb-[30px]">
                                    <div className="bg-[#ead7ab] rounded-[12px] px-[25px] pt-[22px] pb-[15px]">
                                        <h4 className="tracking-[0.5px] font-family-Quicksand-Bold text-[20px] text-[#931217] mb-[12px] leading-[normal] capitalize">{dashboardDetail?.relationPlanDetail?.planName}</h4>
                                        <p className="text-[18px] text-stroke font-family-Quicksand-Medium mb-[8px] leading-[normal]">Dedicated relationship manager who shortlist profile for you</p>
                                        <span className="text-stroke font-family-Quicksand-Medium mb-[8px] text-[18px]">Expiry Date : {moment(dashboardDetail?.relationPlanDetail?.planExpiryDate).format('DD-MM-YYYY')}</span>
                                        <Link to="/membership" className="block my-[10px] text-[#ed1c24] leading-[1.5]">Upgrade Package</Link> 
                                    </div>
                                </div> 
                            : null
                        }
                        {
                            dashboardDetail?.planDetail ?
                                <div className="ms:px-[15px] ms:w-1/2 mb-[30px]">
                                    <div className="bg-[#ead7ab] rounded-[12px] px-[25px] pt-[22px] pb-[15px]">
                                        <h4 className="tracking-[0.5px] font-family-Quicksand-Bold text-[20px] text-[#931217] mb-[12px] leading-[normal]">{dashboardDetail?.planDetail?.planName}</h4>
                                        <p className="text-[18px] text-stroke font-family-Quicksand-Medium mb-[8px] leading-[normal]">Unlock upto {dashboardDetail?.planDetail?.unlockProfile} Profile</p>
                                        <span className="text-stroke font-family-Quicksand-Medium mb-[8px] text-[18px]">Expiry Date : {moment(dashboardDetail?.planDetail?.planExpiryDate).format('DD-MM-YYYY')}</span>
                                        <Link to="/membership" className="block my-[10px] text-[#ed1c24] leading-[1.5]">Upgrade Package</Link>
                                    </div>
                                </div> 
                            : null
                        }
                    </div>
                    <div>
                        {
                            !(dashboardDetail?.planDetail || dashboardDetail?.featureDetail || dashboardDetail?.relationPlanDetail) ? 
                                <div className="">
                                    <div className="bg-[#ead7ab] rounded-[12px] px-[25px] pt-[22px] pb-[15px]">
                                        <h4 className="tracking-[0.5px] font-family-Quicksand-Bold text-[20px] text-[#931217] mb-[12px] leading-[normal]">Upgrade now & Get Premium benefits</h4>
                                        <div className="flex justify-between xms:items-center xms:flex-row flex-col">
                                            <div>
                                                <p className="text-[18px] text-stroke font-family-Quicksand-Medium mb-[8px] leading-[normal]">Unlock upto 50 Profile</p>
                                                <span className="tracking-[0.5px] font-family-Quicksand-Bold text-[22px] text-[#931217] mb-[12px] leading-[normal] text-left truncate">₹ 6299</span>
                                            </div>
                                            <div className="xms:w-auto w-[200px] mt-[10px] xms:mt-0">
                                                <Link to='/membership' className={`block font-family-Quicksand-Medium text-[16px] text-center text-white bg-[#ed1c24] rounded-[50px] px-[25px] py-[8px]`}>View All Plans</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            : null
                        }
                    </div>
                </div>
                {
                    dashboardDetail?.relationManager ? 
                        <div>
                            <h4 className="text-[#ed1c24] ms:leading-[36px] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] mb-[10px] text-[24px]">Relationship Manager Contact Details</h4>
                            <div className="bg-[#f0e1b9] rounded-[12px] px-[25px] py-[22px] mb-[20px]">
                                <div className="flex my-[8px] leading-[25px]">
                                    <span className="font-family-Quicksand-Bold text-[20px] text-[#931217] mr-[20px] leading-[normal]">Name:</span>
                                    <span title={'Sagar Vekariya'} className="block overflow-hidden whitespace-nowrap overflow-ellipsis font-family-Quicksand-Regular text-[18px] text-stroke">{dashboardDetail?.relationManager?.firstName} {dashboardDetail?.relationManager?.lastName}</span>
                                </div>
                                <div className="flex my-[8px] leading-[25px]">
                                    <span className="font-family-Quicksand-Bold text-[20px] text-[#931217] mr-[20px] leading-[normal]">Email:</span>
                                    <span title={'sagar.vekariya@aliansoftware.net'} className="block overflow-hidden whitespace-nowrap overflow-ellipsis font-family-Quicksand-Regular text-[18px] text-stroke">{dashboardDetail?.relationManager?.email}</span>
                                </div>
                                <div className="flex my-[8px] leading-[25px]">
                                    <span className="font-family-Quicksand-Bold text-[20px] text-[#931217] mr-[20px] leading-[normal]">Phone:</span>
                                    <span title={''} className="block overflow-hidden whitespace-nowrap overflow-ellipsis font-family-Quicksand-Regular text-[18px] text-stroke">{dashboardDetail?.relationManager?.mobile?.countryCode} {dashboardDetail?.relationManager?.mobile?.mobileNumber}</span>
                                </div>
                            </div>
                        </div> 
                    : dashboardDetail?.relationPlanDetail ? 
                        <div>
                            <h4 className="text-[#ed1c24] ms:leading-[36px] leading-[normal] font-family-ZCOOLXiaoWei-Regular ms:text-[30px] mb-[10px] text-[24px]">Relationship Manager</h4>
                            <div className="bg-[#f0e1b9] rounded-[12px] px-[25px] py-[22px] mb-[20px]">
                                <span className="font-family-Quicksand-Bold text-[20px] text-[#931217] mr-[20px] leading-[normal]">The relationship manager will be assigned shortly.</span>
                            </div>
                        </div> 
                    : null
                }
            </div>
        </div>
    )
};

export default MemberShip;