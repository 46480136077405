import { Link, useNavigate } from 'react-router-dom';
import Button from '../../atom/button';
import './index.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {getPlanAction} from '../../../../src/stores/action/getPlanAction.js';
import Spinner from "../../../../src/components/atom/spinner/spinner.jsx";

const Plans = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(true);
    const planDetail = useSelector(state => state.getPlanReducer.getPlan);
    useEffect(() => {
        const fetchData = async () => {
            if (!planDetail) {
                setIsLoading(true);
                await dispatch(getPlanAction());
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [planDetail, dispatch]);
    const handleNavigation = (e) => {
        if(e.planName === "Gold"){
            navigate('/membership-add-on?plan=gold')
        }else if(e.planName === "Diamond"){
            navigate('/membership-add-on?plan=diamond')
        }else if(e.planName === "Platinum"){
            navigate('/membership-add-on?plan=platinum')
        }
    };
    return(
        <>
            {
                isLoading && !planDetail ? 
                    <div className='min-h-[600px]'>
                        <Spinner /> 
                    </div>
                :
                <div className={'xl:max-w-[1140px] lg:max-w-[960px] xl:mt-[90px] xl:mb-[100px] lg:mt-[60px] lg:mb-[60px] mt-[40px] mb-[40px] md:max-w-[720px] ms:max-w-[540px] w-full sm:px-[15px] xxms:px-[22px] xms:px-[30px] mx-auto'}>
                    <h2 className={'relative text-[#ed1c24] text-center leading-[1.2] font-family-ZCOOLXiaoWei-Regular xl:text-[48px] lg:text-[40px] md:text-[34px] text-[30px] after:bg-no-repeat after:w-[100%] after:h-[100%] after:left-0 after:top-[28px] after:bg-bottom after:absolute after:bg-[url("../../assets/images/png/title_shape.png")]'}>Upgrade now & get Premium Benefits</h2>
                    <p className="font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke leading-[normal] mb-[40px] text-center mt-[60px]">Select Membership Package</p>
                    <div className="grid lg:md:grid-cols-3 grid-cols-1 text-center lg:mx-[-15px] mb-[25px] mx-auto lg:max-w-[100%] max-w-[517px]">
                        {
                            planDetail ? 
                                planDetail?.filter((ele) => ele.planType === "plan").map((e,ind) => (
                                    <div key={ind} className="lg:mb-0 mb-[45px] bg-white lg:mx-[15px] rounded-[12px] pt-[45px] pb-[20px] px-[15px] border border-[#e3bf5f] hover-box-shadow after:bg-no-repeat after:w-[250px] after:h-[60px] after:top-[-31px] after:left-[20px] after:absolute relative after:bg-[url('../../assets/images/png/shape1.png')]">
                                        <p className="text-[#931217] font-family-Quicksand-Bold leading-[1.2] text-[1.25rem] mb-[25px]">{e.planDuration} Months</p>
                                        <h3 className="mt-[45px] relative leading-[1.2] font-family-ZCOOLXiaoWei-Regular text-[48px] mb-[55px] plan after:w-[100%] after:h-[100px] after:absolute after:bg-no-repeat after:left-0 after:top-[-16px] after:bg-bottom after:bg-[url('../../assets/images/png/plan_ttl_bg.png')]">{e.planName}</h3>
                                        <p className="font-family-Quicksand-Regular text-[20px] text-[#262626] text-stroke leading-[normal] text-center">Unlock upto {e.unlockProfiles} Profile</p>
                                        <p className="text-[#931217] font-family-Quicksand-Bold leading-[1.2] text-[1.25rem] mt-[25px]">₹ {e.price}</p>
                                        <Button class="block bg-[#dec31b] text-[#fff] text-[20px] font-family-Quicksand-Medium rounded-[50px] px-[35px] py-[12px] mt-[30px] mb-[15px] cursor-pointer leading-[normal] mx-auto hover:bg-[#ed1c24]" buttonName={'Continue'} onClick={() => handleNavigation(e)}></Button>
                                    </div>
                                )) 
                            : null
                        }
                    </div>
                    <Link className='font-family-Quicksand-Bold w-[max-content] text-[20px] text-[#ed1c24] relative mx-auto my-[10px] block leading-[normal] text-center after:w-[30px] after:h-[2px] after:absolute after:right-[-40px] after:top-[50%] after:bg-[#ed1c24]' to={'/'}>Skip Now</Link>
                </div>
            }
        </>
    )
};

export default Plans;