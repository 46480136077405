import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as env from '../../../config/env.js';
import { Link, useNavigate } from 'react-router-dom';
import male from "../../../assets/images/png/male.png";
import female from '../../../assets/images/png/female.png';
import unlock from '../../../assets/images/png/unlock.png';
import locked from '../../../assets/images/png/locked.png';
import like from '../../../assets/images/png/Like-red1.png';
import disLike from '../../../assets/images/png/disLike.png';
import Pagination from '../../atom/pagination.jsx'; // Adjust the path as necessary
import moment from 'moment';

const MyMatchesUserDetail = ({ userDetails,matchesCount,getMyMatchesData,page,handleLikeDisLike,imageClass,imageClassWrapper,contentClassWrapper }) => {
    const userDetail = useSelector(state => state?.user?.user);
    const navigate = useNavigate();
    const [currentPage] = useState(page);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const itemsPerPage = env.LIMIT; // Adjust the number of items per page as needed

    const totalPages = Math.ceil(matchesCount / itemsPerPage);

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"  // Optional: Smooth scrolling behavior
            });
            getMyMatchesData((page * env.LIMIT) - env.LIMIT ,env.LIMIT,page);
        }
    };
    const [currentItems,setCurrentItems] = useState(userDetails);
    const handleDetailPage = (e) => {
        navigate(`/author/${userDetail?.userId}/${e?.userId}`);
    };
    const calculateAge = (dateOfBirth) => {
        const dob = moment(dateOfBirth);
        const now = moment();
        return now.diff(dob,'years');
    };
    const handleLike = (action,index,id,e) => {
        e.stopPropagation();
        handleLikeDisLike(action,index,id)
    };
    useEffect(() => {
        setCurrentItems(userDetails);
    },[userDetails])
    const getImageUrl = (photoUrls, size) => {
        const photo = photoUrls.find(photo => photo[size]);
        return photo ? photo[size] : null;
    };
    return (
        <>
            <div className="">
                {currentItems.map((e, ind) =>
                    <div key={ind} className="relative cursor-pointer" onClick={() => handleDetailPage(e)}>
                        <div>
                            <span className="absolute text-white bg-[#b7892d] left-[10px] top-[10px] z-10 text-[14px] font-family-Quicksand-Bold rounded-[7px] pt-[4px] pb-[4px] px-[7px]">{e.profileId}</span>
                        </div>
                        {
                            e?.isFeatured ?
                            <div>
                                <span className="absolute text-white bg-[#931217] right-[10px] top-[10px] z-10 text-[14px] font-family-Quicksand-Bold rounded-[7px] pt-[4px] pb-[4px] px-[7px] border border-[#931217]">FEATURED</span>
                            </div> : null
                        }
                        <div className={`${e?.isFeatured ? 'border border-[#931217]' : 'border border-[#e9d2a4]'} hover:shadow-[6px_6px_26px_-5px_rgba(0,0,0,0.12)] grid grid-cols-2 ${imageClassWrapper ? 'sm:grid-cols-12' : 'sm:grid-cols-10'}  bg-white relative sm:max-h-[262px] sm:min-h-[260px] rounded-[12px] mb-[30px]`}>
                            <div className={`${imageClassWrapper ? imageClassWrapper : 'sm:col-span-3'} relative col-span-2 min-h-[250px] sm:min-h-[auto]`}>
                                <img className={`${imageClass ? imageClass : 'w-full'} rounded-[12px] sm:h-[260px] h-[260px] mx-auto object-position-top-center object-cover`} src={e.profileMainPhoto && e.profileMainPhoto.length ? getImageUrl(e?.profileMainPhoto?.[0], "250x260") : e?.gender === 1 ? male : female} alt={`${e.gender === 1 ? 'male' : 'female'}`} />
                            </div>
                            <div className={`${contentClassWrapper ? contentClassWrapper : 'sm:col-span-7 pl-[22px]'} py-[15px] pr-[20px]  col-span-2 text-center  sm:text-left`}>
                                <div className="sm:grid sm:grid-cols-2 sm:items-center w-full pb-[13px] border-b-[2px] border-[#e9d2a4] mb-[10px]">
                                    <div>
                                        <h4 className="leading-[normal] font-family-Quicksand-Bold text-[20px] text-[#931217] truncate">{e?.firstName}</h4>
                                        <p className="mt-[4px] font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">Profile Created by {e.basicProfileCreatedFor ? dropDown['profileCreatorFor']?.option.find(ele => ele.key === Number(e.basicProfileCreatedFor))?.name  : '-'}</p>
                                    </div>
                                    <div>
                                        <span className="leading-[normal] font-family-Quicksand-Medium text-stroke text-[#931217] text-[18px]">{e.matchScore}/6 <span className="leading-[normal] font-family-Quicksand-Medium text-stroke text-[#931217] text-[14px]">Matched</span></span>
                                        <p className="mt-[4px] font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e?.lastLoginTime ? `Last Seen ${moment(e?.lastLoginTime).format('MMMM D')}` : '-'}</p>
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2">
                                    <div className="mb-[8px]">
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.horoscopeDateOfBirth ? `${calculateAge(e.horoscopeDateOfBirth)} yrs |` : ''} {e.basicHeight ? dropDown['height']?.option.find(ele => ele.key === Number(e?.basicHeight))?.name : ''} | {e.basicMotherTongue ? dropDown['motherTongue']?.option.find(ele => ele.key === Number(e?.basicMotherTongue))?.name : ''}</p>
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.basicReligion ? dropDown['religion']?.option.find(ele => ele.key === Number(e.basicReligion))?.name : ''}, {e.basicCaste ? dropDown['caste']?.option.find(ele => ele.key === Number(e.basicCaste))?.name : ''}</p>
                                    </div>
                                    <div className="mb-[8px]">
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.educationOccupation ? dropDown['occupation']?.option.find(ele => ele.key === Number(e.educationOccupation))?.name : ''}</p>
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.educationAnnualIncome ? dropDown['annualIncome']?.option.find(ele => ele.key === Number(e.educationAnnualIncome))?.name : ''}</p>
                                    </div>
                                    <div className="mb-[8px]">
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.locationCity}, {e.locationState}, {e.locationCountry}</p>
                                    </div>
                                    <div className="mb-[8px]">
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1 truncate">{e.basicMaritalStatus ? dropDown['maritalStatus']?.option.find(ele => ele.key === Number(e.basicMaritalStatus))?.name : ''}</p>
                                    </div>
                                    <div className="sm:col-span-2 line-clamp-2 mb-[17px]">
                                        <p className="font-family-Quicksand-Medium text-[14px] text-stroke-1 leading-[normal] text-stroke-1">{e.aboutMe}</p>
                                    </div>
                                    <div className="sm:col-span-2 flex items-center justify-between">
                                        <div className="flex items-center">
                                            <img className="pr-[10px]" src={e.isProfileUnlock ? unlock : locked} alt="unlock" />
                                            <Link className="relative after:bg-[#ed1c24] after:h-[2px] after:w-[22px] after:absolute after:bottom-[4px] after:ml-[10px] block font-family-Quicksand-SemiBold text-[18px] leading-[normal] text-[#ed1c24]">View Profile</Link>
                                        </div>
                                        <div>
                                            {
                                                e?.isProfileLike === true ?
                                                <img onClick={(event) => handleLike('disLike',ind,e.userId,event)} className="ml-auto" src={like} alt="like" /> 
                                                : <img onClick={(event) => handleLike('like',ind,e.userId,event)} className="ml-auto" src={disLike} alt="dislike" />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {
                totalPages > 1 ? <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} /> : null
            }
        </>
    );
};

export default MyMatchesUserDetail;