import { useEffect, useState } from "react";
import Button from "../../atom/button";
import { useDispatch, useSelector } from 'react-redux';
import { getPartnerAction } from "../../../stores/action/getPartnerAction";
import Spinner from "../../atom/spinner/spinner";
const PartnerPerference = (props) => {
    const dispatch = useDispatch();
    const [isLoading,setIsLoading] = useState(false);
    const dropDown = useSelector(state => state?.getDropDown?.getDropDown);
    const partnerDetail = useSelector(state => state?.getPartner?.getPartner);

    useEffect(() => {
        const fetchPartnerDetail = async () => {
            if (partnerDetail === null && props?.userDetail?.userId) {
                try {
                    setIsLoading(true);
                    await dispatch(getPartnerAction(props?.userDetail?.userId));
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error',error);
                    setIsLoading(false);
                }
            }
        };
        fetchPartnerDetail();
    }, [partnerDetail, dispatch, props?.userDetail?.userId]);
    return(
        <>
            {
                isLoading ? 
                <>
                    <Spinner /> 
                </>
                : 
                <div>
                    <div>
                        <div>
                            <div className="flex items-center justify-between mb-[10px]">
                                <h2 className="text-[#ed1c24] font-family-ZCOOLXiaoWei-Regular text-[30px] md:text-[34px] lg:text-[48px] lg:leading-[57.6px] md:leading-[30px] leading-[normal]">Partner Preference</h2>
                                <Button 
                                    class={'bg-[#b7882c] leading-[normal] tracking-[normal] rounded-[50px] pb-[8px] pt-[5px] px-[30px] text-[#fff] font-family-Quicksand-SemiBold text-[16px]'}
                                    buttonName={'Edit'}
                                    onClick={(event) => props.onClick ? props.onClick(event) : ''}
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-[#f0e1b9] rounded-[12px] ms:pt-[20px] ms:pb-[10px] ms:px-[12px] pt-[20px] pb-[10px] px-[0px] mb-[20px]">
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Age</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.basicAgeMax && partnerDetail?.basicAgeMin ? `${dropDown['age']?.option.find(e => e.key === Number(partnerDetail?.basicAgeMin))?.name} - ${dropDown['age']?.option.find(e => e.key === Number(partnerDetail?.basicAgeMax))?.name} Years of age` : '-'}</p>
                                </div>
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Height</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.basicHeightMax && partnerDetail?.basicHeightMin ? `${dropDown['height']?.option.find(e => e.key === Number(partnerDetail?.basicHeightMin))?.name} - ${dropDown['height']?.option.find(e => e.key === Number(partnerDetail?.basicHeightMax))?.name}` : '-'}</p>
                                </div>
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Religion</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.basicReligion && partnerDetail?.basicReligion.length ? dropDown['religion']?.option.filter(e => partnerDetail?.basicReligion.includes(e.key)).map(ele => ele.name).join(", ") : '-'}</p>
                                </div>
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Caste</h5>
                                    <p className="max-h-[300px] overflow-auto break-all leading-[normal] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.basicCaste && partnerDetail?.basicCaste.length ? dropDown['caste']?.option.filter(e => partnerDetail?.basicCaste.includes(e.key)).map(ele => ele.name).join(", ") : '-'}</p>
                                </div>
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Country</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.locationCountry && partnerDetail?.locationCountry.length ? partnerDetail?.locationCountry.join(", ") : '-'}</p>
                                </div>
                                <div className="px-[15px]">
                                    <h5 className="leading-[normal] font-family-Quicksand-Bold text-[#931217] text-[16px]">Annual Income</h5>
                                    <p className="leading-[normal] word-break-[break-word] text-[18px] font-family-Quicksand-Medium text-[#262626] mb-[15px] mt-[2px]">{partnerDetail?.educationAnnualIncome ? dropDown['annualIncome']?.option.find(e => e.key === Number(partnerDetail?.educationAnnualIncome))?.name : '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default PartnerPerference;